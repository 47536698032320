import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";

const ProductdetailsModal = ({ isOpen, onRequestClose, typeId }) => {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const initialProductState = {
    productname: "",
    productcode: "",
    date: currentDate,
    productstatus: "",
    productTypeId:typeId,
    productType:"",
    consumerTypeId:"",
    hsnCode: "",
    netWeight: "",
    gst: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    pprice: {
      price: 0,
    },
    addPrice: {
      additionalPrice: 0,
    },
    newConPrice: {
      newConPrice: 0,
    },
    productPoints: [
      { points: "", pointsType: "sales" },
      { points: "", pointsType: "additional" },
      { points: "", pointsType: "new connection" }
    ]
  };

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);
  const [productTypes, setProductTypes] = useState([]);
  const [customerType, setCustomerTypes] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState("");

  const url = "/api/product/create";

  useEffect(() => {
    fetchProductTypes();
  }, []);

  const fetchProductTypes = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get("/api/producttype", {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProductTypes(response.data);
      const consumerRes = await axios.get("/api/consumertype", {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCustomerTypes(consumerRes.data);
    } catch (error) {
      handleError(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post(url, newProduct, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log(response.data);
      setLoading(false);
      toast.success("Product created successfully");
      onRequestClose();
      setNewProduct(initialProductState);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 400) {
      toast.error(error.response.data.code);
    } else {
      toast.error("An error occurred while creating the product");
    }
    setLoading(false);
  };

  const rhandle = (e) => {
    const { id, value } = e.target;
    const newdata = { ...newProduct };
    if (id.startsWith("pprice") || id.startsWith("addPrice") || id.startsWith("newConPrice")) {
      const nestedKeys = id.split(".");
      newdata[nestedKeys[0]][nestedKeys[1]] = value;
    } else {
      newdata[id] = value;
        if (typeId !== 1) {
          newdata.addPrice.additionalPrice = "";
          newdata.newConPrice.newConPrice = "";
          newdata.netWeight=0;
        } 
      }

    if (id === "productPoints.sales") {
      newdata.productPoints[0].points = value;
    } else if (id === "productPoints.additional") {
      newdata.productPoints[1].points = value;
    } else if (id === "productPoints.new connection") {
      newdata.productPoints[2].points = value;
    } else {
      newdata[id] = value;
    
        if (typeId !== 1) {
          newdata.netWeight = 0;
        }

    }
    setNewProduct(newdata);
  };

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
       const [metadata, content] = reader.result.split(",");
       resolve({ metadata, content });};
       reader.onerror = function (error) {
       reject(error);};}); }

       const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          try {
            const { metadata, content } = await getBase64FromFile(file);
     
      setNewProduct(prevState => ({
        ...prevState,
        imageMeta: metadata,
        image: content
      }));
          } catch (error) {
            console.error("Error converting file to base64", error);
          }
        }
      };


  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productname">Product Name</label>
                    <input
                      className="form-control"
                      id="productname"
                      type="text"
                      placeholder="Product Name"
                      value={newProduct.productname}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productcode">Product Code</label>
                    <input
                      className="form-control"
                      id="productcode"
                      type="text"
                      placeholder="Product Code"
                      value={newProduct.productcode}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>
                {newProduct.productTypeId === 1 && (
                <div className="row mb-2">
                  <div className="form-floating-sm">
                  <label htmlFor="consumerTypeId">Customer Type</label>
                    <select
                      className="form-select"
                      id="consumerTypeId"
                      // value={newProduct.productTypeId}
                      onChange={(e) => rhandle(e)}
                    >
                      <option value="">Select Customer Type</option>
                      {customerType.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.consumerType}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                )}
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="hsnCode">HSN Code</label>
                    <input
                      className="form-control"
                      id="hsnCode"
                      type="text"
                      placeholder="HSN Code"
                      value={newProduct.hsnCode}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="gst">GST</label>
                    <input
                      className="form-control"
                      id="gst"
                      type="text"
                      placeholder="0"
                      value={newProduct.gst}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>
                <div className="row">
                {newProduct.productTypeId === 1 && (
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="netWeight">Net Weight</label>
                    <input
                      className="form-control"
                      id="netWeight"
                      type="text"
                      placeholder="0"
                      value={newProduct.netWeight}
                      onChange={(e) => rhandle(e)}
                      // disabled={!newProduct.productTypeId || newProduct.productTypeId !== "1"}
                    />
                  </div>
                )}
                </div>
                <div className="row">
                  <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="price">Sales Price</label>
                    <input
                      className="form-control"
                      id="pprice.price"
                      type="number"
                      placeholder="0"
                      // value={newProduct.pprice.price}
                      onChange={(e) => rhandle(e)}
                  
                      />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productPoints.sales">Sales Points</label>
                    <input
                      className="form-control"
                      id="productPoints.sales"
                      type="text"
                      placeholder="0"
                      value={newProduct.productPoints[0].points}
                      onChange={(e) => rhandle(e)}
                     
                    />
                  </div>
                </div>
                </div>
 
                {newProduct.productTypeId === 1 && (
                  <>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="additionalprice">Additional Price</label>
                    <input
                      className="form-control"
                      id="addPrice.additionalPrice"
                      type="number"
                      placeholder="0"
                      // value={newProduct.pprice.price}
                      onChange={(e) => rhandle(e)}
                      // disabled={!newProduct.productTypeId || newProduct.productTypeId !== "1"}         
                                  />
                  </div>
                  </div>

                  <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productPoints.new connection">New Connection Points</label>
                    <input
                      className="form-control"
                      id="productPoints.new connection"
                      type="text"
                      placeholder="0"
                      value={newProduct.productPoints[2].points}
                      onChange={(e) => rhandle(e)}
                      // disabled={!newProduct.productTypeId || selectedProductType !== "1"}
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="newconnection">New Connection Price</label>
                    <input
                      className="form-control"
                      id="newConPrice.newConPrice"
                      type="number"
                      placeholder="0"
                      // value={newProduct.pprice.price}
                      onChange={(e) => rhandle(e)}
                      // disabled={!newProduct.productTypeId ||selectedProductType !== "1"}         
                    />
                  </div>
                </div>
               
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productPoints.additional">Additional Points</label>
                    <input
                      className="form-control"
                      id="productPoints.additional"
                      type="text"
                      placeholder="0"
                      value={newProduct.productPoints[1].points}
                      onChange={(e) => rhandle(e)}
                      // disabled={!newProduct.productTypeId || selectedProductType !== "1"}
                    />
                  </div>
                  </div>
                </div>
                </>
                )}

                <div className="col-md-6">
  <div className="form-floating-sm mb-2">
    <label htmlFor="image">Upload Image</label>

    <input
      className="form-control"
      id="image"
      type="file" 
      onChange={handleImageChange}
      accept="image/jpeg, image/png, application/pdf" 
      />
  </div>
</div>
               
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProductdetailsModal;
