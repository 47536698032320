import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "../components/admindashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import img from "../images/report.png";


function PersonnelReport() {
  const [username, setUsername] = useState("");
  const [documentStatus, setDocumentStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchDocumentStatus = async () => {
    if (!username) {
      toast.error("Please enter a user ID.");
      return;
    }

    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/user/${username}/documents/status`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setDocumentStatus(response.data);
    } catch (error) {
      console.error("Error fetching document status:", error);
      toast.error("Failed to load document status.");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setUsername(value);
  };

  const generatePDF = () => {
    if (!documentStatus) {
      toast.error("No data available to generate PDF.");
      return;
    }
  
    try {
      const doc = new jsPDF();
      const tableColumn = ["Document Type", "Status"];
      const tableRows = [
        ["Adhar Proof", documentStatus.adharProof ? "Completed" : "Not Completed"],
        ["PAN Proof", documentStatus.panProof ? "Completed" : "Not Completed"],
        ["Passport Proof", documentStatus.passportProof ? "Completed" : "Not Completed"],
        ["DL Proof", documentStatus.dlProof ? "Completed" : "Not Completed"],
      ];
  
      const imgData = img;
      const logoWidth = 60;
      const logoHeight = 25;
      const logoX = 10;
      const logoY = 10;
      doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);
  
      // Aligning the text (Vijay Gas Agencies and address) to the right
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
  
      // Add "Vijay Gas Agencies"
      const agencyText = "Vijay Gas Agencies";
      const agencyTextX = pageWidth - doc.getTextWidth(agencyText) - 10; // Right align
      const agencyTextY = logoY + logoHeight / 2;
      doc.text(agencyText, agencyTextX, agencyTextY);
  
      // Add the address below "Vijay Gas Agencies"
      const addressText = "1010/27 Mysore Road, Gundlupete-571111";
      const addressTextX = pageWidth - doc.getTextWidth(addressText) - 10; // Right align
      const addressTextY = agencyTextY + 5;
      doc.text(addressText, addressTextX, addressTextY);
  
      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      const reportTitle = "Personnel Documentation Checklist";
      const reportTitleY = logoY + logoHeight + 20;
      doc.text(20, reportTitleY, reportTitle);
  
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      const userTextY = reportTitleY + 10;
  
      // Add user details
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      const userDetailsTitleY = userTextY + 10;
      doc.text("User Details:", 20, userDetailsTitleY);
  
      const userDetails = [
        [`First Name: ${documentStatus.firstName}`],
        [`Phone: ${documentStatus.phone}`],
        [`Gender: ${documentStatus.gender}`],
        [`Aadhar Number: ${documentStatus.aadhaarnumber}`],
        [`DOB: ${documentStatus.DOB}`],
      ];
  
      doc.autoTable({
        startY: userDetailsTitleY + 10,
        body: userDetails.map(detail => [detail]),
        styles: { cellPadding: 2, fontSize: 10 },
      });
  
      doc.autoTable(tableColumn, tableRows, { startY: doc.lastAutoTable.finalY + 10 });
  
      doc.save("personnel_documentation_checklist.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Failed to generate PDF.");
    }
  };
  


  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <div className="card card-raised mt-4">
        <div className="card-body">
          <br />
          <div className="col-xl-10 mx-xl-auto">
            <div className="card card-raised mb-3">
              <div className="card-body p-4">
                <form onSubmit={(e) => e.preventDefault()} className="text-center">
                  <div className="d-flex justify-content-center align-items-center">
                    <img className="top-bar__logo" src={img} alt="logo" />
                    <div className="ml-3">
                      <h3 className="report-title">Vijay Gas Agencies</h3>
                      <p className="report-address">1010/27 Mysore Road, Gundlupete</p>
                    </div>
                  </div>
                  <br />
                  <div>
                    <label htmlFor="username">User Name:</label>
                    <input
                      id="username"
                      type="text"
                      value={username}
                      onChange={handleInputChange}
                      className="form-control mx-auto"
                      style={{ width: '300px' }}
                    />
                  </div>
                  <br />
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={fetchDocumentStatus}
                    disabled={loading}
                  >
                    Generate Report
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={generatePDF}
                    disabled={!documentStatus}
                  >
                    Download PDF
                  </button>
                </form>
                {loading && <div className="loading"></div>}
                {documentStatus && (
                  <div>
                    <br />
                    <table className="table table-bordered text-center">
                      <thead>
                        <tr>
                          <th>FirstName</th>
                          <th>Phone</th>
                          <th>Gender</th>
                          <th>AadharNumber</th>
                          <th>DOB</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{documentStatus.firstName}</td>
                          <td>{documentStatus.phone}</td>
                          <td>{documentStatus.gender}</td>
                          <td>{documentStatus.aadhaarnumber}</td>
                          <td>{documentStatus.DOB}</td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <table className="table table-bordered text-center">
                      <thead>
                        <tr>
                          <th>Document Type</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Adhar Proof</td>
                          <td>{documentStatus.adharProof ? "Completed" : "Not Completed"}</td>
                        </tr>
                        <tr>
                          <td>PAN Proof</td>
                          <td>{documentStatus.panProof ? "Completed" : "Not Completed"}</td>
                        </tr>
                        <tr>
                          <td>Passport Proof</td>
                          <td>{documentStatus.passportProof ? "Completed" : "Not Completed"}</td>
                        </tr>
                        <tr>
                          <td>DL Proof</td>
                          <td>{documentStatus.dlProof ? "Completed" : "Not Completed"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default PersonnelReport;
