import React, { useEffect, useState, useReducer } from "react";
import axios from "../api/baseurl";
import { BsCheckCircle } from "react-icons/bs";
import { BsXCircle } from "react-icons/bs";
import "./approval.css";
import { toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link,useNavigate } from "react-router-dom";
import "./Spinner.css";
import "./Loader.css";

function Transanctionincomingapproval({ selectedDate }) {
  const [vehicles, setVehicles] = useState([]);
  const [rapprovals, setRapprovals] = useState([]);
  const [status, setStatus] = useState({
    id: "",
    approvalstatus: "",
    orgcode: "",
    quantity: "",
  });
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);

  const [filterVehicleId, setFilterVehicleId] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

const [outgoingCurrentPage, setOutgoingCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items to show per page

  const applyFilters = () => {
    let filteredApprovals = [...rapprovals];
    if (filterVehicleId) {
      filteredApprovals = filteredApprovals.filter(approval => approval.vehicle === filterVehicleId);
    }
    if (filterStatus) {
      filteredApprovals = filteredApprovals.filter(approval => approval.approvalStatus === filterStatus);
    }
    return filteredApprovals;
  };

  const handleFilterChange = () => {
    setOutgoingCurrentPage(1);
  };

  const handleOutgoingPrevPage = () => {
    if (outgoingCurrentPage > 1) {
      setOutgoingCurrentPage(outgoingCurrentPage - 1);
    }
  };

  const handleOutgoingNextPage = () => {
    if (outgoingCurrentPage < Math.ceil(applyFilters().length / itemsPerPage)) {
      setOutgoingCurrentPage(outgoingCurrentPage + 1);
    }
  };

  const filteredApprovals = applyFilters();
  const indexOfLastItem = outgoingCurrentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentApprovals = filteredApprovals.slice(indexOfFirstItem, indexOfLastItem);

   // Calculate page numbers based on total items and items per page
   const pageNumber = [];
   for (let i = 1; i <= Math.ceil(rapprovals.length / itemsPerPage); i++) {
     pageNumber.push(i);
   }
   const getData = async () => {
    try {
      if (!selectedDate) return;
  
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoader(true);
  
      const prof2 = await axios.get(
        `/api/returntransaction/orgcode=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${selectedDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setRapprovals(prof2.data);

      const vehicles = await axios.get(
        `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setVehicles(vehicles.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);  
    }
  };
 

  useEffect(() => {
    getData();
  }, [selectedDate,reducerValue]);

  function trsubmit(e, ids, status) {
    e.preventDefault();
    setLoading(ids);
  
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    const entitiesToUpdate = ids.map(id => ({
      id: Number(id),
      approvalstatus: status,
    }));
  
    axios
      .post(
        "/api/Returntransaction/update-approval-status",
        entitiesToUpdate,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(null);
        forceUpdate();
      })
      .catch((error) => {
        handleError(error);
      });
  }

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

    return (
      <div className="d-flex align-items-right ">
        <div className="col-14  align-items-center mx-xl-auto  ps-xl-10">
        <div className="card card-raised mb-5" style={{ marginTop:'-50px' }}>
            <div className="card-body p-5">
              <div className="card-title">
                Transaction Incoming Approval Details
                {loader && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "10px",
                  }}
                ></div>
              )}{" "} 
              </div>
              <br />


              <div className="mb-4 d-flex align-items-center">
  <label htmlFor="vehicleId" className="me-2">FILTER BY VEHICLE ID:</label>
  <select
    id="vehicleId"
    value={filterVehicleId}
    onChange={(e) => setFilterVehicleId(e.target.value)}
  >
    <option value="">ALL</option>
    {vehicles.map((vehi) => (
              <option key={vehi.id} value={vehi.truckinfo}>
                {vehi.truckinfo}
              </option>
            ))}
  </select>

  <label htmlFor="status" className="ms-6 me-2">FILTER BY STATUS:</label>
  <select
    id="status"
    value={filterStatus}
    onChange={(e) => setFilterStatus(e.target.value)}
  >
    <option value="">ALL</option>
    <option value="APPROVED">APPROVED</option>
    <option value="REQUESTED">REQUESTED</option>
    <option value="REJECTED">REJECTED</option>
  </select>
</div>
              <table className="table table-bordered ">
                <thead>
                  <tr 
                    style={{
                      fontSize: "17px",
                      backgroundColor: " #6200ea",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Vehicle Id</th>
                    <th>Product Name</th>
                    <th>Sales</th>
                    <th>New Connection</th>
                    <th>Additional</th>
                    <th>Onlinepay</th>
                    <th>Exempted</th>
                    <th>Returned Product</th>
                    <th>Transfer</th>
                    <th>Status</th>
                   
                  </tr>
                </thead>
                <tbody>
                {currentApprovals.length === 0 ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      NO DATA AVAILABLE
                    </td>
                  </tr>
                ) : (
                  currentApprovals.map((item, index) => (
    <React.Fragment key={index}>
        {/* Render vehicle and status only once for each group */}
        <tr>
          <td rowSpan={item.returnTrans.length}>{item.vehicle}</td>
         
          {/* Render first row of returnTrans */}
          <td>{item.returnTrans[0].productname}</td>
          <td>{item.returnTrans[0].quantity}</td>
          <td>{item.returnTrans[0].neworg}</td>
          <td>{item.returnTrans[0].additional}</td>
          <td>{item.returnTrans[0].onlinepay}</td>
          <td>{item.returnTrans[0].exempted}</td>
          <td>{item.returnTrans[0].returnedproduct}</td>
          <td>{item.returnTrans[0].transfer}</td>
          {item.approvalStatus === "REQUESTED" ? (
          <td rowSpan={item.returnTrans.length} id="icon" value={item.approvalstatus}>
         
            <React.Fragment>
              &nbsp;{item.approvalStatus} &nbsp;
              {loading && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "10px",
                  }}
                ></div>
              )}{" "}
              {/* Add your loader here if needed */}
              <BsCheckCircle
                className="icon"
                id="item.approvalstatus"
                style={{ backgroundColor: "green" }}
                value="APPROVED"
                onClick={(e) =>
                  trsubmit(
                    e,
                    item.returnTrans.map((tin) => tin.id),
                    "APPROVED"
                  )
                }
              />
              &ensp;
              <BsXCircle
                className="icon"
                value="REJECTED"
                style={{ backgroundColor: "red" }}
                id="item.approvalstatus"
                onClick={(e) =>
                  trsubmit(
                    e,
                    item.returnTrans.map((tin) => tin.id),
                    "REJECTED"
                  )
                }
              />
            </React.Fragment>
            </td>
          ) : (
            <td rowSpan={item.returnTrans.length}>
            <span>{item.approvalStatus}</span>
            </td>
          )}
        
      </tr>
        {/* Render subsequent rows of returnTrans */}
        {item.returnTrans.slice(1).map((trans, idx) => (
          <tr key={`${index}-${idx}`}>
            <td>{trans.productname}</td>
            <td>{trans.quantity}</td>
            <td>{trans.neworg}</td>
            <td>{trans.additional}</td>
            <td>{trans.onlinepay}</td>
            <td>{trans.exempted}</td>
            <td>{trans.returnedproduct}</td>
            <td>{trans.transfer}</td>
          </tr>
        ))}
      </React.Fragment>
    )))}
  </tbody>
</table>
                {/* Pagination controls */}
          <nav>
            <ul className="pagination justify-content-center">
              {pageNumber.map((number) => (
                <li
                  key={number}
                  className={`page-item ${number === outgoingCurrentPage ? "active" : ""}`}
                >
                 
                </li>
              ))}
            </ul>
          
  <ul className="pagination justify-content-center">
    <li className={`page-item ${outgoingCurrentPage === 1 ? "disabled" : ""}`}>
      <button className="page-link" onClick={handleOutgoingPrevPage }>
        Previous
      </button>
    </li>
    {pageNumber.map((number) => (
      <li
        key={number}
        className={`page-item ${number === outgoingCurrentPage ? "active" : ""}`}
      >
        <button className="page-link" onClick={() => setOutgoingCurrentPage(number)}>
          {number}
        </button>
      </li>
    ))}
    <li className={`page-item ${outgoingCurrentPage === pageNumber.length ? "disabled" : ""}`}>
      <button className="page-link" onClick={handleOutgoingNextPage }>
        Next
      </button>
    </li>
  </ul>
</nav>
            </div>
          </div>
        </div>
      </div>
 
  );
}

export default Transanctionincomingapproval;
