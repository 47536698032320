import Pro from "./pro";
import Dashboard from "./admindashboard";
import Sidebar from "./adminsidebar";
function AdminProfile() {
  return (
    <div>
      <Dashboard></Dashboard>

      <Pro></Pro>
    </div>
  );
}
export default AdminProfile;
