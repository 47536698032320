import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { useNavigate } from "react-router-dom";
function Productquantity() {
  const [pquantity, setPquantity] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios.get("/api/proiductquantity", {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(trans);

    setPquantity(trans.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="col-12  mx-xl-auto ps-xl-6 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Product Details</div>
            <br />

            <table className="table table-bordered ">
              <thead>
                <tr style={{ fontSize: "15px" }}>
                  <th>ID </th>
                  <th>Productid</th>
                  <th>Orgcode</th>
                  <th>Loadinquantity</th>
                  <th>Loadinstatus</th>
                  <th>Loadoutquantity</th>
                  <th>Loadoutstatus</th>
                  <th>Transactioninquantity</th>
                  <th>Transactioninstatus</th>
                  <th>Transactionoutquantity</th>
                  <th>Transactionoutstatus</th>
                  <th>Exempted</th>
                  <th>Online</th>
                  <th>Returned</th>
                </tr>
              </thead>
              <tbody>
                {pquantity.map((pquantity) => {
                  return (
                    <tr key={pquantity.id}>
                      <td>{pquantity.id}</td>
                      <td>{pquantity.productid}</td>
                      <td>{pquantity.orgcode}</td>
                      <td>{pquantity.loadinquantity}</td>
                      <td>{pquantity.loadinstatus}</td>
                      <td>{pquantity.loadoutquantity}</td>
                      <td>{pquantity.loadoutstatus}</td>
                      <td>{pquantity.transactioninquantity}</td>
                      <td>{pquantity.transactioninstatus}</td>
                      <td>{pquantity.transactionoutquantity}</td>
                      <td>{pquantity.transactionoutstatus}</td>
                      <td>{pquantity.exempted}</td>
                      <td>{pquantity.online}</td>
                      <td>{pquantity.returned}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Productquantity;
