
import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  // Import Modal and Button from react-bootstrap

import axios from "../api/baseurl";

const VehicleHistoryModal = ({ onClose, initialPriceHistory, productId }) => {
  const [localPriceHistory, setLocalPriceHistory] = useState([]);
  
    useEffect(() => {
      // const fetchData = async () => {
      //   try {
      //     const token = JSON.parse(localStorage.getItem("data")).token;
      //     const response = await axios.get(`/api/ProductPrice/productid=${productId}`, {
      //       headers: { Authorization: `Bearer ${token}` },
      //     });
      //     setLocalPriceHistory(response.data);
         
      //   } catch (error) {
      //     console.error("Error fetching price history:", error);
      //   }
      // };
  
      // fetchData();
  }, []);
  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delivery Agent Mapped History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table">
          <thead>
            <tr>
            <th>Name</th>

              <th>Delivery Agent</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(initialPriceHistory) && initialPriceHistory.map((entry) => (
              <tr key={entry.id}>
                <td>{entry.name}</td>
                <td>{entry.deliveryagentid}</td>
                <td>{entry.date}</td>

              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}


export default VehicleHistoryModal;
