import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";

import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
const Overheadmodal = ({ isOpen, onRequestClose }) => {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;      
  const initialProductState = {
    productname: "",
    userrole: "",
    date: "",
    title: "",
    description: "",
    createddate: currentDate,
    duedate: "",
    givenQty:"",
    takenQty:"",
    type: "Stock",
    amount:"",
    vehicleid:"",
    consumerNumber:"",
    consumerName:"",
    phoneNumber:"",
    completed:false,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  };
  const [vehicle, setVehicle] = useState([]);

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [type, setType] = useState("Stock");
  const url = "/api/overhead/create";
  const navigate = useNavigate();

    const fetchData = async () => {
      try {
       
          const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
          const token = JSON.parse(localStorage.getItem("data")).token;
          
          const vehicles = await axios.get(
            `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
      
          console.log(vehicles);
      
          setVehicle(vehicles.data);
  
          // Fetch product data
          const productResponse = await axios.get(
            `/api/product/productstatus=true/orgcode=${orgCode}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          console.log("Response from Product API:", productResponse.data);
          setProducts(productResponse.data);
        
      } catch (error) {
        handleError(error);
      }
    };
  
    useEffect(() => {
      fetchData();
    }, []);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post(url, newProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setLoading(false);
      toast.success("Over Head Updated successfully");
      fetchData();
      onRequestClose(); 
      setNewProduct(initialProductState);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }
  function rhandle(e) {
    const { id, value } = e.target;
    setNewProduct((prevState) => ({ ...prevState, [id]: value }));
  }

   function handleTypeChange(e) {
    const { value } = e.target;
    setType(value);
    setNewProduct((prevState) => ({ ...prevState, type: value }));
  }

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Overhead </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="vehicleid">Vehicle Info</label>
                    <select
                     className="form-select"
                     id="vehicleid"
                      value={newProduct.vehicleid}
                      onChange={ rhandle}
                      >
                        <option>select vehicle</option>
                        {vehicle.map((vehicle) => {
                          return (
                            <option value={vehicle.truckinfo}>
                              {vehicle.truckinfo}
                            </option>
                          );
                        })}
                        </select>
                  
                  </div>
                </div>
            <div className="row mb-2">
  <div className="form-floating-sm">
    <label htmlFor="productname">Product Name</label>
    <select
      className="form-select"
      id="productname"
      value={newProduct.productname}
      onChange={ rhandle}
    >
      <option value="">Select Product Name</option>
      {products.map((product, index) => (
        <option key={index} value={product.productname}>
          {product.productname}
        </option>
      ))}
    </select>
  </div>
</div>

<div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="consumernumber">Consumer Number</label>
                        <input
                          className="form-control"
                          id="consumerNumber"
                          type="text"
                          placeholder="Consumer Number"
                          value={newProduct.consumerNumber}
                          onChange={rhandle}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="consumername">Consumer Name</label>
                        <input
                          className="form-control"
                          id="consumerName"
                          type="text"
                          placeholder="Consumer Name"
                          value={newProduct.consumerName}
                          onChange={rhandle}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="phonenumber">Phone Number</label>
                        <input
                          className="form-control"
                          id="phoneNumber"
                          type="text"
                          placeholder="Phone Number"
                          value={newProduct.phoneNumber}
                          onChange={rhandle}
                        />
                      </div>
                    </div>
<div className="row mb-2 mt-3">
                  <div className="form-check form-check-inline ms-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="stock"
                      value="Stock"
                      checked={type === "Stock"}
                      onChange={handleTypeChange}
                    />
                    <label className="form-check-label" htmlFor="stock">
                      Stock
                    </label>
                 
                  <div className="form-check form-check-inline ms-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="finance"
                      value="Finance"
                      checked={type === "Finance"}
                      onChange={handleTypeChange}
                    />
                    <label className="form-check-label" htmlFor="finance">
                      Finance
                    </label>
                  </div>
                </div>
                </div>

                {type === "Stock" && (
                  <>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="givenQty">Given Quantity</label>
                        <input
                          className="form-control"
                          id="givenQty"
                          type="text"
                          placeholder="Given Quantity"
                          value={newProduct.givenQty}
                          onChange={rhandle}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="takenQty">Taken Quantity</label>
                        <input
                          className="form-control"
                          id="takenQty"
                          type="text"
                          placeholder="Taken Quantity"
                          value={newProduct.takenQty}
                          onChange={rhandle}
                        />
                      </div>
                    </div>
                  </>
                )}

                {type === "Finance" && (
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="amount">Amount</label>
                      <input
                        className="form-control"
                        id="amount"
                        type="text"
                        placeholder="0"
                        value={newProduct.amount}
                        onChange={rhandle}
                      />
                    </div>
                  </div>
                )}

                
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="createddate">Created Date</label>
                    <input
                      className="form-control"
                      id="createddate"
                      type="date"
                      placeholder="createddate"
                      value={newProduct.createddate}
                      onChange={(e) => rhandle(e)}
                    />
                    
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="duedate">Due Date</label>
                    <input
                      className="form-control"
                      id="duedate"
                      type="date"
                      placeholder="duedate"
                      value={newProduct.duedate}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="title">Title</label>
                    <input
                      className="form-control"
                      id="title"
                      type="text"
                      placeholder="title"
                      value={newProduct.title}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>
                <div className="row">
  <div className="form-floating-sm mb-2">
    <label htmlFor="description">Description</label>
    <textarea
      className="form-control"
      id="description"
      placeholder="description"
      value={newProduct.description}
      onChange={(e) => rhandle(e)}
      rows="2" // You can adjust the number of rows as needed
    />
  </div>
</div>

                
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}

                />
                 &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  

              </form>
            </div>
          </div>
        </div>

      </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default Overheadmodal;