import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import "./Spinner.css";
import SalesareadetailsModal from "./Createsalesarea";
import { useParams } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import SalesareaeditModalPopup from "./Salesareaedit";
function Salesareacomponent() {
  const { Id } = useParams();
  console.log(Id);

  const [isModalOpen, setIsModalOpen] = useState(false);
 
; 
  const [product, setProduct] = useState([]);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);

  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
 

  

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/salesarea/territorynameid=${Id}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct([...response.data]);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };
  useEffect(() => {
    getData();
  }, [isModalOpen]);
 
 

  
 
 
  const handleProduct = () => {
    setIsModalOpen(true);


  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    window.location.reload();
  };
  
  const onClickhandler = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(id);
    try{
      await axios({
      url: "/api/salesarea",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
     
        orgid: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("deleted successfully");
    setLoader(false); 
    getData();
  } catch (error) {
    if (error.response && error.response.status === 500) {
        const errorMessage = error.response.data.message;
        console.error("Error deleting territory:", errorMessage);
        toast.error(errorMessage);
    } else {
        console.error("Error deleting territory:", error);
    }
    setLoader(false);
}
};
const handleEdit = async (id) => {
  setIsProductEditModalOpen(true);

  setSelectedProductId(id);
 
};

const closeProductEditModal = () => {
  setIsProductEditModalOpen(false);
  getData();

};

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      
      {loader && <div className="loading"></div>} 
      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Sales Area Details
             
              <button className="btn btn-primary"onClick={handleProduct}>
               ADD
            </button>
            </div>
  
            <br />
            
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >

                  <th>Sales Area</th>
                  <th>Actions</th>


                </tr>
              </thead>
              <tbody>
              <tr>
            {/* <td>Selected Territory ID:{Id}</td> */}
          </tr>
              {product.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (

                product.map((product) => {
                  return (
                    <tr key={product.id}>
                     <td>{product.salesarea}</td>
                     <td> 
                     <button
                      onClick={() => handleEdit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              product.id,
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>
                   </td>
                     
                     
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
            <div>
           
           <SalesareadetailsModal isOpen={isModalOpen} onRequestClose={handleCloseModal}territoryId={Id}  />
           {isProductEditModalOpen && (
              < SalesareaeditModalPopup
                isOpen={isProductEditModalOpen}
                onRequestClose={closeProductEditModal}
                Id={selectedProductId}
              />
            )}
      </div>


          </div>
        </div>{" "}
      </div>
    </div>   
  );
}

export default Salesareacomponent;
