import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "../api/baseurl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Spinner.css";
function Addmember() {
  const navigate = useNavigate();
 
  const url = "/api/register";
  const [admindata, setAdata] = useState([
    {
      username: "",
      password: "",
      passwordConfirm: "",
      role: "",
      organization: {
        name: "",
        orgcode: "",
        gstin: "",
        pan: "",
        contact: "",
        userid: "",
      },
    },
  ]);
  const [loading, setLoading] = useState(false);
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(url, {
        firstname: admindata.firstname,
        lastname: admindata.lastname,
        orgname: admindata.orgname,
 
        contactnum: admindata.phonenumber,
        country: admindata.country,
 
        username: admindata.username,
        password: admindata.password,
        passwordConfirm: admindata.passwordConfirm,
        role: admindata.role,
        organization: {
          name: admindata.name,
          orgcode: admindata.orgcode,
          gstin: admindata.gstin,
          pan: admindata.pan,
          contact: admindata.contact,
          userid: admindata.userid,
        },
      })
      .then((res) => {
        console.log(res.data.roleRequested);
        setLoading(false);
        toast("otp send to your mail");
      });
  }
  function rhandle(e) {
    const newdata = { ...admindata };
    newdata[e.target.id] = e.target.value;
    setAdata(newdata);
  }
  return (
    <div className="bg-primary">
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xxl-5 col-xl-6">
                  <div className="card card-raised shadow-10 mt-5 mt-xl-10 mb-5">
                    <div className="card-body p-5">
                      <div className="text-center">
                        <Link to="/users/admindashboard">
                          <h1 className="display-5 mb-0">Add Members</h1>
                        </Link>
                      </div>
 
                      <form onSubmit={(e) => rsubmit(e)}>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="username">username</label>
                            <input
                              className="form-control"
                              id="username"
                              type="text"
                              placeholder="username"
                              value={admindata.username}
                              onChange={(e) => rhandle(e)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="password">password</label>
                            <input
                              className="form-control"
                              id="password"
                              type="password"
                              placeholder="password"
                              value={admindata.password}
                              onChange={(e) => rhandle(e)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="confirmpassword">
                              confirm password
                            </label>
                            <input
                              className="form-control"
                              id="passwordConfirm"
                              type="password"
                              placeholder="password"
                              value={admindata.passwordConfirm}
                              onChange={(e) => rhandle(e)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="role">Role</label>
                            <select id="role" onChange={(e) => rhandle(e)}>
                              <option
                                value="ROLE_MANAGER"
                                disabled
                                selected="true"
                              >
                                Manager
                              </option>
                              <option value="ROLE_ADMIN">Admin</option>
                              <option value="ROLE_DELIVERY_AGENT">
                                DeliveryAgent
                              </option>
                            </select>
                          </div>
                        </div>
 
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="organisationname">
                              organization name
                            </label>
                            <input
                              className="form-control"
                              id="name"
                              type="text"
                              placeholder="organization name"
                              value={admindata.name}
                              onChange={(e) => rhandle(e)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="organisationcode">
                              organisation code
                            </label>
                            <input
                              className="form-control"
                              id="orgcode"
                              type="text"
                              placeholder="organization code"
                              value={admindata.orgcode}
                              onChange={(e) => rhandle(e)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="gstin">gstin</label>
                            <input
                              className="form-control"
                              id="gstin"
                              type="text"
                              placeholder="gstin"
                              value={admindata.gstin}
                              onChange={(e) => rhandle(e)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="pan">pan</label>
                            <input
                              className="form-control"
                              id="pan"
                              type="text"
                              placeholder="pan"
                              value={admindata.pan}
                              onChange={(e) => rhandle(e)}
                            />
                          </div>
                        </div>
 
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="contact">contact</label>
                            <input
                              className="form-control"
                              id="contact"
                              type="text"
                              placeholder="contact"
                              value={admindata.contact}
                              onChange={(e) => rhandle(e)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="userid">userid</label>
                            <input
                              className="form-control"
                              id="userid"
                              type="text"
                              placeholder="userid"
                              value={admindata.userid}
                              onChange={(e) => rhandle(e)}
                            />
                          </div>
                        </div>
 
                        <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                          <Link
                            className="small fw-500 text-decoration-none"
                            to="/signin"
                          >
                            Sign in instead
                          </Link>
 
                          <input
                            type="submit"
                            value="create account"
                            className="btn btn-primary"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
 
        <div id="layoutAuthentication_footer">
          <footer className="p-4">
            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between small">
              <div className="me-sm-3 mb-2 mb-sm-0">
                <div className="fw-500 text-white">
                  Copyright &copy; Your Website 2021
                </div>
              </div>
              <div className="ms-sm-3">
                <a className="fw-500 text-decoration-none link-white" href="#!">
                  Privacy
                </a>
                <a
                  className="fw-500 text-decoration-none link-white mx-4"
                  href="#!"
                >
                  Terms
                </a>
                <a className="fw-500 text-decoration-none link-white" href="#!">
                  Help
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}
export default Addmember;
 