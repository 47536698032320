import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
 
function FamilyeditModalPopup({ isOpen, onRequestClose, Id }) {
  const { userid } = useParams();

  const [uproduct1, setUproduct1] = useState({
    name: "",
    relationship: "",
    phoneNumber: "",
    gender: "",
    dob: "",
    maritalstatus:"",
    userid:userid,
   
 orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
 
  const [loading, setLoading] = useState(false);
  const[gender,setGender]=useState([]);
  const[marital,setMarital]=useState([]);
const[relationship,setRelationship]=useState([]);
  const navigate = useNavigate();
 
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const response = await axios.get(`/api/familydetails/${Id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUproduct1(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {
    if (Id) {
      getData();
    }
  }, [Id]);
  useEffect(() => {
    fetchProductTypes();
  }, []);
 
  const fetchProductTypes = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get("/api/gender", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGender(response.data);
      const response1 = await axios.get("/api/relationship", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRelationship(response1.data);
      const Response = await axios.get("/api/maritalstatus", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMarital(Response.data);
    } catch (error) {
    }
  };
 
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .post(
        "/api/familydetails/update",
        uproduct1,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Family Details updated successfully");
        onRequestClose();
        getData();
 
      })
      .catch((error) => {
        console.log(error);
      });
  }
 
  const rhandle = (event, field) => {
    const actualValue = event.target.value;
    setUproduct1({
      ...uproduct1,
      [field]: actualValue,
    });
  };
 
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update Family Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4">
            <form onSubmit={(e) => rsubmit(e)}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="name"> Name</label>
                  <input
                    className="form-control"
                    id="name"
                    type="text"
                    placeholder="name"
                    value={uproduct1.name}
                    onChange={(e) => rhandle(e, "name")}
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="dob">DOB</label>
                  <input
                    className="form-control"
                    id="dob"
                    type="date"
                    placeholder="dob"
                    value={uproduct1.dob}
                    onChange={(e) => rhandle(e, "dob")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    className="form-control"
                    id="phoneNumber"
                    type="text"
                    placeholder="phoneNumber"
                    value={uproduct1.phoneNumber}
                    onChange={(e) => rhandle(e, "phoneNumber")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="gender">Gender</label>
                    <select
                      className="form-control"
                      id="gender"
                      value={uproduct1.gender}
                      onChange={(e) => rhandle(e, "gender")}
                      >
                      <option value="">Select Gender</option>
                      {gender.map((type) => (
                        <option key={type.id} value={type.gender}>
                          {type.gender}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="relationship">Relationship Status</label>
                    <select
                      className="form-control"
                      id="relationship"
                      value={uproduct1.relationship}
                      onChange={(e) => rhandle(e, "relationship")}
                      >
                      <option value="">Select relationship</option>
                      {relationship.map((type) => (
                        <option key={type.id} value={type.relationshipStatus}>
                          {type.relationshipStatus}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="maritalstatus">Marital Status</label>
                    <select
                      className="form-control"
                      id="maritalstatus"
                      value={uproduct1.maritalstatus}
                      onChange={(e) => rhandle(e, "maritalstatus")}
                      >
                      <option value="">Select </option>
                      {marital.map((type) => (
                        <option key={type.id} value={type.maritalstatus}>
                          {type.maritalstatus}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                  <br />
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                
            </form>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
export default FamilyeditModalPopup;