import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
 
function Overheadeditmodal({ isOpen, onRequestClose,id }) {

  const [uproduct1, setUproduct1] = useState({
    productname: "",
    userrole: "",
    date: "",
    title: "",
    description: "",
    createddate: "",
    duedate: "",
    givenQty:"",
    takenQty:"",
    type:"",
    amount:0,
    consumerName:"",
    consumerNumber:"",
    phoneNumber:"",
    completed:false,
 orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  
 
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState([]);
  const [type, setType] = useState("");

  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
 
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const response = await axios.get(`/api/overhead/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUproduct1(response.data);
      setType(response.data.type);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
    setUproduct1({
      ...uproduct1,
      type: event.target.value,
      givenQty: "",
      takenQty: "",
      amount: 0
    });
  };
 
  useEffect(() => {
    
      getData();
    
  }, [id]);
 
  const fetchData = async () => {
    try {
     
        const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
        const token = JSON.parse(localStorage.getItem("data")).token;
        
        const vehicles = await axios.get(
          `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
    
        console.log(vehicles);
    
        setVehicle(vehicles.data);
      

        // Fetch product data
        const productResponse = await axios.get(
          `/api/product/productstatus=true/orgcode=${orgCode}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log("Response from Product API:", productResponse.data);
        setProducts(productResponse.data);
      
    } catch (error) {
     handleError(error);
    }
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .post(
        "/api/overhead/update",
        uproduct1,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("over head updated successfully");
        onRequestClose();
        getData();
 
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  }
 
  const rhandle = (event, field) => {
    const actualValue = event.target.value;

  
      setUproduct1({
        ...uproduct1,
        [field]: actualValue,
      });
    
  };
 
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update Overhead</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4">
            <form onSubmit={(e) => rsubmit(e)}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="vehicleid">Vehicle Info</label>
                    <select
                     className="form-select"
                     id="vehicleid"
                      value={uproduct1.vehicleid}
                      onChange={(e) => rhandle(e, "vehicleid")}
                      >
                        <option>select vehicle</option>
                        {vehicle.map((vehicle) => {
                          return (
                            <option value={vehicle.truckinfo}>
                              {vehicle.truckinfo}
                            </option>
                          );
                        })}
                        </select>
                  
                  </div>
                </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="productname">Product Name</label>
                 <select
                    className="form-control"
                    id="productname"
                    type="text"
                    placeholder="productname"
                    value={uproduct1.productname}
                    onChange={(e) => rhandle(e, "productname")}
                  >
                     <option value="">Select Product Name</option>
      {products.map((product, index) => (
        <option key={index} value={product.productname}>
          {product.productname}
        </option>
      ))}
    </select>
                  </div>
              </div>
              <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="consumernumber">Consumer Number</label>
                        <input
                          className="form-control"
                          id="consumerNumber"
                          type="text"
                          placeholder="Consumer Number"
                          value={uproduct1.consumerNumber}
                          onChange={(e) => rhandle(e, "consumerNumber")}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="consumername">Consumer Name</label>
                        <input
                          className="form-control"
                          id="consumerName"
                          type="text"
                          placeholder="Consumer Name"
                          value={uproduct1.consumerName}
                          onChange={(e) => rhandle(e, "consumerName")}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="phonenumber">Phone Number</label>
                        <input
                          className="form-control"
                          id="phoneNumber"
                          type="text"
                          placeholder="Phone Number"
                          value={uproduct1.phoneNumber}
                          onChange={(e) => rhandle(e, "phoneNumber")}
                        />
                      </div>
                    </div>
<div className="row mb-2 mt-3">
                  <div className="form-check form-check-inline ms-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="stock"
                      value="Stock"
                      checked={type === "Stock"}
                      onChange={handleTypeChange}
                    />
                    <label className="form-check-label" htmlFor="stock">
                      Stock
                    </label>
                 
                  <div className="form-check form-check-inline ms-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="finance"
                      value="Finance"
                      checked={type === "Finance"}
                      onChange={handleTypeChange}
                    />
                    <label className="form-check-label" htmlFor="finance">
                      Finance
                    </label>
                  </div>
                </div>
                </div>

                {type === "Stock" && (
                  <>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="givenQty">Given Quantity</label>
                        <input
                          className="form-control"
                          id="givenQty"
                          type="text"
                          placeholder="Given Quantity"
                          value={uproduct1.givenQty}
                          onChange={(e) => rhandle(e, "givenQty")}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="takenQty">Taken Quantity</label>
                        <input
                          className="form-control"
                          id="takenQty"
                          type="text"
                          placeholder="Taken Quantity"
                          value={uproduct1.takenQty}
                          onChange={(e) => rhandle(e, "takenQty")}
                        />
                      </div>
                    </div>
                  </>
                )}

                {type === "Finance" && (
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="amount">Amount</label>
                      <input
                        className="form-control"
                        id="amount"
                        type="text"
                        placeholder="0"
                        value={uproduct1.amount}
                        onChange={(e) => rhandle(e, "amount")}
                      />
                    </div>
                  </div>
                )}

                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="createddate">Created Date</label>
                    <input
                      className="form-control"
                      id="createddate"
                      type="date"
                      placeholder="createddate"
                      value={uproduct1.createddate}
                    onChange={(e) => rhandle(e, "createddate")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="duedate">Due Date</label>
                  <input
                      className="form-control"
                      id="duedate"
                      type="date"
                      placeholder="duedate"
                      value={uproduct1.duedate}
                    onChange={(e) => rhandle(e, "duedate")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="title">Title</label>
                    <input
                      className="form-control"
                      id="title"
                      type="text"
                      placeholder="title"
                      value={uproduct1.title}
                    onChange={(e) => rhandle(e, "title")}
                  />
                  </div>
              </div>
             <div className="row">
            <div className="form-floating-sm mb-2">
              <label htmlFor="description">Description</label>
              <textarea
                className="form-control"
                id="description"
                placeholder="description"
                value={uproduct1.description}
                onChange={(e) => rhandle(e)}
                rows="2" // You can adjust the number of rows as needed
              />
            </div>
          </div>
              
                  <br />
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                
            </form>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
export default Overheadeditmodal;