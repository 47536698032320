import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";

const CreateService = ({ isOpen, onRequestClose }) => {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const initialProductState = {
    serviceName: "",
    points: "",
    date: currentDate,
    serviceCost: {
      cost: 0,
    },
    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  };

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);

  const url = "/api/product-services/create";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post(url, newProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setLoading(false);
      toast.success("Service created successfully");
      onRequestClose();
      setNewProduct(initialProductState);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 400) {
      toast.error(error.response.data.code);
    } else {
      toast.error("An error occurred while creating the product");
    }
    setLoading(false);
  };

  const rhandle = (e) => {
    const { id, value } = e.target;
    if (id.includes(".")) {
      const [outer, inner] = id.split(".");
      setNewProduct((prevState) => ({
        ...prevState,
        [outer]: {
          ...prevState[outer],
          [inner]: value,
        },
      }));
    } else {
      setNewProduct((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Service</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="serviceName">Service Name</label>
                    <input
                      className="form-control"
                      id="serviceName"
                      type="text"
                      placeholder="Service Name"
                      value={newProduct.serviceName}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="points">Points</label>
                    <input
                      className="form-control"
                      id="points"
                      type="text"
                      placeholder="Points"
                      value={newProduct.points}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="date">Date</label>
                    <input
                      className="form-control"
                      id="date"
                      type="date"
                      value={newProduct.date}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="serviceCost.cost">Service Cost</label>
                    <input
                      className="form-control"
                      id="serviceCost.cost"
                      type="text"
                      placeholder="Service Cost"
                      value={newProduct.serviceCost.cost}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateService;
