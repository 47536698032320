import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import { toast } from "react-toastify";

 
 
function OrganizationSettings() {

    const[loading ,setLoading]=useState(false);
  
    const [orgProfile, setOrgProfile] = useState({
        name: "",
        orgcode: "",
        gstin: "",
        pan: "",
        tan: "",
        contact: "",
        userid: "",
        logo: "",
        imageMeta: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        pincode: "",
        state: "",
        officeLatitude:"",
        officeLongitude:"",
        godownLatitude:"",
        godownLongitude:"",
  
    });
    useEffect(() => {
      const fetchProfile = async () => {
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
          const response = await axios.get(`/api/organizationprofiles/Orgcode=${orgcode}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const userProfileDetails = response.data;
          setOrgProfile(userProfileDetails);
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      };
  
      fetchProfile();
    }, []);
  
    function getBase64FromFile(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
         const [metadata, content] = reader.result.split(",");
         resolve({ metadata, content });};
         reader.onerror = function (error) {
         reject(error);};}); }
  
   const handleImageChange=async(event)=>{
     const convertedImage= await getBase64FromFile(event.target.files[0]);
     const imageObject = {...orgProfile,
           imageMeta: convertedImage.metadata,
           logo:convertedImage.content,};
           setOrgProfile(imageObject);
   }
  
  
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
  
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        await axios.post(`/api/organizationprofiles/update`, orgProfile, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Organization Profile Updated Successfully");
      } catch (error) {
        console.error("Error updating profile:", error);
        toast.error("Error: Failed to update profile");
      } finally {
        setLoading(false);
      }
    };
  
  
    
  const handleChange = (event, field) => {
      let actualValue = event.target.value;
  
      setOrgProfile({ ...orgProfile, [field]: actualValue });
      
    };
  
  
  
  return (
    <div>
    <div>
      <AdminDashboard />
    </div>
    <br />
    <br />
    <br />
    <br />
    {loading && <div className="loading"></div>} 
    <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
              <div className="card-title">Organization Profile Details</div>
              <div className="d-flex flex-column align-items-center">
              <div className="mb-4">
                {orgProfile.logo ? (
                  <img
                  className="img-fluid mb-1"
                  id="image"
                  src={`${orgProfile?.imageMeta},${orgProfile?.logo}`}
                  alt="User Profile"
                  style={{
                    width: "150px",
                    height: "150px",
                    display: "block",
                    background: "#ccc",
                    marginTop: "20px",
                  }}
                />
              ) : (
                <div
                  className="img-fluid mb-1"
                  id="image"
                  style={{
                    width: "150px",
                    height: "150px",
                    display: "block",
                    background: "#ccc",
                    marginTop: "20px",
                  }}
                ></div>
              )}
              <input
                className="col-7"
                type="file"
                id="image"
                onChange={(event) => {
                  console.log(event.target.files[0]);
                  handleImageChange(event);
                }}
                accept="image/*"
              />
            </div>
              <form onSubmit={handleSubmit} className="w-100">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organizationname">Organization Name:</label>
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        value={orgProfile.name}
                        onChange={(e) => handleChange(e, "name")}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organization">Organization Code:</label>
                      <input
                        className="form-control"
                        id="orgcode"
                        type="text"
                        value={orgProfile.orgcode}
                        onChange={(e) => handleChange(e, "orgcode")}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organization">Contact Number:</label>
                      <input
                        className="form-control"
                        id="contact"
                        type="text"
                        value={orgProfile.contact}
                        onChange={(e) => handleChange(e, "contact")}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organization">Email ID:</label>
                      <input
                        className="form-control"
                        id="username"
                        type="email"
                        value={orgProfile.username}
                        onChange={(e) => handleChange(e, "username")}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="territoryname">Territory Name:</label>
                      <input
                        className="form-control"
                        id="territoryname"
                        type="text"
                        value={orgProfile.territoryname}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="salesarea">Sales Area:</label>
                      <input
                        className="form-control"
                        id="salesarea"
                        type="text"
                        value={orgProfile.salesarea}
                        readOnly
                      />
                    </div>
                  </div>
                  </div>

                  <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">GSTIN:</label>
                      <input
                        className="form-control"
                        id="gstin"
                        type="text"
                        value={orgProfile.gstin}
                        onChange={(e) => handleChange(e, "gstin")}
                      />
                    </div>
                  </div>
                 
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="state">PAN:</label>
                      <input
                        className="form-control"
                        id="pan"
                        type="text"
                        value={orgProfile.pan}
                        onChange={(e) => handleChange(e, "pan")}
                      />
                    </div>
                  </div>

                <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">TAN:</label>
                      <input
                        className="form-control"
                        id="tan"
                        type="text"
                        value={orgProfile.tan}
                        onChange={(e) => handleChange(e, "tan")}
                      />
                    </div>
                  </div>
                 </div>

                 <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="addressLine1">Address 1:</label>
                      <input
                        className="form-control"
                        id="addressLine1"
                        type="text"
                        value={orgProfile.addressLine1}
                        onChange={(e) => handleChange(e, "addressLine1")}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="addressLine2">Address 2:</label>
                      <input
                        className="form-control"
                        id="addressLine2"
                        type="text"
                        value={orgProfile.addressLine2}
                        onChange={(e) => handleChange(e, "addressLine2")}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">City:</label>
                      <input
                        className="form-control"
                        id="city"
                        type="text"
                        value={orgProfile.city}
                        onChange={(e) => handleChange(e, "city")}
                      />
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="officeLatitude">Office Latitude:</label>
                      <input
                        className="form-control"
                        id="officeLatitude"
                        type="text"
                        value={orgProfile.officeLatitude}
                        onChange={(e) => handleChange(e, "officeLatitude")}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="officeLongitude">Office Longitude:</label>
                      <input
                        className="form-control"
                        id="officeLongitude"
                        type="text"
                        value={orgProfile.officeLongitude}
                        onChange={(e) => handleChange(e, "officeLongitude")}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="godownlatitude">Godown Latitude:</label>
                      <input
                        className="form-control"
                        id="godownLatitude"
                        type="text"
                        value={orgProfile.godownLatitude}
                        onChange={(e) => handleChange(e, "godownLatitude")}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="godownLongitude">Godown Longitude:</label>
                      <input
                        className="form-control"
                        id="godownLongitude"
                        type="text"
                        value={orgProfile.godownLongitude}
                        onChange={(e) => handleChange(e, "godownLongitude")}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="pincode">Pincode:</label>
                      <input
                        className="form-control"
                        id="pincode"
                        type="text"
                        value={orgProfile.pincode}
                        onChange={(e) => handleChange(e, "pincode")}
                      />
                    </div>
                  </div>
                
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="state">State:</label>
                      <input
                        className="form-control"
                        id="state"
                        type="text"
                        value={orgProfile.state}
                        onChange={(e) => handleChange(e, "state")}
                      />
                    </div>
                  </div>
                  </div>

                <br />
                <input
                  type="submit"
                  value="Save Changes"
                  className="btn btn-primary"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationSettings;