import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

function OrgUpdateModal({ isOpen, onRequestClose, orgid}) {
  const navigate = useNavigate();

const [salesAarea, setSalesArea]=useState([]);
const [territories, setTerritories]=useState([]);
const [orgDetails, setOrgDetails]=useState("");

const token = JSON.parse(localStorage.getItem("data")).token;

  const [uproduct1, setUproduct1] = useState({
    id: orgid,
    territoryname:"",
    salesarea:""
  });

  const [loading, setLoading] = useState(false);
  const getData = async () => {
    setLoading(true);
    try {
      const orgResponse = await axios.get(`/api/organizationprofiles/${orgid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setOrgDetails(orgResponse.data);

      setUproduct1({
        ...uproduct1,
        territoryname: orgResponse.data.territoryname || "",
        salesarea: orgResponse.data.salesarea || "",
      });

      const trans = await axios.get(`/api/territory`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setTerritories(trans.data);
      
      // If there is an existing territory, fetch the corresponding sales areas
      if (orgResponse.data.territoryname) {
        handlesalesArea(orgResponse.data.territoryname);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const handlesalesArea = async (selectedTerritory) => {
    try {
        const res = await axios.get(
            `/api/salesarea/territoryname=${selectedTerritory}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
      
        setSalesArea(res.data);
    } catch (error) {
        console.error(error);
    }
}

  
  useEffect(() => {
    getData();
  }, []);


  const rsubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    axios
      .post(
        "/api/organizationprofiles/update",uproduct1,
      
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("API Response:", response.data);
        setLoading(false);
        toast.success("Territory and Sales Area updated successfully");
        onRequestClose();
      })
      .catch((error) => {
       setLoading(false);
       handleError(error);
      });
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  const handleChange = (event, field) => {
    const { value } = event.target;
    setUproduct1(prevDetails => ({
      ...prevDetails,
      [field]: value
    }));
    if (field === "territoryname") {
      handlesalesArea(value);
      setUproduct1(prevDetails => ({
        ...prevDetails,
        salesarea: "" // Clear sales area when territory changes
      }));
    }
  };
  


  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {loading && <div className="loading"></div>}
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={(e) => rsubmit(e)}>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="orgcode">Org Code</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="orgcode"
                      value={orgDetails.orgcode}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                     
                  <label htmlFor="territoryname">Territory Name</label>
                                              <select
                                                id="territoryname"
                                                className="form-select"
                                                type="text"
                                                placeholder="Territory Name"
                                                value={uproduct1.territoryname}
                                                onChange={(e) => {
                                                  handleChange(e, "territoryname");
                                                  handlesalesArea(e.target.value);
                                                }}
                                              >
                                                <option>select Territory</option>
                                                {territories.map((cu) => (
                                                  <option key={cu.id} value={cu.territoryname}>
                                                    {cu.territoryname}
                                                  </option>
                                                ))}
                                              </select>

                                              <label htmlFor="salesarea">Sales Area</label>
                                              <select
                                                id="salesarea"
                                                className="form-select"
                                                type="text"
                                                placeholder="salesarea"
                                                value={uproduct1.salesarea}
                                                onChange={(e) => {
                                                  handleChange(e, "salesarea");
                                                }}
                                              >
                                                <option>select Sales Area</option>
                                                {salesAarea.map((sub) => (
                              <option value={sub.salesarea} key={sub.id}>
                                {sub.salesarea}
                              </option>
                            ))}
                                              </select>
                  </div>
                </div>
                <br />
                <input
                  type="submit"
                  value="Update"
                  className="btn btn-primary"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default OrgUpdateModal;
