import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiEditAlt } from "react-icons/bi";
import ProducteditModalPopup from "./productedit";
import ProductmasterModal from "./Createproductmaster";
import ProductmastereditModalPopup from "./Productmasteredit";
import Pullproducts from "./Pullproducts";
import { useNavigate } from "react-router";

function Productmaster() {
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [product, setProduct] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/super-products`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct([...response.data]);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  useEffect(() => {
    getData();
  }, [isModalOpen,isCreateModalOpen]);

  const onClickhandler = async (e, id) => {
    setLoading(id);
    const checked = e.target.checked;
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      await axios.get(`/api/super-products/productUpdate/id=${id}/value=${checked}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Product status updated");
      getData();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleProductEdit = async (id) => {
    setSelectedProductId(id);
    setIsProductEditModalOpen(true);

  
  };

  const closeProductEditModal = () => {
    setIsProductEditModalOpen(false);
    getData();
  };

  const handleAddProduct = () => {
    setIsModalOpen(true);
    setProduct([]);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
    setProduct([]);

  };

  // Function to handle closing create modal
  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
    setProduct([]);
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      
      {loading && <div className="loading"></div>} 
      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Product Details
             <span style={{marginRight:"550px"}}></span>
              <button className="btn btn-primary"onClick={handleAddProduct}>
               ADD
            </button>
            <button className="btn btn-primary" onClick={handleCreateModalOpen}>
                Pull Products
              </button>
            </div>
  
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                  
                   <th>Product Type</th>
                   <th>Customer Type</th>
                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>HSN Code</th>
                  <th>GST</th>
                  <th>Net weight</th>
                  <th>Edit</th>
                  <th>Status</th>
                 

                </tr>
              </thead>
              <tbody>
              {product.length === 0 ? (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (

                product.map((product) => {
                  return (
                    <tr key={product.id}>
                     <td>{product.proType.productType}</td>
                     <td>{product.custType?.consumerType}</td>
                     <td>
  <div style={{ textAlign: "center" }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {product.image && product.imageMeta ? (
        <img
          className="img-fluid rounded-circle mb-1"
          src={`data:image/png;base64,${product.image}`}
          alt="Product"
          style={{ maxWidth: "50px", maxHeight: "70px", marginRight: '10px' }}
        />
      ) : (
        <div style={{ width: "50px", height: "70px", marginRight: '10px' }}></div>
      )}
      <span style={{ marginRight: '10px' }}>{product.productName}</span>
     
    </div>
  </div>
</td>
                      <td>{product.productCode}</td>
                      <td>{product.hsnCode}</td>
                      <td>{product?.gst} %</td>
                      <td>{product.netWeight} Kg</td>
                   
                      <td> 
                     <button
                      onClick={() => handleProductEdit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td>
                      <td>
                        <div className="form-check form-switch">
                          {loading == product.id && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={product.productStatus}
                            onClick={(e) => onClickhandler(e, product.id)}
                            value={product.id}
                          />
                        </div>
                      </td>
                   
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
            <div>
           
            
            
            {isProductEditModalOpen && (
              <ProductmastereditModalPopup
                isOpen={isProductEditModalOpen}
                onRequestClose={closeProductEditModal}
                Id={selectedProductId}
              />
            )}
            {isModalOpen && (
           <ProductmasterModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
            )}

{isCreateModalOpen && (
           <Pullproducts isOpen={isCreateModalOpen} onRequestClose={handleCreateModalClose} />
)}
      </div>


          </div>
        </div>{" "}
      </div>
    </div>   
  );
}

export default Productmaster;
