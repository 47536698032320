import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import "./Spinner.css";
import VehicleAreamap from "./Addvehicleareamap";
import Search from "./search";
import { MdDeleteForever } from "react-icons/md";
import { IoMdAddCircleOutline } from "react-icons/io";
import Dagentandvehiclemapping from "./Dagentandvehiclemapping";
import VehicleAreaMapping from "./vehicleAreaMapping";
import UpdateVehicleAreaAgentMap from "./updateVehiclleAreaAgentMap";

function VehicleAreaAgentMap() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [truckInfo, setTruckInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchbydate, setSearchbydate] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm1, setSearchTerm1] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const date = new Date();
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [searchDate, setSearchDate] = useState(currentDate);

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      // Fetching truckinfo data
      const vehicleResponse = await axios.get(`/api/vehicle/orgcode=${orgCode}/date=${currentDate}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTruckInfo(vehicleResponse.data);
      console.log("Fetched truck info:", vehicleResponse.data); // Debugging line
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const searchName = async (text) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
    setSearchbydate(false);
    setSearchDate(text);
    setLoading(true);

    try {
      const searchResponse = await axios.get(`/api/vehicle/orgcode=${orgCode}/date=${text}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTruckInfo(searchResponse.data);
      console.log("Search results:", searchResponse.data); // Debugging line
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred. Please try again.");
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleAddArea = (truckinfo) => {
    setSelectedVehicle(truckinfo );
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const handleUpdateModal = (truckinfo,vehicleid) => {
    setSelectedVehicle({truckinfo, vehicleid })
    setIsUpdateModalOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setIsUpdateModalOpen(false);
    getData();
  };

  const openAddMemberModal = (vehicleId, truckinfo) => {
    setSelectedVehicle({ vehicleId, truckinfo });
    setIsAddMemberModalOpen(true);
  };
 
  const closeAddMemberModal = () => {
    setSelectedVehicle(null);
    setIsAddMemberModalOpen(false);
    getData();
  };

  const  onClickhandlerdelete = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    const vehicles = await axios({
      url: "/api/DeliveryAgentVehicleMapping",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
        
        orgid: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    }).then((res) => {
      toast.success("Delivery Agent deleted successfully");
      getData();
      setLoading(null);
    });
  };

  const  onClickhandlerAreaDelete = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    const vehicles = await axios({
      url: "/api/VehicleAreaMapping",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
        
        orgid: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    }).then((res) => {
      toast.success("Area deleted successfully");
      getData();
      setLoading(null);
    });
  };


  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />

      {loading && <div className="loading"></div>}
      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Vehicle Mapping

              <div style={{ position: "relative", fontSize: "14px", right: "350px" }}>
                <Search searchName={searchName} />{" "}
              </div>

            </div>

            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                  <th>Vehicle Id</th>
                  <th>Agents</th>
                  <th>Area</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
              {truckInfo.length === 0 ? (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>No data available</td>
                </tr>
              ) : (
                truckInfo.map((v) => (
                  <tr key={v.tru}>
                    <th>{v.truckinfo}</th>
                    <td>
                      {Array.isArray(v.deliveryAgent) && v.deliveryAgent.length > 0 ? (
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                          {v.deliveryAgent.map((agent) => (
                            <li key={agent.id} style={{ display: 'block', marginBottom: '5px' }}>
                              {agent.name}
                              <button
                                style={{
                                  border: "none",
                                  background: "white",
                                  color: "grey",
                                  marginLeft: "5px",
                                  fontSize: "15px",
                                }}
                                onClick={(e) => onClickhandlerdelete(e, agent.id)}
                              >
                                <MdDeleteForever />
                              </button>
                            </li>
                          ))}
                        </ul>
                    ) : (
                      <p style={{ color: "red" }}>No Delivery Agent Added</p>
                    )}

                         <div>
                          <button
                            onClick={() =>
                              openAddMemberModal(v.vehicleid, v.truckinfo)
                            }
                          >
                          <IoMdAddCircleOutline />


                          </button>
                          </div  >

                  
                    <span style={{ marginLeft: '5px' }}></span>
                      </td>
                      <td>
                      {Array.isArray(v.vehicleArea) && v.vehicleArea.length > 0 ? (
     <ul style={{ listStyleType: 'none', padding: 0 }}>
     {v.vehicleArea.map((area) => (
       <li key={area.id} style={{ display: 'block', marginBottom: '5px' }}>
                            {area.areaname}
                            <button
                              key={area.id}
                              style={{
                                border: "none",
                                background: "white",
                                color: "grey",
                                position: "relative",
                                left: "5px",
                                fontSize: "15px",
                              }}
                              onClick={(e) => {
                                onClickhandlerAreaDelete(e, area.id);     
                              }}
                            >
                              <MdDeleteForever />
                            </button>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p style={{ color: "red" }}>No Area Added</p>
                    )}

<div>
                          <button
                            onClick={() =>
                              handleAddArea(v.truckinfo)
                            }
                          >
                          <IoMdAddCircleOutline />


                          </button>
                          </div  >

                  
                    <span style={{ marginLeft: '5px' }}></span>
                      </td>
                      <td>
                      <button className="btn btn-primary" onClick={() =>
                              handleUpdateModal(v.truckinfo, v.vehicleid)}>Update</button>
                      </td>
                  
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            {isAddMemberModalOpen && selectedVehicle && (
        <Dagentandvehiclemapping
          isOpen={isAddMemberModalOpen}
          onRequestClose={closeAddMemberModal}
          params={{ vid: selectedVehicle.vehicleId, truckinfo: selectedVehicle.truckinfo }}
        />
      )}
           {isModalOpen && selectedVehicle && (
              <VehicleAreaMapping
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                vehicleId={selectedVehicle}
              />
           )}

{isUpdateModalOpen && selectedVehicle && (
              <UpdateVehicleAreaAgentMap
                isOpen={isUpdateModalOpen}
                onRequestClose={handleCloseUpdateModal}
                params={{ vid: selectedVehicle.vehicleid, truckinfo: selectedVehicle.truckinfo }}
                searchdate={searchDate}
              />
           )}
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default VehicleAreaAgentMap;
