import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";

import { useNavigate } from "react-router";

const AreaMappedVehicles = ({ isOpen, onRequestClose,vehiclesData }) => {
    const navigate = useNavigate();
    function handleVehicleArea(e,vehicleid,deviceid) {
        navigate(`/users/OpenGoogleMap/${vehicleid}/${deviceid }`);
      }

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered >
      <Modal.Header closeButton>
        <Modal.Title>Vehicles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
        <table className="table table-bordered" >
                    <thead>
                      <tr>
                        <th>Vehicles</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>

                       {vehiclesData.map((v) => {
                  return (
                    <tr key={v.id}>
                     <td>{v.vehicleid}</td>
                        <td>
                      <button className="btn btn-primary" 
                      onClick={(e) => handleVehicleArea(e,v.vehicleid,v.vehicles.deviceid)}
                      >
                        VIEW Location
                      </button>
                    </td>
                         
                      </tr>
                  )})}
                    </tbody>
                  </table>
      </div>
     
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default AreaMappedVehicles;
