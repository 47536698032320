import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const CustomerpaymentModal = ({ isOpen, onRequestClose }) => {
  const [AddCustomerPaymentdetails, setAddCustomerPaymentdetails] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    date: "",
    amountpaid: "",
    customerName: "",
    modeofpaymentid: "",
    paymentreferencenumber: "",
  });

  const [paymentModes, setPaymentModes] = useState([]);
  const [exemptedcustomer, setExemptedcustomer] = useState([]);
  const [loading, setLoading] = useState(false);
  const url = "/api/customerpayment/create";
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      setAddCustomerPaymentdetails({
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
        date: "",
        amountpaid: "",
        customerName: "",
        modeofpaymentid: "",
        paymentreferencenumber: "",
      });
    }

    const date = new Date();
    date.setDate(date.getDate());
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
    setAddCustomerPaymentdetails((prev) => ({ ...prev, date: currentDate }));

    // Fetch payment modes
    const fetchPaymentModes = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get("/api/Modeofpayment", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPaymentModes(response.data);
      } catch (error) {
        handleError(error);
      }
    };
    fetchPaymentModes();

    const getData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const res = await axios.get(
          `/api/exemptedcustomer/orgcode=${JSON.parse(
            localStorage.getItem("data")
          ).orgcode}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setExemptedcustomer(res.data);
      } catch (error) {
        handleError(error);
      }
    };
    getData();
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    try {
      const response = await axios.post(
        url,
        AddCustomerPaymentdetails,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response.data);
      setLoading(false);
      toast.success("Customer Payment Details added successfully");
      navigate("/users/customerpayment");
      onRequestClose(); 
    } catch (error) {
      setLoading(false);
      handleError(error)
    }
  };
  

  const handleChange = (e) => {
    const { id, value } = e.target;
    setAddCustomerPaymentdetails((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Customer Payment Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
               
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="amountpaid">Amount Paid</label>
                    <input
                      className="form-control"
                      id="amountpaid"
                      type="text"
                      placeholder="Amount Paid"
                      value={AddCustomerPaymentdetails.amountpaid}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="customerName">Customer Name</label>
                    <select
                      id="customerName"
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option>Select Customer</option>
                      {exemptedcustomer.map((ec) => (
                        <option key={ec.id} value={ec.customername}>
                          {ec.customername}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="modeofpaymentid">Mode Of Payment</label>
                    <select
                      id="modeofpaymentid"
                      className="form-control"
                      value={AddCustomerPaymentdetails.modeofpaymentid}
                      onChange={handleChange}
                    >
                      <option>Select Mode Of Payment</option>
                      {paymentModes.map((mode) => (
                        <option key={mode.id} value={mode.name}>
                          {mode.type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="paymentreferencenumber">Payment Reference Number</label>
                    <input
                      className="form-control"
                      id="paymentreferencenumber"
                      type="text"
                      placeholder="Payment Reference Number"
                      value={AddCustomerPaymentdetails.paymentreferencenumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <input type="submit" value="SAVE" className="btn btn-primary" />
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomerpaymentModal;
