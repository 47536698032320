import React, { useEffect, useState, useReducer } from "react";
import { Button, Container, Spinner, Table } from "react-bootstrap";
import axios from "../api/baseurl";
import LayoutWithSideNav from "./layout";
import { BsCheckCircle } from "react-icons/bs";
import { BsXCircle } from "react-icons/bs";
import Dashboard from "./dashboard";
import "./Spinner.css";
function Approval() {
  const [approvals, setApprovals] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [status, setStatus] = useState({
    id: "",
    approvalstatus: "",
    username: "",
    role: "",
  });
  const [loading, setLoading] = useState(false);
  const url = "/api/authority";

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;

    const prof1 = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(prof1);

    setApprovals(prof1.data);
  };

  useEffect(() => {
    getData();
  }, [reducerValue]);

  function rsubmit(e, id, user, astatus, orgid, role) {
    e.preventDefault();
    setLoading(true);
    const newdata = { ...status };
    newdata[e.target.id] = e.target.value;
    setStatus(newdata);
    // if(!sub.status)

    //{

    //setSub(true)

    // }

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        "/api/superadmin/authority",
        {
          id: id,
          approvalstatus: astatus,
          username: user,
          role: role,
          orgid: orgid,
        },

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
    forceUpdate();
  }
  function rhandle(e) {
    const newdata = { ...status };
    newdata[e.target.id] = e.target.value;
    setStatus(newdata);
  }
  return (
    <div>
      <div>
        <Dashboard />
      </div>
      <div className="d-flex align-items-center mx-3 me-lg-0">
        <div className="d-flex">
          <div className="card card-raised mb-4">
            <div className="card-body p-3">
              <table className="table table-bordered table-light  table-hover">
                <thead>
                  <th>ID </th>

                  <th>USERNAME</th>

                  <th>ROLE</th>

                  <th>ORGANIZATIONID</th>
                  <th>APPROVALSTATUS</th>
                </thead>
                <tbody>
                  {approvals.map((approval) => {
                    return (
                      <tr key={approval.id}>
                        <td>{approval.id}</td>

                        <td>{approval.username}</td>

                        <td>{approval.role}</td>
                        <td>{approval.orgid}</td>
                        {approval.approvalstatus == "REQUESTED" ? (
                          <td id="icon" value={approval.approvalstatus}>
                            {approval.approvalstatus} &nbsp;{" "}
                            <BsCheckCircle
                              className="icon"
                              style={{ backgroundColor: "green" }}
                              id="approval.approvalstatus"
                              value="APPROVED"
                              onClick={(e) =>
                                rsubmit(
                                  e,
                                  approval.id,
                                  approval.username,
                                  "APPROVED",
                                  approval.orgid,
                                  approval.role
                                )
                              }
                            />
                            &ensp;
                            <BsXCircle
                              className="icon"
                              value="REJECTED"
                              style={{ backgroundColor: "red" }}
                              id="approval.approvalstatus"
                              onClick={(e) =>
                                rsubmit(
                                  e,
                                  approval.id,
                                  approval.username,
                                  "REJECTED",
                                  approval.orgid,
                                  approval.role
                                )
                              }
                            />
                          </td>
                        ) : (
                          <td id="icon" value={approval.approvalstatus}>
                            {" "}
                            {approval.approvalstatus}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default Approval;
