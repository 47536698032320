import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Button, Pagination, Form } from "react-bootstrap"; // Import Pagination from react-bootstrap
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConsumerDetailModal from "./Consumerdetailsmodal";

function ConsumerLoyaltyPoints() {
  const [loader, setLoader] = useState(false);
  const [searchOption, setSearchOption] = useState("MONTH");
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [typeFilter, setTypeFilter] = useState("");
  const [consumerLoyalty, setConsumerLoyalty] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedConsumerNumber, setSelectedConsumerNumber] = useState(null);

  const date = new Date();
  const date1 = new Date();
  date.setDate(date.getDate());
  date1.setDate(date1.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  let currentDate1 = `${year}-${month}`;
  
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/consumerloyalty/orgcode=${orgCode}/${month}/${year}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setConsumerLoyalty(response.data);
    
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred.");
    }
    setLoader(false);
  };

  const handleFilterChange = (event) => {
    const selectedOption = event.target.value;
    setSearchOption(selectedOption);
    setSelectedMonth(null);
    setSelectedYear(null);
  };

  const searchByMonth = async (month, year) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      let searchResponse;
      
        searchResponse = await axios.get(
          `/api/consumerloyalty/orgcode=${JSON.parse(
            localStorage.getItem("data")
          ).orgcode}/${month}/${year}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      
      setConsumerLoyalty([...searchResponse.data]);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const searchByYear = async (year) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      let searchResponse;
    
        searchResponse = await axios.get(
          `/api/consumerloyalty/orgcode=${JSON.parse(
            localStorage.getItem("data")
          ).orgcode}/${year}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      
      setConsumerLoyalty([...searchResponse.data]);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };
  const handleCardClick = (consumerNumber) => {
    setSelectedConsumerNumber(consumerNumber);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedConsumerNumber(null);
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />

      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex justify-content-between w-100">
              <div className="card-title form-group mb-4">Top 10 Consumers List</div>
              <div>
                <label>FILTER :</label>
                <select value={searchOption} onChange={handleFilterChange}>
                  <option value="MONTH">Month</option>
                  <option value="YEAR">Year</option>
                </select>
                &ensp;
                {searchOption === "MONTH" && (
                  <React.Fragment>
                    <Form.Label>Month:</Form.Label>
                    <DatePicker
                      selected={selectedMonth !== null && selectedYear !== null ? new Date(selectedYear, selectedMonth) : new Date()}
                      onChange={(date) => {
                        const month = date.getMonth() + 1;
                        const year = date.getFullYear();
                        setSelectedYear(year);
                        setSelectedMonth(date.getMonth());
                        searchByMonth(month, year);
                      }}
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                    />
                  </React.Fragment>
                )}
                {searchOption === "YEAR" && (
                  <React.Fragment>
                    <Form.Label>Year:</Form.Label>
                    <DatePicker
                      selected={selectedYear ? new Date(selectedYear, 0) : new Date()}
                      onChange={(date) => {
                        const year = date.getFullYear();
                        setSelectedYear(year);
                        searchByYear(year);
                      }}
                      showYearPicker
                      dateFormat="yyyy"
                    />
                  </React.Fragment>
                )}
              </div>
            </div>

            <br />
            <div className="row">
  {consumerLoyalty.length === 0 ? (
    <div className="col text-center">No Data Available</div>
  ) : (
    consumerLoyalty.map((product, index) => (
      <div key={index} className="col-12 mb-4"> {/* Each product in a separate column */}
        <h6>Product: {product.productname}</h6>
        <div className="row row-cols-1 row-cols-md-3 g-4"> {/* Each row contains 3 cards */}
          {product.consumers.map((consumer, idx) => (
            <div key={idx} className="col">
                          <Card onClick={() => handleCardClick(consumer.consumerNumber)}>
                          <Card.Body>
                  <Card.Text>Consumer Name: {consumer.consumerName}</Card.Text>
                  <Card.Text>Consumer Number: {consumer.consumerNumber}</Card.Text>
                  <Card.Text>Points: {consumer.points}</Card.Text>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
        <ConsumerDetailModal
        show={showModal}
        handleClose={handleCloseModal}
        consumerNumber={selectedConsumerNumber}
      />
      </div>
    ))
  )}
</div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsumerLoyaltyPoints;
