import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";
import { error } from "jquery";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let params = useParams();

    return <Component {...props} params={params} />;
  }
  return ComponentWithRouterProp;
}

function Exemptededit(props) {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [uexempted1, setUexempted1] = useState({
    id: "",
    customername: "",
    categoryname: "",
    productname: "",
    deliveredqty: "",
    date: "",
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    await axios
      .get(`/api/ExemptedDetails/${props.params.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setUexempted1(res.data);
        setLoading(false);
      })
      .catch((error)=>{
        setLoading(false);
        handleError(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
 
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        "/api/ExemptedDetails/update",
        uexempted1,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Exempted details updated successfully");
        navigate("/users/exemptedverification");
      })

      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  }

  const rhandle = (event, field) => {
    let actualValue = event.target.value;

    setUexempted1({ ...uexempted1, [field]: actualValue });
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      {loading && <div className="loading"></div>}
      <div className="col-8  mx-xl-auto px-xl-5 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Update Exempted Details</div>
            <br />

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    <label for="Exempted Id">Exempted Id</label>
                    <input
                      class="form-control"
                      id="id"
                      type="text"
                      placeholder="id"
                      value={uexempted1.id}
                      readOnly
                    />
                    <br />
                    <label for="Customername">Customername</label>
                    <input
                      className="form-control"
                      id="customername"
                      type="text"
                      placeholder="customername"
                      value={uexempted1.customername}
                      onChange={(e) => rhandle(e, "customername")}
                    />
                    <br />
                    <label for="Categoryname">Categoryname</label>
                    <input
                      className="form-control"
                      id="categoryname"
                      type="text"
                      placeholder="categoryname"
                      value={uexempted1.categoryname}
                      onChange={(e) => rhandle(e, "categoryname")}
                    />
                    <br />
                    <label for="Productname">Productname</label>
                    <input
                      className="form-control"
                      id="productname"
                      type="text"
                      placeholder="productname"
                      value={uexempted1.productname}
                      onChange={(e) => rhandle(e, "productname")}
                    />
                    <br />
                    <label for="Deliveredqty">Deliveredqty</label>
                    <input
                      className="form-control"
                      id="deliveredqty"
                      type="text"
                      placeholder="deliveredqty"
                      value={uexempted1.deliveredqty}
                      onChange={(e) => rhandle(e, "deliveredqty")}
                    />
                    <br />
                    <label for="Date">Date</label>
                    <input
                      className="form-control"
                      id="date"
                      type="text"
                      placeholder="date"
                      value={uexempted1.date}
                      onChange={(e) => rhandle(e, "date")}
                    />
                    <br />
                    <input
                      type="submit"
                      value="Update"
                      className="btn btn-primary"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default withRouter(Exemptededit);
