import React from "react";
import ReactDOM from "react-dom";
import './styles.css';
import App from "./App";


import { createRoot } from 'react-dom/client';

// Now you can use createRoot to render your application
const root = createRoot(document.getElementById('root'));
root.render(<App />);