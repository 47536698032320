import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";
import { Modal, Button } from "react-bootstrap";
import BusImage from '../images/Bus.png';
import CarImage from '../images/Car.png';
import TruckImage from '../images/Truck.png';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let params = useParams();
    return <Component {...props} params={params} />;
  }
  return ComponentWithRouterProp;
}

function VehicleeditsModalPopup({ isOpen, onRequestClose, params }) {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [updateVehicle, setUpdateVehicle] = useState({
    id: params.id,
    truckinfo: "",
    licenceNo: "",
    deviceid: "",
    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const [selectedImage, setSelectedImage] = useState({
    image: null,
    imageMeta: null,
    imageName: null,
  });

  const VehicleImage = [BusImage, CarImage, TruckImage];
  const [loading, setLoading] = useState(false);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [associatedDevices, setAssociatedDevices] = useState([]);
  const [api1, setapi1] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [api, setapi] = useState({});

  const navigate = useNavigate();
  const deviceApiUrl = process.env.REACT_APP_CONVOIZ_URL;

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      try {
        const response1 = await axios.get(
          `/api/Locationtrack/userid=${JSON.parse(localStorage.getItem("data")).userid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setapi(response1.data);

        const response2 = await axios.get(`/api/vehicle/${params.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUpdateVehicle(response2.data);

        const vehicleresponse = await axios.get(`/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setapi1(vehicleresponse.data);

        const deviceResponse = await axios.get(`${deviceApiUrl}/api/devicevehicle/apptoken`, {
          headers: {
            AppName: `${response1.data.appname}`,
            APIKey: `${response1.data.apikey}`,
          },
        });

        const filteredDevices = deviceResponse.data.filter(device =>
          !vehicleresponse.data.some(vehicle => vehicle?.deviceid === device?.deviceid)
        );
        setAssociatedDevices(vehicleresponse.data);
        setDeviceOptions(filteredDevices);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [params.id]);

  useEffect(() => {}, [deviceOptions, associatedDevices]);

  const openImageModal = (id) => {
    setSelectedVehicleId(id);
    setShowModal(true);
  };

  const closeImageModal = () => {
    setShowModal(false);
    setSelectedImage({});
  };

  const handleImageSelect = (image) => {
    const imageName = image.split('/').pop();
    fetch(image)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result.split(',')[1];
          setSelectedImage({
            image: base64data,
            imageMeta: reader.result.split(',')[0],
            imageName: imageName,
          });
        };
        reader.readAsDataURL(blob);
      });
  };

  const handleUpdateImage = async () => {
    const { image, imageMeta } = selectedImage;
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      await axios.post(
        `/api/vehicle/update`,
        {
          image: image,
          imageMeta: imageMeta,
          id: selectedVehicleId,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success("Image updated successfully");
      closeImageModal();
    } catch (error) {
      console.error("Error updating image:", error);
      toast.error("Error updating image");
    }
  };

  const rsubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .post("/api/vehicle/update", updateVehicle, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Vehicle updated successfully");
        navigate("/users/vehicledetail");
        onRequestClose();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error("Error updating vehicle");
      });
  };

  const rhandle = (event, field) => {
    const actualValue = event.target.value;
    const newValue = field === "deviceid" ? actualValue.trim() : actualValue;
    setUpdateVehicle({ ...updateVehicle, [field]: newValue });
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Vehicle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={rsubmit}>
                <label htmlFor="truckinfo">Vehicle</label>
                <input
                  className="form-control"
                  id="truckinfo"
                  type="text"
                  placeholder="truckinfo"
                  value={updateVehicle.truckinfo}
                  onChange={(e) => rhandle(e, "truckinfo")}
                />
                <br />
                <label htmlFor="deviceid">Device Id</label>
                <select
                  className="form-select"
                  id="deviceid"
                  value={updateVehicle.deviceid}
                  onChange={(e) => rhandle(e, "deviceid")}
                >
                  <option value="">Select Device</option>
                  {deviceOptions.map((device) => (
                    <option key={device.deviceid} value={device.deviceid}>
                      {device.deviceid}
                    </option>
                  ))}
                </select>
                <br />
                <button
                  style={{ padding: '3px 7px', fontSize: '13px', marginTop: '5px' }}
                  className="btn btn-primary"
                  type="button"
                  onClick={() => openImageModal(updateVehicle.id)}
                >
                  Choose Image
                </button>
                <br />
                <br />
                <br />
                <Modal
                  show={showModal}
                  onHide={closeImageModal}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Select an Image</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Box>
                      {VehicleImage.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={`Vehicle ${index}`}
                          style={{
                            width: '100px',
                            height: '100px',
                            margin: '5px',
                            cursor: 'pointer',
                            border: selectedImage.imageName === image.split('/').pop() ? '2px solid blue' : '1px solid gray',
                          }}
                          onClick={() => handleImageSelect(image)}
                        />
                      ))}
                    </Box>
                    <Typography style={{ marginTop: '16px' }}>
                      Selected Image: {selectedImage.imageName || 'None'}
                    </Typography>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={handleUpdateImage}>
                      Update Image
                    </Button>
                    <Button variant="secondary" onClick={closeImageModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                <input
                  type="submit"
                  value="Update"
                  className="btn btn-primary"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default VehicleeditsModalPopup;
