import Dashboard from "./dashboard";
import SuperSidebar from "./supersidebar";
import TransactionOutgoing from "./TransactionOutgoingDetails";
function SuperTransactionOutgoing() {
  return (
    <div>
      <div>
        <Dashboard />
      </div>
      <div>
        <SuperSidebar />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div>
        <TransactionOutgoing />
      </div>
    </div>
  );
}
export default SuperTransactionOutgoing;
