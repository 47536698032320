import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Spinner.css";

const AddconsumerModal = ({ isOpen, onRequestClose }) => {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;      
  
  const [newProduct, setNewProduct] = useState(
    {
      consumernumber: "",
      consumername: "",
      phone: "",
      longitude: "",
      latitude: "",
      area: "",
      orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
      address1: "",
      address2: "",
      address3: "",
      product:""
  });
  const [products, setProducts] = useState([]);
  const [areaNames, setAreaNames] = useState([]);

  
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchAreaNames = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const areaUrl = `/api/AreaMaster/orgcode=${orgCode}`;

      try {
        const response = await axios.get(areaUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAreaNames(response.data);

        const productResponse = await axios.get(
          `/api/product/orgcode=${orgCode}/productstatus=true/productTypeId=${1}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log("Response from Product API:", productResponse.data);
        setProducts(productResponse.data);
      } catch (error) {
        handleError(error);
      }
    };

    fetchAreaNames();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post("/api/consumerdetails/create", newProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setLoading(false);
      toast.success("Consumer Details created successfully");  
      onRequestClose(); 
      setNewProduct(newProduct);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  const rhandle = (e,field) => {
    const actualValue = e.target.value;
    setNewProduct({
      ...newProduct,
      [field]: actualValue,
    });
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Consumer List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>

              <div className="row mb-2">
  <div className="form-floating-sm">
    <label htmlFor="product">Product Name</label>
    <select
      className="form-select"
      id="product"
      value={newProduct.product}
      onChange={(e) => rhandle(e, "product")}
    >
      <option value="">Select Product Name</option>
      {products.map((product, index) => (
        <option key={index} value={product.productname}>
          {product.productname}
        </option>
      ))}
    </select>
  </div>
</div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="consumernumber">Consumer Number</label>
                    <input
                      className="form-control"
                      id="consumernumber"
                      type="text"
                      placeholder="Consumer Number"
                      value={newProduct.consumernumber}
                      onChange={(e) => rhandle(e, "consumernumber")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="consumername">Consumer Name</label>
                    <input
                      className="form-control"
                      id="consumername"
                      type="text"
                      placeholder="Consumer Name"
                      value={newProduct.consumername}
                      onChange={(e) => rhandle(e, "consumername")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="phone">Phone</label>
                    <input
                      className="form-control"
                      id="phone"
                      type="text"
                      placeholder="Phone"
                      value={newProduct.phone}
                      onChange={(e) => rhandle(e, "phone")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="area">Area Name</label>
                    <select
                      className="form-select"
                      id="area"
                      value={newProduct.area}
                      onChange={(e) => rhandle(e, "area")}
                    >
                      <option value="">Select Area Name</option>
                      {areaNames.map((area) => (
                        <option key={area.id} value={area.areaname}>
                          {area.areaname}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
               
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="latitude">Latitude</label>
                    <input
                      className="form-control"
                      id="latitude"
                      type="text"
                      placeholder="Latitude"
                      value={newProduct.latitude}
                      onChange={(e) => rhandle(e, "latitude")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="longitude">Longitude</label>
                    <input
                      className="form-control"
                      id="longitude"
                      type="text"
                      placeholder="Longitude"
                      value={newProduct.longitude}
                      onChange={(e) => rhandle(e, "longitude")}
                    />
                  </div>
                </div>
               
               
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="address1">Address</label>
                    <input
                      className="form-control"
                      id="address1"
                      type="text"
                      placeholder="Address"
                      value={newProduct.address1}
                      onChange={(e) => rhandle(e, "address1")}
                    />
                  </div>
                </div>
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>  
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default AddconsumerModal;
