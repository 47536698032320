import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";

import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
const Addstaffrewards = ({ isOpen, onRequestClose }) => {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;      
  const initialProductState = {
    producttype:"",
    sales: 0,
    newconnection: 0,
    additional: 0,
    excempted: 0,
    qrPay:0,
    payAndbook:0,
    bookAndpay:0,
    hose:0,
    inception:0,
    safetycheck:0,
    bankdeposite:0,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  };

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);
  const [productTypes, setProductTypes] = useState([]); 
  const [selectedProductType, setSelectedProductType] = useState("");

  const url = "/api/reward/create";
  const navigate = useNavigate();

  useEffect(() => {
    fetchProductTypes(); // Fetch product types when component mounts
  }, []);

  const fetchProductTypes = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode=  JSON.parse(localStorage.getItem("data")).orgcode;
      const response = await axios.get("/api/producttype", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProductTypes(response.data);
    } catch (error) {
      handleError(error);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode=  JSON.parse(localStorage.getItem("data")).orgcode;

    try {
      const response = await axios.post(url, newProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setLoading(false);
      toast.success("Reward created successfully");
      onRequestClose(); 
      setNewProduct(initialProductState);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };
  
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const rhandle = (e) => {
    const { id, value } = e.target;
    const newdata = { ...newProduct, [id]: value };
  
    // Reset dependent fields based on the selected product type
    if (id === "producttype") {
      setSelectedProductType(value);
  
      if (value === "Consumables") {
        newdata.newconnection = "";
        newdata.additional = "";
        newdata.excempted = "";
        newdata.qrPay = "";
        newdata.payAndbook = "";
        newdata.bookAndpay = "";
        newdata.hose = "";
        newdata.inception = "";
        newdata.safetycheck = "";
        newdata.bankdeposite = "";
        

      } else if (value === "Miscellaneous") {
        newdata.sales = "";
        newdata.newconnection = "";
        newdata.additional = "";
        newdata.excempted = "";
        newdata.qrPay = "";
        newdata.payAndbook = "";
        newdata.bookAndpay = "";
        newdata.hose = "";
        newdata.inception = "";
        newdata.safetycheck = "";
        newdata.bankdeposite = "";
      } else if (value === "Refill Sales") {
        newdata.sales = "";
        newdata.qrPay = "";
        newdata.payAndbook = "";
        newdata.bookAndpay = "";
        newdata.hose = "";
        newdata.inception = "";
        newdata.safetycheck = "";
        newdata.bankdeposite = "";
      }
    }
  
    setNewProduct(newdata);
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Rewards</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="producttype">Product Type</label>
                    <select
                      className="form-control"
                      id="producttype"
                      value={newProduct.producttype}
                      onChange={(e) => rhandle(e)}
                    >
                      <option value="">Select Product Type</option>
                      {productTypes.map((type) => (
                        <option key={type.id} value={type.productType}>
                          {type.productType}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    
                    <label htmlFor="sales">Sales</label>
                    <input
                      className="form-control"
                      id="sales"
                      type="text"
                      placeholder="0"
                      value={newProduct.sales}
                      onChange={(e) => rhandle(e)}
                      disabled={!newProduct.producttype ||selectedProductType !== "Consumables"}         

                    />
                   
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="newconnection">New Connection</label>
                    <input
                      className="form-control"
                      id="newconnection"
                      type="number"
                      placeholder="0"
                      value={newProduct.newconnection}
                      onChange={(e) => rhandle(e)}

                      disabled={!newProduct.producttype ||selectedProductType !== "Refill Sales"}         
                    />
                  
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="additional">Additional</label>
                    <input
                      className="form-control"
                      id="additional"
                      type="text"
                      placeholder="0"
                      value={newProduct.additional}
                      onChange={(e) => rhandle(e)}
                      disabled={!newProduct.producttype ||selectedProductType !== "Refill Sales"}         

                    />
                    
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="excempted">Exempted</label>
                    <input
                      className="form-control"
                      id="excempted"
                      type="number"
                      placeholder="0"
                      value={newProduct.excempted}
                      onChange={(e) => rhandle(e)}
                      disabled={!newProduct.producttype ||selectedProductType !== "Refill Sales"}         
                      />
                  </div>
                </div>
 

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="qrPay">QR Pay</label>
                    <input
                      className="form-control"
                      id="qrPay"
                      type="number"
                      placeholder="0"
                      value={newProduct.qrPay}
                      onChange={(e) => rhandle(e)}
                      disabled={!newProduct.producttype ||selectedProductType !== "Miscellaneous"}         
                                  />
                  </div>
                </div>
                 

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="payAndbook">Pay & Book</label>
                    <input
                      className="form-control"
                      id="payAndbook"
                      type="number"
                      placeholder="0"
                      value={newProduct.payAndbook}
                      onChange={(e) => rhandle(e)}
                      disabled={!newProduct.producttype ||selectedProductType !== "Miscellaneous"}         
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="bookAndpay">Book & Pay</label>
                    <input
                      className="form-control"
                      id="bookAndpay"
                      type="number"
                      placeholder="0"
                      value={newProduct.bookAndpay}
                      onChange={(e) => rhandle(e)}
                      disabled={!newProduct.producttype ||selectedProductType !== "Miscellaneous"}         
                    />
                  </div>
                </div>  <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="hose">Hose</label>
                    <input
                      className="form-control"
                      id="hose"
                      type="number"
                      placeholder="0"
                      value={newProduct.hose}
                      onChange={(e) => rhandle(e)}
                      disabled={!newProduct.producttype ||selectedProductType !== "Miscellaneous"}         
                    />
                  </div>
                </div>  <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="inception">Inspection</label>
                    <input
                      className="form-control"
                      id="inception"
                      type="number"
                      placeholder="0"
                      value={newProduct.inception}
                      onChange={(e) => rhandle(e)}
                      disabled={!newProduct.producttype ||selectedProductType !== "Miscellaneous"}         
                    />
                  </div>
                </div>  <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="safetycheck">Safety Check</label>
                    <input
                      className="form-control"
                      id="safetycheck"
                      type="number"
                      placeholder="0"
                      value={newProduct.safetycheck}
                      onChange={(e) => rhandle(e)}
                      disabled={!newProduct.producttype ||selectedProductType !== "Miscellaneous"}         
                    />
                  </div>
                </div>  <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="bankdeposite">Bank Deposite</label>
                    <input
                      className="form-control"
                      id="bankdeposite"
                      type="number"
                      placeholder="0"
                      value={newProduct.bankdeposite}
                      onChange={(e) => rhandle(e)}
                      disabled={!newProduct.producttype ||selectedProductType !== "Miscellaneous"}         
                    />
                  </div>
                </div>
                 
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}

                />
                 &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  

              </form>
            </div>
          </div>
        </div>

      </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default Addstaffrewards;