import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";

const HistoryModal = ({ isOpen, onRequestClose, subModelId }) => {
  const [exemptedDetails, setExemptedDetails] = useState(null); 
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(
          `/api/SubmodelandfeatureDo/modelId=${subModelId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setExemptedDetails(response.data);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [subModelId]);

  if (!isOpen || !exemptedDetails) return null; 

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>History of Subscription Details</Modal.Title>
        {""}
              {loader && (
        <div
          className="loader"
          style={{ position: "absolute", left: "210px", top: "15px" }}
        ></div>
      )}
      </Modal.Header>
      <Modal.Body>
        <table className="table">
          <thead>
            <tr>
            <th>Submodel</th>

              <th>Region</th>
              <th>Cost</th>
              <th>Features</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td>{exemptedDetails.smodel.submodel}</td>

              <td>{exemptedDetails.smodel.region}</td>
              <td>{exemptedDetails.smodel.cost}</td>
              <td>
                <ul>
                  {exemptedDetails.features.map((feature, index) => (
                    <li key={index}>{feature.features}</li>
                  ))}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default HistoryModal;
