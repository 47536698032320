import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";

import "./Spinner.css";
import { useNavigate } from "react-router";
function SubregionCreateModal ({ isOpen, onRequestClose }) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const [loading, setLoading] = useState(false);
  
    const [regions, setRegions] = useState({
      region: "",
      currency: "",
      symbol: "",
    });
  
    const handleChange = (event, field) => {
      let actualValue = event.target.value;
      setRegions({ ...regions, [field]: actualValue });
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      setLoading(true);
  
      axios
        .post("/api/subscription-region/create", regions, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
         onRequestClose();
          toast.success("Subscription region added Successfully");
          setRegions([]);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
         handleError(error);
         setRegions([]);
        });
      }      
      
      const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else if (error.response && error.response.status === 400) {
      toast.error(error.response.data[0].code);
    }
    else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Subscription Region</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    
                    <label htmlFor="region">Region</label>
                    <input
                      className="form-control"
                      id="region"
                      type="text"
                      placeholder="enter region"
                      value={regions.region}
                      onChange={(e) => handleChange(e, "region")}
                    />
                   
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="currency">Currency</label>
                    <input
                      className="form-control"
                      id="currency"
                      type="text"
                      placeholder="enter currency"
                      value={regions.currency}
                      onChange={(e) => handleChange(e, "currency")}
                    />
                  
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="symbol">Symbol</label>
                    <input
                      className="form-control"
                      id="symbol"
                      type="text"
                      placeholder="enter symbol"
                      value={regions.symbol}
                      onChange={(e) => handleChange(e, "symbol")}
                    />
                    
                  </div>
                </div>

             <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}

                />
                 &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  

              </form>
            </div>
            {loading && <div className="loading"></div>} 
          </div>
        </div>

      </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default SubregionCreateModal;