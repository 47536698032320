import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Customerdetails() {
  const [Customerdetails, setCustomerdetails] = useState([]);
  const [loader, setLoader] = useState(true);

  const getData = async () => {
    try{
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios.get("api/CustomerDetails", {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(trans);

    setCustomerdetails(trans.data);
    setLoader(false);
  } catch (error) {
    setLoader(false);
    handleError(error);
  }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } 
    else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div>
        <Dashboard />
      </div>
      <br />
      <br />
      <br />
      <div>
        <div className="col-10  mx-xl-auto ps-xl-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="card-title">Customer  Details
              {""}
              {loader && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}</div>
              <br />
              <Link to="/users/AddCustomerdetails">
                  <button className="btn btn-primary float-end">ADD</button>
                </Link>
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    <th>Id </th>

                    <th>Orgcode</th>
                    <th>Date</th>
                    <th>Customer Name</th>
                    <th>Customer Number</th>
                    <th>Customer type</th>
                    <th>Distance</th>
                    <th>Tariff</th>
                    




                  </tr>
                </thead>
                <tbody>
                  {Customerdetails.map((accd) => {
                    return (
                      <tr key={accd.id}>
                        <td>{accd.id}</td>
                        <td>{accd.orgcode}</td>
                        <td>{accd.date}</td>
                        <td>{accd.customername}</td>
                        <td>{accd.customernumber}</td>
                        <td>{accd.customertype}</td>
                        <td>{accd.distance}</td>
                        <td>{accd.tariff}</td>

                        




                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customerdetails;