import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";

import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
const SalesareadetailsModal = ({ isOpen, onRequestClose,territoryId }) => {

  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;      
  const initialProductState = {
    salesarea: "",
    territorynameid: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  };

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);
  

  const url = "/api/salesarea/create";
  useEffect(() => {
    setNewProduct((prevState) => ({
        ...prevState,
        territorynameid: territoryId,
    }));
}, [territoryId]);
  const navigate = useNavigate();

 
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
        console.log("Submitting form with newProduct:", newProduct); // Debugging

      const response = await axios.post(url, [newProduct], {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("Response from server:", response.data);       setLoading(false);
      toast.success("Teritory created successfully");
      setNewProduct(initialProductState);
      onRequestClose();
    } catch (error) {
      handleError(error);
    }
  };
  
  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 400) {
      toast.error(error.response.data.code);
    } else {
    }
    setLoading(false);
  };
  const rhandle = (e) => {
    const { id, value } = e.target;
    if (id === 'territorynameid') {
      setNewProduct((prevState) => ({
        ...prevState,
        territorynameid: value,
      }));
    } else {
      setNewProduct((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Sales Area</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                    
                    <label htmlFor="territorynameid">Teritory ID</label>
                    <input
                                            className="form-control"
                                            id="territorynameid"
                                            type="text"
                                            placeholder="Territory ID"
                                            value={newProduct.territorynameid}
                                            onChange={(e) => rhandle(e)}
                                        />
                   
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    
                    <label htmlFor="salesarea">Sales Area</label>
                    <input
                      className="form-control"
                      id="salesarea"
                      type="text"
                      placeholder="salesarea"
                      value={newProduct.salesarea}
                      onChange={(e) => rhandle(e)}
                    />
                   
                  </div>
                </div>
              
                 
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}

                />
                 &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  

              </form>
            </div>
          </div>
        </div>

      </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default SalesareadetailsModal;