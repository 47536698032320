import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import {FiInfo } from "react-icons/fi";  
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MdDeleteForever } from "react-icons/md";


const LoadIncoming = ({ isOpen, onRequestClose, loadinDetails,InvoiceId,vehicleId, products,ttinDate}) => {
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [transOutDeleteModal, setTransOutDeleteModal] = useState(false);
  const [transOutDetails, setTransOutDetails] = useState([]);

  const handleDeleteTransactionOutCnfm = async (date,truckinfo, invoiceid) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
  
      // Make the first API request
      const res = await axios.get(
        `/api/loadin/orgcode=${ JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/truckinfo=${truckinfo}/invoiceid=${invoiceid}`,
        {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTransOutDetails(res.data);

      setTransOutDeleteModal(true);
    } catch (error) {
      // Handle errors if needed
      // handleError(error);
    }
  };

  

  const closeCnfmModal = () => {
    setTransOutDeleteModal(false);
  };

  const handleDeleteAllTransOut = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
   
    const entities = loadinDetails.map(item => ({
      id: item.id,
    }));

    try {
      // Call the API to delete all items with IDs in allIds
      const response = await axios.delete(
        '/api/loadin/deleteList',
        {
          headers: { Authorization: `Bearer ${token}` },
          data: entities, // Pass the list of entities as data in the request body
        }
      );
      console.log(response.data); // Log the response from the API
      toast.success("Load Incoming deleted successfully");
      closeCnfmModal();
      onRequestClose();
    } catch (error) {
        toast.error(error.response.data.message);
        console.error('Error deleting items:', error);
        // Handle other errors as needed
    }
  };

  useEffect(() => {
  
  }, []);

  return (
    <>
   

{/* delete modal */}
<Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Load Incoming Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
<div>
{loadinDetails.length > 0 && (
<div>
<label style={{ fontSize: '1em', color: "#000000" }}>Vehicle Id: {loadinDetails[0].truckinfo}</label>
<div>
<label style={{ fontSize: '1em', color: "#000000" }}>Date: {loadinDetails[0].date}</label>
</div>
<div>
<label style={{ fontSize: '1em', color: "#000000" }}>InvoiceId: {loadinDetails[0].invoiceid}</label>
</div>
</div>
)}

  </div>

  <table className="table table-bordered ">
    <thead>
    <tr>
            <th>Id</th>
           <th>Product name</th>
           <th>Product code</th>
           <th>Quantity</th>
            <th>Approval Status</th>
            {/* <th>Actions</th> */}
            
          </tr>
        </thead>
        <tbody>
        {loadinDetails.map((ttin, index) => (
        <tr key={index}>
                <th>{ttin.id}</th>
                <th>{ttin.productname}</th>
                <th>{ttin.productcode}</th>
                <th>{ttin.quantity}</th>
                <th>{ttin.approvalstatus}</th>
                
                  

 {/* <th> 
                     
    <MdDeleteForever style={{ marginLeft: '25px',cursor: 'pointer', fontSize:"18px" }}  title="Delete"
    onClick={() => handleDeleteTransactionOutCnfm(ttinDate, ttin.truckinfo, ttin.invoiceid)}
    /></th> */}
                </tr>
      ))}
          </tbody>
        </table>
          <div className="card-title form-group d-flex align-items-center justify-content-between mt-5"> 
          <Button variant="btn btn-primary" onClick={onRequestClose}>
          Cancel
        </Button> 

    <button className="btn btn-primary" 
   onClick={ handleDeleteAllTransOut}
    >Delete All</button>
    </div>

  </Modal.Body>
  <Modal.Footer>
   </Modal.Footer>
</Modal>
</>
      );
    }
export default LoadIncoming;
