import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Spinner.css";

const Addcsvfil = ({ isOpen, onRequestClose }) => {
  const date = new Date();
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const initialProductState = {
    
    area: "",
    date: currentDate,
    file: null,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    product:""
  };

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [areaNames, setAreaNames] = useState([]);
  const [products, setProducts] = useState([]);

  const url = "/api/consumerupload/create-and-upload";
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAreaNames = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const areaUrl = `/api/AreaMaster/orgcode=${orgCode}`;

      try {
        const response = await axios.get(areaUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAreaNames(response.data);

        const productResponse = await axios.get(
          `/api/product/orgcode=${orgCode}/productstatus=true/productTypeId=${1}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log("Response from Product API:", productResponse.data);
        setProducts(productResponse.data);
      } catch (error) {
       handleError(error);
      }
    };

    fetchAreaNames();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    console.log("CSV File:", csvFile);

    const formData = new FormData();
    console.log(formData);
    formData.append("file", csvFile);
    
    formData.append("area", newProduct.area);
    formData.append("date", newProduct.date);
    formData.append("orgcode", newProduct.orgcode);
    formData.append("product", newProduct.product);

    try {
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      setLoading(false);
      toast.success("CSV file uploaded successfully");
      onRequestClose();
      setNewProduct(initialProductState);
      setCsvFile(null);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const rhandle = (e) => {
    const { id, value } = e.target;
    setNewProduct((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleCsvFileChange = (e) => {
    const file = e.target.files[0]; // Get the first file from the input
    setCsvFile(file); // Update state with the selected file
  
    const formData = new FormData(); // Create a new FormData object
    formData.append("file", file); // Append the file to the FormData object
  
    console.log("CSV File:", file); // Log the selected file
    console.log(formData); // Log the FormData object with the appended file
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Upload CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="date">Date</label>
                    <input
                      className="form-control"
                      id="date"
                      type="date"
                      placeholder="date"
                      value={newProduct.date}
                      onChange={rhandle}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="area">Area Name</label>
                    <select
                      className="form-control"
                      id="area"
                      value={newProduct.area}
                      onChange={rhandle}
                    >
                      <option value="">Select Area Name</option>
                      {areaNames.map((area) => (
                        <option key={area.id} value={area.areaname}>
                          {area.areaname}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-2">
  <div className="form-floating-sm">
    <label htmlFor="product">Product Name</label>
    <select
      className="form-select"
      id="product"
      value={newProduct.product}
      onChange={ rhandle}
    >
      <option value="">Select Product Name</option>
      {products.map((product, index) => (
        <option key={index} value={product.productname}>
          {product.productname}
        </option>
      ))}
    </select>
  </div>
</div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="file" className="me-1">Upload Document:</label>
                    <input
                      className="form-control smaller-input flex-grow-1"
                      id="file"
                      type="file"
                      placeholder="Upload document"
                      onChange={handleCsvFileChange}
                    />
                  </div>
                </div>
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default Addcsvfil;
