import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";

import AdminDashboard from "./admindashboard";
import {useNavigate } from "react-router-dom";
import "./Spinner.css";
function UpdateDaCashDetails ({ isOpen, onRequestClose,daCashId }) {
    const date = new Date();

    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    var curr_hour = date.getHours();
    var curr_min = date.getMinutes();
  
    var curr_sec = date.getSeconds();
  
    let currentDate = `${year}-${month}-${day}`;
    let currTime = `${curr_hour}:${curr_min}:${curr_sec}`;

  const [loading, setLoading] = useState(false);
  
  const [denom, setDenom] = useState([]);
 
  let finaltotal=0;
  const [cash, setCash] = useState({
    id:daCashId,
    vehicleid: "",
    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
    totalamount: finaltotal,
    datetime: "",
    time: currTime,
    referenceNumber:"",
    depositAmount: 0,
    denomoinationvalue: [
      {
        facevalue: "",
        denominationnumber: "",
      },
    ],
  });


  const getDaCashById = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    
      try {
        const [cashResponse, denomResponse] = await Promise.all([
            axios.get(`/api/DeliveryAgentCashPayment/${daCashId}`, {
                headers: { Authorization: `Bearer ${token}` },
            }),
            axios.get(`/api/DeliveryagentDenomination/daCashPaymentId=${daCashId}`, {
                headers: { Authorization: `Bearer ${token}` },
            }),
        ]);

        const updatedDenominationValue = denomResponse.data.map((item) => ({
            ...item,
            denominationnumber: item.denominationnumber || "",
            facevalue: item.facevalue || "",
        }));

        setCash(prevCash => ({
          ...prevCash,
          ...cashResponse.data,
          denomoinationvalue: updatedDenominationValue,
      }));
    } catch (error) {
       handleError(error);
    }
};

      


  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("data")).token;

  const handleChange = (event, field, index, faceValue) => {
    let actualValue = event.target.value;
    let newValue = actualValue !== "" ? parseFloat(actualValue) : null;

    if (field === "depositAmount") {
        setCash({ ...cash, depositAmount: newValue || 0 }); // Ensure depositAmount is a number
        updateTotal(newValue || 0, cash.denomoinationvalue); // Update total immediately for depositAmount changes
        return;
    }

    if (field === "referenceNumber") {
        setCash({ ...cash, referenceNumber: actualValue });
        return;
    }

    let updatedDenominations = [...cash.denomoinationvalue];
    let denominationToUpdate = updatedDenominations.find((denom) => denom.facevalue === faceValue);
    
    if (!denominationToUpdate) {
        denominationToUpdate = { facevalue: faceValue, denominationnumber: newValue || "" };
        updatedDenominations.push(denominationToUpdate);
    } else {
        denominationToUpdate[field] = newValue;
    }

    setCash((prevCash) => ({
        ...prevCash,
        denomoinationvalue: updatedDenominations,
    }));

    // Calculate the total amount based on updated denomination values and deposit amount
    updateTotal(cash.depositAmount || 0, updatedDenominations);
};



const updateTotal = (depositAmount, denominations) => {
    let totalAmount = 0;

    denominations.forEach((item) => {
        if (item && item.denominationnumber && item.facevalue) {
            totalAmount += item.denominationnumber * item.facevalue;
        }
    });

    totalAmount += depositAmount; // Add deposit amount to total, ensure it's a number

    setCash((prevCash) => ({ ...prevCash, totalamount: totalAmount }));
};

  
  
  
  const handleSubmit = (event,) => {
    event.preventDefault();
    setLoading(true);
    const filteredDenominations = cash.denomoinationvalue.filter(
      item => item && item.facevalue && item.denominationnumber
  );

  // Update the cash object with the filtered denominations
  const updatedCash = {
      ...cash,
      denomoinationvalue: filteredDenominations,
  };
    
    axios
      .post("/api/DeliveryAgentCashPayment/dacashpaymentupdate", updatedCash, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        onRequestClose();
        toast.success("financials updated successfully");
        getData();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };

  const getData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
  
      const res = await axios.get("/api/denominations/status=true", {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const denominationsData = res.data.map((item) => ({
        facevalue: item.denominations,
        denominationnumber: "",
      }));
  
      setDenom(res.data);
      setCash((prevCash) => ({
        ...prevCash,
        denomoinationvalue: denominationsData,
      }));
    } catch (error) {
      handleError(error);
    }
};


  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  useEffect(() => {
    getData();
    if(daCashId){
      getDaCashById();
    }

    
  }, [daCashId]);

  

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Delivery Agent Cash Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div
            className="col-12 mx-xl-auto ps-xl-2">
            <div className="card card-raised ">
              <div className="card-body ">
<div>Vehicle Id : {cash.vehicleid}</div>
                <br></br>

                <form onSubmit={(e) => handleSubmit(e, finaltotal)}>
                <div className="row">
  <div className="col-md-6">
    <div className="form-floating-sm mb-2 d-flex align-items-center">
      <label htmlFor="referenceNumber" className="me-2">Deposit Reference Number:</label>
      <input
        className="form-control form-control-sm"
        id="referenceNumber"
        style={{ width: "50%" }}
        value={cash.referenceNumber}
        onChange={(e) => handleChange(e, "referenceNumber")}
      />
    </div>
  </div>
  <div className="col-md-6">
    <div className="form-floating-sm mb-2 d-flex align-items-center">
      <label htmlFor="depositAmount" className="me-2">Deposit Amount:</label>
      <input
        className="form-control form-control-sm"
        id="depositAmount"
        style={{ width: "50%" }} 
        value={cash.depositAmount}
        onChange={(e) => {
          handleChange(e, "depositAmount");
        }}
      />
    </div>
  </div>
</div>
<br/>
{denom.map((denomination, index) => {
    const faceValue = denomination.denominations; // Assuming denominations is the face value property

     const matchingDenomination = cash.denomoinationvalue && Array.isArray(cash.denomoinationvalue)
        ? cash.denomoinationvalue.find((denom) => denom && denom.facevalue === faceValue)
        : '';
    return (
        <div className="d-flex" key={index}>
            <div className="row">
                <div className="form-floating-sm mb-2">
                    <input
                        className="form-control"
                        id={`facevalue-${index}`}
                        value={faceValue}
                        readOnly
                    />
                </div>
            </div>
            <div>&nbsp;&nbsp;&nbsp;*&nbsp;</div>
            &nbsp; &nbsp;
            <div className="row">
                <div className="form-floating-sm mb-2">
                    <input
                        className="form-control"
                        id={`denominationnumber-${index}`}
                        value={matchingDenomination ? matchingDenomination.denominationnumber : ''}
                        onChange={(e) => handleChange(e, "denominationnumber", index, faceValue)}
                    />
                </div>
            </div>
            <div>&nbsp;&nbsp;&nbsp;=&nbsp;</div>
            &nbsp; &nbsp;
            <div className="row">
                <div className="form-floating-sm mb-2">
                    <input
                        className="form-control"
                        id={`total-${index}`}
                        value={
                            matchingDenomination
                                ? matchingDenomination.denominationnumber * faceValue
                                : ''
                        }
                    />
                </div>
            </div>
        </div>
    );
})}

                  <br/>
                  <div className="row">
                    <div className="form-floating-sm mb-2 d-flex">
                      <h3>Total: </h3>{" "}
                      <input
                        className="form-control"
                        id="totalamount"
                        //value={cash.totalamount}
                        value={cash.totalamount}
                      />
                    </div>
                  </div>

                  <div className="card-title form-group d-flex align-items-center justify-content-between mt-5"> 
  <Button variant="btn btn-primary" onClick={onRequestClose} >
               Cancel
               </Button> 
                    <input
                      className="btn btn-primary"
                      type="submit"
                      value="Update"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>


      </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default UpdateDaCashDetails;