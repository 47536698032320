import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever } from "react-icons/md";
import { Pagination, Form, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AddCampaigns from "./addCampaigns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FaEdit } from "react-icons/fa";

function Campaigns() {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
const [currentPage, setCurrentPage] = useState(0);
const [totalPages, setTotalPages] = useState(0);
const [searchTerm, setSearchTerm] = useState("");
const [selectedcampaign, setSelectedCampaign] = useState(null);

const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [enabled, setEnabled] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (searchTerm) {
      searchData();
    } else {
      getData(currentPage);
    }
  }, [searchTerm, currentPage]);
 
  const searchData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
   

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const searchUrl =`/api/campaign/orgid=${orgCode}`;
      const response = await axios.get(searchUrl,  {
        headers: { Authorization: `Bearer ${token}` },
      });

      setCampaigns(response.data);
      setLoader(false);

    } catch (error) {
      handleError(error);
    }
  };
  const getData = async (pageNumber = 0) => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
  
      const trans = await axios.get(`/api/campaign/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
        params: { page: pageNumber }, 
        headers: { Authorization: `Bearer ${token}` },
      });
      setCampaigns(trans.data.content);
      setCurrentPage(trans.data.number);
      setTotalPages(trans.data.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };
  function onClickhandler(e, id, disabled) {
    // setLoading(id);
    setLoading(true)
    const token = JSON.parse(localStorage.getItem("data")).token;
   
    console.log(token);
    axios.post(`/api/campaign/update`, {
      id: id,
      disabled: disabled
    }, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false);
        toast.success("Status updated");
        getData();
      })
      .catch((error)=>{
        setLoading(false);
        handleError(error);
      })
  }
  const handleAddCampaigns = () => {
    setSelectedCampaign(null);
    setIsModalOpen(true);
  };

  const handleEditcampaign = (campaign) => {
    setSelectedCampaign(campaign);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();

  };
  useEffect(() => {
    if (searchTerm || startDate || endDate || enabled !== null) {
      // Ensure to fetch data when filters change
      getData(currentPage);
    }
  }, [searchTerm, startDate, endDate, enabled, currentPage]);
  
  // Filter data according to the current filters
  const filteredData = campaigns.filter((campaign) => {
    const campaignStartDate = new Date(campaign.startDate);
    const campaignEndDate = new Date(campaign.endDate);
    const selectedStartDate = new Date(startDate);
    const selectedEndDate = new Date(endDate);
  
    return (
      (campaign.campaignName && campaign.campaignName.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (startDate ? campaignStartDate >= selectedStartDate : true) &&
      (endDate ? campaignEndDate <= selectedEndDate : true) &&
      (enabled !== null ? campaign.disabled === (enabled === 'true') : true)
    );
  });
  

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };


  // const handleDeleteAccount = async (id) => {
  //   const token = JSON.parse(localStorage.getItem("data")).token;
  //   try {
  //     await axios.delete(`/api/accounts/delete/${id}`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });
  
  //     toast.success("Account Deleted successfully");
  //     getData();
  //   } catch (error) {
  //     handleError(error);
  //   }
  // };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } 
    else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  const handleViewCampaignPointsDetails = (campaignId) => {
    setSelectedCampaign(campaignId);
    navigate(`/users/campaign-points/${campaignId}`);
  };

 
  
 
  return (
    <div>
      <div>
        <Dashboard />
      </div>
      <div>
        <div className="col-13  mx-xl-auto ps-xl-10">
          <div className="card card-raised mt-10">
            <div className="card-body " >
            <div className="d-flex align-items-center justify-content-between">
              <span className="card-title">Campaigns </span>
             
            <span style={{position:"absolute", display: "flex", left: "380px", width: '300px' }}>
                  <input
                    type="search"
                    className='form-control'
                    placeholder='Search by Campaign name'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ paddingLeft: "2rem", width: "100%" }}
                  />
                  <span className="position-absolute" style={{ left: "15px", top: "50%", transform: "translateY(-50%)" }}>
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                </span>
                 <button className="btn btn-primary float-end" onClick={handleAddCampaigns}>
               ADD
            </button>
            </div>
            <div className="row mt-4">
                <Col className="me-1">
                  <Form.Group controlId="startDate">
                    <Form.Label>Start Date :</Form.Label>
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="endDate">
                    <Form.Label>End Date :</Form.Label>
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col className="me-1">
                  <Form.Group controlId="enabled">
                    <Form.Label>Filter by Status :</Form.Label>
                    <select
                      value={enabled}
                      onChange={(e) => setEnabled(e.target.value === "" ? null : e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="false">Enabled</option>
                      <option value="true">Disabled</option>
                    </select>
                  </Form.Group>
                </Col>
                </div>

            <br/>
            <Row>
            {filteredData.map((accd) => (
              
            <Col key={accd.id} md={4} className="mb-3">
<div className="card card-raised h-100">                
   <div className="card-body">
<Card style={{ cursor: 'pointer' ,marginRight:"10px"}}
                  title="View Details" onClick={() => handleViewCampaignPointsDetails(accd.id)}>
                <Card.Body>
                <Card.Text className="d-flex justify-content-end">
  <input
    className="form-check-input"
    id="flexSwitchCheckDefault"
    type="checkbox"
    defaultChecked={accd.disabled === false}
    style={{ cursor: "pointer", marginRight: "13px" }}
    onClick={(e) => {
      e.stopPropagation();
      onClickhandler(e, accd.id, !accd.disabled);
    }}
    title="Update Status"
    value={accd.id}
  />
  <FaEdit
    style={{ fontSize: "18px", cursor: "pointer", marginRight: "10px" }}
    onClick={(e) => {
      e.stopPropagation(); // Stop click event from bubbling up
      handleEditcampaign(accd);
    }}
    title="Edit Campaign"
  />
  <MdDeleteForever
    style={{ fontSize: "20px", cursor: "pointer" }}
    onClick={(e) => {
      e.stopPropagation();
      // handleDeleteAccount(accd.id);
    }}
    title="Delete Account"
  />
</Card.Text>

                  <div className="card-title">
                <Card.Text>{accd.campaignName}</Card.Text>
             </div>
             <Card.Text>{accd.startDate} to {accd.endDate}</Card.Text>
                
                  <Card.Text>{accd.description}</Card.Text>
                  <Card.Text className="card-footer bg-light text-center">Points : {accd.points}</Card.Text>
                 
               
                </Card.Body>
              </Card>
              </div>
              </div>
             
              </Col>
             
          ))}
                </Row>
                <div className="d-flex justify-content-center">
                <Pagination>
                  <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                  <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                  {renderPageNumbers()}
                  <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                  <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
                </Pagination>
              </div>
            </div>
          </div>
          <br />
          {isModalOpen && (
          <AddCampaigns isOpen={isModalOpen} onRequestClose={handleCloseModal} campaignDetails={selectedcampaign} />
          )}
        </div>
      </div>
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default Campaigns;
