import React, { useEffect, useState, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import moment from "moment";
import ManagerSidebar from "./managerSidebar";
import { useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}

function LiveGodownupdate(props) {
  const date = new Date();
  const date1 = new Date();
  date.setDate(date.getDate());
  date1.setDate(date1.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [openstock, setOpenstock] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    prodDo: [{ pid: "", qty: "", status: "", productname: "" }],
  });
  const [openingstock, setOpeningstock] = useState({
    date: currentDate,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    prodDo: [{ pid: "", qty: "", status: "", productname: "" }],
  });
  const [loading, setLoading] = useState(false); // Add loading state
  const [loader8, setLoader8] = useState(false);
  const [consumable, setConsumable] = useState([]);
  const [refill, setRefill] = useState([]);
  const inputRef = useRef(null);

  const [projectedValues, setProjectedValues] = useState([]);
  const [previousData, setPreviousData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoader8(true); // Start loading
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

        const projectedValues = await axios.get(
          `/api/CloseStockDo/live-godown-stock/orgcode=${orgcode}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const filteredProducts = projectedValues.data.prodDo.filter(product =>
          product.product.productTypeId === 1
        );
        setProjectedValues(filteredProducts);
        setErrorMessage("");

        setLoader8(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        handleError(error);
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.message);
        }
      } finally {
        setLoader8(false);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 60000);

    return () => clearInterval(interval); 
  }, []);

  const handleRefresh = async () => {
    setLoader8(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
      const projectedValues = await axios.get(
        `/api/CloseStockDo/live-godown-stock/orgcode=${orgcode}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const filteredProducts = projectedValues.data.prodDo.filter(product =>
        product.product.productTypeId === 1
      );
      setProjectedValues(filteredProducts);
      setErrorMessage("");

    } catch (error) {
      setLoader8(false);
    
      // handleError(error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
      }
    } finally {
      setLoader8(false);
    }
    };


  function handleError(error) {
    if (error.response?.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      toast.error(error.response?.data?.message || 'An error occurred.');
    }
  }

  const tableCellStyle = {
    padding: '5px',  // Reduced padding
    fontSize: '12px' // Reduced font size
  };

  const headerCellStyle = {
    padding: '8px',  // Reduced padding for header cells
    fontSize: '12px' // Reduced font size for header cells
  };
  return (
    
    <div className="col-8 mx-xl-auto ps-xl-10" style={{ position: 'absolute', top: '360px' }}>
        {loading && <div className="loading"></div>} 
        <div className="card card-raised" style={{ width: '99%', marginLeft:'4px' }}>
        <div className="card-body">
        <div className="d-flex justify-content-center align-items-center mb-2">
          <div className="card-title" style={{ fontSize: '18px' }}>
            Live Stock{""}
            {loader8 && (
              <div className="loader" style={{  left: '15px' ,  transform: 'translateY(-50%)'
              }}></div>
            )}
              <button
                className="btn btn-primary"
                style={{
                  fontSize: '9px',
                  padding: '7px 5px',
                  marginLeft: '10px',
                }}
                onClick={handleRefresh}
              >
                Refresh
              </button>
              {/* <div style={{ fontSize: '15px', marginTop: '10px',textAlign:"center" }}>
              Refill Sales
            </div> */}
            </div>
            
          </div>
          <br/>
          <br/>
          <div className="col-md-10 mx-auto triangle-container" style={{ position: 'relative', height: '100px', marginBottom: '-20px' }}>
  <div className="triangle" style={{
    width: '0',
    height: '0',
    borderLeft: '200px solid transparent',  // Increased size
    borderRight: '200px solid transparent', // Increased size
    borderBottom: '80px solid #6200ea',    // Increased height
    margin: '0 auto'
  }}>
     <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '14px',
                writingMode: 'horizontal-tb', // Ensure horizontal text
                whiteSpace: 'nowrap' // Prevent text wrapping
              }}>
                GODOWN
              </div>
  </div>
</div>
<div className="table-responsive col-md-9 mx-auto" style={{ padding: '0 10px', maxHeight: '350px',  overflowY: 'auto',   display: 'flex', }}>
            {projectedValues.length === 0 ? (
              <div style={{ textAlign: 'center', fontSize: '14px', padding: '20px' }}>No data available</div>
            ) : (
              <table className="table table-bordered" style={{ width: '100%', maxWidth: '600px' }}> {/* Set maxWidth if needed */}
                <thead>
                  <tr style={{ fontSize: "14px", backgroundColor: "#6200ea", color: "white", textAlign: "center" }}>
                    <th style={headerCellStyle}>Product</th>
                    <th style={headerCellStyle} id="full">Full</th>
                    <th style={headerCellStyle} id="empty">Empty</th>
                    <th style={headerCellStyle} id="defective">Defective</th>
                  </tr>
                </thead>
                <tbody>
                  {projectedValues.map((products) => (
                    <tr key={products.product.id}>
                       <th style={tableCellStyle}>
                       <div style={{ display: 'flex', alignItems: 'center' }}>
                       <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {products.product.image && products.product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${products.product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "30px", height: "30px", borderRadius: "50%" }}></div>
                          )}
                        </div>
                        <span style={{fontSize: '12px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                        {products.product.productname}
                          </span>
                       
                        </div>
                      </th>
                      {products.pdo.map((ps, index) => (
                        <td style={tableCellStyle} key={index}>{ps.qty}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          {errorMessage && (
            <div style={{ color: 'red', marginTop: '20px', textAlign: 'center' }}>
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(LiveGodownupdate);