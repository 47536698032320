import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import "./Spinner.css";
import VehicleAreamap from "./Addvehicleareamap";
import Search from "./search";

function VehicleAreamaster() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [truckInfo, setTruckInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [areaNames, setAreaNames] = useState([]);
  const [vehicleareaNames, setVehicleAreaNames] = useState([]);
  const [searchbydate, setSearchbydate] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm1, setSearchTerm1] = useState("");

  const date = new Date();
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      // Fetching truckinfo data
      const vehicleResponse = await axios.get(`/api/vehicle/orgid=${orgCode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTruckInfo(vehicleResponse.data);
 // Fetching area names
 const areaResponse = await axios.get(`/api/AreaMaster/orgcode=${orgCode}`, {
  headers: { Authorization: `Bearer ${token}` },
});
setAreaNames(areaResponse.data);
// Fetching area names
const VehicleareaResponse = await axios.get(`/api/VehicleAreaMapping/orgcode=${orgCode}/date=${currentDate}`, {
  headers: { Authorization: `Bearer ${token}` },
});
setVehicleAreaNames(VehicleareaResponse.data);
console.log(VehicleareaResponse);
      setLoading(false);
    } catch (error) {
    }
  };

  const searchName = async (text) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
    setSearchbydate(false);

    setLoading(true);
   
    try {
      const searchResponse = await axios.get(`/api/VehicleAreaMapping/orgcode=${orgCode}/date=${text}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSearchResults(searchResponse.data);
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred. Please try again.");
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleAddProduct = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };
  const filteredData = areaNames.filter((areaNames) =>
    areaNames.areaname.toLowerCase().includes((searchTerm || '').toLowerCase())
);
const filteredData1 = truckInfo.filter((truckInfo) =>
  truckInfo.truckinfo.toLowerCase().includes((searchTerm1 || '').toLowerCase())
);

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />

      {loading && <div className="loading"></div>}
      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Vehicle Area Map
           
              <div style={{ position: "relative" ,fontSize:"14px",right:"140px"}}>
        <Search searchName={searchName} />{" "}
      </div>
      <div style={{ position: "relative" ,fontSize:"14px",right:"200px"}}>

      <input
      type='text'
      // data-kt-user-table-filter='search'
      className='form-control form-control w-450px ps-14'
      placeholder='Search Areaname'
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
    </div>
    <div style={{ position: "relative" ,fontSize:"14px",right:"200px"}}>

<input
type='text'
// data-kt-user-table-filter='search'
className='form-control form-control w-450px ps-14'
placeholder='Search Vehicle'
value={searchTerm1}
onChange={(e) => setSearchTerm1(e.target.value)}
/>
</div>
              <button className="btn btn-primary" onClick={handleAddProduct}>
                ADD
              </button>
            </div>

            <br />
            <table className="table table-bordered">
  <thead>
    <tr>
    <th>Vehicle/Area</th>
      {filteredData1.map((info) => (
        <th key={info.id}>{info.truckinfo}</th>
      ))}
    </tr>
  </thead>
  <tbody>
  {filteredData.map((area) => (
    <tr key={area.id}>
      <td>{area.areaname}</td>
      {filteredData1.map((info) => (
        <td key={`${area.id}-${info.id}`}>
        {searchbydate
          ? vehicleareaNames.some(
              (item) => item.vehicleid === info.truckinfo && item.areaname === area.areaname
            ) && <span>&#10003;</span>
          : searchResults.some(
              (item) => item.vehicleid === info.truckinfo && item.areaname === area.areaname
            ) && <span>&#10003;</span>}
      </td>
      ))}
    </tr>
  ))}
</tbody>
</table>
            <div>
              <VehicleAreamap
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
              />
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default VehicleAreamaster;
