import React, { useState, useEffect } from "react";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

const MonthlyTarget = ({ isOpen, onRequestClose }) => {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [loader, setLoader] = useState(false);
  const [productTargets, setProductTargets] = useState({}); 
  const [currentMonth, setCurrentMonth] = useState(""); 

  const [monthlyTarget, setMonthlyTarget] = useState({
    date: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    products: [
      {
        productcode: "",
        productname: "",
        target: "",
      },
    ],
  });
  const url = "/api/productmonthlytarget/create";

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    const trans = await axios.get(
      `/api/product/productstatus=true/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(trans);

    setProduct(trans.data);
    setMonthlyTarget({
      ...monthlyTarget,
      products: trans.data.map((item) => ({
        productcode: item.productcode,
        productname: item.productname,
        target: 0,
      })),
    });

    setLoading(false);
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      // toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   rsubmit();
  // };

 
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;
    axios

      .post(url, monthlyTarget, { headers: { Authorization: `Bearer ${token}` } })

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Monthly Target Added successfully");
        onRequestClose();

      })

      .catch((error) => {
        console.error("Error:", error.response.data); 
        setLoading(false);
        toast.error(error.response.data.message);
      });
  }

const rhandle = (newDate, field, index) => {
  if (field === 'date') {
    const month = newDate.getMonth() + 1; // Months are 0-indexed, so add 1
    const year = newDate.getFullYear();
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}`; // Pad month with leading zero if needed

    setMonthlyTarget({ ...monthlyTarget, date: formattedDate });
  } else if (field === 'target') {
    const newProducts = [...monthlyTarget.products];
    newProducts[index] = { ...newProducts[index], target: newDate };

    setMonthlyTarget({ ...monthlyTarget, products: newProducts });
  } else {
    setMonthlyTarget({ ...monthlyTarget, [field]: newDate });
  }
};
 
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
      <Modal.Title>Monthly Target</Modal.Title>
    </Modal.Header>
    <Modal.Body>
   

      <form onSubmit={rsubmit}>
         <div className="mb-3">
    Month/Year:
    <DatePicker
      selected={monthlyTarget.date ? new Date(`${monthlyTarget.date}`) : null}
      onChange={(date) => rhandle(date, 'date')}
      showMonthYearPicker
      dateFormat="MM/yyyy"
      placeholderText="Select Month/Year"
    />
  </div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Product Code</th>
              <th>Target</th>
            </tr>
          </thead>
          <tbody>
            {monthlyTarget.products.map((product, index) => (
              <tr key={product.productcode}>
                <td>{product.productcode}</td>
                <td>{product.productname}</td>
                <td>
                  <input
                    id="id"
                    type="number"
                    placeholder="0"
                    style={{ border: "none" }}
                    onChange={(e) =>
                      rhandle(e.target.value, "target", index)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button variant="primary" type="submit">
          Save
        </Button>
          &nbsp;
          <Button variant="secondary" onClick={onRequestClose}>
            Close
          </Button>
        </form>
        {loading && <div className="loading"></div>}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default MonthlyTarget;
