import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";
import { Modal,Button } from "react-bootstrap";

function AddTariffFreight({ isOpen, onRequestClose}) {

  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [tariffFreight, setTariffFreight] = useState({
    date: currentDate,
    tariff:"",
    kilometer:"",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
 

  useEffect(() => {

  }, []);

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        "/api/tariffFrieght/create",
        tariffFreight,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("updated successfully");
        onRequestClose();
      })

      .catch((error) => {
        setLoading(false);
       handleError(error);
      });
  }

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const rhandle = (event, field) => {
    let actualValue = event.target.value;

    setTariffFreight({ ...tariffFreight, [field]: actualValue });
  };
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Create Tariff</Modal.Title>
    </Modal.Header>
    <Modal.Body>
     
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="date">Date</label>
                    <input
                      className="form-control"
                      id="date"
                      type="text"
                      placeholder="date"
                      value={tariffFreight.date}
                      //onChange={(e) => rhandle(e, "date")}
                    />
                  </div>
                </div>
                 
                {/* <div className="row">
                <div className="form-floating-sm mb-2">
                    <label htmlFor="tariff">Kilometer</label>

                    <input
                      className="form-control"
                      id="kilometer"
                      type="text"
                      placeholder="kilometer"
                      value={tariffFreight.kilometer}
                      onChange={(e) => rhandle(e, "kilometer")}
                    />
                   </div>
                   </div> */}

                   <div className="row">
                   <div className="form-floating-sm mb-2">
                    <label htmlFor="tariff">Tariff</label>

                    <input
                      className="form-control"
                      id="tariff"
                      type="text"
                      placeholder="0"
                      value={tariffFreight.tariff}
                      onChange={(e) => rhandle(e, "tariff")}
                    />
                    </div>
                    </div>
                
                    
                           
                    <br />

                    <input
                      type="submit"
                      value="Update"
                      className="btn btn-primary"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                  </form>
                </div>
              </div>
            </div>



         </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}

export default AddTariffFreight;
