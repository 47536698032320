import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import "@material/mwc-tab-bar/mwc-tab-bar.js";
import "@material/mwc-tab/mwc-tab.js";
import "@material/mwc-textfield/mwc-textfield.js";
import { Link } from "react-router-dom";
import Agentprofile from "./Agentprofile";
import UserAttendance from "./Userattendance";
import Salary from "./Salary";
import Employment from "./Employment";
import Agentinsurance from "./Agentinsurance";
 
  function UserProfile(props) {

  const { userid } = useParams();


  const[loading ,setLoading]=useState(false);
  const [userProfileDetails, setUserProfileDetails] = useState(null); // Declare and initialize userProfileDetails
  const [activeTab, setActiveTab] = useState('Agentprofile');

  const [profile, setProfile] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    image: null,
    imageMeta:"",
    country:"",
    company:"",
    email:"",
    gender:"",
    dob:"",
    userid:userid

  });
  useEffect(() => {
    // Fetch user profile details from the server based on userid
    const fetchProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/account-bio/userid=${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userProfileDetails = response.data;
        setProfile({
          ...profile,
          ...userProfileDetails
        });
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchProfile();
  }, [userid]);

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
       const [metadata, content] = reader.result.split(",");
       resolve({ metadata, content });};
       reader.onerror = function (error) {
       reject(error);};}); }

 const handleImageChange=async(event)=>{
   const convertedImage= await getBase64FromFile(event.target.files[0]);
   const imageObject = {...profile,
         imageMeta: convertedImage.metadata,
         image:convertedImage.content,};
         setProfile(imageObject);
 }



  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      await axios.post(`/api/account-bio/update`, profile, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Profile Updated Successfully");
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error: Failed to update profile");
    } finally {
      setLoading(false);
    }
  };


  
const handleChange = (event, field) => {
    let actualValue = event.target.value;

    setProfile({ ...profile, [field]: actualValue });
    
  };

  useEffect(() => {
    if (userProfileDetails) {
      setProfile({
        firstname: userProfileDetails.firstname || "",
        lastname: userProfileDetails.lastname || "",
        phone: userProfileDetails.phone || "",
        image: userProfileDetails.image || null,
        imageMeta: userProfileDetails.imageMeta || "",
        country: userProfileDetails.country || "",
        company: userProfileDetails.company || "",
        email: userProfileDetails.email || "",
        gender: userProfileDetails.gender || "",
        dob: userProfileDetails.dob || "",
      });
    }
  }, [userProfileDetails]);

  // Rest of the component code...


  return (
    <div>
      <AdminDashboard />
      <br/>
              <br/>
              <br/>
              
             
     
      <br />
 
 
      <div className="d-flex align-items-center ">
        <div className="col-10  align-items-center mx-xl-auto  ps-xl-10">
       
   
      <div
        className="container-xl p-10 px-11"
        style={{ position: "relative", left: "100px" }}
      >
        <mwc-tab-bar style={{ marginBottom: "-1px", marginLeft:'-220px',marginTop:'-50px'}}  activeIndex="0"
       >
            <mwc-tab
              label="Profile"
              icon="person"
              stacked
              active={activeTab === "Agentprofile"}
              onClick={() => setActiveTab("Agentprofile")}

            ></mwc-tab>
         
         <mwc-tab
              label="Employment"
              icon="calendar_today"
              stacked
              active={activeTab === "Employment"}
              onClick={() => setActiveTab("Employment")}
            ></mwc-tab>
            <mwc-tab
              label="Attendence"
              icon="event_available"
              stacked
              active={activeTab === "Userattendance"}
              onClick={() => setActiveTab("Userattendance")}
            ></mwc-tab>
          <mwc-tab
              label="Insurance"
              icon="real_estate_agent"
              stacked
              active={activeTab === "insurance"}
              onClick={() => setActiveTab("insurance")}
            ></mwc-tab>

         
            <mwc-tab
              label="Salary"
              icon="attach_money"
              stacked
              active={activeTab === "salary"}
              onClick={() => setActiveTab("salary")}
            ></mwc-tab>
         
        </mwc-tab-bar>
        </div>
        </div>
        </div>
       
{/* Tab content */}
     
          {/* Render content based on activeTab */}
            {activeTab === "Agentprofile" && (
            <Agentprofile/>
          )}
          
          {activeTab === "Employment" && (
            <Employment/>
          )}
          {activeTab === "Userattendance" && (
            <UserAttendance  />
          )}
          {activeTab === "insurance" && (
            <Agentinsurance  />
          )}
          {activeTab === "salary" && <Salary  />}
        </div>
  );
}
 
export default UserProfile;