import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import "@material/mwc-tab-bar/mwc-tab-bar.js";
import "@material/mwc-tab/mwc-tab.js";
import "@material/mwc-textfield/mwc-textfield.js";
import { Link } from "react-router-dom";

  function Salary(props) {

  


  return (
    <div>
      
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex">
              <div className="card-title">Salary Under Construction</div>{" "}
              <br/>
              <br/>
              <br/>
             
            
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
 
 
    
    </div>
  );
}
 
export default Salary;