import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useParams } from "react-router-dom";
 
import { Modal, Button } from "react-bootstrap";
 
import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
const ModelInc = ({ isOpen, onRequestClose }) => {
  const { userid } = useParams();
 
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;      
  const initialProductState = {
    underWriter: "",
    policyNumber: "",
    policyDate: "",
    expiryDate: "",
    fileSize: "",
    file:null,
    fileMeta:"",
    userid:userid,
   
   
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  };
 
  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);
const[gender,setGender]=useState([]);
const[relationship,setRelationship]=useState([]);
  const url = "/api/employeeinsurance/create ";
  const navigate = useNavigate();
 
  // useEffect(() => {
  //   fetchProductTypes();
  // }, []);
 
 
 
 
  const handleSubmit = async (e) => {
    e.preventDefault();
   
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post(url, newProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setLoading(false);
      toast.success("Insurance Details created successfully");  
      onRequestClose();
      setNewProduct(initialProductState);
    } catch (error) {
    }
  };
 
 
 
 
  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 400) {
      toast.error(error.response.data.code);
    } else {
    }
    setLoading(false);
  };
  const rhandle = (e) => {
    const { id, value } = e.target;
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [id]: value,
    }));
  };
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }
  const handlePdfFileChange = async (e) => {
    const file = e.target.files[0];
    const convertedPdf = await getBase64FromFile(file);
    const pdfObject = {
      ...newProduct,

      file: convertedPdf.content,
      fileSize: file.size,
      fileMeta:convertedPdf.metadata
    };

    setNewProduct(pdfObject);
  };
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Insurance Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                   
                    <label htmlFor="underWriter">UnderWriter</label>
                    <input
                      className="form-control"
                      id="underWriter"
                      type="text"
                      placeholder="underWriter"
                      value={newProduct.underWriter}
                      onChange={(e) => rhandle(e)}
                    />
                   
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                   
                    <label htmlFor="policyNumber">Policy Number</label>
                    <input
                      className="form-control"
                      id="policyNumber"
                      type="number"
                      placeholder="policy number..."
                      value={newProduct.policyNumber}
                      onChange={(e) => rhandle(e)}
                    />
                   
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                   
                    <label htmlFor="policyDate">Policy Date</label>
                    <input
                      className="form-control"
                      id="policyDate"
                      type="date"
                      placeholder="policyDate"
                      value={newProduct.policyDate}
                      onChange={(e) => rhandle(e)}
                    />
                   
                  </div>
                </div>
 
                <div className="row">
                  <div className="form-floating-sm mb-2">
                   
                    <label htmlFor="expiryDate">Expiry Date</label>
                    <input
                      className="form-control"
                      id="expiryDate"
                      type="date"
                      placeholder="expiryDate"
                      value={newProduct.expiryDate}
                      onChange={(e) => rhandle(e)}
                    />
                   
                  </div>
                </div>
 
                <div className="row">
                              <div className="form-floating-sm mb-2 d-flex align-items-center">
                                <label htmlFor="file" className="me-1">Upload Document:</label>
                                <input
                                  className="form-control smaller-input flex-grow-1"
                                  id="file"
                                  type="file"
                                  placeholder="Upload document"
                                  onChange={(e) => handlePdfFileChange(e)}
                                />
                               
                               
                              </div>
                            </div>
               
                 
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
 
                />
                 &nbsp;&nbsp;&nbsp;&nbsp;
 
                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  
 
              </form>
            </div>
          </div>
        </div>
 
      </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};
 
export default ModelInc;