import React, { useState, useEffect } from "react";
import ModelInc from "./ModelInc";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { Button } from "react-bootstrap";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import InsuranceeditModalPopup from "./Updateinsurance";
function Agentinsurance() {

  const { userid } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [insurance, setInsurance] = useState(null); // Initialize with null
  const[loading ,setLoading]=useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const navigate = useNavigate();

  const getData = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      const url = `/api/employeeinsurance/userid=${userid}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setInsurance(response.data);
      setLoading(false);

      console.log(response.data);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
    }
  };
  const onClickhandler = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try{
      await axios({
      url: "/api/employeeinsurance",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
        orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("deleted successfully");
    setLoading(false); 
    getData();
  } catch (error) {
    setLoading(false); 
  }
};

  const handleAddMember = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };
  const handleEdit = async (Id) => {
    setSelectedProductId(Id);
 
      setIsEditModalOpen(true);
   
  };
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    getData();
 
  };

  useEffect(() => {
    getData();
  }, [userid]);
  const onClickdownload = async (e, id) => {
    e.preventDefault();
    
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    try {
      const response = await axios({
        url: `/api/employeeinsurance/download/id=${id}`,
        method: "GET",
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const contentType = response.headers["content-type"];
      let extension = "";
  
      // Determine the file extension based on content type
      if (contentType.includes("application/pdf")) {
        extension = "pdf";
      } else if (contentType.includes("image/jpeg")) {
        extension = "jpg";
      } else if (contentType.includes("image/png")) {
        extension = "png";
      } else {
        console.error("Unsupported file type:", contentType);
        return;
      }
  
      // Create a blob URL and download link for the file
      const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `file.${extension}`);
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.error("Error downloading PDF. Please try again.");
    }
  };

  

  return (
    <div className="col-12 mx-xl-auto ps-xl-10">
 {/* <div>
        <AdminDashboard />
      </div>     */}
           {loading && <div className="loading"></div>} 

        <div className="card card-raised">
        <div className="card-body">
          <div className="card-title">Insurance Details</div>
          <Button className="btn btn-primary float-end" onClick={handleAddMember}>
            Add
          </Button>
          <br />
          <br />
          <table className="table table-bordered" style={{ position: "relative" }}>
            <thead>
              <tr
                style={{
                  fontSize: "17px",
                  backgroundColor: "#6200ea",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <th>UnderWriter</th>
                <th>Policy No.</th>
                <th>Policy Date</th>
                <th>Expiry Date</th>
                <th>Edit</th>

                <th>Document</th>

              </tr>
            </thead>
            <tbody>
              {!insurance ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    No data available.
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>{insurance.underWriter}</td>
                  <td>{insurance.policyNumber}</td>
                  <td>{insurance.policyDate}</td>
                  <td>{insurance.expiryDate}</td>
                  <td> 
                     <button
                      onClick={() => handleEdit(insurance.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              insurance.id,
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                       
                      
                   </td>
                  <th>
                                <button className="btn btn-primary"
                                  onClick={(e) =>
                                    onClickdownload(e, insurance.id)
                                  }
                                >
                                  Download
                                </button>
                              </th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <ModelInc isOpen={isModalOpen} onRequestClose={handleCloseModal} />
      {isEditModalOpen && (
              <InsuranceeditModalPopup
                isOpen={isEditModalOpen}
                onRequestClose={closeEditModal}
                Id={selectedProductId}
              />
            )}
    </div>
  );
}

export default Agentinsurance;
