import { blue } from "@mui/material";
import logo from "../images/energynow.gif";

function Privacypolicy() {
  return (
    <>
      <div className="col-xl-12 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4 ">
            <div className="col-xl-12 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div
                  className="card-body p-4 "
                  style={{ backgroundColor: "blue" }}
                >
                  <div
                    className="card-title d-flex"
                    style={{
                      fontSize: "25px",
                    }}
                  >
                    {" "}
                    <img
                      class="mb-3"
                      src={logo}
                      alt="..."
                      style={{ height: "40px", position: "relative" }}
                    />
                    <h4
                      style={{
                        position: "relative",
                        left: "430px",
                        top: "20px",
                        color: "white",
                      }}
                    >
                      Privacy Policy
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <h4>1. INTRODUCTION</h4>
            <h6>
              {" "}
              Cynefian Private Limited ("Company", "we", "us", "our") respects
              the privacy of our users ("user", "you", "your"). This Privacy
              Policy explains how we collect, use, disclose, and safeguard your
              information when you visit our website or use our services.
            </h6>{" "}
            <h4> 2. DATA COLLECTION </h4>
            <h6>
              We may collect and process the following data about you:
              <ul>
                {" "}
                <li>
                  <h5>Identity and Contact Data:</h5> This includes data which
                  relates to your identity such as your first name, last name,
                  username, title, billing address, delivery address, email
                  address, and telephone numbers.
                </li>{" "}
                <li>
                  <h5>Technical Data:</h5> This may include information such as
                  geolocation data, IP address, your login data, the type of
                  device you used to access our site or services, your internet
                  service provider, your device’s operating system, browser
                  type, the pages you viewed and the order of those pages, and
                  the date and time of your visit.
                </li>{" "}
                <li>
                  {" "}
                  <h5> Usage Data:</h5> This includes details of your usage of
                  our website, products, and services, including traffic data,
                  logs and other communication data and resources, as well as
                  information about how you respond to our marketing
                  communications.
                </li>{" "}
                <li>
                  {" "}
                  <h5>Marketing and Communications Data:</h5> This includes your
                  preferences in receiving marketing from us and our third
                  parties and your communication preferences.
                </li>
              </ul>
            </h6>{" "}
            <h4> 3. USE OF YOUR INFORMATION</h4>
            <h6>
              We use the collected data for various purposes:
              <ul>
                <li>To maintain and improve our website and services</li>
                <li>
                  To manage your account and provide you with customer support
                </li>{" "}
                <li>
                  To perform research and analysis about your use of our
                  services
                </li>
                <li>
                  {" "}
                  To develop and display content and advertising tailored to
                  your interests on our website and other sites
                </li>{" "}
                <li>
                  {" "}
                  To verify your eligibility and deliver prizes in connection
                  with contests and sweepstakes{" "}
                </li>{" "}
                <li>
                  {" "}
                  To enforce our terms and conditions To manage our business and
                  perform functions as otherwise described to you at the time of
                  collection.
                </li>
              </ul>
            </h6>{" "}
            <h4> 4. DATA DISCLOSURE</h4>
            <h6>
              {" "}
              We do not share your personal information with others except as
              indicated within this policy or when we inform you and give you an
              opportunity to opt-out of having your personal information shared.
            </h6>{" "}
            <h4> 5. DATA SECURITY</h4>
            <h6>
              {" "}
              We have implemented measures designed to secure your personal
              information from accidental loss and from unauthorized access,
              use, alteration, and disclosure.
            </h6>{" "}
            <h4> 6. DATA RETENTION</h4>
            <h6>
              {" "}
              We retain personal information for as long as necessary to fulfill
              the purposes for which you provided it, comply with our legal
              obligations, resolve disputes, and enforce our legal agreements
              and policies.
            </h6>{" "}
            <h4> 7. YOUR RIGHTS </h4>
            <h6>
              Under the data protection laws, you have a number of important
              rights. Those include rights to:
              <ul>
                {" "}
                <li>
                  {" "}
                  Fair processing of information and transparency over how we
                  use your use personal information
                </li>
                <li> Access to your personal information</li>{" "}
                <li>
                  {" "}
                  Require us to correct any mistakes in your information which
                  we hold
                </li>{" "}
                <li>
                  {" "}
                  Require the deletion of personal information concerning you in
                  certain situations
                </li>{" "}
                <li>
                  {" "}
                  Receive the personal information concerning you which you have
                  provided to us, in a structured, commonly used, and
                  machine-readable format
                </li>{" "}
                <li>
                  {" "}
                  Object at any time to processing of personal information
                  concerning you for direct marketing
                </li>{" "}
                <li>
                  {" "}
                  Object to decisions being taken by automated means which
                  produce legal effects concerning you or s imilarly
                  significantly affect you
                </li>{" "}
                <li>
                  {" "}
                  Object in certain other situations to our continued processing
                  of your personal information
                </li>
              </ul>
            </h6>{" "}
            <h4>8.CHANGES TO THE POLICY</h4>
            <h6>
              We will post any changes we make to our privacy policy on this
              page and, if they’re significant changes, we will notify you by
              sending you an email.
            </h6>
            <h4>9. CONTACT US</h4>
            <h6>
              {" "}
              Should you have any queries regarding this Privacy Policy, about
              our processing of your personal data or wish to exercise your
              rights you can contact Cynefian's Data Protection Officer using
              this email address: support@cynefian.com. This is without
              prejudice to your right to launch a claim with your data
              protection authority
            </h6>
          </div>
        </div>{" "}
      </div>
    </>
  );
}
export default Privacypolicy;
