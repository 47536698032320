import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
 
function ExpensesEditModalPopup({ isOpen, onRequestClose, Id }) {
    const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [uproduct1, setUproduct1] = useState({
    id: "",
    amount: "",
    description: "",
    vehicleid:"",
    date: currentDate,
   
 orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const [vehicle, setVehicle] = useState([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
 
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const response = await axios.get(`/api/cashpaymentexpenses/${Id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUproduct1(response.data);
      const vehicles = await axios.get(
        `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setVehicle(vehicles.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {
    if (Id) {
      getData();
    }
  }, [Id]);
 
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .post(
        "/api/cashpaymentexpenses/update",
        uproduct1,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Expenses updated successfully");
        onRequestClose();
        getData();
 
      })
      .catch((error) => {
        console.log(error);
      });
  }
 
  const rhandle = (event, field) => {
    const value = event.target.value;
    setUproduct1((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
 
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update Expenses</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4">
            <form onSubmit={(e) => rsubmit(e)}>
          
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="date">date</label>
                  <input
                    className="form-control"
                    id="date"
                    type="date"
                    placeholder="date"
                    value={uproduct1.date}
                    onChange={(e) => rhandle(e, "date")}
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="vehicleid">Vehicle</label>
                  <select
                    className="form-control"
                    id="vehicleid"
                    type="text"
                    placeholder="vehicleid"
                    value={uproduct1.vehicleid}
                    onChange={(e) => rhandle(e, "vehicleid")}
                  >
                     <option>Select Vehicle</option>
                        {vehicle.map((vehicle) => {
                          return (
                            <option value={vehicle.truckinfo}>
                              {vehicle.truckinfo}
                            </option>
                          );
                        })}
                      </select>
                  </div>

                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="amount">Amount</label>
                  <input
                    className="form-control"
                    id="amount"
                    type="text"
                    placeholder="amount"
                    value={uproduct1.amount}
                    onChange={(e) => rhandle(e, "amount")}
                  />
                  </div>
                  </div>

                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="description">Description</label>
                  <input
                    className="form-control"
                    id="description"
                    type="text"
                    value={uproduct1.description}
                    onChange={(e) => rhandle(e, "description")}
                  />
                  </div>
                  </div>
                  
              </div>
                 
                  <br />
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>   
            </form>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
export default ExpensesEditModalPopup;