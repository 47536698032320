import React from "react";
import AdminDashboard from "../components/admindashboard";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

function Vehiclereports() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleBack = () => {
    navigate("/users/Reportspage");
  };

  return (
    <div>
      <div>
        <br/>
        <br/>
        <AdminDashboard />
      </div>
      <br />
      <br />

      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group">
              <h3>Vehicle Reports</h3>
              <br/>
              <div className="row">
                <div className="col-4">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => handleNavigation("/users/VehicleMaintenanceReport")}
                  >
                    Vehicle Maintenance Report
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => handleNavigation("/users/FuelLog")}
                  >
                    Fuellog Report
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => handleNavigation("/users/Invoicereports")}
                  >
                    Vehicle Mileage Report
                  </button>
                </div>
              </div>

              
              <br/>
              

              <Button
                style={{ marginLeft: "5%" }}
                variant="secondary"
                onClick={handleBack}
              >
                Back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vehiclereports;
