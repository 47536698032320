import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import SettingseditModalPopup from "./Settingsedit";
import AddSettings from "./AddSettings";
import AdminDashboard from "./admindashboard";
import UpdateTariffFreight from "./updateTariffFreight";
import AddTariffFreight from "./addTariffFreight";
import TariffHistoryModal from "./tariffHistoryModal";

 
 
function TariffFreight() {
  const [tariffFreight, setTariffFreight] = useState([]);
  const [isTariffUpdateModalOpen, setIsTariffUpdateModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [tariffId, setTariffId] = useState(null);

  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  // const onClickhandler1 = async (e, vid, vusername, vrole) => {
  //   const token = JSON.parse(localStorage.getItem("data")).token;
  //   setLoading(vid);
  //   try {
  //     const vehicles = await axios.delete(`/api/accounts/delete/${vid}`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //       data: {
  //         id: vid,
  //         username: vusername,
  //         role: vrole,
  //         orgid: JSON.parse(localStorage.getItem("data")).orgcode,
  //       },
  //     });
  
  //     toast.success("Deleted successfully");
  //     getData();
  //     setLoading(null);
  //   } catch (error) {
  //     console.error("Error deleting vehicle:", error);
  //     setLoading(null);
  //     toast.error("Error deleting vehicle");
  //   }
  // };

 
  const getData = async () => {
  try {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
 
    const trans = await axios.get( `/api/tariffFrieght/orgcode=${orgcode}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
 
    console.log(trans);
 
    setTariffFreight(trans.data);
  } catch (error) {
    console.error("Error fetching :", error);
  } 
};
  useEffect(() => {
    getData();
  }, []);
 
 
  const handleTariffUpdate = (id) => {
    setTariffId(id);
    setIsTariffUpdateModalOpen(true);

   
  };
 
  const closeTariffUpdateModal = () => {
    setIsTariffUpdateModalOpen(false);
    getData();
 
  };
  const handleAddTariff = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const handleTariffHistory = () => {
    setHistoryModalOpen(true);
  };
 
  const closeHistoryModal = () => {
    setHistoryModalOpen(false);
    getData();
 
  };
  
 
  return (
    <div>
    <div>
      <AdminDashboard />
    </div>
    <br />
    <br />
    <br />
    <br />

    <div className="col-12 mx-xl-auto ps-xl-10">
      <div className="card card-raised">
        <div className="card-body">
          <div className="card-title form-group d-flex align-items-center justify-content-between">
            Tariff Freight   
         
            <button className="btn btn-primary " onClick={handleAddTariff}>
              ADD
            </button>
            </div>
            <br/>
            <div>
            <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    <th>Date</th>
                    {/* <th>Kilometer</th> */}
                    <th>Tariff</th>
                    {/* <th>Actions</th> */}

                  </tr>
                </thead>
                <tbody>
  {tariffFreight.length > 0 ? (
    tariffFreight.map((tar) => (
      <tr key={tar.id}>
        <td>{tar.date}</td>
        {/* <td>{tar.kilometer}</td> */}
        <td>{tar.tariff}</td>
        {/* <td>
          <button onClick={() => handleTariffHistory(tar.orgcode)}>History</button>
          <span style={{ marginRight: '5px' }}></span>
          <button onClick={() => handleTariffUpdate(tar.id)}>Update</button>
        </td> */}
      </tr>
    ))
  ) : (
    <tr>
      <td className="text-center" colSpan="3">No data available</td>
    </tr>
  )}
</tbody>

              </table>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <AddTariffFreight
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
        />
      )}
      {/* {isTariffUpdateModalOpen && (
        <UpdateTariffFreight
          isOpen={isTariffUpdateModalOpen}
          onRequestClose={closeTariffUpdateModal}
          tariffId={tariffId}
        />
      )}

{historyModalOpen && (
        <TariffHistoryModal
          isOpen={historyModalOpen}
          onRequestClose={closeHistoryModal}
        />
      )} */}


    </div>
  );
}

export default TariffFreight;