import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Spinner.css";

const ProductmasterModal = ({ isOpen, onRequestClose }) => {
  const date = new Date();
  date.setDate(date.getDate());
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const currentDate = `${year}-${month}-${day}`;

  const initialProductState = {
    productName: "",
    productCode: "",
    date: currentDate,
    productStatus: "",
    productTypeId:"",
    customerTypeId:"",
    hsnCode: "",
    netWeight: 0,
    gst: "",
    productType: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  };

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);
  const [productTypes, setProductTypes] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([]);
  
  const url = "/api/super-products/create";
  const navigate = useNavigate();

  useEffect(() => {
    fetchProductTypes();
  }, []);

  const fetchProductTypes = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get("/api/producttype", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProductTypes(response.data);

      const customerTypeResponse = await axios.get("/api/consumertype", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCustomerTypes(customerTypeResponse.data);
    } catch (error) {
      handleError(error);
    }
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post(url, newProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(false);
      toast.success("Product created successfully");
      onRequestClose();
      setNewProduct(initialProductState);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }


  const handleChange = (e) => {
    const { id, value } = e.target;
    const newdata = { ...newProduct };
      newdata[id] = value;
      if (id === "productTypeId") {
        if (value !== "1") {
          newdata.netWeight = 0;
        }
      }
    setNewProduct(newdata);
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Product Master</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                  <label htmlFor="productTypeId">Product Type</label>
                    <select
                      className="form-select"
                      id="productTypeId"
                      // value={newProduct.productTypeId}
                      onChange={handleChange}
                    >
                      <option value="">Select Product Type</option>
                      {productTypes.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.productType}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="productName">Product Name</label>
                    <input
                      className="form-control"
                      id="productName"
                      type="text"
                      placeholder="Product Name"
                      value={newProduct.productName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="productCode">Product Code</label>
                    <input
                      className="form-control"
                      id="productCode"
                      type="text"
                      placeholder="Product Code"
                      value={newProduct.productCode}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                  <label htmlFor="customerTypeId">Customer Type</label>
                    <select
                      className="form-select"
                      id="customerTypeId"
                      // value={newProduct.productTypeId}
                      onChange={handleChange}
                    >
                      <option value="">Select Customer Type</option>
                      {customerTypes.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.consumerType}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="hsnCode">HSN Code</label>
                    <input
                      className="form-control"
                      id="hsnCode"
                      type="text"
                      placeholder="HSN Code"
                      value={newProduct.hsnCode}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="gst">GST</label>
                    <input
                      className="form-control"
                      id="gst"
                      type="text"
                      placeholder="0"
                      value={newProduct.gst}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {newProduct.productTypeId ==='1' && (
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="netWeight">Net Weight</label>
                    <input
                      className="form-control"
                      id="netWeight"
                      type="text"
                      placeholder="Net Weight"
                      value={newProduct.netWeight}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                )}
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProductmasterModal;
