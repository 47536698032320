import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "../components/admindashboard";
import img from "../images/report.png";
import './totalervreport.css';

function TotalErvReport() {
  const [reportParams, setReportParams] = useState({
    month: new Date().getMonth() + 1, 
    year: new Date().getFullYear(),
  });

  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Handle form input changes
  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setReportParams((prevState) => ({
      ...prevState,
      [field]: field === "month" || field === "year" ? parseInt(value, 10) : value,
    }));
  };

  // Fetch invoice data
  const fetchInvoiceData = async () => {
    const { month, year } = reportParams;

    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const response = await axios.get(
        `/api/ervtotal/orgcode=${orgCode}/${month}/${year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setInvoiceData(response.data);
    } catch (error) {
      console.error("Error fetching invoice data:", error);
      toast.error("Failed to load invoice data.");
    } finally {
      setLoading(false);
    }
  };

  // Generate PDF report
  const generatePDF = () => {
    if (!invoiceData) {
      toast.error("No data available to generate PDF.");
      return;
    }

    const doc = new jsPDF();
    const tableColumn = ["ERVID", "Product", "Quantity"];
    const tableRows = [];

    // Create table rows from invoiceData
    Object.entries(invoiceData.product_totals).forEach(([product, ervidList]) => {
      ervidList.forEach(({ ervid, quantity }) => {
        const rowData = [ervid, product, quantity];
        tableRows.push(rowData);
      });
    });

    const imgData = img;
    const logoWidth = 70;
    const logoHeight = 40;
    const logoX = 10;
    const logoY = 10;
    doc.addImage(imgData, 'PNG', logoX, logoY, logoWidth, logoHeight);

    doc.setFontSize(18);
    doc.setTextColor(86, 150, 214);
    const textX = doc.internal.pageSize.width - 10;
    const textY = logoY + 10;
    doc.text("Vijay Gas Agencies", textX, textY, { align: 'right' });

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
    const addressY = textY + 10;
    doc.text(addressText, textX, addressY, { align: 'right' });

    doc.setFontSize(18);
    doc.setTextColor(86, 150, 214);
    const reportTitle = "Total ERV Report";
    const reportTitleY = logoY + logoHeight + 20;
    doc.text(20, reportTitleY, reportTitle);

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(`Month: ${reportParams.month}, Year: ${reportParams.year}`, 20, reportTitleY + 10);

    doc.autoTable(tableColumn, tableRows, { startY: reportTitleY + 20 });
    doc.save("erv.pdf");
  };

  return (
    <div>
      <AdminDashboard />
      <br/><br/>
      <div className="col-8 mx-xl-auto px-xl-10">
        <br/><br/> <br/><br/>
        <div className="card card-raised total-erv-card">
          <br />
          <br />
          <div className="card-body">
            <div className="card-title">Total ERV Report</div>

            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <img className="top-bar__logo" src={img} alt="logo" />
                <div className="ml-3">
                  <h3 style={{ fontFamily: 'Sans-serif' }}>Vijay Gas Agencies</h3>
                  <p style={{ fontFamily: 'Sans-serif' }}>1010/27 Mysore Road, Gundlupete - 571111</p>
                </div>
              </div>
            </div>
            <br/><br/>
            <form onSubmit={(e) => e.preventDefault()}>
              <div>
                &nbsp;&nbsp;&nbsp;
                <label htmlFor="month">Month:</label>
                <input
                  id="month"
                  type="number"
                  min="1"
                  max="12"
                  value={reportParams.month}
                  onChange={(e) => handleInputChange(e, "month")}
                />{" "}
                &nbsp;&nbsp;&nbsp;
                <label htmlFor="year">Year:</label>
                <input
                  id="year"
                  type="number"
                  value={reportParams.year}
                  onChange={(e) => handleInputChange(e, "year")}
                />
              </div>
              <br />
              <button
                type="button"
                className="btn btn-primary"
                onClick={fetchInvoiceData}
                disabled={loading}
              >
                Generate Report
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                className="btn btn-secondary"
                onClick={generatePDF}
                disabled={!invoiceData}
              >
                Download PDF
              </button>
            </form>
            {loading && <div className="loading">Loading...</div>}
            {invoiceData && (
              <div className="table-container">
                <br />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>ERVID</th>
                      <th>Product</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(invoiceData.product_totals).map(
                      ([product, ervidList]) =>
                        ervidList.map(({ ervid, quantity }) => (
                          <tr key={`${product}-${ervid}`}>
                            <td>{ervid}</td>
                            <td>{product}</td>
                            <td>{quantity}</td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default TotalErvReport;
