import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "../components/admindashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Import FontAwesome icons
import "./Rolereport.css";
import img from "../images/report.png";

function VehicleMaintenanceReport() {
  const [reportParams, setReportParams] = useState({
    vehicleid: "",
    year: new Date().getFullYear(),
  });

  const [maintenanceReport, setMaintenanceReport] = useState(null);
  const [vehicle, setVehicle] = useState([]);
  const [loading, setLoading] = useState(false);

  // Function to fetch available vehicles
  const fetchVehicles = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const response = await axios.get(`/api/vehicle/orgid=${orgCode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVehicle(response.data);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
      toast.error("Failed to load vehicles.");
    }
  };

  // Function to fetch maintenance report data
  const fetchMaintenanceReport = async () => {
    if (!reportParams.vehicleid) {
      toast.error("Please select a vehicle.");
      return;
    }

    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/vehiclemaintenance/${reportParams.vehicleid}/${reportParams.year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMaintenanceReport(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching maintenance report:", error);
      toast.error("Failed to load maintenance report.");
      setLoading(false);
    }
  };

  // Function to handle form input changes
  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setReportParams((prevState) => ({ ...prevState, [field]: value }));
  };

  // Function to handle year change
  const handleYearChange = (date) => {
    setReportParams((prevState) => ({ ...prevState, year: date.getFullYear() }));
  };

  // Function to generate PDF report
  const generatePDF = () => {
    if (!maintenanceReport) {
      toast.error("No data available to generate PDF.");
      return;
    }

    const doc = new jsPDF();
    const tableColumn = ["Month", "Date", "Description", "Amount"];
    const tableRows = [];

    for (const [month, monthData] of Object.entries(maintenanceReport.monthly_reports)) {
      const services = monthData.services;
      const totalAmount = monthData.total;

      if (services.length === 0) {
        const data = [month, "No services for this month", "", ""];
        tableRows.push(data);
      } else {
        services.forEach((service, index) => {
          const data = [
            index === 0 ? month : "",
            service.date,
            service.description,
            service.amount,
          ];
          tableRows.push(data);
        });
      }

      tableRows.push([
        { content: "Total", colSpan: 3, styles: { halign: "right", fillColor: [220, 220, 220] } },
        { content: totalAmount.toFixed(2), styles: { fillColor: [220, 220, 220] } },
      ]);
    }

    const imgData = img; // Assuming img is the base64 or URL of the logo image
    const logoWidth = 60;
    const logoHeight = 25;
    const logoX = 10; // Positioning logo to the left side
    const logoY = 10;
    doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);

    // Aligning the address to the right side of the page
    const pageWidth = doc.internal.pageSize.getWidth();
    const addressText = "Vijay Gas Agencies\n1010/27 Mysore Road, Gundlupete-571111";
    const addressLines = addressText.split("\n");
    const maxAddressWidth = Math.max(...addressLines.map(line => doc.getTextWidth(line)));
    const addressX = pageWidth - maxAddressWidth - 10; // 10 units padding from the right edge
    const addressY = logoY + logoHeight / 2;

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    addressLines.forEach((line, index) => {
      doc.text(line, addressX, addressY + (index * 5)); // Adjust 5 units line spacing
    });

    doc.setFontSize(14);
    doc.setTextColor(86, 150, 214);
    doc.text(16, 40, "Vehicle Maintenance Report");

    doc.setFontSize(10);
    doc.text(
      16,
      50,
      `Vehicle ID: ${maintenanceReport.vehicleid}, Year: ${maintenanceReport.year}`
    );

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 60,
      didParseCell: function (data) {
        if (data.row.raw[0].content === "Total") {
          data.cell.styles.fillColor = [220, 220, 220]; // Light grey
        }
      },
    });

    doc.text(150, 285, "***This is an e-generated report");
    doc.save("maintenance_report.pdf");
  };


  useEffect(() => {
    fetchVehicles();
  }, []);

  return (
    <div>
      <AdminDashboard />
      <br/><br/>
      <div className="col-8 mx-xl-auto px-xl-10">
        <br/><br/>
        <div className="card card-raised">
          
          <div className="card-body">
            <br />
            
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4">
                <form onSubmit={(e) => e.preventDefault()} className="text-center">
                <div className="d-flex justify-content-center align-items-center">
                    <img className="top-bar__logo" src={img} alt="logo" />
                    <div className="ml-3">
                      <h3 className="report-title">Vijay Gas Agencies</h3>
                      <p className="report-address">1010/27 Mysore Road, Gundlupete</p>
                    </div>
                  </div>
                  <br/>
                  <div className="card-title">Vehicle Maintenance Report</div>
                    <div className="form-group">
                      <label htmlFor="vehicle">
                        <FontAwesomeIcon icon={faCar} /> Vehicle:
                      </label>
                      <select
                        id="vehicle"
                        value={reportParams.vehicleid}
                        onChange={(e) => handleInputChange(e, "vehicleid")}
                        className="form-control"
                      >
                        <option value="">Select Vehicle</option>
                        {vehicle.map((v) => (
                          <option key={v.id} value={v.truckinfo}>
                            {v.truckinfo}
                          </option>
                        ))}
                      </select>
                    </div>
                    <br />
                    <div className="form-group">
                      <label htmlFor="year">Year:</label>
                      <DatePicker
                        selected={new Date(reportParams.year, 0)}
                        onChange={handleYearChange}
                        showYearPicker
                        dateFormat="yyyy"
                        className="form-control"
                      />
                    </div>
                    <br />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={fetchMaintenanceReport}
                      disabled={loading}
                    >
                      Generate Report
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={generatePDF}
                      disabled={!maintenanceReport}
                    >
                      <FontAwesomeIcon icon={faFilePdf} /> Download PDF
                    </button>
                  </form>
                  {loading && <div className="loading"></div>}
                  {maintenanceReport && (
                    <div>
                      <br />
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Month</th>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(
                            maintenanceReport.monthly_reports
                          ).map(([month, monthData]) => {
                            const services = monthData.services;
                            const totalAmount = monthData.total;
                            return (
                              <React.Fragment key={month}>
                                {services.length > 0 ? (
                                  services.map((service, index) => (
                                    <tr key={`${month}-${index}`}>
                                      {index === 0 && (
                                        <td rowSpan={services.length}>
                                          {month}
                                        </td>
                                      )}
                                      <td>{service.date}</td>
                                      <td>{service.description}</td>
                                      <td>{service.amount}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td>{month}</td>
                                    <td colSpan="3">No services for this month.</td>
                                  </tr>
                                )}
                                <tr className="total-row">
                                  <td colSpan="3" style={{ fontWeight: "bold", backgroundColor: "#dcdcdc" }}>Total</td>
                                  <td style={{ fontWeight: "bold", backgroundColor: "#dcdcdc" }}>{totalAmount.toFixed(2)}</td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default VehicleMaintenanceReport;
