import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminDashboard from "../components/admindashboard";
import Modal from "react-modal";
import "./Rolereport.css"; 
import img from "../images/report.png";

function Rolereport() {
  const [reportData, setReportData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [staffDetails, setStaffDetails] = useState([]);
  const [activeRole, setActiveRole] = useState(null);

  const getData = async () => {
    const storedData = JSON.parse(localStorage.getItem("data"));
    const token = storedData?.token;
    const orgcode = storedData?.orgcode;

    if (!token || !orgcode) {
      toast.error("Authorization details are missing.");
      setLoading(false);
      return;
    }

    try {
      console.log("Fetching data from API...");
      const response = await axios.get(`/api/role-report/orgid=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { t: new Date().getTime() },
      });
      console.log("API Response: ", response.data);
      if (response.data.accounts) {
        console.log("Accounts data found: ", response.data.accounts);
        setReportData(response.data);
      } else {
        console.log("No accounts data found in the response.");
        toast.error("No accounts data found in response.");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  };

  const generatePDF = () => {
    setLoading(true);
  
    try {
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.width;
  
      // Add Logo
      const margins = { top: 20, bottom: 20, left: 10, right: 10 };
    
      const imgData = img;
      const logoWidth = 60;
      const logoHeight = 25;
      const logoX = 10;
      const logoY = 10;
      doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);
  
      // Add Agency Name next to Logo
     
  
      // Add Address aligned to the right
      doc.setFontSize(16);
      doc.setTextColor(86, 150, 214);
      doc.text("Vijay Gas Agencies", doc.internal.pageSize.width - margins.right, margins.top + 10, { align: 'right' });
  
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      doc.text("1010/27 Mysore Road, Gundlupete - 571111", doc.internal.pageSize.width - margins.right, margins.top + 20, { align: 'right' });
  
      // Add Report Title below the logo
      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      const reportTitle = "Role Report";
      const reportTitleY = logoY + logoHeight + 20;
      doc.text(20, reportTitleY, reportTitle);
  
      // Generate Table
      const tableColumn = [
        "User Name",
        "Role",
        "Orgid",
        "Name",
        "Phone Number",
      ];
      const tableRows = [];
  
      reportData.accounts.forEach((item) => {
        const data = [
          item.username,
          item.role,
          item.orgid,
          item.name,
          item.phNo,
        ];
        tableRows.push(data);
      });
  
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: reportTitleY + 15,
        theme: "grid",
        headStyles: { fillColor: [86, 150, 214] },
        margin: { top: 30 },
        columnStyles: {
          0: { cellWidth: 50 }, // Adjust column widths if needed
          1: { cellWidth: 50 },
          2: { cellWidth: 50 },
          3: { cellWidth: 50 },
          4: { cellWidth: 50 },
        },
      });
  
      // Footer Note
      doc.setFontSize(10);
      doc.setTextColor(150, 150, 150);
      doc.text(
        10,
        doc.internal.pageSize.height - 20,
        "*** this is an e-generated report"
      );
  
      doc.save("role_report.pdf");
    } catch (error) {
      console.error("Error generating PDF: ", error);
      toast.error("Failed to generate report.");
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    getData();
  }, []);

  const handleRoleClick = (role) => {
    if (activeRole === role) {
      setActiveRole(null);
      setStaffDetails([]);
    } else {
      const roleData = reportData.accounts.filter((item) => item.role === role);
      setSelectedRole(role);
      setStaffDetails(roleData);
      setActiveRole(role);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRole(null);
    setStaffDetails([]);
  };

  return (
    <div>
      <AdminDashboard />
      <br /><br /><br />
      <div className="card card-raised mt-4">
        <div className="card-body">
          <br />
          <div className="col-xl-10 mx-xl-auto">
            <div className="card card-raised mb-3">
              <div className="card-body p-4">
                <form onSubmit={(e) => e.preventDefault()} className="text-center">
                  <div className="d-flex justify-content-center align-items-center">
                    <img className="top-bar__logo" src={img} alt="logo" />
                    <div className="ml-3">
                      <h3 className="report-title">Vijay Gas Agencies</h3>
                      <p className="report-address">1010/27 Mysore Road, Gundlupete</p>
                    </div>
                  </div>
                  <br />
                  <button
                    type="button"
                    disabled={loading}
                    className="btn btn-primary mb-3"
                    onClick={generatePDF}
                  >
                    {loading ? "Downloading..." : "Download"}
                  </button>
                </form>
                <table className="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th className="short-column">ID</th>
                      <th className="short-column">Role</th>
                      <th className="medium-column">Number of Staff</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="3">Loading data...</td>
                      </tr>
                    ) : reportData.role_report && reportData.role_report.length > 0 ? (
                      reportData.role_report.map((role, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{role.role}</td>
                            <td>
                              <button onClick={() => handleRoleClick(role.role)}>
                                {role["no of staff"]}
                              </button>
                            </td>
                          </tr>
                          {activeRole === role.role && (
                            <tr>
                              <td colSpan="3">
                                <table className="table table-bordered text-center">
                                  <thead>
                                    <tr>
                                      <th className="short-column">Username</th>
                                      <th className="short-column">Orgid</th>
                                      <th className="medium-column">Name</th>
                                      <th className="medium-column">Phone Number</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {staffDetails.map((staff, idx) => (
                                      <tr key={idx}>
                                        <td>{staff.username}</td>
                                        <td>{staff.orgid}</td>
                                        <td>{staff.name}</td>
                                        <td>{staff.phNo}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Rolereport;
