import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Link, useNavigate } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import "./Spinner.css";
import { Modal,Button } from "react-bootstrap";
  const OfficeFinancialCreate = ({ isOpen, onRequestClose }) => {
    const [time, setTime] = useState(new Date());
  const date = new Date();

  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");

  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const token = JSON.parse(localStorage.getItem("data")).token;
  const [uproductcost, setUproductcost] = useState({
    date: currentDate,
    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
    products: [
      {
        productcode: "",
        productname: "",
        productType:"",
        salesCost: "",
        additionalCost:"",
        newConCost:""
      },
    ],
  });

  const [product, setProduct] = useState([]);
  const [officeDetails, setOfficeDetails] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const url = "/api/officefinancial/create";
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
        setLoading(true);

        const pro = await axios.get(
            `/api/product/orgcode=${
                JSON.parse(localStorage.getItem("data")).orgcode
            }/productstatus=true`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        setProduct(pro.data);

        const response = await axios.get(
            `/api/officefinancial/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
            { headers: { Authorization: `Bearer ${token}` } }
        );
        const fetchedOfficeDetails = response.data;
        console.log("o", fetchedOfficeDetails);

        const updatedProducts = pro.data.map((item) => {
            const officeDetail = fetchedOfficeDetails.find(
                (officeItem) => officeItem.productcode === item.productcode
            );

            if (officeDetail) {
                return {
                    productcode: item.productcode,
                    productname: item.productname,
                    productType: item.productType,
                    salesCost: officeDetail.salecost,
                    additionalCost: officeDetail.additionalcost,
                    newConCost: officeDetail.newconection,
                };
            } else {
                return {
                    productcode: item.productcode,
                    productname: item.productname,
                    productType: item.productType,
                    salesCost: item.pprice ? item.pprice.price : null,
                    additionalCost: item.addPrice ? item.addPrice.additionalPrice : null,
                    newConCost: item.newConPrice ? item.newConPrice.newConPrice : null,
                };
            }
        });

        setUproductcost({
            ...uproductcost,
            products: updatedProducts,
        });

        setOfficeDetails(fetchedOfficeDetails);
        setLoading(false);
    } catch (error) {
        setLoading(false);
        handleError(error);
    }
};


 

  useEffect(() => {
    getData();
  }, []);


  function rsubmit(e) {
    e.preventDefault();
    
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        uproductcost,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        navigate("/users/financialdetails");
        toast.success("Product cost updated successfully");
        onRequestClose(); 
      })
      .catch((error) => {
       handleError(error);
      });
  }
  function rhandle(e, field, index) {
    let actualValue = e.target.value;
   
    if (field === "salesCost" || field === "newConCost" || field === "additionalCost") {
      const newproduct = [...uproductcost.products];
      newproduct[index] = { ...newproduct[index], [field]: actualValue };
      setUproductcost({ ...uproductcost, products: newproduct });
    } else {
      setUproductcost({ ...uproductcost, [field]: actualValue });
    }
  }

      function handleError(error) {
        if (error.response && error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else {
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error('An error occurred.');
          }
        }
      }
  

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Update Product Cost</Modal.Title>
      </Modal.Header>
      <Modal.Body>
            

      <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    <div>
                      <label for="date">Date</label>
                      <input
                        id="date"
                        value={currentDate}
                        onChange={(e) => rhandle(e, "date")}
                      />{" "}
                    
                                         </div>
                    <br />
                    <br />
                    <div>
                      <div>
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>Product Code</th>
                              <th>Product Name</th>
                              <th>Sales Cost</th>
                              <th>Additional Cost</th>
                              <th>New Connection Cost</th>
                            </tr>
                          </thead>
                          <tbody>
                            {uproductcost.products.map((product, index) => {
                              return (
                                <>
                                  <tr key={product.productcode}>
                                    <td>{product.productcode}</td>
                                    <td>{product.productname}</td>
                                    <td>
                                      <input
                                        id="id"
                                        type="text"
                                        placeholder={uproductcost.products[index].salesCost}
                                        style={{ border: "none" }}
                                        onChange={(e) =>
                                          rhandle(e, "salesCost", index)
                                        }
                                      />
                                    </td>

                                    <td>
                                      <input
                                        id="id"
                                        type="text"
                                        placeholder={uproductcost.products[index].additionalCost}
                                        style={{ border: "none" }}
                                        onChange={(e) =>
                                          rhandle(e, "additionalCost", index)
                                        }
                                        disabled={product.productType === "Consumables"}
                                      />
                                    </td>

                                    <td>
                                      <input
                                        id="id"
                                        type="text"
                                        placeholder={uproductcost.products[index].newConCost}
                                        style={{ border: "none" }}
                                        onChange={(e) =>
                                          rhandle(e, "newConCost", index)
                                        }
                                        disabled={product.productType === "Consumables"}
                                      />
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>{" "}
                    </div>
<div className="form-group d-flex align-items-center justify-content-between mt-5">
                    <button
                      type="submit"
                      value="Save"
                      className="btn btn-primary"
                      onClick={(e) => rsubmit(e)}
                    >
                      Save
                    </button>
                    <Button variant="secondary" onClick={onRequestClose}>
Close
</Button>
</div>
                  </form>
                </div>
              </div>
            </div>


            </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default OfficeFinancialCreate;
