// ConsumerDetailModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import axios from '../api/baseurl';
import { toast } from 'react-toastify';

function ConsumerDetailModal({ show, handleClose, consumerNumber }) {
  const [consumerData, setConsumerData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show && consumerNumber) {
      fetchConsumerData(consumerNumber);
    }
  }, [show, consumerNumber]);

  const fetchConsumerData = async (consumerNumber) => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/consumerdetails/consumernumber=${consumerNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setConsumerData(response.data);
      setLoading(false);
    } catch (error) {
      toast.error("An error occurred while fetching consumer data.");
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Consumer Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div>Loading...</div>
        ) : (
          consumerData && (
            <Table striped bordered hover size="sm">
              <tbody>
                <tr>
                  <td>Consumer Number</td>
                  <td>{consumerNumber}</td>
                </tr>
                <tr>
                  <td>Consumer Name</td>
                  <td>{consumerData.consumername}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{consumerData.phone}</td>
                </tr>
                <tr>
                  <td>Org Code</td>
                  <td>{consumerData.orgcode}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>{`${consumerData.address1}, ${consumerData.address2}, ${consumerData.address3}`}</td>
                </tr>
                <tr>
                  <td>Loyalty Points</td>
                  <td>{consumerData.loyaltyPoints}</td>
                </tr>
                <tr>
                  <td>Latitude</td>
                  <td>{consumerData.latitude}</td>
                </tr>
                <tr>
                  <td>Longitude</td>
                  <td>{consumerData.longitude}</td>
                </tr>
                <tr>
                  <td>Area</td>
                  <td>{consumerData.area}</td>
                </tr>
              </tbody>
            </Table>
          )
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConsumerDetailModal;
