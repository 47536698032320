import React, { useState, useEffect } from "react";
import axios from "../api/baseurl";
import "./ReportFooter.css"; // Import the CSS file
import { Link, useNavigate } from "react-router-dom";
import AdminDashboard from "./admindashboard";

function ReportFooter() {
  const [profile, setProfile] = useState({
    id: "",
    companyLogo: null,
    imageMeta: "",
  });

  const [orgDetails, setOrgDetails] = useState({
    name: "",
    orgcode: "",
    gstin: "",
    pan: "",
    tan: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    pincode: "",
    state: "",
    contact: "",
    username: "",
  });

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/companydetails`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data && response.data.length > 0) {
          setProfile(response.data[0]);
        }
      } catch (error) {
        console.error("Error fetching company details:", error);
      }
    };

    const fetchOrgDetails = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
        const orgResponse = await axios.get(`/api/organizationprofiles/Orgcode=${orgcode}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOrgDetails(orgResponse.data);
      } catch (error) {
        console.error("Error fetching organization details:", error);
      }
    };

    fetchProfile();
    fetchOrgDetails();
  }, []);

  return (
    <div className="content-wrapper">
      <AdminDashboard />
      <br />
      <br />
      <br />
      <header className="header p-4">
        <div className="header-content" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className="fw-500">
            <div className="form-floating-sm mb-2">
              {/* <label htmlFor="companyLogo">VM Logo:</label> */}
              <div className="d-flex align-items-center">
                {profile.companyLogo ? (
                  <img
                    className="img-fluid circular-image mb-1"
                    id="companyLogo"
                    src={`${profile.imageMeta},${profile.companyLogo}`}
                    alt="Company Logo"
                    style={{
                      width: "100px",
                      height: "50px",
                      background: "#ccc",
                      marginLeft: "300px", // Move the logo 45px to the right
                    }}
                  />
                ) : (
                  <div
                    className="img-fluid circular-image mb-1"
                    id="companyLogo"
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                      background: "#ccc",
                      marginLeft: "45px", // Move the logo 45px to the right
                    }}
                  ></div>
                )}
              </div>
            </div>
          </div>
          <div className="organization-details" style={{ textAlign: 'right', marginRight: '450px' }}>
            <h4 style={{display: 'flex', justifyContent: 'space-around', maxWidth: '400px'}}>{orgDetails.name}</h4>
            <p style={{display: 'flex', justifyContent: 'space-around', maxWidth: '400px'}}>{orgDetails.addressLine1}, {orgDetails.addressLine2}</p>
            <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '400px' }}>
              <p>Phone: {orgDetails.contact}</p>
             <span   style={{marginRight:'15px'}}></span>
             
              <p>Email: {orgDetails.username}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '400px' }}>
              <p>GSTIN: {orgDetails.gstin}</p>
              <p>PAN: {orgDetails.pan}</p>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default ReportFooter;