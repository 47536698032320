import React, { useEffect, useState } from "react";
import {Button, Tab, Tabs } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import "@material/mwc-tab-bar/mwc-tab-bar.js";
import "@material/mwc-tab/mwc-tab.js";
import "@material/mwc-textfield/mwc-textfield.js";
import Drivers from "./drivers";
import InvoiceNumbers from "./invoices";
import ErvNumbers from "./ervNumbers";
 
  function ViewTruckInfo() {

  const { truckinfo } = useParams();

  const [activeTab, setActiveTab] = useState('drivers');
  useEffect(() => {
 
  }, []);

  const navigate = useNavigate();
  const handleBackButtonClick = () => {
    navigate(-1);
  };


  return (
    <div>
      <AdminDashboard />
<div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
          <div className="d-flex align-items-center mb-3">
              <div className="flex-grow-1 text-center">
                <h5>{truckinfo}</h5>
              </div>
              <Button variant="secondary" onClick={handleBackButtonClick}>
                Back
              </Button>
            </div>
            <Tabs
              id="truckinfo-tabs"
              activeKey={activeTab}
              onSelect={(tab) => setActiveTab(tab)}
              className="mb-3"
            >
              <Tab eventKey="drivers" title="Drivers">
              {activeTab === 'drivers' && (
                <Drivers selectedTruck={truckinfo} />
              )}
              </Tab>
              <Tab eventKey="invoice" title="Invoices">
              {activeTab === 'invoice' && (
                <InvoiceNumbers selectedTruck={truckinfo} />
              )}
              </Tab>
              <Tab eventKey="erv" title="ERVs">
              {activeTab === 'erv' && (
                <ErvNumbers selectedTruck={truckinfo} />
              )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default ViewTruckInfo;