import React from "react";
import { Modal, Button } from 'react-bootstrap';  // Import Modal and Button from react-bootstrap

const ProductPointsHistory = ({ onClose, salesPoints, additionalPoints, newConPoints }) => {
  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {salesPoints.length > 0 && "Sales Points History"}
          {additionalPoints.length > 0 && "Additional Points History"}
          {newConPoints.length > 0 && "New Connection Points History"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {salesPoints.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Sales Points</th>
              </tr>
            </thead>
            <tbody>
              {salesPoints.map((entry) => (
                <tr key={entry.id}>
                  <td>{entry.date}</td>
                  <td>{entry.points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {additionalPoints.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Additional Points</th>
              </tr>
            </thead>
            <tbody>
              {additionalPoints.map((entry) => (
                <tr key={entry.id}>
                  <td>{entry.date}</td>
                  <td>{entry.points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {newConPoints.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>New Connection Points</th>
              </tr>
            </thead>
            <tbody>
              {newConPoints.map((entry) => (
                <tr key={entry.id}>
                  <td>{entry.date}</td>
                  <td>{entry.points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProductPointsHistory;
