import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";

const ConversionModal = ({ isOpen, onRequestClose}) => {
  const date = new Date();
  const date1 = new Date();
  date.setDate(date.getDate());
  date1.setDate(date1.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [newProduct, setNewProduct] = useState({
    id: "",
    customerName: "",
    customerNumber: "",
    phoneNumber: "",
    quantity: "",
    fromProduct: "",
    toProduct: "",
    date: currentDate,   
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [fromProducts, setFromProducts] = useState([]);
  const [toProducts, setToProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isOpen) {
          const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
          const token = JSON.parse(localStorage.getItem("data")).token;

          const productResponse = await axios.get(
            `/api/product/productstatus=true/orgcode=${orgCode}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          console.log("Response from Product API:", productResponse.data);
          setProducts(productResponse.data);
          
          const fromProductList = productResponse.data.filter(
            (product) => product.productType === "Refill Sales"
          );
          setFromProducts(fromProductList);

          const toProductList = productResponse.data.filter(
            (product) => product.productType !== "Refill Sales"
          );
          setToProducts(toProductList);
        }
      } catch (error) {
        handleError(error);
      }
    };

    fetchData();
  }, [isOpen]);

  const url = "/api/conversion-exchange/create";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    const token = JSON.parse(localStorage.getItem("data")).token;
    
    try {
      const response = await axios.post(url, newProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setLoading(false);
      toast.success("Created successfully");
onRequestClose();
      setNewProduct({
        id: "",
        customerName: "",
        customerNumber: "",
        phoneNumber: "",
        quantity: "",
        fromProduct: "",
        toProduct: "",
        date: "",   
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
      });
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setNewProduct(prevState => ({
      ...prevState,
      [id]: value
    }));
  }

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Conversion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="col-xl-10 mx-xl-auto">
            <div className="card card-raised mb-3">
              <div className="card-body p-4">
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="date">Date</label>
                    <input
                      className="form-control"
                      id="date"
                      type="date"
                      placeholder="Product Code"
                      value={newProduct.date}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="fromProduct">Product From</label>
                    <select
                      className="form-select"
                      id="fromProduct"
                      value={newProduct.fromProduct}
                      onChange={handleChange}
                    >
                      <option value="">Select Product</option>
                      {fromProducts.map((product, index) => (
                        <option key={index} value={product.productname}>
                          {product.productname}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="toProduct">To Product</label>
                    <select
                      className="form-select"
                      id="toProduct"
                      value={newProduct.toProduct}
                      onChange={handleChange}
                    >
                      <option value="">Select Product</option>
                      {fromProducts.map((product, index) => (
                        <option key={index} value={product.productname}>
                          {product.productname}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="quantity">Quantity</label>
                    <input
                      className="form-control"
                      id="quantity"
                      type="text"
                      placeholder="quantity"
                      value={newProduct.quantity}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="customerName">Customer Name</label>
                    <input
                      className="form-control"
                      id="customerName"
                      type="text"
                      placeholder="Customer Name"
                      value={newProduct.customerName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="customerNumber">Customer Number</label>
                    <input
                      className="form-control"
                      id="customerNumber"
                      type="text"
                      placeholder="Customer Number"
                      value={newProduct.customerNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      className="form-control"
                      id="phoneNumber"
                      type="text"
                      placeholder="Phone Number"
                      value={newProduct.phoneNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <Button variant="primary" type="submit" disabled={loading}>Save</Button>
                    <Button variant="secondary" onClick={onRequestClose} className="ms-2">Close</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ConversionModal;
