import React, { useState, useEffect } from "react";
import "@material/mwc-tab-bar/mwc-tab-bar.js";
import "@material/mwc-tab/mwc-tab.js";
import "@material/mwc-textfield/mwc-textfield.js";
import axios from "../api/baseurl";
import "./Spinner.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./admindashboard";
function Changeofpassword() {
  const token = JSON.parse(localStorage.getItem("data")).token;

  const [pass, setpass] = useState({
    password: "",
    passwordConfirm: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setpass({ ...pass, [field]: actualValue });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(pass);

    //submit the data to server to generate token
    axios
      .post("/api/changePassword", pass, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => response.data)
      .then(
        (result) => {
          console.log(result);
          setLoading(false);
          navigate("/signin");
          toast.success("Password Change Successfully");
        },
        (error) => {
          this.setState({ error });
        }
      );
  };
  return (
    <div class="bg-dark">
      <br />
      <br />
      <br />
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div class="container">
              <div class="row justify-content-center">
                <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-8">
                  <div className="card card-raised shadow-10 mt-5 mt-xl-10 mb-4">
                    <div class="card-body p-5">
                      <div className="card-title">Change Your Password</div>
                      <br />

                      <div className="col-xl-10 mx-xl-auto ">
                        <div className="card card-raised mb-3">
                          <div className="card-body p-4 ">
                            <form onSubmit={(e) => handleSubmit(e)}>
                              <div className="mb-4">
                                <input
                                  class="form-control"
                                  label="New Password"
                                  outlined
                                  placeholder="Enter new password"
                                  type="password"
                                  id="password"
                                  value={pass.password}
                                  onChange={(e) => handleChange(e, "password")}
                                ></input>
                              </div>

                              <div className="mb-8">
                                <input
                                  class="form-control"
                                  label="Confirm New Password"
                                  outlined
                                  placeholder="Confirm password"
                                  type="password"
                                  id="passwordConfirm"
                                  value={pass.passwordConfirm}
                                  onChange={(e) =>
                                    handleChange(e, "passwordConfirm")
                                  }
                                ></input>
                              </div>
                              <div className="text-end">
                                <button
                                  className="btn btn-secondary"
                                  type="submit"
                                >
                                  Reset Password
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}
export default Changeofpassword;
