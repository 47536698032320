import React, { useState } from "react";
import { Link } from "react-router-dom";
import "@material/mwc-textfield/mwc-textfield.js";
import axios from "../api/baseurl";
import "./Spinner.css";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./footer";
function Forgotpassword() {
  const [admindata, setAdata] = useState({
    token: "",
  });
  const navigate = useNavigate();

  const url = `/api/validatePasswordReset?token=${admindata.token}`;

  const [loading, setLoading] = useState(false);
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(url)

      .then(
        (res) => {
          console.log(res.data);
          setLoading(false);
          navigate("/resetpassword");
          toast("OTP validated successfully");
         })
  .catch((error) => {
    console.error("Error:", error);
    setLoading(false); 
    toast.error("An error occurred. Please try again later.");
  });
}
  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setAdata({ ...admindata, [field]: actualValue });
  };
 
  return (
    <div className="bg-primary">
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-lg-4">
                  <div className="card card-raised shadow-10 mt-5 mt-xl-10 mb-4">
                    <div className="row g-0">
                      <div className="col-lg-12 col-lg-12">
                        <div className="card-body p-5">
                          <div className="text-center">
                            <img
                              className="mb-3"
                              src="assets/img/icons/background.svg"
                              alt="..."
                              style={{ height: "48px" }}
                            />
                            <h1 className="display-5 mb-0">Verify</h1>
                          </div>
                          <form onSubmit={(e) => rsubmit(e)}>
                            <div className="mb-4">
                              <input
                                class="form-control"
                                type="text"
                                placeholder="enter OTP"
                                id="token"
                                value={admindata.token}
                                onChange={(e) => handleChange(e, "token")}
                              ></input>
                            </div>
                            <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                              <Link
                                className="small fw-500 text-decoration-none"
                                to="/signin"
                              >
                                Return to login
                              </Link>
                              <input
                                type="submit"
                                className="btn btn-primary"
                                value="otpvarification"
                              />
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="text-center mb-5">
                        <Link
                          className="small fw-500 text-decoration-none link-white"
                          to="/register"
                        >
                          Need an account? Sign up!
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>

        <div id="layoutAuthentication_footer">
         <Footer/>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}
export default Forgotpassword;
