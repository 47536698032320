import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
 
function ProductmastereditModalPopup({ isOpen, onRequestClose, Id }) {
  const [uproduct1, setUproduct1] = useState({
    id: "",
    productName: "",
    productCode: "",
    productTypeId:"",
    customerTypeId:"",
    hsnCode:"",
    netWeight:"",
    date: "",
    gst:"",
    image:"",
    imageMeta:"",
    proType:{
      productType:"",
    },
    custType:{
      consumerType:"",
    },
 orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
 
  const [loading, setLoading] = useState(false);
  const [consumerTypes, setConsumerTypes] = useState([]);
  const navigate = useNavigate();
 
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const response = await axios.get(`/api/super-products/${Id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUproduct1(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getConsumerTypes = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get("/api/consumertype", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setConsumerTypes(response.data);
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {
    if (Id) {
      getData();
      getConsumerTypes();
    }
  }, [Id]);
 
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .post(
        "/api/super-products/update",
        uproduct1,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Product updated successfully");
        onRequestClose();
        getData();
 
      })
      .catch((error) => {
        console.log(error);
      });
  }
 
  const rhandle = (event, field) => {
    const value = event.target.value;
    setUproduct1((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
       const [metadata, content] = reader.result.split(",");
       resolve({ metadata, content });};
       reader.onerror = function (error) {
       reject(error);};}); }

       const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          try {
            const { metadata, content } = await getBase64FromFile(file);
      
      setUproduct1(prevState => ({
        ...prevState,
        imageMeta: metadata,
        image: content
      }));
          } catch (error) {
            console.error("Error converting file to base64", error);
          }
        }
      };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update Product</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4">
            <form onSubmit={(e) => rsubmit(e)}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="productType">Product Type</label>
                  <input
                    className="form-control"
                    id="productType"
                    type="text"
                    placeholder="product type"
                    value={uproduct1.proType.productType}
                    onChange={(e) => rhandle(e, "productType")}
                    readOnly
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="productName">Product Name</label>
                  <input
                    className="form-control"
                    id="productName"
                    type="text"
                    placeholder="productName"
                    value={uproduct1.productName}
                    onChange={(e) => rhandle(e, "productName")}
                    readOnly
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="productCode">Product Code</label>
                  <input
                    className="form-control"
                    id="productCode"
                    type="text"
                    placeholder="productCode"
                    value={uproduct1.productCode}
                    onChange={(e) => rhandle(e, "productCode")}
                    readOnly
                  />
                  </div>
                  <div className="row">
                <div className="form-floating-sm mb-2">
                  <label htmlFor="customerTypeId">Customer Type</label>
                  <select
                    className="form-select"
                    id="customerTypeId"
                    value={uproduct1.customerTypeId}
                    onChange={(e) => rhandle(e, "customerTypeId")}
                  >
                    <option value="">Select Customer Type</option>
                    {consumerTypes.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.consumerType}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="hsnCode">HSN Code</label>
                  <input
                    className="form-control"
                    id="hsnCode"
                    type="text"
                    placeholder="HSN Code"
                    value={uproduct1.hsnCode}
                    onChange={(e) => rhandle(e, "hsnCode")}
                  />
                  </div>
                  </div>

                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="gst">GST</label>
                  <input
                    className="form-control"
                    id="gst"
                    type="text"
                    placeholder="0"
                    value={uproduct1.gst}
                    onChange={(e) => rhandle(e, "gst")}
                  />
                  </div>
                  </div>
                  {uproduct1.productTypeId === 1 && (
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="netweight">Net Weight</label>
                  <input
                    className="form-control"
                    id="netWeight"
                    type="text"
                    placeholder="net weight"
                    value={uproduct1.netWeight}
                    onChange={(e) => rhandle(e, "netWeight")}
                  />
                  </div>
                  </div>
                  )}
              </div>
              <div className="col-md-6">
  <div className="form-floating-sm mb-2">
    <label htmlFor="image">Upload Image</label>

    <input
      className="form-control"
      id="image"
      type="file" 
      onChange={handleImageChange}
      accept="image/jpeg, image/png, application/pdf" 
      />
  </div>
</div>

                  <br />
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                
            </form>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
export default ProductmastereditModalPopup;