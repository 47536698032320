import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import Search from "./search";
import { toast } from "react-toastify";
import AdminDashboard from "./admindashboard";
import { IoSearchOutline } from "react-icons/io5";
import "reactjs-popup/dist/index.css";
import "./Spinner.css";
import "./Loader.css";
import Modal from "react-modal";
import { BiEditAlt } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import { BsXCircle } from "react-icons/bs";
import { AiOutlineCheck } from "react-icons/ai";
import Overheadmodal from "./overheadmodal";
import Overheadeditmodal from "./OverheadEdit";
import Createofficesale from "./Officesale";
import ReciveopenModal from "./Receivemodal";
import PerformModal from "./Performmodal";

function ConsumableStock() {
  const [openstock, setOpenstock] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [product, setProduct] = useState([]);
  const [searchbytoutvehicle, setSearchbytoutvehicle] = useState([]);
  const date = new Date();
  const date1 = new Date();
  date.setDate(date.getDate());
  date1.setDate(date1.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [closestock, setClosestock] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    datetime: currentDate,
  });
  const [closestockone, setClosestockone] = useState([]);
  const [orgStartDate, setOrgStartDate] = useState("");
  const [closestocktwo, setClosestocktwo] = useState([]);
  const [closestockByCurrentdate, setClosestockByCurrentdate] = useState([]);
  const [openingstock, setOpeningstock] = useState([]);
  const [closestockBydate, setClosestockByDate] = useState([]);
  const [searchstate, setSearchstate] = useState(true);
  const [tsearchstate, setTsearchstate] = useState(true);
  const [searchbydate, setSearchbydate] = useState([]);
  const [searchloadin, setSearchloadin] = useState([]);
  const [searchloadout, setSearchloadout] = useState([]);
  const [searchtin, setSearchtin] = useState([]);
  const [searchtout, setSearchtout] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [Searchclosestock, setSearchclosestock] = useState([]);
  const [searchDaDetails, setSearchDaDeatails] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [tto1, setTto1] = useState([]);
  const [ttin, setTtin] = useState([]);
  const [tlin, setTLin] = useState([]);
  const [tlout, setTLout] = useState([]);
  // const [tindetails, settindetails] = useState([]);
  const [toutdetails, settoutdetails] = useState([]);
  // const [lindetails, setLindetails] = useState([]);
  // const [loutdetails, setLoutdetails] = useState([]);
  const [searchbyvehicle, setSearchbyvehicle] = useState([]);
  const [searchToutByProduct, setSearchToutByProduct] = useState([]);
  const [searchTinbyProduct, setSearchTinbyProduct] = useState([]);
  const [searchbyv, setSearchbyv] = useState(true);
  const [searchbyPro, setSearchbyPro] = useState(true);
  const [vehicledetails, setVehicledetails] = useState({
    vehicleid: "",
  });
  const [productname, setProductname] = useState({
    productname: "",
  });
  const [amountdenominationdetails, setdenominationdetails] = useState([]);
  const [totalamountdetails, setTotalamountdetails] = useState([]);
  const [loader0, setLoader0] = useState(false);

  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);
  const [loader5, setLoader5] = useState(false);
  const [loader6, setLoader6] = useState(false);
  const [loader7, setLoader7] = useState(false);
  const [loader8, setLoader8] = useState(false);


  const [returnfilteredStatus, setReturnfilterstatus] = useState([]);
  const [toutfilteredStatus, setToutfilterstatus] = useState([]);
  const [searchDate, setSearchDate]= useState(currentDate);

  const [lastUpdatedClosestock, setLastUpdatedClosestock] = useState("");
  const [dapaymentdetails, setDapaymentdetails] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isoverheadModalOpen, setIsoverheadModalOpen] = useState(false);
  const [overhead, setOverhead] = useState([]);
  const [consumable, setConsumable] = useState([]);
  const [refill, setRefill] = useState([]);
  const [isReciveopenModalModalOpen, setIsReciveopenModalModalOpen] = useState(false);
  const [selectedReceiveVehicleId, setSelectedReceiveVehicleId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const[isPerformdatendModalIsOpen, setIsPerformdayendModalOpen] = useState(false);
  const [selectedPerformdate, setSelectedPerformdate] = useState(null);




  const [isoverheadEditModalOpen, setIsoverheadEditModalOpen] = useState(false);
  const [selectedoverheadId, setSelectedoverheadId] = useState(null);
  const [iscreatesaleModalOpen, setIscreatesaleModalOpen] = useState(false);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0'); 
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${day}/${monthNames[monthIndex]}/${year}`;
  }
  const navigate = useNavigate();
  function dateHandler(e, searchDate) {
    navigate(`/users/updateExceptionopenstock/${searchDate}`);
  }

  function updateStockDateHandler(e, searchDate) {
    navigate(`/users/updateopenstock/${searchDate}`);
  }
  const searchName = async (text) => {
    const fSearchDate = formatDate(text);
    const fSearchDateObj = new Date(fSearchDate);
const orgStartDateObj = new Date(orgStartDate);
console.log("s",fSearchDate);
console.log("o",orgStartDate);
setSearchstate(false);
setSearchbyPro(true);
setSearchbyv(true);
setSearchDate(text);

setTsearchstate(false);
if (fSearchDateObj < orgStartDateObj) {
  setErrorMessage(true);
  setLoading(false); 
} else {
  setErrorMessage(false);

    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await Promise.all([
      axios
        .get(
          `/api/OpeningstockDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setOpenstock(response.data.prodDo);
          console.log(response.data);
        }),
        axios
        .get(
          `/api/Openingstock/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/datetime=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setOpeningstock(response.data);
        }),
        (async () => {
          try {
            const loadInResponse = await axios.get(
              `/api/totalLoadinDo/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            setTLin(loadInResponse.data.prodDo);
          } catch (error) {
            if (error.response && error.response.status === 404) {
              console.error("Data not found for totalLoadinDo:", error.response.data);
            } else {
              console.error("An error occurred for totalLoadinDo:", error);
            }
          }
        })(),
        (async () => {
          try {
            const loadOutResponse = await axios.get(
              `/api/totalLoadoutDo/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            setTLout(loadOutResponse.data.prodDo);
          } catch (error) {
            if (error.response && error.response.status === 404) {
              console.error("Data not found for totalLoadoutDo:", error.response.data);
            } else {
              console.error("An error occurred for totalLoadoutDo:", error);
            }
          }
        })(),
        axios
        .get(
          `/api/overhead/duedate/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setOverhead(response.data);
        }),
        axios
        .get(
          `/api/consumableproduct/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setConsumable(response.data);
        }),
        axios
        .get(
          `/api/refillproduct/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setRefill(response.data);
        }),
      axios
        .get(
          `/api/totaltransaction/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setTtin(response.data);
        }),
      axios
        .get(
          `/api/totaltransactionDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setTto1(response.data.prodDo);
        }),
      axios
        .get(
          `/api/CloseStockDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setClosestockone(response.data.prodDo);
        }),
        axios
        .get(
          `/api/CloseStock/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/datetime=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setClosestockByDate(response.data);
        }),

        axios
        .get(
          `/api/DacashDetails/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setDapaymentdetails(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            //toast.success(error.response.data.message);
          } else {
            //toast.error(error.response.data.message);
          }
        })
        
        
    ])


 .finally(() => {
    setLoading(false); // Set loading to false after request completes (both success and failure)
});  
}  
  };
  function denominationdetails(text, vehicleid){
    setModalIsOpen(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .get(
        `/api/DeliveryAgentCashPayment/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        console.log(res);
        setTotalamountdetails(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the API call completes (both success and failure)
      });
  
      axios
      .get(
        `/api/DeliveryagentDenomination/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${text}/vehicleid=${vehicleid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setdenominationdetails(res.data);
        console.log(res);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the API call completes (both success and failure)
      });
  
  }
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    setLoading(true)
    // setLoader5(true);
    // setLoader1(true);
    // setLoader2(true);
    // setLoader3(true);
    // setLoader4(true);
    // setLoader6(true);
    // setLoader7(true);


    await Promise.all([
      axios
        .get(
          `/api/OpeningstockDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
         // setLoader(false);
          setOpenstock(response.data.prodDo);
        }),
        axios
        .get(
          `/api/refillproduct/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setRefill(response.data);
        }),
        axios
        .get(
          `/api/consumableproduct/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setConsumable(response.data);
        }),
        axios
        .get(
          `/api/overhead/duedate/${
            JSON.parse(localStorage.getItem("data")).orgcode
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setOverhead(response.data);
        }),

      axios
        .get(
          `/api/Returntransaction/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          console.log(res);
          setReturnfilterstatus({
            filteredStatus: res.data.filter(
              (status) => status.approvalstatus === "REQUESTED"
            ),
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
           // toast.success(error.response.data.message);
          } else {
           // toast.error(error.response.data.message);
          }
        }),
      axios
        .get(
          `/api/transactionoutgoing/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          console.log(res);
          setToutfilterstatus({
            toutfilteredStatus: res.data.filter(
              (status) => status.approvalstatus === "REQUESTED"
            ),
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            //toast.success(error.response.data.message);
          } else {
           // toast.error(error.response.data.message);
          }
        }),
        axios
        .get(
          `/api/product/productstatus=true/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          // Filter products with productType "Refill Sales"
          const ConsumableProducts = res.data.filter(product => product.productType === "Consumables");
          // Update the state with the filtered products
          setProduct(ConsumableProducts);
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        }),

      axios
        .get(
          `/api/vehicle/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setVehicle(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            //toast.success(error.response.data.message);
          } else {
            //toast.error(error.response.data.message);
          }
        }),

      // setLoader(true);
      axios
        .get(
          `/api/CloseStockDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
        //  setLoader(false);
          setClosestockone(response.data.prodDo);
         // setLoader6(false);
          // setProductstock(trans.data.prodDo[0].pdo);
          console.log("openstock:", openstock);
        }),
        axios
        .get(
          `/api/CloseStock/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/datetime=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setClosestockByCurrentdate(response.data);
          //setLoader6(false);

          // setProductstock(trans.data.prodDo[0].pdo);
          console.log("openstock:", openstock);
        }),
      // setLoader(true);
      axios
        .get(
          `/api/Openingstock/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/datetime=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
        //  setLoader(false);
          setOpeningstock(response.data);
        }),

        axios
        .get(
          `/api/dayendtransactiondate/lastUpdated/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          //setLoader(false);
          setLastUpdatedClosestock(response.data);
          //setLoader6(false);
        }),
      axios
        .get(
          `/api/totalLoadinDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setTLin(res.data.prodDo);
         // setLoader1(false);
        })
        .catch((error) => {
          if (error.response.status === 404) {
           // toast.success(error.response.data.message);
          //  setLoader1(false);
          } else {
            //toast.error(error.response.data.message);
           // setLoader1(false);
          }
        }),
      axios
        .get(
          `/api/totalLoadoutDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setTLout(res.data.prodDo);
         // setLoader2(false);
        })
        .catch((error) => {
          if (error.response.status === 404) {
           // toast.success(error.response.data.message);
           // setLoader2(false);
          } else {
            //toast.error(error.response.data.message);
           // setLoader2(false);
          }
        }),
      axios
        .get(
          `/api/totaltransaction/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setTtin(res.data);
          console.log(res)
         // setLoader3(false);
        })
        .catch((error) => {
          if (error.response.status === 404) {
           // toast.success(error.response.data.message);
           // setLoader3(false);
          } else {
            //toast.error(error.response.data.message);
           // setLoader3(false);
          }
        }),
      axios
        .get(
          `/api/totaltransactionDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setTto1(res.data.prodDo);
         // setLoader4(false);
        })
        .catch((error) => {
          if (error.response.status === 404) {
           // toast.success(error.response.data.message);
           // setLoader4(false);
          } else {
           // toast.error(error.response.data.message);
           // setLoader4(false);
          }
        }),

      // axios
      //   .get(
      //     `/api/totalloadin/orgid=${
      //       JSON.parse(localStorage.getItem("data")).orgcode
      //     }/date=${currentDate}`,
      //     {
      //       headers: { Authorization: `Bearer ${token}` },
      //     }
      //   )
      //   .then((res) => {
      //     setLindetails(res.data);
      //   })
      //   .catch((error) => {
      //     if (error.response.status === 404) {
      //      // toast.success(error.response.data.message);
      //     } else {
      //       //toast.error(error.response.data.message);
      //     }
      //   }),

      // axios
      //   .get(
      //     `/api/totalloadout/orgid=${
      //       JSON.parse(localStorage.getItem("data")).orgcode
      //     }/date=${currentDate}`,
      //     {
      //       headers: { Authorization: `Bearer ${token}` },
      //     }
      //   )
      //   .then((res) => {
      //     setLoutdetails(res.data);
      //   })
      //   .catch((error) => {
      //     if (error.response.status === 404) {
      //      // toast.success(error.response.data.message);
      //     } else {
      //       //toast.error(error.response.data.message);
      //     }
      //   }),

      // axios
      //   .get(
      //     `/api/totaltransaction/orgcode=${
      //       JSON.parse(localStorage.getItem("data")).orgcode
      //     }/date=${currentDate}`,
      //     {
      //       headers: { Authorization: `Bearer ${token}` },
      //     }
      //   )
      //   .then((res) => {
      //     settindetails(res.data);
      //   })
      //   .catch((error) => {
      //     if (error.response.status === 404) {
      //      // toast.success(error.response.data.message);
      //     } else {
      //      // toast.error(error.response.data.message);
      //     }
      //   }),

      // axios
      //   .get(
      //     `/api/totaltransactionout/orgid=${
      //       JSON.parse(localStorage.getItem("data")).orgcode
      //     }/date=${currentDate}`,
      //     {
      //       headers: { Authorization: `Bearer ${token}` },
      //     }
      //   )
      //   .then((res) => {
      //     settoutdetails(res.data);
      //     console.log(toutdetails);
      //   })
      //   .catch((error) => {
      //     if (error.response.status === 404) {
      //      // toast.success(error.response.data.message);
      //     } else {
      //       //toast.error(error.response.data.message);
      //     }
      //   }),

      axios
        .get(
          `/api/DacashDetails/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setDapaymentdetails(res.data);
          console.log(res.data);
         // setLoader5(false);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            //toast.success(error.response.data.message);
           // setLoader5(false);
          } else {
            //toast.error(error.response.data.message);
            //setLoader5(false);
          }
        })
      ])
      .finally(() => {
        setLoading(false); // Set loading to false after all requests complete
      });
    };

  const getOrganizationProfile = async () => {
    const token = JSON.parse(localStorage.getItem('data')).token;

    try {
      const response = await axios.get(
        `/api/organizationprofiles/Orgcode=${JSON.parse(localStorage.getItem('data')).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setOrgStartDate(response.data.createddate);
    } catch (error) {
      if (error.response.status === 404) {
        console.log('Organization profile not found:', error.response.data.message);
        // Handle 404 error (organization profile not found)
      } else {
        console.error('Error fetching organization profile:', error.response.data.message);
        // Handle other errors
      }
    }
  };

  const [products, setProducts]=useState([]);
  useEffect(() => {
    const fetchData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
        .get(
          `/api/product/productstatus=true/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
            const ConsumableProducts = res.data.filter(product => product.productType === "Consumables");
            setProduct(ConsumableProducts);
          })
      }
      fetchData();

    getData();
    getOrganizationProfile();
  }, []);
  const searchbyvehi = async (e, id) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbyv(false);
    setSearchbyPro(true);
    if (id === "ALL") {
      setLoading(true);
      try {
        const tinResponse = await axios.get(
          `/api/totaltransaction/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}`,
           { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchbyvehicle(tinResponse.data);
        //setSearchTinbyProduct([]);
  
        const toutvehicleResponse = await axios.get(
          `/api/totaltransactionDo/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchbytoutvehicle(toutvehicleResponse.data.prodDo);
        //setSearchToutByProduct([]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    } else {
      setLoading(true);
      try {
        const tinResponse = await axios.get(
          `/api/totaltransaction/vehicleid=${id}/date=${searchDate}/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchbyvehicle(tinResponse.data);
        //setSearchTinbyProduct([]);
  
        const toutvehicleResponse = await axios.get(
          `/api/totaltransactionDo/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}/vehicleid=${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchbytoutvehicle(toutvehicleResponse.data.prodDo);
        //setSearchToutByProduct([]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        setLoading(false);
      }
    }
  };

  const searchbyProductname = async (e, productname) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbyPro(false);
    setSearchbyv(true);
    if (productname === "ALL") {
      setLoading(true);
      try {
        const tinResponse = await axios.get(
          `/api/totaltransaction/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}`,
           { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchTinbyProduct(tinResponse.data);
        //setSearchbyvehicle([]);
  
        const toutvehicleResponse = await axios.get(
          `/api/totaltransactionDo/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchToutByProduct(toutvehicleResponse.data.prodDo);
       // setSearchbytoutvehicle([]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    } else {
      setLoading(true);
      try {
        const tinResponse = await axios.get(
          `/api/totaltransaction/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}/productname=${productname}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchTinbyProduct(tinResponse.data);
        //setSearchbyvehicle([]);
  
        const toutvehicleResponse = await axios.get(
          `/api/totaltransactionDo/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}/productname=${productname}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchToutByProduct(toutvehicleResponse.data.prodDo);
       // setSearchbytoutvehicle([]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        setLoading(false);
    }
  }
  };

  console.log(searchbyvehicle, "sdsd");
  const onClickSearch = async (e, date) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios
      .get(`/api/Stockfilter/datetime=${date}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOpenstock(response.data.prodDo);
        console.log("openstock:", openstock);
      });

    const vehicles = await axios.get(
      `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(vehicles);

    setVehicle(vehicles.data);
  };
  const createclosestock = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios
      .post(`/api/CloseStock/create`, closestock, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response);
        toast.success("closestock created successfully");
        setClosestock(response.data);
        setLoader6(false);

      });
  };
  console.log("filter", returnfilteredStatus);
  console.log("tfilter", toutfilteredStatus);
  const onclickhandler = async (e,searchDate) => {
    setIsPerformdayendModalOpen(true);
    setSelectedPerformdate(searchDate) ; 
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;

    setLoading(true);
      {
      // axios
      //   .post("/api/totalloadin/create", lindetails, {
      //     headers: { Authorization: `Bearer ${token}` },
      //   })
      //   .then((res) => {
      //     console.log(res);
      //     //setLoading(false);
      //   });
      // axios
      //   .post("/api/totalloadout/create", loutdetails, {
      //     headers: { Authorization: `Bearer ${token}` },
      //   })
      //   .then((res) => {
      //     console.log(res);
      //     //setLoading(false);
      //   });
      // axios
      //   .post("/api/totaltransaction/create", tindetails, {
      //     headers: { Authorization: `Bearer ${token}` },
      //   })
      //   .then((res) => {
      //     console.log(res);
      //     //setLoading(false);
      //   });
      // axios
      //   .post("/api/totaltransactionout/create", tto1, {
      //     headers: { Authorization: `Bearer ${token}` },
      //   })
      //   .then((res) => {
      //     toast.success("Transaction End Successfully");
      //    // setLoading(false);
      //   });
      // axios
      //   .post("/api/DacashDetails/create", dapaymentdetails, {
      //     headers: { Authorization: `Bearer ${token}` },
      //   })
      //   .then((res) => {
      //     console.log(res);
      //     //setLoading(false);
      //   });

      // await axios
      //   .post("/api/CloseStock/update", openingstock, {
      //     headers: { Authorization: `Bearer ${token}` },
      //   })
      //   .then((res) => {
      //     console.log(res);
      //     setLoading(false);
      //     setClosestocktwo(res.data);
      //     window.location.reload();
      //     toast.success("Transaction End and Closestock updated Successfully");
      //   })
      //   .catch((error) => {
      //     setLoading(false);
      //     toast.error(error.response.data.message);
      //   })
      //   .finally(() => {
      //     setLoading(false); // Set loading to false after the API call completes (both success and failure)
      //   });
      }
  };
  const closePerformdayendModal = () => {
    setIsPerformdayendModalOpen(false);
    searchName(searchDate);
  };
  function rhandle(e, field) {
    e.preventDefault();
    let actualValue = e.target.value;

    setVehicledetails({ vehicledetails, [field]: actualValue });
  }
  console.log(vehicledetails, "fff");

  function handleProduct(e, field) {
    e.preventDefault();
    let actualValue = e.target.value;

    setProductname({ productname, [field]: actualValue });
  }
 
  function openModal(e, vehicleid) {
    setModalIsOpen(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .get(
        `/api/DeliveryAgentCashPayment/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${currentDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        console.log(res);
        setTotalamountdetails(res.data);
        //setLoading(false);
      });
      
    axios
      .get(
        `/api/DeliveryagentDenomination/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${currentDate}/vehicleid=${vehicleid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setdenominationdetails(res.data);
        console.log(res);
        //setLoading(false);
      });
  }

  // const getOrgDetails = async () => {
  //   setLoading(true);
  //   const trans = await axios
  //     .get("/api/subscription-region", {
  //       headers: { Authorization: `Bearer ${token}` },
  //     })
  //     .then((res) => {
  //       setOrgDetails(res.data);
  //       setLoading(false);
  //   })
  //   .catch((error) => {
  //     setLoading(false);
  //   });
  // };

  function closeModal() {
    setModalIsOpen(false);
  }
  const customStyles = {
    content: {
      width: "25%",
      backgroundColor: "rgba(0, 0, 0, .1)",
      position: "relative",
      left: "550px",
      top: "200px",
    },
  };
  const handleRefresh= async () => {
    setLoader0(true); // Set Load Incoming loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const response = await
      axios
      .get(
        `/api/overhead/duedate/${
          JSON.parse(localStorage.getItem("data")).orgcode
        }`,      {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setOverhead(response.data);
    } catch (error) {
    } finally {
      setLoader0(false); // Set Load Incoming loader to false
    }
  };
  const handleRefresh1 = async () => {
    setLoader1(true); // Set Load Incoming loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const response = await
      axios
      .get(
        `/api/OpeningstockDo/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`,       {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setOpenstock(response.data.prodDo);
    } catch (error) {
    } finally {
      setLoader1(false); // Set Load Incoming loader to false
    }
  };
  const handleRefresh2 = async () => {
    setLoader2(true); // Set Load Incoming loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const res = await
      axios
      .get(
        `/api/totalLoadinDo/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`,{
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTLin(res.data.prodDo);
    } catch (error) {
    } finally {
      setLoader2(false); // Set Load Incoming loader to false
    }
  };
  const handleRefresh3 = async () => {
    setLoader3(true); // Set Load Incoming loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const res = await
      axios
      .get(
        `/api/totalLoadoutDo/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`,   {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTLout(res.data.prodDo);
    } catch (error) {
    } finally {
      setLoader3(false); // Set Load Incoming loader to false
    }
  };
  const handleRefresh4 = async () => {
    setLoader4(true); // Set Load Incoming loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const res = await
      axios
      .get(
        `/api/totaltransactionDo/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`,  {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTto1(res.data.prodDo);
    } catch (error) {
    } finally {
      setLoader4(false); // Set Load Incoming loader to false
    }
  };
  const handleRefresh5 = async () => {
    setLoader5(true); // Set Load Incoming loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const res = await
      axios
      .get(
        `/api/totaltransaction/orgcode=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTtin(res.data);
    } catch (error) {
      setLoader5(false);      // Handle error
    } finally {
    }
  };
  const handleRefresh6 = async () => {
    setLoader6(true); // Set Load Incoming loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const res = await 
      axios
      .get(
        `/api/DacashDetails/orgcode=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`, {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setDapaymentdetails(res.data);
    } catch (error) {
    } finally {
      setLoader6(false); // Set Load Incoming loader to false
    }
  };
  const handleRefresh7 = async () => {
    setLoader7(true); // Set Load Incoming loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const response = await 
      axios
      .get(
        `/api/CloseStockDo/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`, {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setClosestockone(response.data.prodDo);
    } catch (error) {
    } finally {
      setLoader7(false); // Set Load Incoming loader to false
    }
  };
  const handleRefresh8 = async () => {
    setLoader8(true); // Set Loader to true
    
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
  
      // Make parallel requests using Promise.all
      const [consumableResponse, refillResponse] = await Promise.all([
        axios.get(`/api/consumableproduct/orgcode=${orgcode}/date=${currentDate}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`/api/refillproduct/orgcode=${orgcode}/date=${currentDate}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
      ]);
  
      // Update state with the responses
      setConsumable(consumableResponse.data);
      setRefill(refillResponse.data);
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally {
      setLoader8(false); // Set Loader to false after requests are completed
    }
  };
  const handleAdd = () => {
    setIsoverheadModalOpen(true);
   };
   const handleoverheadCloseModal = () => {
    setIsoverheadModalOpen(false);
    searchName(searchDate);
  };

  const handlecreatesale = () => {
    setIscreatesaleModalOpen(true);
   };

   const handlecreatesaleCloseModal = () => {
    setIscreatesaleModalOpen(false);
    searchName(searchDate);
  };
  const handleoverheadedit = async (id) => {
    setSelectedoverheadId(id);
   
    setIsoverheadEditModalOpen(true);
    
  };
  
  const closeoverheadEditModal = () => {
    setIsoverheadEditModalOpen(false);
    searchName(searchDate);
  
  };
  function rsubmit(e, id, status) {
    e.preventDefault();
    setLoading(true);
  
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    axios
      .post(
        "/api/overhead/update",
        {
          id: id,
          completed: status,      },
  
        { headers: { Authorization: `Bearer ${token}` } }
      )
  
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        getData();
      })
  
      .catch((error) => {
        console.log(error);
      });
  }

  const reciveopenModal = async (date, vehicleid) => {
    setSelectedReceiveVehicleId(vehicleid);
    setSelectedDate(date);
    setIsReciveopenModalModalOpen(true);


  };
 
  const closereciveopenModal = () => {
    setIsReciveopenModalModalOpen(false);
    searchName(searchDate);
 
  };
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      {/* {" "}
      {loader && (
        <div
          className="loader"
          style={{ position: "absolute", left: "750px", top: "75px" }}
        ></div>
      )} */}
      <br /> <br /> <br /> <br />
      
      <div
        style={{ position: "relative", top: "40px" }}
        className="col-13  mx-xl-auto ps-xl-10"
      >

              <div >
  <div className="card-body">
    <div className="d-flex">
    <br />
    <table className="table table-bordered ">
      <thead>
        <tr
          style={{
            fontSize: "17px",
            color: "black",
            backgroundColor:"white",
            textAlign: "center",
          }}
        >
          <th>
            Last Day End Transaction Completed on :{" "}
            {lastUpdatedClosestock && (
              <span className="ml-2">{formatDate(lastUpdatedClosestock.dedate)}</span>
              
            )}
            <br/>
            <br/>
              <Search searchName={searchName} />{" "}

          </th>
        </tr>
      </thead>
    </table>
    </div>
  </div>
</div>
</div>{" "}

{(searchstate && tsearchstate) && (
 <>
        {openingstock.length === 0 ? (
          <>
           <div style={{ position: "relative", top: "40px", textAlign: "center" }} className="col-11 mx-xl-auto ps-xl-10">
    <div className="card card-raised">
      <div className="card-body">
                <h5 style={{ color: "red" }}>Please Complete Day End Transaction for Previous Date.</h5>
                <br />

  <button className="btn btn-primary" onClick={(e) => dateHandler(e, searchDate)}>Create Exception</button>

            </div>
            </div>
            </div>
          </>
          
        ) : (
          <>
        <br></br>
        <div className="col-12  mx-xl-auto ps-xl-10">
       <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Over Head  Details{""}
           
                  {loader0 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )} 

<button className="btn btn-primary"
style={{
 fontSize: '9px',
 padding: '7px 5px',
 top: '10px',
 right: '330px',
}}  onClick={handleRefresh}>
Refresh
</button> 

              <button className="btn btn-primary"onClick={handleAdd}>
               ADD
            </button>
          
            </div>
                  
                      
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "15px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                  <th>Vehicle</th>
                  <th>Product Name</th>
                  <th>Type</th>
                  <th>Given Quantity</th>
                  <th>Taken Quantity</th>
                  <th>Amount</th>
                  <th>Created By</th>
                  <th>Created Date</th>
                  <th>Due Date</th>
                  <th>Description</th>
                  <th>Status</th>
                   <th>Edit</th>

                </tr>
              </thead>
              <tbody>
              {overhead.length === 0 ? (
            <tr>
              <td colSpan="12" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
            overhead.map((product) => {
                  return (
                    <tr key={product.id}>
                     <td>{product.vehicleid}</td>

                      <td>{product.productname}</td>
                      <td>{product.type}</td>
                      <td>{product.givenQty}</td>
                      <td>{product.takenQty}</td>
                      <td>{product.amount}</td>
                      <td>{product.userrole}</td>
                    
                      <td>{product.createddate} </td>
                      <td>{product.duedate} </td>
                      <td>{product.description} </td>
                      {product.completed == false ? (
                        <td id="icon" value={product.status}>
                          &nbsp; Pending &nbsp;
                          <BsCheckCircle
                            className="icon"
                            id="approval.approvalstatus"
                            style={{ backgroundColor: "green" }}
                            value="true"
                            onClick={(e) => rsubmit(e, product.id, true)}
                            />
                            &ensp;
      &ensp;
      <BsXCircle
                            className="icon"
                            value="Notverified"
                            style={{ backgroundColor: "red" }}
                            id="product.status"
                            onClick={(e) => rsubmit(e, product.id, false, product.orgcode)}
                            />
                             </td>
                        ) : (
                        <td id="icon" value={product.status}>
                          
                          <div className="d-flex">
                           
                            <AiOutlineCheck style={{ color: "green" }} />
                          </div>
                        </td>
                      )}
                      <td> 
                     <button
                      onClick={() => handleoverheadedit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td>
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
            <div>
          
             
           
      </div>


          </div>
        </div>{" "}
      </div>
     
      <br/>
        
             <div className="col-12  mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body ">
            <div className="d-flex">
              <div className="card-title">Open Stock Details</div>
              {" "}
                  {loader1 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )} 
                    <span style={{ marginLeft: '25px' }}></span>

                   <button className="btn btn-primary"
                   style={{
                    fontSize: '9px',
                    padding: '7px 5px'
                  }}  onClick={handleRefresh1}>
        Refresh
      </button>            
             <div style={{ position: "relative", marginLeft: "60%" }}>
             
                <button className="btn btn-primary" onClick={(e) => updateStockDateHandler(e, searchDate)}>Update Stock</button>
              
            </div>
            </div>
            <br />
            
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th id="date">Product</th>

                  <th id="full">Full</th>

                  <th id="empty">Empty</th>
                  <th id="defective">Defective</th>
                </tr>{" "}
              </thead>
              <tbody>
              {openstock.length === 0 ? (
            <tr>
              <td colSpan={product.length + 1}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                searchstate &&
                  openstock.map((products) => {
                    return (
                      <tr>
                        <th>{products.product.productname}</th>
                        {products.pdo.map((ps, index) => {
                          return <th key={index}>{ps.qty}</th>;
                        })}
                      </tr>
                    );
                  }))}
              </tbody>
            </table>
          </div>
        </div>
       </div>

      <br />  
      <div>
        <div className="col-12  mx-xl-auto ps-xl-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="d-flex">
                <div
                  className="card-title"
                  style={{
                    fontSize: "20px",
                    // backgroundColor: "blue",
                  }}
                >
                  Total Load In Details{" "}
                  {loader2 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
<span style={{ marginLeft: '25px' }}></span>

                    <button className="btn btn-primary"
                     style={{
                      fontSize: '9px',
                      padding: '7px 5px'
                    }} onClick={handleRefresh2}>
        Refresh
      </button>  
                </div>
              </div>
              <br />
              <div className="table-responsive">
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: " #6200ea",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Vehicle Id</th>
                    {product.map((prod) => {
                      return <th>{prod.productname}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                {searchstate && tlin.length === 0 ? (
            <tr>
              <td colSpan={product.length + 1}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
                    tlin.map((ttin) => {
                      return (
                        <tr>
                          <th>{ttin.vehicle}</th>
                          {product.map((ps, pIndex) => {
                                  const productQty = ttin.pdo.find((item) => item.product === ps.productname);
                                  return (
                                    <th key={pIndex}>
                                      {productQty.qty!==null ? productQty.qty : 0}
                                    </th>
                                  );
                                })}
                        </tr>
                      );
                    }))}
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
        <br />  
        <div className="col-12  mx-xl-auto ps-xl-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="d-flex">
                <div
                  className="card-title"
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Total Load Out Details
                  {loader3 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
                                                        <span style={{ marginLeft: '25px' }}></span>

                    <button className="btn btn-primary"
                     style={{
                      fontSize: '9px',
                      padding: '7px 5px'
                    }} onClick={handleRefresh3}>
        Refresh
      </button>  
                </div>
              </div>
              <br />
              <div className="table-responsive">
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: " #6200ea",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Vehicle Id</th>
                    {product.map((prod) => {
                      return <th>{prod.productname}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                {searchstate && tlout.length === 0 ? (
            <tr>
              <td colSpan={product.length + 1}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
                    tlout.map((ttin) => {
                      return (
                        <tr>
                          <th>{ttin.vehicle}</th>
                          {product.map((ps, pIndex) => {
            const productQty = ttin.pdo.find(item => item.product === ps.productname);
            return (
              <th key={pIndex}>
                {productQty ? productQty.qty : 0}
              </th>
            );
          })}
                        </tr>
                      );
                    }))}
                    
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />  
      <div>
      <div className="col-12  mx-xl-auto ps-xl-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="d-flex">
                <div
                  className="card-title"
                  style={{
                    fontSize: "20px",
                    // backgroundColor: "blue",
                  }}
                >
                  Total Transaction Out
                  {loader4 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
               <span style={{ marginLeft: '25px' }}></span>

                    <button className="btn btn-primary"
                     style={{
                      fontSize: '9px',
                      padding: '7px 5px'
                    }} onClick={handleRefresh4}>
        Refresh
      </button>  
                </div>
                &nbsp;&nbsp;&nbsp;
                &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div>FILTER BY VEHICLE:
                  {" "}
                  <select
                    id="vehicleid"
                    type="text"
                    placeholder="vehicleid"
                    onChange={(e) => rhandle(e, "vehicleid")}
                  >
                    <option>ALL</option>
                    {vehicle.map((vehicle) => {
                      return (
                        <option value={vehicle.truckinfo}>
                          {vehicle.truckinfo}
                        </option>
                      );
                    })}
                  </select>{" "}
                  <button
                    type="submit"
                    value="search"
                    className="btn btn-primary"
                    id="btn"
                    onClick={(e) => searchbyvehi(e, vehicledetails.vehicleid)}
                  >
                    <IoSearchOutline id="IoSearchOutline" />
                  </button>

                  &ensp; &ensp; &ensp; &ensp; &ensp;
                  FILTER BY PRODUCT: 
                  <select
                    id="productname"
                    type="text"
                    placeholder="productname"
                    onChange={(e) => handleProduct(e, "productname")}
                  >
                    <option>ALL</option>
                    {products.map((p) => {
                      return (
                        <option value={p.productname}>
                          {p.productname}
                        </option>
                      );
                    })}
                  </select>{" "}
                  <button
                    type="submit"
                    value="search"
                    className="btn btn-primary"
                    id="btn"
                   onClick={(e) => searchbyProductname(e, productname.productname)}
                  >
                    <IoSearchOutline id="IoSearchOutline" />
                  </button>
                </div>
              </div>
              <br />
              <div className="table-responsive">
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: " #6200ea",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Vehicle Id</th>
                    {product.map((prod) => {
                      return <th key={prod.productname}>{prod.productname}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                {tto1.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                    searchbyv && searchbyPro &&
                    searchstate &&
                    tto1.map((ttin) => {
                      return (
                        <tr>
                          {ttin.vehicle.truckinfo === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{ttin.vehicle.truckinfo}</th>
                          )}
                         
                          {ttin.pdo.map((ps, index) => {
                            return <th key={index}> {ps.qty === null ? ps.totalQty : ps.qty}</th>;
                          })}
                        </tr>
                      );
                    }))}
                  {!searchbyv &&
                    searchbytoutvehicle.map((ttout) => {
                      return (
                        <tr>
                          {ttout.vehicle.truckinfo === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{ttout.vehicle.truckinfo}</th>
                          )}
                         
                          {ttout.pdo.map((ps1, index) => {
                            return <th key={index}> {ps1.qty === null ? ps1.totalQty : ps1.qty}</th>;
                          })}
                        </tr>
                      );
                    })}

                {!searchbyPro &&
                    searchToutByProduct.map((ttout) => {
                      return (
                        <tr>
                         {ttout.vehicle.truckinfo === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{ttout.vehicle.truckinfo}</th>
                          )}
                         {product.map((prod) => {
  const productQty = ttout.pdo.find((ps1) => ps1.product === prod.productname)?.qty;
  const displayQty = productQty != null ? productQty : ttout.pdo.find((ps1) => ps1.product === prod.productname)?.totalQty || 0;
  return <th key={prod.productname}>{displayQty}</th>;
})}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
        <br />  
        <div className="col-12 mx-xl-auto ps-xl-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="d-flex">
                <div
                  className="card-title"
                  style={{
                    fontSize: "20px",
                    // backgroundColor: "blue",
                  }}
                >
                  Total Transaction In
                  {loader5 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
                                                        <span style={{ marginLeft: '25px' }}></span>

                    <button className="btn btn-primary"
                     style={{
                      fontSize: '9px',
                      padding: '7px 5px'
                    }} onClick={handleRefresh5}>
        Refresh
      </button>  
                </div>
                &nbsp;&nbsp;&nbsp;
                &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {/* <div>
                  {" "}
                  <select
                    id="vehicleid"
                    type="text"
                    placeholder="vehicleid"
                    onChange={(e) => rhandle(e, "vehicleid")}
                  >
                    <option>select vehicle</option>
                    {vehicle.map((vehicle) => {
                      return (
                        <option value={vehicle.truckinfo}>
                          {vehicle.truckinfo}
                        </option>
                      );
                    })}
                  </select>{" "}
                  <button
                    type="submit"
                    value="search"
                    className="btn btn-primary"
                    id="btn"
                    onClick={(e) => searchbyvehi(e, vehicledetails.vehicleid)}
                  >
                    <IoSearchOutline id="IoSearchOutline" />
                  </button>
              </div> */}
             </div>

              <br />
              <div className="table-responsive">
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: " #6200ea",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Vehicle Id</th>
                    <th>Product Name</th>
                    <th>Sales</th>
                    <th>New Connection</th>
                    <th>Additionals</th>
                    <th>Onlinepay</th>
                    <th>Exempted</th>
                    <th>Returned Product</th>
                    <th>Transfer</th>
                    <th>Total</th>
                    <th>Total Amount</th>
                    
                  </tr>
                </thead>
                <tbody>
                {ttin.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                  searchbyv && searchbyPro &&
                    tsearchstate &&
                    ttin.map((ttin) => {
                      return (
                        <tr style={{ color: ttin.fromOutgoing === true ? 'red' : 'black' }}>
                          {ttin.vehicleid == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.vehicleid}</td>
                          )}

                          {ttin.productname == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.productname}</td>
                          )}
                          {ttin.totalquantity == null ? (
                            <td>{ttin.totalofallquantity}</td>
                          ) : (
                            <td>{ttin.totalquantity}</td>
                          )}
                           
                           {ttin.totalneworg == null ? (
                            <td>{ttin.totalofallneworg}</td>
                          ) : (
                            <td>{ttin.totalneworg}</td>
                          )}
                           {ttin.totaladditional == null ? (
                            <td>{ttin.totalofalladditional}</td>
                          ) : (
                            <td>{ttin.totaladditional}</td>
                          )}
                           {ttin.totalonlinepay == null ? (
                            <td>{ttin.totalofallonlinepay}</td>
                          ) : (
                            <td>{ttin.totalonlinepay}</td>
                          )}
                          {ttin.totalexempted == null ? (
                            <td>{ttin.totalofallexempted}</td>
                          ) : (
                            <td>{ttin.totalexempted}</td>
                          )}
                           {ttin.totalreturnedproduct == null ? (
                            <td>{ttin.totalofallreturnedproduct}</td>
                          ) : (
                            <td>{ttin.totalreturnedproduct}</td>
                          )}
                           {ttin.totaltransfer == null ? (
                            <td>{ttin.totalofalltransfer}</td>
                          ) : (
                            <td>{ttin.totaltransfer}</td>
                          )}

                          
                           {ttin.totalsale == null ? (
                            <td>{ttin.totalofallsale}</td>
                          ) : (
                            <td>{ttin.totalsale}</td>
                          )}
                         
                         
                         
                         
                         
                          {/* {ttin.totaloutgoingcylinder == null ? (
                            <td>{ttin.totalofalloutgoingcylinder}</td>
                          ) : (
                            <td>{ttin.totaloutgoingcylinder}</td>
                          )} */}
                          

                         
                          {ttin.totalamount == null ? (
                            <td>{ttin.totalofallamount}</td>
                          ) : (
                            <td style={{ color: ttin.totalAmountFrom === 'fleet' ? 'green' : 'orange' }}>{ttin.totalamount}</td>
                          )}
                        </tr>
                      );
                    }))}
                  {!searchbyv &&
                    searchbyvehicle.map((ttin) => {
                      return (
                        <tr>
                          {ttin.vehicleid == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.vehicleid}</td>
                          )}

                          {ttin.productname == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.productname}</td>
                          )}
                           {ttin.totalquantity == null ? (
                            <td>{ttin.totalofallquantity}</td>
                          ) : (
                            <td>{ttin.totalquantity}</td>
                          )}
                          
                          {ttin.totalneworg == null ? (
                            <td>{ttin.totalofallneworg}</td>
                          ) : (
                            <td>{ttin.totalneworg}</td>
                          )}
                           {ttin.totaladditional == null ? (
                            <td>{ttin.totalofalladditional}</td>
                          ) : (
                            <td>{ttin.totaladditional}</td>
                          )}
                            {ttin.totalonlinepay == null ? (
                            <td>{ttin.totalofallonlinepay}</td>
                          ) : (
                            <td>{ttin.totalonlinepay}</td>
                          )}
                           {ttin.totalexempted == null ? (
                            <td>{ttin.totalofallexempted}</td>
                          ) : (
                            <td>{ttin.totalexempted}</td>
                          )}
                           {ttin.totalreturnedproduct == null ? (
                            <td>{ttin.totalofallreturnedproduct}</td>
                          ) : (
                            <td>{ttin.totalreturnedproduct}</td>
                          )}
                           {ttin.totaltransfer == null ? (
                            <td>{ttin.totalofalltransfer}</td>
                          ) : (
                            <td>{ttin.totaltransfer}</td>
                          )}

                            {ttin.totalsale == null ? (
                            <td>{ttin.totalofallsale}</td>
                          ) : (
                            <td>{ttin.totalsale}</td>
                          )}
                          {/* {ttin.totaloutgoingcylinder == null ? (
                            <td>{ttin.totalofalloutgoingcylinder}</td>
                          ) : (
                            <td>{ttin.totaloutgoingcylinder}</td>
                          )} */}
                          
                          {ttin.totalamount == null ? (
                            <td>{ttin.totalofallamount}</td>
                          ) : (
                            <td style={{ color: ttin.totalAmountFrom === 'fleet' ? 'green' : 'orange' }}>{ttin.totalamount}</td>
                          )}
                        </tr>
                      );
                    })}


                {!searchbyPro &&
                    searchTinbyProduct.map((ttin) => {
                      return (
                        <tr>
                          {ttin.vehicleid == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.vehicleid}</td>
                          )}

                          {ttin.productname == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.productname}</td>
                          )}
                           {ttin.totalquantity == null ? (
                            <td>{ttin.totalofallquantity}</td>
                          ) : (
                            <td>{ttin.totalquantity}</td>
                          )}
                          
                          {ttin.totalneworg == null ? (
                            <td>{ttin.totalofallneworg}</td>
                          ) : (
                            <td>{ttin.totalneworg}</td>
                          )}
                           {ttin.totaladditional == null ? (
                            <td>{ttin.totalofalladditional}</td>
                          ) : (
                            <td>{ttin.totaladditional}</td>
                          )}
                            {ttin.totalonlinepay == null ? (
                            <td>{ttin.totalofallonlinepay}</td>
                          ) : (
                            <td>{ttin.totalonlinepay}</td>
                          )}
                           {ttin.totalexempted == null ? (
                            <td>{ttin.totalofallexempted}</td>
                          ) : (
                            <td>{ttin.totalexempted}</td>
                          )}
                           {ttin.totalreturnedproduct == null ? (
                            <td>{ttin.totalofallreturnedproduct}</td>
                          ) : (
                            <td>{ttin.totalreturnedproduct}</td>
                          )}
                           {ttin.totaltransfer == null ? (
                            <td>{ttin.totalofalltransfer}</td>
                          ) : (
                            <td>{ttin.totaltransfer}</td>
                          )}

                            {ttin.totalsale == null ? (
                            <td>{ttin.totalofallsale}</td>
                          ) : (
                            <td>{ttin.totalsale}</td>
                          )}
                          {/* {ttin.totaloutgoingcylinder == null ? (
                            <td>{ttin.totalofalloutgoingcylinder}</td>
                          ) : (
                            <td>{ttin.totaloutgoingcylinder}</td>
                          )} */}
                          
                          {ttin.totalamount == null ? (
                            <td>{ttin.totalofallamount}</td>
                          ) : (
                            <td style={{ color: ttin.totalAmountFrom === 'fleet' ? 'green' : 'orange' }}>{ttin.totalamount}</td>
                          )}
                        </tr>
                      );
                    })}
                  
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
        <br/>
      <div className="col-12  mx-xl-auto ps-xl-10">
       <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Office Sale Details{""}
           
                  {loader8 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )} 

<button className="btn btn-primary"
style={{
 fontSize: '9px',
 padding: '7px 5px',
 top: '10px',
 right: '310px',
}}  onClick={handleRefresh8}>
Refresh
</button> 

              <button className="btn btn-primary"onClick={handlecreatesale}>
               Create Sale
            </button>
          
            </div>
                  
            <div className="mt-2 mb-2">
            <h2 className="card-title">Refill Sales :</h2>
            </div>    
    
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                   <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Sale</th>
                  <th>New Connection</th>
                  <th>Additional</th>

                  <th>Exempted</th>
                  <th>Onlinepay</th>
                  <th>Transfer</th>
                  <th>Total</th>
                   {/* <th>Edit</th> */}

                </tr>
              </thead>
              <tbody>
              {searchstate && refill.length === 0 ? (
            <tr>
              <td colSpan="9" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
            refill.map((product) => {
                  return (
                    <tr key={product.id}>
                   <td>{product.productName}</td>
                    <td>{product.productCode}</td>
                     <td>{product.sale}</td>

                      <td>{product.newconnection}</td>

                      <td>{product.additional}</td>
                    
                      <td>{product.exempted} </td>
                      <td>{product.onlinepay} </td>
                      <td>{product.transfer} </td>
                      <td>{product.total}</td>

                     
                      {/* <td> 
                     <button
                      onClick={() => handleoverheadedit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td> */}
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>

            <div className="mt-3 mb-2">
            <h2 className="card-title">Consumables :</h2>
            </div>  
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Quantity</th>

                  <th>Total</th>
                 
                   {/* <th>Edit</th> */}

                </tr>
              </thead>
              <tbody>
              {searchstate && consumable.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
            consumable.map((product) => {
                  return (
                    <tr key={product.id}>
                     <td>{product.productname}</td>

                      <td>{product.productcode}</td>
                      <td>{product.quantity}</td>

                      <td>{product.total}</td>
                    
                    
                   
                      {/* <td> 
                     <button
                      onClick={() => handleoverheadedit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td> */}
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
           
            <div>
          
             
           
      </div>


          </div>
        </div>{" "}
      </div>
<br/>
      </div>
      <div className="col-12  mx-xl-auto ps-xl-10">
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="d-flex">
              <div
                className="card-title"
                style={{
                  fontSize: "20px",
                  // backgroundColor: "blue",
                }}
              >
                Delivery Agent Payment Details
                {loader6 && (
                  <div
                    className="loader"
                    style={{
                      position: "relative",
                      left: "20px",
                      top: "10px",
                    }}
                  ></div>
                )}
                                                      <span style={{ marginLeft: '25px' }}></span>

                  <button className="btn btn-primary" 
                   style={{
                    fontSize: '9px',
                    padding: '7px 5px'
                  }}onClick={handleRefresh6}>
        Refresh
      </button>  
              </div>
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Vehicle Id</th>
                  <th>Total Amount</th>
                  <th>Paid Amount</th>
                  <th>Expenses</th>
                  <th>Pending Amount</th>
                </tr>
              </thead>
              <tbody>
              {dapaymentdetails.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
                dapaymentdetails.map((dacash, index) => {
                  return (
                    <tr key={index}>
                      {dacash.vehicleid === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{dacash.vehicleid}</th>
                          )}

                      {dacash.totalamount !==null ? (
                      <th style={{ color: dacash.totalAmountFrom === 'fleet' ? 'green' : 'orange' }}>{dacash.totalamount}</th>
                      ):(
                        <th>
                        {dacash.totalOfAllAmount}</th>
                      )}

                      {dacash.paidamount!==null ?(
                      <th>
                        <button
                          onClick={(e) => openModal(e, dacash.vehicleid)}
                          style={{ border: "none", backgroundColor: "white" }}
                        >
                          {dacash.paidamount}
                        </button>{" "}
                        <div>
                          <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                          >
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Facevalue</th>

                                  <th>Denomination Number</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {amountdenominationdetails.map((dadenom) => {
                                  return (
                                    <tr>
                                      <th>{dadenom.facevalue}</th>
                                      <th>{dadenom.denominationnumber}</th>
                                      <th>
                                        {dadenom.facevalue *
                                          dadenom.denominationnumber}
                                      </th>
                                    </tr>
                                  );
                                })}
                              </tbody>{" "}
                            </table>
                            <button
                              className="btn btn-primary"
                              onClick={closeModal}
                            >
                              Close
                            </button>
                          </Modal>
                        </div>
                      </th>
                      ):(
                        <th>{dacash.totalOfAllPaidAmount}</th>
                      )}

{dacash.expenses !==null ? (
                      <th>{dacash.expenses}</th>
                      ):(
                        <th>
                        {dacash.totalOfAllExpenses}</th>
                      )}

<th style={{ display: 'flex', alignItems: 'center' }}>
  <span style={{ flex: 1 }}>
    {dacash.penddingamount !== null ? dacash.penddingamount : dacash.totalOfAllPendinAmount}
  </span>
  {dacash.penddingamount !== null && (
  <button
    className="btn btn-primary"
    style={{
      fontSize: '9px',
      padding: '7px 5px',
      marginLeft: '10px', // Adjust the margin as needed
    }}
    onClick={() => reciveopenModal(dacash.date, dacash.vehicleid)}
  >
    Receive
  </button>
  )}
</th>

                    </tr>
                  );

                  
                }))}
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <br />
      <br />
          {closestockByCurrentdate.length===0 ? (
          <div
            className="endtransaction"
            style={{ position: "relative", marginLeft: "80%" }}
          >
            <button className="btn btn-primary" onClick={(e) => onclickhandler(e,searchDate)}>
              Perform Day End
            </button>
          </div>
          ):(
        <h5 style={{ position: "relative", marginLeft: "45%"}}> 
     Transaction completed for {formatDate(closestockByCurrentdate[0].datetime)}.</h5>
          )}
      <br />
      <br />{" "}
      <div className="col-12  mx-xl-auto ps-xl-10">
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="d-flex">
              <div className="card-title">Close Stock Details
              {loader7 && (
                  <div
                    className="loader"
                    style={{
                      position: "relative",
                      left: "20px",
                      top: "10px",
                    }}
                  ></div>
                )}
                                                      <span style={{ marginLeft: '25px' }}></span>

              <button className="btn btn-primary"
               style={{
                fontSize: '9px',
                padding: '7px 5px'
              }} onClick={handleRefresh7}>
        Refresh
      </button>  
              </div>
            </div>
            <br />
            <br />

            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th id="date">Product</th>

                  <th id="full">Full</th>

                  <th id="empty">Empty</th>
                  <th id="empty">Defective</th>
                </tr>{" "}
               </thead>
               <tbody>
               {closestockone===null? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
                  closestockone.map((products) => {
                    return (
                      <tr>
                        <th>{products.product.productname}</th>
                        {products.pdo.map((ps, index) => {
                          return <th key={index}>{ps.qty}</th>;
                        })}
                      </tr>
                    );
                  }))}
              </tbody>
            </table>
            </div>
  </div>
</div>
</>
)}
</>
)}

 {(!searchstate && !tsearchstate) && (
 <>
        {errorMessage ===true ? (
          <>
          <div style={{ position: "relative", top: "40px", textAlign: "center" }} className="col-11 mx-xl-auto ps-xl-10">
   <div className="card card-raised">
     <div className="card-body">
<h5 style={{ color: "red" }}>No data exists for your organization before {orgStartDate}.</h5>
</div>
           </div>
           </div>
         </>
        ) : (
          <>
            {openingstock.length ===0 ? (
              <>
              <div style={{ position: "relative", top: "40px", textAlign: "center" }} className="col-11 mx-xl-auto ps-xl-10">
   <div className="card card-raised">
     <div className="card-body">
                <h5 style={{ color: "red" }}>Please Complete Day End Transaction for Previous Date.</h5>
                <br />
              
                  <button className="btn btn-primary" onClick={(e) => dateHandler(e, searchDate)}>Create Exception</button>
                  </div>
                  </div>
                  </div>
              </>  
        ) : (
          <>
        <br></br>
        <div className="col-12  mx-xl-auto ps-xl-10">
      <div className="card card-raised">
          <div className="card-body  ">
          <div className="card-title form-group d-flex align-items-center justify-content-between">Over Head Details{""}
           
           {loader0 && (
             <div
               className="loader"
               style={{
                 position: "relative",
                 left: "20px",
                 top: "10px",
               }}
             ></div>
           )} 

<button className="btn btn-primary"
style={{
fontSize: '9px',
padding: '7px 5px',
top: '10px',
right: '330px',
}}  onClick={handleRefresh}>
Refresh
</button> 

       <button className="btn btn-primary"onClick={handleAdd}>
        ADD
     </button>
   
     </div>
           
               
     <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "15px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                  <th>Vehicle</th>
                  <th>Product Name</th>
                  <th>Type</th>
                  <th>Given Quantity</th>
                  <th>Taken Quantity</th>
                  <th>Amount</th>
                  <th>Created By</th>
                  <th>Created Date</th>
                  <th>Due Date</th>
                  <th>Description</th>
                  <th>Status</th>
                   <th>Edit</th>

                </tr>
              </thead>
              <tbody>
              {overhead.length === 0 ? (
            <tr>
              <td colSpan="12" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
           
            overhead.map((product) => {
                  return (
                    <tr key={product.id}>
                     <td>{product.vehicleid}</td>
                      <td>{product.productname}</td>
                      <td>{product.givenQty}</td>
                      <td>{product.quantity}</td>
                      <td>{product.amount}</td>
                      <td>{product.userrole}</td>
                    
                      <td>{product.createddate} </td>
                      <td>{product.duedate} </td>
                      <td>{product.description} </td>
                      {product.completed == false ? (
                        <td id="icon" value={product.status}>
                          &nbsp; Pending &nbsp;
                          <BsCheckCircle
                            className="icon"
                            id="approval.approvalstatus"
                            style={{ backgroundColor: "green" }}
                            value="true"
                            onClick={(e) => rsubmit(e, product.id, true)}
                            />
                            &ensp;
      &ensp;
      <BsXCircle
                            className="icon"
                            value="Notverified"
                            style={{ backgroundColor: "red" }}
                            id="product.status"
                            onClick={(e) => rsubmit(e, product.id, false, product.orgcode)}
                            />
                             </td>
                        ) : (
                        <td id="icon" value={product.status}>
                          
                          <div className="d-flex">
                           
                            <AiOutlineCheck style={{ color: "green" }} />
                          </div>
                        </td>
                      )}
                      <td> 
                     <button
                      onClick={() => handleoverheadedit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td>
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
            <div>
          
             
           
      </div>


          </div>
        </div>{" "}
      </div>
    
      <br/>

         <div className="col-12  mx-xl-auto ps-xl-10">
    <div className="card card-raised">
      <div className="card-body ">
        <div className="d-flex">
          <div className="card-title">Open Stock Details</div>
          {/* {" "}
              {loader1 && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "20px",
                    top: "10px",
                  }}
                ></div>
              )}    */}
                  {/* <button className="btn btn-primary" 
                  style={{
                    fontSize: '12px',
                    padding: '5px 10px'
                  }} 
                  onClick={handleRefresh1}>
        Refresh
      </button>  */}
          <div style={{ position: "relative", marginLeft: "60%" }}>
        
            <button className="btn btn-primary" onClick={(e) => updateStockDateHandler(e, searchDate)}>Update Stock</button>
      
          </div>
        </div>
        <br />
        
        <table className="table table-bordered ">
          <thead>
            <tr
              style={{
                fontSize: "17px",
                backgroundColor: " #6200ea",
                color: "white",
                textAlign: "center",
              }}
            >
              <th id="date">Product</th>

              <th id="full">Full</th>

              <th id="empty">Empty</th>
              <th id="defective">Defective</th>
            </tr>{" "}
          </thead>
          <tbody>
          {openstock.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
              openstock.map((products) => {
                return (
                  <tr>
                    <th>{products.product.productname}</th>
                    {products.pdo.map((ps, index) => {
                      return <th key={index}>{ps.qty}</th>;
                    })}
                  </tr>
                );
              }))}
          </tbody>
        </table>
      </div>
    </div>
   </div>

  <br /> 
  <div>
    <div className="col-12  mx-xl-auto ps-xl-10">
      <div className="card card-raised ">
        <div className="card-body ">
          <div className="d-flex">
            <div
              className="card-title"
              style={{
                fontSize: "20px",
                // backgroundColor: "blue",
              }}
            >
              Total Load In Details{" "}
              {/* {loader1 && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "20px",
                    top: "10px",
                  }}
                ></div>
              )} */}
            </div>
          </div>
          <br />
          <div className="table-responsive">
          <table className="table table-bordered ">
            <thead>
              <tr
                style={{
                  fontSize: "17px",
                  backgroundColor: " #6200ea",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <th>Vehicle Id</th>
                {product.map((prod) => {
                  return <th>{prod.productname}</th>;
                })}
              </tr>
            </thead>
            <tbody>
            {tlin.length === 0 ? (
            <tr>
              <td colSpan={product.length + 1}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                tlin.map((ttin) => {
                  return (
                    <tr>
                      <th>{ttin.vehicle}</th>
                      {product.map((ps, pIndex) => {
                                  const productQty = ttin.pdo.find((item) => item.product === ps.productname);
                                  return (
                                    <th key={pIndex}>
                                      {productQty.qty!==null ? productQty.qty : 0}
                                    </th>
                                  );
                                })}
                    </tr>
                  );
                }))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
    <br />  
    <div className="col-12  mx-xl-auto ps-xl-10">
      <div className="card card-raised ">
        <div className="card-body ">
          <div className="d-flex">
            <div
              className="card-title"
              style={{
                fontSize: "20px",
              }}
            >
              Total Load Out Details
              {/* {loader2 && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "20px",
                    top: "10px",
                  }}
                ></div>
              )} */}
            </div>
          </div>
          <br />
          <div className="table-responsive">
          <table className="table table-bordered ">
            <thead>
              <tr
                style={{
                  fontSize: "17px",
                  backgroundColor: " #6200ea",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <th>Vehicle Id</th>
                {product.map((prod) => {
                  return <th>{prod.productname}</th>;
                })}
              </tr>
            </thead>
            <tbody>
            {tlout.length === 0 ? (
            <tr>
              <td colSpan={product.length + 1}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                tlout.map((ttin) => {
                  return (
                    <tr>
                      <th>{ttin.vehicle}</th>
                      {product.map((ps, pIndex) => {
            const productQty = ttin.pdo.find(item => item.product === ps.productname);
            return (
              <th key={pIndex}>
                {productQty ? productQty.qty : 0}
              </th>
            );
          })}
                    </tr>
                  );
                }))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />  
  <div>
  <div className="col-12  mx-xl-auto ps-xl-10">
      <div className="card card-raised ">
        <div className="card-body ">
          <div className="d-flex">
            <div
              className="card-title"
              style={{
                fontSize: "20px",
                // backgroundColor: "blue",
              }}
            >
              Total Transaction Out
              {/* {loader4 && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "20px",
                    top: "10px",
                  }}
                ></div>
              )} */}
            </div>
            &nbsp;&nbsp;&nbsp;
            &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div> FILTER BY VEHICLE:
              {" "}
              <select
                id="vehicleid"
                type="text"
                placeholder="vehicleid"
                onChange={(e) => rhandle(e, "vehicleid")}
              >
                <option>ALL</option>
                {vehicle.map((vehicle) => {
                  return (
                    <option value={vehicle.truckinfo}>
                      {vehicle.truckinfo}
                    </option>
                  );
                })}
              </select>{" "}
              <button
                type="submit"
                value="search"
                className="btn btn-primary"
                id="btn"
                onClick={(e) => searchbyvehi(e, vehicledetails.vehicleid)}
              >
                <IoSearchOutline id="IoSearchOutline" />
              </button>
              &ensp; &ensp; &ensp; &ensp; &ensp;
              FILTER BY PRODUCT:
                  <select
                    id="productname"
                    type="text"
                    placeholder="productname"
                    onChange={(e) => handleProduct(e, "productname")}
                  >
                    <option>ALL</option>
                    {products.map((p) => {
                      return (
                        <option value={p.productname}>
                          {p.productname}
                        </option>
                      );
                    })}
                  </select>{" "}
                  <button
                    type="submit"
                    value="search"
                    className="btn btn-primary"
                    id="btn"
                   onClick={(e) => searchbyProductname(e, productname.productname)}
                  >
                    <IoSearchOutline id="IoSearchOutline" />
                  </button>
            </div>
          </div>
          <br />
          <div className="table-responsive">
          <table className="table table-bordered ">
            <thead>
              <tr
                style={{
                  fontSize: "17px",
                  backgroundColor: " #6200ea",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <th>Vehicle Id</th>
                {product.map((prod) => {
                  return <th key={prod.productname}>{prod.productname}</th>;
                })}
              </tr>
            </thead>
            <tbody>
            {!searchbyv && 
                searchbytoutvehicle.map((ttout) => {
                  return (
                    <tr>
                      {ttout.vehicle.truckinfo === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{ttout.vehicle.truckinfo}</th>
                          )}
                      {ttout.pdo.map((ps1, index) => {
                        return <th key={index}> {ps1.qty === null ? ps1.totalQty : ps1.qty}</th>;
                      })}
                    </tr>
                  );
                })}

         {!searchbyPro &&
                searchToutByProduct.map((ttout) => {
                  return (
                    <tr>
                       {ttout.vehicle.truckinfo === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{ttout.vehicle.truckinfo}</th>
                          )}
                      {product.map((prod) => {
  const productQty = ttout.pdo.find((ps1) => ps1.product === prod.productname)?.qty;
  const displayQty = productQty != null ? productQty : ttout.pdo.find((ps1) => ps1.product === prod.productname)?.totalQty || 0;
  return <th key={prod.productname}>{displayQty}</th>;
})}
                    </tr>
                  );
                })}
               {tto1.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchbyv && searchbyPro && searchbytoutvehicle && tto1.map((ttin) => {
              return (
                    <tr>
                       {ttin.vehicle.truckinfo === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{ttin.vehicle.truckinfo}</th>
                          )}
                      {ttin.pdo.map((ps, index) => {
                        return <th key={index}> {ps.qty === null ? ps.totalQty : ps.qty}</th>;
                      })}
                    </tr>
                  );
                }))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
    <br />  
    <div className="col-12 mx-xl-auto ps-xl-10">
      <div className="card card-raised ">
        <div className="card-body ">
          <div className="d-flex">
            <div
              className="card-title"
              style={{
                fontSize: "20px",
                // backgroundColor: "blue",
              }}
            >
              Total Transaction In
              {/* {loader3 && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "20px",
                    top: "10px",
                  }}
                ></div>
              )} */}
            </div>
            &nbsp;&nbsp;&nbsp;
            &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {/* <div>
              {" "}
              <select
                id="vehicleid"
                type="text"
                placeholder="vehicleid"
                onChange={(e) => rhandle(e, "vehicleid")}
              >
                <option>select vehicle</option>
                {vehicle.map((vehicle) => {
                  return (
                    <option value={vehicle.truckinfo}>
                      {vehicle.truckinfo}
                    </option>
                  );
                })}
              </select>{" "}
              <button
                type="submit"
                value="search"
                className="btn btn-primary"
                id="btn"
                onClick={(e) => searchbyvehi(e, vehicledetails.vehicleid)}
              >
                <IoSearchOutline id="IoSearchOutline" />
              </button>
          </div> */}
         </div>

          <br />
          <div className="table-responsive">
          <table className="table table-bordered ">
            <thead>
              <tr
                style={{
                  fontSize: "17px",
                  backgroundColor: " #6200ea",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <th>Vehicle Id</th>
                <th>Product Name</th>
                <th>Sales</th>
                <th>New Connection</th>
                <th>Additionals</th>
                <th>Onlinepay</th>
                <th>Exempted</th>
                <th>Returned Product</th>
                <th>Transfer</th>
                <th>Total</th>
                <th>Total Amount</th>
                
              </tr>
            </thead>
            <tbody>
            {!searchbyv && 
                searchbyvehicle.map((ttin) => {
                  return (
                    <tr>
                      {ttin.vehicleid == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.vehicleid}</td>
                      )}

                      {ttin.productname == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.productname}</td>
                      )}
                      {ttin.totalquantity == null ? (
                        <td>{ttin.totalofallquantity}</td>
                      ) : (
                        <td>{ttin.totalquantity}</td>
                      )}
                     
                      {ttin.totalneworg == null ? (
                        <td>{ttin.totalofallneworg}</td>
                      ) : (
                        <td>{ttin.totalneworg}</td>
                      )}
                       {ttin.totaladditional == null ? (
                        <td>{ttin.totalofalladditional}</td>
                      ) : (
                        <td>{ttin.totaladditional}</td>
                      )}
                        {ttin.totalonlinepay == null ? (
                        <td>{ttin.totalofallonlinepay}</td>
                      ) : (
                        <td>{ttin.totalonlinepay}</td>
                      )}
                      {ttin.totalexempted == null ? (
                        <td>{ttin.totalofallexempted}</td>
                      ) : (
                        <td>{ttin.totalexempted}</td>
                      )}
                       {ttin.totalreturnedproduct == null ? (
                        <td>{ttin.totalofallreturnedproduct}</td>
                      ) : (
                        <td>{ttin.totalreturnedproduct}</td>
                      )}
                       {ttin.totaltransfer == null ? (
                        <td>{ttin.totalofalltransfer}</td>
                      ) : (
                        <td>{ttin.totaltransfer}</td>
                      )}
                       {ttin.totalsale == null ? (
                        <td>{ttin.totalofallsale}</td>
                      ) : (
                        <td>{ttin.totalsale}</td>
                      )}
          
                      {/* {ttin.totaloutgoingcylinder == null ? (
                        <td>{ttin.totalofalloutgoingcylinder}</td>
                      ) : (
                        <td>{ttin.totaloutgoingcylinder}</td>
                      )} */}
                      
                      {ttin.totalamount == null ? (
                        <td>{ttin.totalofallamount}</td>
                      ) : (
                        <td>{ttin.totalamount}</td>
                      )}
                    </tr>
                  );
                })}
                 {!searchbyPro &&
                searchTinbyProduct.map((ttin) => {
                  return (
                    <tr>
                      {ttin.vehicleid == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.vehicleid}</td>
                      )}

                      {ttin.productname == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.productname}</td>
                      )}
                      {ttin.totalquantity == null ? (
                        <td>{ttin.totalofallquantity}</td>
                      ) : (
                        <td>{ttin.totalquantity}</td>
                      )}
                     
                      {ttin.totalneworg == null ? (
                        <td>{ttin.totalofallneworg}</td>
                      ) : (
                        <td>{ttin.totalneworg}</td>
                      )}
                       {ttin.totaladditional == null ? (
                        <td>{ttin.totalofalladditional}</td>
                      ) : (
                        <td>{ttin.totaladditional}</td>
                      )}
                        {ttin.totalonlinepay == null ? (
                        <td>{ttin.totalofallonlinepay}</td>
                      ) : (
                        <td>{ttin.totalonlinepay}</td>
                      )}
                      {ttin.totalexempted == null ? (
                        <td>{ttin.totalofallexempted}</td>
                      ) : (
                        <td>{ttin.totalexempted}</td>
                      )}
                       {ttin.totalreturnedproduct == null ? (
                        <td>{ttin.totalofallreturnedproduct}</td>
                      ) : (
                        <td>{ttin.totalreturnedproduct}</td>
                      )}
                       {ttin.totaltransfer == null ? (
                        <td>{ttin.totalofalltransfer}</td>
                      ) : (
                        <td>{ttin.totaltransfer}</td>
                      )}
                       {ttin.totalsale == null ? (
                        <td>{ttin.totalofallsale}</td>
                      ) : (
                        <td>{ttin.totalsale}</td>
                      )}
          
                      {/* {ttin.totaloutgoingcylinder == null ? (
                        <td>{ttin.totalofalloutgoingcylinder}</td>
                      ) : (
                        <td>{ttin.totaloutgoingcylinder}</td>
                      )} */}
                      
                      {ttin.totalamount == null ? (
                        <td>{ttin.totalofallamount}</td>
                      ) : (
                        <td>{ttin.totalamount}</td>
                      )}
                    </tr>
                  );
                })}
              {ttin.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchbyv && searchbyPro && searchbyvehicle && ttin.map((ttin) => {
              return (
                <tr style={{ color: ttin.fromOutgoing === true ? 'red' : 'black' }}>
                      {ttin.vehicleid == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.vehicleid}</td>
                      )}

                      {ttin.productname == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.productname}</td>
                      )}
                      {ttin.totalquantity == null ? (
                        <td>{ttin.totalofallquantity}</td>
                      ) : (
                        <td>{ttin.totalquantity}</td>
                      )}
                       {ttin.totalneworg == null ? (
                        <td>{ttin.totalofallneworg}</td>
                      ) : (
                        <td>{ttin.totalneworg}</td>
                      )}
                       {ttin.totaladditional == null ? (
                        <td>{ttin.totalofalladditional}</td>
                      ) : (
                        <td>{ttin.totaladditional}</td>
                      )}
                      {ttin.totalonlinepay == null ? (
                        <td>{ttin.totalofallonlinepay}</td>
                      ) : (
                        <td>{ttin.totalonlinepay}</td>
                      )}

                      {ttin.totalexempted == null ? (
                        <td>{ttin.totalofallexempted}</td>
                      ) : (
                        <td>{ttin.totalexempted}</td>
                      )}
                       {ttin.totalreturnedproduct == null ? (
                        <td>{ttin.totalofallreturnedproduct}</td>
                      ) : (
                        <td>{ttin.totalreturnedproduct}</td>
                      )}
                       {ttin.totaltransfer == null ? (
                        <td>{ttin.totalofalltransfer}</td>
                      ) : (
                        <td>{ttin.totaltransfer}</td>
                      )}

                      {ttin.totalsale == null ? (
                        <td>{ttin.totalofallsale}</td>
                      ) : (
                        <td>{ttin.totalsale}</td>
                      )}
                     
                      {ttin.totalamount == null ? (
                        <td>{ttin.totalofallamount}</td>
                      ) : (
                        <td style={{ color: ttin.totalAmountFrom === 'fleet' ? 'green' : 'orange' }}>{ttin.totalamount}</td>
                      )}
                    </tr>
                  );
                }))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
    <br/>
      <div className="col-12  mx-xl-auto ps-xl-10">
       <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Office Sale Details{""}
           
                  {loader8 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )} 

<button className="btn btn-primary"
style={{
 fontSize: '9px',
 padding: '7px 5px',
 top: '10px',
 right: '310px',
}}  onClick={handleRefresh8}>
Refresh
</button> 

              <button className="btn btn-primary"onClick={handlecreatesale}>
               Create Sale
            </button>
          
            </div>
                  
                      
            <div className="mt-2 mb-2">
            <h2 className="card-title">Refill Sales :</h2>
            </div>  
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                   <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Sale</th>
                  <th>New Connection</th>
                  <th>Additional</th>

                  <th>Exempted</th>
                  <th>Onlinepay</th>
                  <th>Transfer</th>
                  <th>Total</th>
                   {/* <th>Edit</th> */}

                </tr>
              </thead>
              <tbody>
              {!searchstate && refill.length === 0 ? (
            <tr>
              <td colSpan="9" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            !searchstate &&
            refill.map((product) => {
                  return (
                    <tr key={product.id}>
                   <td>{product.productName}</td>
                    <td>{product.productCode}</td>
                     <td>{product.sale}</td>

                      <td>{product.newconnection}</td>

                      <td>{product.additional}</td>
                    
                      <td>{product.exempted} </td>
                      <td>{product.onlinepay} </td>
                      <td>{product.transfer} </td>
                      <td>{product.total}</td>

                     
                      {/* <td> 
                     <button
                      onClick={() => handleoverheadedit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td> */}
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>

            <div className="mt-2 mb-2">
            <h2 className="card-title">Consumables :</h2>
            </div>  
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                  }}
                >
                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Quantity</th>

                  <th>Total</th>
                 
                   {/* <th>Edit</th> */}

                </tr>
              </thead>
              <tbody>
              {!searchstate && consumable.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            !searchstate &&
            consumable.map((product) => {
                  return (
                    <tr key={product.id}>
                     <td>{product.productname}</td>

                      <td>{product.productcode}</td>
                      <td>{product.quantity}</td>

                      <td>{product.total}</td>
                    
                    
                   
                      {/* <td> 
                     <button
                      onClick={() => handleoverheadedit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td> */}
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
           
            <div>
          
             
           
      </div>


          </div>
        </div>{" "}
      </div>

  </div>
  <div className="col-12  mx-xl-auto ps-xl-10">
    <div className="card card-raised ">
      <div className="card-body ">
        <div className="d-flex">
          <div
            className="card-title"
            style={{
              fontSize: "20px",
            }}
          >
            Delivery Agent Payment Details
            {/* {loader5 && (
              <div
                className="loader"
                style={{
                  position: "relative",
                  left: "20px",
                  top: "10px",
                }}
              ></div>
            )} */}
          </div>
          &nbsp;&nbsp;&nbsp;
          &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <br />
        <table className="table table-bordered ">
          <thead>
            <tr
              style={{
                fontSize: "17px",
                backgroundColor: " #6200ea",
                color: "white",
                textAlign: "center",
              }}
            >
              <th>Vehicle Id</th>
              <th>Total Amount</th>
              <th>Paid Amount</th>
              <th>Expenses</th>
              <th>Pending Amount</th>
            </tr>
          </thead>
          <tbody>
          
          {dapaymentdetails.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
dapaymentdetails.map((searchDa, index) => {
return (
  <tr key={index}>
    {searchDa.vehicleid === null ? (
                            <th>Total</th>
                          ) : (
                            <th>{searchDa.vehicleid}</th>
                          )}

{searchDa.totalamount !== null ? (

    <th style={{ color: searchDa.totalAmountFrom === 'fleet' ? 'green' : 'orange' }}>{searchDa.totalamount}</th>
  ) : (
    <th>{searchDa.totalOfAllAmount}</th>
  )}

  {searchDa.paidamount!==null ?(
    <th>
                    <button
                      onClick={(e) => denominationdetails(searchDa.date,searchDa.vehicleid)}
                      style={{ border: "none", backgroundColor: "white" }}
                    >
                      {searchDa.paidamount}
                    </button>{" "}
                    <div>
                      <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Facevalue</th>

                              <th>Denomination Number</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {amountdenominationdetails.map((dadenom) => {
                              return (
                                <tr>
                                  <th>{dadenom.facevalue}</th>
                                  <th>{dadenom.denominationnumber}</th>
                                  <th>
                                    {dadenom.facevalue *
                                      dadenom.denominationnumber}
                                  </th>
                                </tr>
                              );
                            })}
                          </tbody>{" "}
                        </table>
                        <button
                          className="btn btn-primary"
                          onClick={closeModal}
                        >
                          Close
                        </button>
                      </Modal>
                    </div>
                  </th>
                   ) : (
                    <th>{searchDa.totalOfAllPaidAmount}</th>
                  )}

{searchDa.expenses !==null ? (
                      <th>{searchDa.expenses}</th>
                      ):(
                        <th>
                        {searchDa.totalOfAllExpenses}</th>
                      )}
<th style={{ display: 'flex', alignItems: 'center' }}>
  <span style={{ flex: 1 }}>
    {searchDa.penddingamount !== null ? searchDa.penddingamount : searchDa.totalOfAllPendinAmount}
  </span>
  {searchDa.penddingamount !== null && (
    <button
      className="btn btn-primary"
      style={{
        fontSize: '9px',
        padding: '7px 5px',
        marginLeft: '10px', // Adjust the margin as needed
      }}
      onClick={() => reciveopenModal(searchDa.date, searchDa.vehicleid)}
    >
      Receive
    </button>
  )}
</th>

  </tr>
);
}))
}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  
  <br />
  <br />
        {closestockBydate.length===0 ?(
        <div
          className="endtransaction"
          style={{ position: "relative", marginLeft: "80%" }}
        >
          <button className="btn btn-primary" onClick={(e) => onclickhandler(e,searchDate)}>
           Perform Day End
          </button>
        </div>
        ):(
      <h5 style={{ position: "relative", marginLeft: "45%"}}> 
      Transaction completed for {formatDate(closestockBydate[0].datetime)}.</h5>
        )}
  <br />
  <br />{" "}
  <div className="col-12  mx-xl-auto ps-xl-10">
    <div className="card card-raised ">
      <div className="card-body ">
        <div className="d-flex">
          
         
        <div
            className="card-title"
            style={{
              fontSize: "20px",
            }}
          >
            Close Stock Details{""}
            {/* {loader6 && (
              <div
                className="loader"
                style={{
                  position: "relative",
                  left: "20px",
                  top: "10px",
                }}
              ></div>
            )} */}
          </div>
          &nbsp;&nbsp;&nbsp;
          &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <br />

        <table className="table table-bordered ">
          <thead>
            <tr
              style={{
                fontSize: "17px",
                backgroundColor: " #6200ea",
                color: "white",
                textAlign: "center",
              }}
            >
              <th id="date">Product</th>

              <th id="full">Full</th>

              <th id="empty">Empty</th>
              <th id="empty">Defective</th>
            </tr>{" "}
           </thead>
           <tbody>
              
           {closestockone.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            closestockone.map((products) => {
                return (
                  <tr>
                    <th>{products.product.productname}</th>
                    {products.pdo.map((ps, index) => {
                      return <th key={index}>{ps.qty}</th>;
                    })}
                  </tr>
               );
              })
          )}
        </tbody>
      </table>
    </div>
  </div>
</div>
</>

)}
</>
)}
</>
)}
      {loading && <div className="loading"></div>}
      {iscreatesaleModalOpen && (
      <Createofficesale isOpen={iscreatesaleModalOpen} onRequestClose={handlecreatesaleCloseModal} 
      date={searchDate}
      />
     )}

      <Overheadmodal isOpen={isoverheadModalOpen} onRequestClose={handleoverheadCloseModal} />
      {isoverheadEditModalOpen && (
              <Overheadeditmodal
                isOpen={isoverheadEditModalOpen}
                onRequestClose={closeoverheadEditModal}
                id={selectedoverheadId}
              />
            )}
            {isReciveopenModalModalOpen && (
              <ReciveopenModal
                isOpen={isReciveopenModalModalOpen}
                onRequestClose={closereciveopenModal}
                vehicleid={selectedReceiveVehicleId}
                receiveDate={selectedDate}
              />
            )}
 {isPerformdatendModalIsOpen && (
              <PerformModal
                isOpen={isPerformdatendModalIsOpen}
                onRequestClose={closePerformdayendModal}
                performdate={selectedPerformdate}
              />
            )}

    </div>
  );
}

export default ConsumableStock;