import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Button, Pagination, Form } from "react-bootstrap"; // Import Pagination from react-bootstrap
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConsumerDetailModal from "./Consumerdetailsmodal";
import { useParams } from "react-router";
import Search from "./search";
import { FaMedal } from "react-icons/fa";

function CampaignRewrdPoints() {
    const { campaignId } = useParams();

  const [loader, setLoader] = useState(false);
  const [searchOption, setSearchOption] = useState("MONTH");
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [typeFilter, setTypeFilter] = useState("");
  const [campaignRewards, setCampaignRewards] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedConsumerNumber, setSelectedConsumerNumber] = useState(null);

  const date = new Date();
  const date1 = new Date();
  date.setDate(date.getDate());
  date1.setDate(date1.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  let currentDate1 = `${year}-${month}`;
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
  
  useEffect(() => {
    const fetchData= async () => {
        const token = JSON.parse(localStorage.getItem("data")).token;
        setLoader(true);
    
        try {
          const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
          const url = `/api/rewardpoints/orgcode=${orgCode}/campaignId=${campaignId}/date=${currentDate}`;
          const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setCampaignRewards(response.data);
        
          setLoader(false);
        } catch (error) {
          handleError(error);
        }
    };

    fetchData();
  }, [campaignId]);


  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred.");
    }
    setLoader(false);
  };

//   const handleFilterChange = (event) => {
//     const selectedOption = event.target.value;
//     setSearchOption(selectedOption);
//     setSelectedMonth(null);
//     setSelectedYear(null);
//   };

//   const searchByMonth = async (month, year) => {
//     const token = JSON.parse(localStorage.getItem("data")).token;
//     setLoader(true);
//     try {
//       let searchResponse;
      
//         searchResponse = await axios.get(
//           `/api/consumerloyalty/orgcode=${JSON.parse(
//             localStorage.getItem("data")
//           ).orgcode}/${month}/${year}`,
//           {
//             headers: { Authorization: `Bearer ${token}` },
//           }
//         );
      
//       setConsumerLoyalty([...searchResponse.data]);
//       setLoader(false);
//     } catch (error) {
//       handleError(error);
//     }
//   };

//   const searchByYear = async (year) => {
//     const token = JSON.parse(localStorage.getItem("data")).token;
//     setLoader(true);
//     try {
//       let searchResponse;
    
//         searchResponse = await axios.get(
//           `/api/consumerloyalty/orgcode=${JSON.parse(
//             localStorage.getItem("data")
//           ).orgcode}/${year}`,
//           {
//             headers: { Authorization: `Bearer ${token}` },
//           }
//         );
      
//       setConsumerLoyalty([...searchResponse.data]);
//       setLoader(false);
//     } catch (error) {
//       handleError(error);
//     }
//   };
//   const handleCardClick = (consumerNumber) => {
//     setSelectedConsumerNumber(consumerNumber);
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setSelectedConsumerNumber(null);
//   };

const campaignRewardsSearch = async (text) => {
  setLoader(true);
  const token = JSON.parse(localStorage.getItem("data")).token;
  await Promise.all([
     
      axios
      .get(
        `/api/rewardpoints/orgcode=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/campaignId=${campaignId}/date=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setCampaignRewards(response.data);
      }),
        ])

.finally(() => {
  setLoader(false);
});  

};

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />

      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex justify-content-between w-100">
              <div className="card-title form-group mb-4">Campaign Rewards Points</div>
              <Search searchName={campaignRewardsSearch} />
              {/* <div>
                <label>FILTER :</label>
                <select value={searchOption} onChange={handleFilterChange}>
                  <option value="MONTH">Month</option>
                  <option value="YEAR">Year</option>
                </select>
                &ensp;
                {searchOption === "MONTH" && (
                  <React.Fragment>
                    <Form.Label>Month:</Form.Label>
                    <DatePicker
                      selected={selectedMonth !== null && selectedYear !== null ? new Date(selectedYear, selectedMonth) : new Date()}
                      onChange={(date) => {
                        const month = date.getMonth() + 1;
                        const year = date.getFullYear();
                        setSelectedYear(year);
                        setSelectedMonth(date.getMonth());
                        searchByMonth(month, year);
                      }}
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                    />
                  </React.Fragment>
                )}
                {searchOption === "YEAR" && (
                  <React.Fragment>
                    <Form.Label>Year:</Form.Label>
                    <DatePicker
                      selected={selectedYear ? new Date(selectedYear, 0) : new Date()}
                      onChange={(date) => {
                        const year = date.getFullYear();
                        setSelectedYear(year);
                        searchByYear(year);
                      }}
                      showYearPicker
                      dateFormat="yyyy"
                    />
                  </React.Fragment>
                )}
              </div> */}
            </div>

            <br />
            <div className="row">
  {campaignRewards.length === 0 ? (
    <div className="col text-center">No Data Available</div>
  ) : (
    campaignRewards.map((cam, index) => (
      <div key={index} className="col-4 mb-4">
        {/* <h6>Campaign Name: {cam.campaignName}</h6> */}
         
                          <Card>
                          <Card.Body>
                            <div className="text-center">
                          <Card.Title className="mb-1">{cam.name}  </Card.Title>
                  <Card.Text><FaMedal/> {cam.points}</Card.Text>
                  </div>
                </Card.Body>
              </Card>
    
      </div>
    ))
  )}
</div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignRewrdPoints;
