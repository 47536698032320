import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
//import { token } from "../api/baseurl";
import CreatableSelect from "react-select/creatable";
import { Modal, Button } from "react-bootstrap";

function ConfirmationPage ({isOpen, onRequestClose, subModelDetails, subUsers})  {
    //const amountDifference = transferToModel.amount - selectedPlan.amount;
    const [subfeaturmodel, setSubfeaturmodel] = useState([]);
    const [subFeatures, setSubFeatures] = useState([]);
    const[selectedNewModelId, setSelectedNewModelId] = useState("");
    const [loading, setLoading] = useState(false);
    const token = JSON.parse(localStorage.getItem("data")).token;
    const [model, setModel] = useState({
      id: "",
      submodel: "",
      newModelId: "",
    });

    const getData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const sfmm = await axios.get("/api/SubmodelandfeatureDo", {
          headers: { Authorization: `Bearer ${token}` },
        });
    
        console.log(sfmm.data.features);
    
        setSubfeaturmodel(sfmm.data);
      } catch (error) {
        handleError(error);
      }

      };


      const getFeatures = async () => {
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const response = await axios.get(`/api/SubmodelandfeatureDo/modelId=${selectedNewModelId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setSubFeatures(response.data.features);
        } catch (error) {
          handleError(error);
        }
       
      };


      const handleChange = (e) => {
        setSelectedNewModelId(e.target.value);
      };

      const handleDisableSubModules = () => {
          setLoading(true);
  
      axios
        .post("/api/subscription-model/subModelDisable",{
          id: subModelDetails.smodel.id,
          newModelId:selectedNewModelId,
        },
         {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
         onRequestClose();
          toast.success("Model Deleted Successfully");
          setSelectedNewModelId("");
          setSubFeatures([]);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
    })};     
  
    const navigate = useNavigate();
    function handleError(error) {
      if (error.response && error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else {
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error('An error occurred.');
        }
      }
    }
  
   
    
    useEffect(() => {
        getData();
  }, []);


  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      {/* <Modal.Title>Create Subscription Model</Modal.Title> */}
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4 ">
     
        {/* <h2>Confirm Transfer</h2>
        <p>Selected Plan: {selectedPlan.name}</p>
        <p>Transfer to Plan: {transferToModel.name}</p>
        <p>Amount Difference: ${amountDifference}</p>
        <button onClick={handleConfirmDelete}>Confirm Transfer</button> */}

    <div className="card-body p-3 card card-raised mb-3">
      <div className="card-title mb-2">You are Deleting this Model</div>
        <p>Model Name : {subModelDetails.smodel?.submodel}</p>
        <p>Region : {subModelDetails.smodel?.region}</p>
        <p>Cost : {subModelDetails.smodel?.cost}</p>
        <p>Features : {subModelDetails.features?.map((sbf, idx) => (
          sbf.features + (idx !== subModelDetails.features.length - 1 ? ', ' : '')
        ))}
          </p>
        </div>
{subUsers.length > 0 && (
<div>
<div style={{fontSize:"17px"}} className="mb-3">Subscribed Users : {subUsers.length} </div>

<div className="card-title mb-2">Choose a New Model to Map an Existing Model</div>
        <label>Models : </label>
  <select
  value={selectedNewModelId} onChange={handleChange} onClick={getFeatures}>
  
    <option value="">Select Model</option>
    {subfeaturmodel.map((model) => (
      <option key={model.id} value={model.id}>
        {model.submodel}, {model.region}, {model.cost}
      </option>
    ))}
  </select>
  {selectedNewModelId && (
    <div className="mt-1">
      Features :
      {/* Render related features here */}
      {/* For example: */}
      <ul>
        {subFeatures.map((feature) => (
          <li key={feature.id}>{feature.features}</li>
        ))}
      </ul>
    </div>
  )}
</div>
)}
  
  <div className="card-title form-group d-flex align-items-center justify-content-between mt-5"> 
  <Button variant="btn btn-primary" onClick={onRequestClose} >
               Cancel
               </Button> 

        <button className="btn btn-primary" onClick={handleDisableSubModules}>Confirm</button>
        </div>
            </div>
          </div>
          {loading && <div className="loading"></div>} 
        </div>
        

      </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationPage;

