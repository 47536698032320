import AdminDashboard from "./admindashboard";
import Sidebar from "./adminsidebar";
import LoadIncoming from "./loadin";
import LoadOutgoing from "./loadoout";
import TransactionIncoming from "./transactionincomingdetails";

function AdminLoadout() {
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <div>
        <Sidebar />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div>
        <LoadOutgoing />
      </div>
    </div>
  );
}
export default AdminLoadout;
