import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import "./Spinner.css";
import "./Loader.css";
import { useParams } from "react-router";
import Dashboard from "./dashboard";

function ViewAdminAccount() {
    const{orgcode}=useParams();
    const{userid}=useParams();
    const{username}=useParams();

    const [orgDetails, setOrgDetails] = useState(null);
    const [loader, setLoader] = useState(true);
    const [subscription, setSubscription] = useState(null); 
    const [paymentDetails, setPaymentDetails] = useState(null); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = JSON.parse(localStorage.getItem("data")).token;

                const orgResponse = await axios.get(
                    `/api/organizationprofiles/Orgcode=${orgcode}`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                setOrgDetails(orgResponse.data);
                setLoader(false);

                const subscriptionResponse = await axios.get(
                    `/api/user/subscription/userid=${userid}`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                setSubscription(subscriptionResponse.data);
                setLoader(false);
                
            
        
         const paymentResponse = await axios.get(
            `/api/user/userpayment/username=${username}`, 
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        if (paymentResponse.data.length > 0) {
            setPaymentDetails(paymentResponse.data[0]); 
        }        console.log(paymentResponse.data);
        setLoader(false);

    } catch (error) {
        console.error("Error fetching data:", error);
        setLoader(false);
    }
};


        if ( orgcode && userid && username) {
            fetchData();
        }
    }, [orgcode, userid, username]);

    return (
        <div className="attendance-page" style={{marginTop:'100px'}}>
    <div className="dashboard-container">
                <Dashboard />
            </div>
            <div className="content-container">

            <div className="col-15 mx-xl-auto ps-xl-30" >
                <div className="card card-raised ">
                    <div className="card-body ">
                        <div className="card-title">Profile Details
                            {loader && (
                                <div
                                    className="loader"
                                    style={{ position: "absolute", left: "210px", top: "15px" }}
                                ></div>
                            )}
                        </div>
                        <br />
                        <div>
                        {orgDetails && (
    <table style={{ fontSize: '17px', marginBottom: '10px' }}>
        <tbody>
            <tr>
                <td style={{ paddingRight: '20px',paddingBottom:"10px" }}>Organization Name:</td>
                <td>{orgDetails.name}</td>
                <td style={{ paddingLeft: '300px',paddingBottom:"10px" }}>Orgcode:</td>
                <td>{orgDetails.orgcode}</td>
            </tr>
            <tr>
                <td style={{ paddingRight: '20px',paddingBottom:"10px" }}>Contact:</td>
                <td>{orgDetails.contact}</td>
                <td style={{ paddingLeft: '300px',paddingBottom:"10px" }}>PAN:</td>
                <td>{orgDetails.pan}</td>
            </tr>
            <tr>
                <td style={{ paddingRight: '20px',paddingBottom:"10px" }}>GST:</td>
                <td>{orgDetails.gstin}</td>
                <td style={{ paddingLeft: '300px',paddingBottom:"10px" }}>TAN:</td>
                <td>{orgDetails.tan}</td>
            </tr>
            <tr>
                <td style={{ paddingRight: '20px',paddingBottom:"10px" }}>City:</td>
                <td>{orgDetails.city}</td>
                <td style={{ paddingLeft: '300px',paddingBottom:"10px" }}>State:</td>
                <td>{orgDetails.state}</td>
            </tr>
            <tr>
                <td style={{ paddingRight: '20px',paddingBottom:"10px" }}>Pincode:</td>
                <td>{orgDetails.pincode}</td>
                <td style={{ paddingLeft: '300px',paddingBottom:"10px" }}></td>
                <td></td>
            </tr>
        </tbody>
    </table>
)}


</div>
<br />

                        {subscription && (
                            <div>
                                <div className="card-title">Subscription Details</div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>User Name</th>
                                            <th>Sub code</th>
                                            <th>TransactionLink</th>
                                            <th>Subdate</th>
                                            <th>Expires</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{subscription.username}</td>
                                            <td>{subscription.subcode}</td>
                                            <td>{subscription.transactionLink}</td>
                                            <td>{subscription.subdate}</td>
                                            <td>{subscription.expires}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}

                        

                        
                        {paymentDetails && (
                            <div>
                                <div className="card-title">Payment Details</div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>User Name</th>
                                            <th>paymentCurrency</th>
                                            {/* <th>tax</th> */}
                                            <th>amount</th>
                                            <th>paymentStatus</th>
                                            {/* <th>paymentRefCode</th> */}
                                            <th>paymentDate</th>
                                            <th>razorpay_payment_id</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{paymentDetails.username}</td>
                                            <td>{paymentDetails.paymentCurrency}</td>
                                            {/* <td>{paymentDetails.tax}</td> */}
                                            <td>{paymentDetails.amount}</td>
                                            <td>{paymentDetails.paymentStatus}</td>
                                            {/* <td>{paymentDetails.paymentRefCode}</td> */}
                                            <td>{paymentDetails.paymentDate}</td>
                                            <td>{paymentDetails.razorpay_payment_id}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default ViewAdminAccount;