import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ExpirydateModal({ isOpen, onRequestClose, expires ,id}) {
  const { orgcode } = useParams();
  const navigate = useNavigate();


  const [uproduct1, setUproduct1] = useState({
    
    id: id,
    expires: new Date(expires),
    orgcode: orgcode,
  });
  console.log("Id prop:", id);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (expires) {
      
      setUproduct1({
        ...uproduct1,
        expires: new Date(expires),
      });
    }
  }, [expires]);

  const rsubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    // const formattedDate = uproduct1.expires.toLocaleDateString('en-GB', {
    //   day: '2-digit',
    //   month: 'short',
    //   year: 'numeric',
    // }) + " " + uproduct1.expires.toLocaleTimeString('en-GB', {
    //   hour: '2-digit',
    //   minute: '2-digit',
    //   second: '2-digit'
    // });
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const formattedDate =
    ("0" + uproduct1.expires.getDate()).slice(-2) +
    "/" +
    monthNames[uproduct1.expires.getMonth()] + // Use month names array
    "/" +
    uproduct1.expires.getFullYear() +
    " " +
    ("0" + uproduct1.expires.getHours()).slice(-2) +
    ":" +
    ("0" + uproduct1.expires.getMinutes()).slice(-2) +
    ":" +
    ("0" + uproduct1.expires.getSeconds()).slice(-2);
    axios
      .post(
        "/api/user/subscription/expirydateupdate",
        {
          id:id,
          expires: formattedDate  
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("API Response:", response.data);
        setLoading(false);
        toast.success("Expiry Date updated successfully");
        onRequestClose();
        navigate(`/users/detailsofsubscription/${orgcode}`);
      })
      .catch((error) => {
       setLoading(false);
       handleError(error);
      });
  };
  console.log("Expires prop:", expires);

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }


  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Expiry Date</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={(e) => rsubmit(e)}>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="id">Id</label>
                    <input
                      className="form-control"
                      id="id"
                      type="number"
                      placeholder="id"
                      value={id}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="expires">Expiry Date</label>
                    <br />
                    <DatePicker
                      selected={uproduct1.expires}
                      onChange={(date) => setUproduct1({ ...uproduct1, expires: date })}
                      dateFormat="dd/MM/yyyy HH:mm:ss"
                      showTimeInput
                      timeInputLabel="Time:"
                      className="form-control"
                    />
                  </div>
                </div>
                <br />
                <input
                  type="submit"
                  value="Update"
                  className="btn btn-primary"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default ExpirydateModal;
