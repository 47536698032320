import axios from "../api/baseurl";
export const loginUser = (logindata) => {
  return axios
    .post("/api/authenticate", logindata)
    .then((response) => response.data);
};
export const usersaccount = (useraccount) => {
  return axios
    .get("/api/accounts/username", useraccount)
    .then((response) => response.data);
};
