import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import Search from "./search";
import AdminDashboard from "./admindashboard";
import "./Spinner.css";
import Financialmanagment from "./financialManagment";
import { error } from "jquery";
import FleetFinancials from "./fleetFinancials";
import OfficeFinancials from "./officeFinancials";
function ProductcostDetails() {
  const [activeTab, setActiveTab] = useState('fleetfinancials');

  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [selectedDate, setSelectedDate] = useState(currentDate);

  // Function to handle date change
  const searchName = async (date) => {
    setSelectedDate(date);
    console.log("sd",selectedDate);
    // Implement your logic for fetching data based on the selected date
    // For example: Call an API to fetch data for the selected date
  };

  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
    
      
      <div className="d-flex align-items-center ">
        <div className="col-10  align-items-center mx-xl-auto  ps-xl-10">
        {/* <div style={{ position: "relative", left: "260px" }}>
        <Search searchName={searchName} />{" "}
      </div> */}
   
      <div
        className="container-xl p-10 px-11"
        style={{ position: "relative", left: "100px" }}
      >
        
        <mwc-tab-bar style={{ marginBottom: "-1px", marginLeft:'-220px',marginTop:'-50px'}}  activeIndex="0"
            >
              <mwc-tab
                label="Fleet Financials"
                stacked
                active={activeTab === "fleetfinancials"}
                onClick={() => setActiveTab("fleetfinancials")}
              ></mwc-tab>
              <mwc-tab
                label="Office Financials"
                stacked
                active={activeTab === "officefinancials"}
                onClick={() => setActiveTab("officefinancials")}
              ></mwc-tab>
            </mwc-tab-bar>

        <hr className="mt-0 mb-4" style={{ width: "1100px", marginLeft:'-290px' }} /> {/* Adjust width as needed */}

</div>
</div>
</div>
      {/* Tab content */}
     
          {/* Render content based on activeTab */}
          {activeTab === "fleetfinancials" && (
            <FleetFinancials selectedDate={selectedDate}/>
          )}
          {activeTab === "officefinancials" && (
            <OfficeFinancials selectedDate={selectedDate} />
          )}
        </div>
       
     
  );
}

export default ProductcostDetails;
