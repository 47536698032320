import React from "react";
import AdminDashboard from "./admindashboard";
import { useNavigate } from "react-router-dom";
import "./Loader.css";
import "./Spinner.css";

function Rewardpointsbutton() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleBack = () => {
    navigate("/users/Rewardpointsbutton");
  };
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />

      <div className="col-9 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Leaderboard
              
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-primary"
                  onClick={() => handleNavigation("/users/Adminleaderboard")}
                >
                  Reward Points
                </button>
              </div>
            </div>
            <br/>
            <br/>
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-primary"
                  onClick={() => handleNavigation("/users/Refillsales")}
                >
                  Refill Sales
                </button>
              </div>
            </div>
            <br/>
            <br/>
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-primary"
                  onClick={() => handleNavigation("/users/Consumablesales")}
                >
                  Consumable Sales
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rewardpointsbutton;
