import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import { Link,useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Search from "./search";
import {   FiInfo } from "react-icons/fi";  
import Modal from "react-modal";
import $ from "jquery";
import Exemptedmodalpopup from "./exemptedmodalpopup";
import AdditionalModalPopup from "./Additionalmodalpopup";
import OnlineModalPopup from "./Onlinemodalpopup";
import TransactionIncoming from "./transactionincomingdetails";
import TransactionOutgoing from "./TransactionOutgoingDetails";
import NewConnectionModalPopup from "./newConnectionPopup";




function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [transactions1, setTransactions1] = useState([]);
  const [tin, setTin] = useState([]);
  const [tout, setTout] = useState([]);
  const [loader, setLoader] = useState(false);
  const [product, setProduct] = useState([]);
  const [searchbydate, setSearchbydate] = useState(true);
  const [selectedOutgoingVehicle, setSelectedOutgoingVehicle] = useState("");
  const [selectedIncomingVehicle, setSelectedIncomingVehicle] = useState("");
  const [exemptedDetails, setExemptedDetails] = useState([]);
  const [additionalDetails, setAdditionaldetails] = useState([]);
  const [newConDetails, setNewConDetails] = useState([]);
  const [onlineDetails, setOnlineDetails] = useState([]);

  const [loadinLoader, setLoadinLoader] = useState(false); // Loader for Load Incoming section
  const [loadoutLoader, setLoadoutLoader] = useState(false); // Loader for Load Outgoing section
  const [exemptedModalIsOpen, setExemptedModalIsOpen] = useState(false);
  const [onlineModalIsOpen, setOnlineModalIsOpen] = useState(false);
  const [Loadin, setLoadin] = useState([]);
  const [Loadout, setLoadout] = useState([]);
const [additionalModalIsOpen, setAdditionalModalIsOpen] = useState(false);
const [newConnectionModalIsOpen, setNewConnectionModalIsOpen] = useState(false);

const [transactionInModalIsOpen, setTransactionInModalIsOpen] = useState(false);
const [transactionOutModalIsOpen, setTransactionOutModalIsOpen] = useState(false);

const [selectedProductName, setSelectedProductName] = useState(null);
const [selectedVehicleId, setSelectedVehicleId] = useState(null);
const [transactionOutDetails, setTransactionOutDetails] = useState([]);
const [transactionInDetails, setTransactionInDetails] = useState([]);
const [toutDate, setToutDate] = useState("");
const [searchToutDate, setsearchToutDate] = useState("");

  const date = new Date();
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [searchDate, setSearchDate]= useState(currentDate);
  // Function to search by date
  function searchName(text) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    setSearchbydate(false);
   
    setSearchDate(text);

    // Fetch outgoing transactions
    const requests = [
      axios.get(`/api/product/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/productstatus=true/productTypeId=${1}`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
      axios.get(`/api/totaltransactionDo/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
      axios.get(`/api/totaltransaction/date=${text}/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
    ];
  
    // Execute all requests concurrently
    Promise.all(requests)
      .then((responses) => {
        const [ productRes, totalTransactionDoRes,totalTransactionRes] = responses;
        setProduct(productRes.data);
        setTout(totalTransactionDoRes.data.prodDo);
        setsearchToutDate(totalTransactionDoRes.data.date); 
        setTin(totalTransactionRes.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoader(false);
      });
  }
 
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
  
    try {
      const [outgoingResponse, productResponse, incomingResponse] = await Promise.all([
        axios.get(`/api/totaltransactionDo/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${currentDate}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`/api/product/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/productstatus=true/productTypeId=${1}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`/api/totaltransaction/date=${currentDate}/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
  
      setTransactions(outgoingResponse.data.prodDo);
      setToutDate(outgoingResponse.data.date);
      setProduct(productResponse.data);
      setTransactions1(incomingResponse.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false);
    }
  };
  
  const navigate = useNavigate();
  function updateTransin(e, searchDate) {
    navigate(`/users/updatetin/${searchDate}`);
  }

  function updateTransOut(e, searchDate) {
    navigate(`/users/updatetout/${searchDate}`);
  }
  function updateFinancials(e, searchDate) {
    navigate(`/users/dacash/${searchDate}`);
  }

  function updateExpenses(e, searchDate) {
    navigate(`/users/expenses/${searchDate}`);
  }
 

  useEffect(() => {
    getData();
  }, []);

  const handleOutgoingVehicleFilterChange = (event) => {
    setSelectedOutgoingVehicle(event.target.value);
  };

  const handleIncomingVehicleFilterChange = (event) => {
    setSelectedIncomingVehicle(event.target.value);
  };

  const outgoingVehicleOptions = new Set();
  const incomingVehicleOptions = new Set();

  transactions.concat(tout).forEach((ttin) => {
    if (!outgoingVehicleOptions.has(ttin.vehicle.truckinfo)) {
      outgoingVehicleOptions.add(ttin.vehicle.truckinfo);
    }
  });

  
  transactions1.concat(tin).forEach((ttin) => {
    if (!incomingVehicleOptions.has(ttin.vehicleid)) {
      incomingVehicleOptions.add(ttin.vehicleid);
    }
  });

 
  const filteredIncomingTransactions = searchbydate
    ? transactions1.filter((ttin) =>
        selectedIncomingVehicle ? ttin.vehicleid === selectedIncomingVehicle : true
      )
    : tin.filter((ttin) =>
        selectedIncomingVehicle ? ttin.vehicleid === selectedIncomingVehicle : true
      );


  const outgoingVehicleOptionsArray = Array.from(outgoingVehicleOptions);
  const incomingVehicleOptionsArray = Array.from(incomingVehicleOptions);

  // Filtered outgoing transactions based on selected vehicle
  
  const filteredOutgoingTransactions = searchbydate
    ? transactions.filter((ttin) =>
        selectedOutgoingVehicle ? ttin.vehicle.truckinfo === selectedOutgoingVehicle : true
      )
    : tout.filter((ttin) =>
        selectedOutgoingVehicle ? ttin.vehicle.truckinfo === selectedOutgoingVehicle : true
      );
      const openExemptedModal = async (productName, date, deliveredVehicleid) => {
        setSelectedProductName(productName);
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const currentDate = new Date().toISOString().split('T')[0];
          const response = await axios.get(
            `/api/ExemptedDetails/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/productname=${productName}/deliveredVehicleid=${deliveredVehicleid}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setExemptedDetails(response.data);
          setExemptedModalIsOpen(true);
        } catch (error) {
          handleError(error);
        }
      };
      
      const closeExemptedModal = () => {
        setExemptedModalIsOpen(false);
      };
      const openAdditionalModal = async (productName, date, deliveredVehicleid) => {
        setSelectedProductName(productName);
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const currentDate = new Date().toISOString().split('T')[0];
          const response = await axios.get(
            `/api/additionaldetails/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/productname=${productName}/deliveredVehicleid=${deliveredVehicleid}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setAdditionaldetails(response.data);
          setAdditionalModalIsOpen(true);
        } catch (error) {
          handleError(error);
        }
      };
      
      const closeAdditionalModal = () => {
        setAdditionalModalIsOpen(false);
       
      };

      const openNewConnectionModal = async (productName, date, deliveredVehicleid) => {
        setSelectedProductName(productName);
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const currentDate = new Date().toISOString().split('T')[0];
          const response = await axios.get(
            `/api/newconnectiondetails/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/productname=${productName}/deliveredVehicleid=${deliveredVehicleid}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setNewConDetails(response.data);
          setNewConnectionModalIsOpen(true);
        } catch (error) {
          handleError(error);
        }
      };
      
      const closeNewConnectionModal = () => {
        setNewConnectionModalIsOpen(false);
      };

      const openOnlineModal = async (productName, date, deliveredVehicleid) => {
        setSelectedProductName(productName);
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const currentDate = new Date().toISOString().split('T')[0];
          const response = await axios.get(
            `/api/OnlineDetail/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/productname=${productName}/deliveredVehicleid=${deliveredVehicleid}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setOnlineDetails(response.data);
          setOnlineModalIsOpen(true);
        } catch (error) {
          handleError(error);
        }
      };
      
      const closeOnlineModal = () => {
        setOnlineModalIsOpen(false);
      };

      const openTransactionOutModal = async (vehicleId, date) => {
        const token = JSON.parse(localStorage.getItem("data")).token;
       setSelectedVehicleId(vehicleId);
       try {
        const trans = await axios.get(
          `/api/TransactionoutgoingDo/vehicle=${vehicleId}/date=${date}/orgcode=${ JSON.parse(localStorage.getItem("data")).orgcode}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
    
        console.log(trans);
    
        setTransactionOutDetails(trans.data.prodDo);
        setToutDate(trans.data.date);
        const pro = await axios.get(
          `/api/product/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/productstatus=true/productTypeId=${1}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
    
        setProduct(pro.data);
    
        setTransactionOutModalIsOpen(true);
      }catch(error){
        handleError(error);
      }
      };

      const closeTransactionOutModal = () => {
        setTransactionOutModalIsOpen(false);
        searchName(searchDate);
      };

      const openTransactionInModal = async (vehicleId,productName, date) => {
        const token = JSON.parse(localStorage.getItem("data")).token;
       setSelectedVehicleId(vehicleId);
       setSelectedVehicleId(productName);
       try {
        const trans = await axios.get(
          `/api/Returntransaction/orgcode=${ JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/vehicleid=${vehicleId}/productname=${productName}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setTransactionInDetails(trans.data);
        setTransactionInModalIsOpen(true);
      }catch(error){
        handleError(error);
      }
      };

      const closeTransactionInModal = () => {
        setTransactionInModalIsOpen(false);
        searchName(searchDate);
      };
      const handleLoadinRefresh = async () => {
        setLoadinLoader(true); // Set Load Incoming loader to true
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
    
          const loadinResponse = await axios.get(
            `/api/totaltransaction/date=${searchDate}/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setLoadin(loadinResponse.data);
          setLoadinLoader(false); 
        } catch (error) {
          setLoadinLoader(false); 
          handleError(error)
        }
       // Set Load Incoming loader to false
      };
    
      const handleLoadoutRefresh = async () => {
        setLoadoutLoader(true); // Set Load Outgoing loader to true
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
    
          const loadoutResponse = await
           axios.get(
            `/api/totaltransactionDo/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setLoadout(loadoutResponse.data);
          setLoadoutLoader(false);
        } catch (error) {
          setLoadoutLoader(false);
          handleError(error);
        }
        // Set Load Outgoing loader to false
      };
      
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br />
      <br />
      {loader && <div className="loading"></div>}
      <div style={{ position: "relative", left: "260px" }}>
        <Search searchName={searchName} />{" "}
      </div>
      <br />
      <div>
        <div>
          <div className="col-12 mx-xl-auto ps-xl-10 ">
            <div className="card card-raised ">
              <div className="card-body ">
                <div className="d-flex">
                  <div className="card-title">Transaction Details
                  {loadinLoader && (
                  <div
                    className="loader"
                    style={{
                      position: "relative",
                      left: "20px",
                      top: "10px",
                    }}
                  ></div>
                )}
                     </div>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button className="btn btn-primary" onClick={(e) => updateExpenses(e, searchDate)}>
                      Expenses Update
                    </button>

                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <button className="btn btn-primary" onClick={(e) => updateFinancials(e, searchDate)}>
                      Financials Update
                    </button>
                  
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                 
                    <button className="btn btn-primary" onClick={(e) => updateTransin(e, searchDate)}>
                      Transaction In Update
                    </button>
          
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                 
                    <button className="btn btn-primary" onClick={(e) => updateTransOut(e, searchDate)}>
                      Transaction Out Update
                    </button>
           
                </div>{" "}
              </div>
            </div>
          </div>
          <br />
          <div>
            <div className="col-12   ps-xl-10 ">
              <div className="card card-raised">
                <div className="card-body  ">
                  <div className="card-title">Transaction Outgoing Details{""}
                  

{loadoutLoader && (
                  <div
                    className="loader"
                    style={{
                      position: "relative",
                      left: "20px",
                      top: "10px",
                    }}
                  ></div>
                )}
                                      <span style={{ marginLeft: '25px' }}></span>

                  <button className="btn btn-primary" onClick={handleLoadoutRefresh}>
        Refresh 
      </button>

                   </div>
                   <br />
                   <br />


        <div>
          <label>Vehicle Id</label>
          <select
            value={selectedOutgoingVehicle}
            onChange={handleOutgoingVehicleFilterChange}
          >
            <option value="">All</option>
            {outgoingVehicleOptionsArray.map((vehicle) => (
      <option key={vehicle} value={vehicle}>
        {vehicle}
              </option>
            ))}
          </select>
        </div>
       


                  <br />
                
                  <div className="table-responsive">
                  <table className="table table-bordered ">
                    <thead>
                      <tr
                        style={{
                          fontSize: "17px",
                          backgroundColor: "blue",
                          color: "white",
                        }}
                      >
                        
                        <th>Vehicle Id</th>
                        {product
 .map((prod, index) => (
    <th key={index}>{prod.productname}</th>
  ))}
                       <th>Status</th>

                      </tr>
                    </thead>
                    <tbody>
                      {searchbydate && filteredOutgoingTransactions.length === 0 ? (
                       <tr>
                      <td colSpan={product.length + 7} className="text-center">NO DATA AVAILABLE</td>
                      </tr>
                       ) : (
                        searchbydate &&
                        filteredOutgoingTransactions.map((ttin) => {
                          return (
                            <tr>
                            <th>{ttin.vehicle.truckinfo}</th>
                           
                            {product
  .map((ps, pIndex) => {
    const productQty = ttin.pdo.find(item => item.product === ps.productname);
    return (
      <th key={pIndex}>
        {productQty ? productQty.qty : 0}
      </th>
    );
  })
}
                           <td>
                             <button className="btn btn-primary" onClick={() => openTransactionOutModal(ttin.vehicle.truckinfo, toutDate)}>View</button>
                             </td>
                          </tr>
                          )
              
                            }))
                            }

          {!searchbydate && filteredOutgoingTransactions.length === 0 ? (
                       <tr>
                      <td colSpan={product.length + 7} className="text-center">NO DATA AVAILABLE</td>
                      </tr>
                       ) : (
                      !searchbydate &&
                        filteredOutgoingTransactions.map((ttin, index) => {
                          return (
                            <tr key={index}>
                            <th>{ttin.vehicle.truckinfo}</th>
                            {product.map((ps, pIndex) => {
            const productQty = ttin.pdo.find(item => item.product === ps.productname);
            return (
              <th key={pIndex}>
                {productQty ? productQty.qty : 0}
              </th>
            );
          })}
                            <td>
                             <button className="btn btn-primary" onClick={() => openTransactionOutModal(ttin.vehicle.truckinfo, searchToutDate)}>View</button>
                             </td>
                          </tr>
                          )
                        }))}
                    </tbody>
                  </table>
                </div>
                
                </div>
              </div>
            </div>
          </div>
              
          <br />
          <div>
            <div className="col-12   ps-xl-10">
              <div className="card card-raised ">
                <div className="card-body ">
                  <div className="card-title">Transaction Incoming Details{""}
                  {loadinLoader && (
                  <div
                    className="loader"
                    style={{
                      position: "relative",
                      left: "20px",
                      top: "10px",
                    }}
                  ></div>
                )}
                                      <span style={{ marginLeft: '25px' }}></span>

                  <button className="btn btn-primary" onClick={handleLoadinRefresh}>
        Refresh 
      </button>
                 
                   </div>
                   <br />
                   <br />
                   <div>
          <label> Vehicle Id</label>
          <select
            value={selectedIncomingVehicle}
            onChange={handleIncomingVehicleFilterChange}
          >
            <option value="">All</option>
            {incomingVehicleOptionsArray.map((vehicle) => (
      <option key={vehicle} value={vehicle}>
        {vehicle}
              </option>
            ))}
          </select>
        </div>
                  <br />
                  <div className="table-responsive">
                  <table className="table table-bordered ">
                    <thead>
                      <tr
                        style={{
                          fontSize: "17px",
                          backgroundColor: "blue",
                          color: "white",
                        }}
                      >
                       <th>Vehicle Id</th>
                    <th>Product Name</th>
                    <th>Sales</th>
                    <th>New Connection</th>
                    <th>Additional</th>
                    <th>Online Pay</th>
                    <th>Exempted</th>
                    <th>Returned Product</th>
                    <th>Transfer</th>
                    <th>Status</th>

                      </tr>
                    </thead>
                    <tbody>

                   
                    {/* {product.map((pro) => {
                          return (
                            <tr>
                            <th></th>

                            <th>{pro.productname}</th>

                          </tr>

                          )

                        })} */}

                      {searchbydate &&  filteredIncomingTransactions.length === 0 ? (
                       <tr>
                      <td colSpan="12" className="text-center">NO DATA AVAILABLE</td>
                      </tr>
                       ) : (
                        searchbydate &&
                        filteredIncomingTransactions.map((ttin) => {
                          return (
                            <tr>
                           <td>{ttin.vehicleid}</td>                           
                              <td>{ttin.productname}</td>
                              <td>{ttin.totalquantity}</td>
                              <td>{ttin.totalneworg}
                              <span style={{ marginLeft: '40px' }}><FiInfo 
                              onClick={() => openNewConnectionModal(ttin.productname, ttin.date, ttin.vehicleid)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                                </span>
                              </td>
                              <td>{ttin.totaladditional}
                              <span style={{ marginLeft: '40px' }}><FiInfo 
                              onClick={() => openAdditionalModal(ttin.productname, ttin.date, ttin.vehicleid)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                                </span>
                              </td>
                              <td>{ttin.totalonlinepay}
                              <span style={{ marginLeft: '30px' }}><FiInfo 
                              onClick={() => openOnlineModal(ttin.productname, ttin.date, ttin.vehicleid)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                                </span>
                              </td>
                              <td>{ttin.totalexempted}
                              <span style={{ marginLeft: '40px' }}><FiInfo 
                              onClick={() => openExemptedModal(ttin.productname, ttin.date,  ttin.vehicleid)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                                </span>
                              </td>
                              <td>{ttin.totalreturnedproduct}</td>
                              <td>{ttin.totaltransfer}</td>
                           
                              <td>
                             <button className="btn btn-primary" onClick={() => openTransactionInModal(ttin.vehicleid, ttin.productname, ttin.date)}>View</button>
                             </td>
                          </tr>
                        );
                      }))}

                    {!searchbydate &&  filteredIncomingTransactions.length === 0 ? (
                       <tr>
                      <td colSpan="12" className="text-center">NO DATA AVAILABLE</td>
                      </tr>
                       ) : (
                      !searchbydate &&
                        filteredIncomingTransactions.map((ttin) => {
                          return (
                            <tr>
                         
                            <td>{ttin.vehicleid}</td>
                            <td>{ttin.productname}</td>
                            <td>{ttin.totalquantity}</td>
                            <td>{ttin.totalneworg}
                            <span style={{ marginLeft: '40px' }}><FiInfo 
                              onClick={() => openNewConnectionModal(ttin.productname, ttin.date, ttin.vehicleid)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                                </span>
                            </td>
                            <td>{ttin.totaladditional}
                            <span style={{ marginLeft: '40px' }}><FiInfo 
                              onClick={() => openAdditionalModal(ttin.productname, ttin.date, ttin.vehicleid)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                                </span>
                            </td>
                      
                            <td>{ttin.totalonlinepay}
                            <span style={{ marginLeft: '30px' }}><FiInfo 
                              onClick={() => openOnlineModal(ttin.productname, ttin.date, ttin.vehicleid)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                                </span>
                            </td>
                            <td>{ttin.totalexempted}
                            <span style={{ marginLeft: '40px' }}><FiInfo 
                              onClick={() => openExemptedModal(ttin.productname, ttin.date, ttin.vehicleid)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                                </span>
                            </td>
                          
                            <td>{ttin.totalreturnedproduct}</td>
                    
                            <td>{ttin.totaltransfer}</td>
                      
            
                            <td>
                             <button className="btn btn-primary" onClick={() => openTransactionInModal(ttin.vehicleid, ttin.productname, ttin.date)}>View</button>
                             </td>
                        </tr>
                         );
                        }))}
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {exemptedModalIsOpen &&(
          <Exemptedmodalpopup
        isOpen={exemptedModalIsOpen}
        onRequestClose={closeExemptedModal}
        exemptedDetails={exemptedDetails}
        productName={selectedProductName} // Pass selected product name to the modal
      />
          )}
           {additionalModalIsOpen &&(
       <AdditionalModalPopup
        isOpen={additionalModalIsOpen}
        onRequestClose={closeAdditionalModal}
        additionalDetails={additionalDetails}
        productName={selectedProductName} 
      />
           )}

{newConnectionModalIsOpen &&(
       <NewConnectionModalPopup
        isOpen={newConnectionModalIsOpen}
        onRequestClose={closeNewConnectionModal}
        newConnectionDetails={newConDetails}
        productName={selectedProductName} 
      />
           )}

      {onlineModalIsOpen &&(   
       <OnlineModalPopup
        isOpen={onlineModalIsOpen} 
        onRequestClose={closeOnlineModal}
        onlineDetails={onlineDetails}
        productName={selectedProductName} 
      />
      )}

{transactionInModalIsOpen &&(
       <TransactionIncoming
        isOpen={transactionInModalIsOpen} 
       onRequestClose={closeTransactionInModal}
       transactionInDetails={transactionInDetails}
       vehicleId={selectedVehicleId}
       selectedProductName={selectedProductName}
      />
)}
 {transactionOutModalIsOpen &&(
       <TransactionOutgoing
        isOpen={transactionOutModalIsOpen} 
        onRequestClose={closeTransactionOutModal}
        transactionOutDetails={transactionOutDetails}
        toutDate={toutDate}
        vehicleId={selectedVehicleId}
        products={product}
      />
 )}
            
        </div>
        
      </div>
    </div>
  );
}

export default Transactions;

