import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function PlansModal({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Plans</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Plan 1: Whatsapp</p>
        <p>Plan 2: SMS</p>
        <p>Plan 3: Maps</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PlansModal;
