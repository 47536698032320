import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../api/baseurl";
import './ReportFooter.css';  // Import the CSS file
import AdminDashboard from "./admindashboard";

function ReportFooter() {
  const [profile, setProfile] = useState({
    id: "",
    companyName: "",
  });

  const [pageNumber] = useState(1); 

  const currentYear = new Date().getFullYear();
  const currentDate = new Date().toLocaleDateString(); 

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/companydetails`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProfile(response.data[0]);
      } catch (error) {
        console.error("Error fetching company details:", error);
      }
    };
  
    fetchProfile();
  }, []);

  function openCynefianWebsite() {
    window.open("http://www.cynefian.com", "_blank");
  }

  return (
    <div className="content-wrapper">
        <AdminDashboard />
      <footer className="footer p-4">
        <div className="footer-content">
          <div className="footer-left">
            {profile.companyName && (
              <div className="fw-500">
                <div className="company-name" style={{ textAlign: "center" }}>
                <span style={{ marginRight: "50%", }}>
                    {profile.companyName}
                  </span>                  <div style={{ textAlign: "right", marginTop: "0.5rem" }}>
                    <span style={{ marginRight: "1rem" }}>
                      Generated Date:{" "}
                      <span style={{ fontWeight: "normal" }}>{currentDate}</span>
                    </span>
                    </div>
                    <div style={{ textAlign: "right", marginTop: "0.5rem" }}>

                    <span>
                      Page NO: <span style={{ fontWeight: "normal",marginRight:"125px" }}>{pageNumber}</span>
                    </span>
                  </div>
                </div>
              </div>
            )}
           <div className="fw-500" style={{ textAlign: "center", marginRight: "42%" }}>
              Copyright &copy;
              <Link
                style={{ color: "black", textDecoration: "none" }}
                onClick={openCynefianWebsite}
              >
                {" "}
                Cynefian
              </Link>{" "}
              {/* {currentYear} */}
            </div>
          </div>
          <div className="footer-right">
            {/* Add any right-aligned content here */}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default ReportFooter;