import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
function Subscriptionbilling() {
  const [subscriptionbilling, setSubscriptionbilling] = useState([]);
  const [adminsubscriptionbilling, setAdminsubscriptionbilling] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    if (JSON.parse(localStorage.getItem("data")).role === "ROLE_SUPER") {
      const trans = await axios
        .get("/api/user/subscription", {
          headers: { Authorization: `Bearer ${token}` },
        })

        .then((res) => {
          setSubscriptionbilling(res.data);
          setLoading(false);
          if (res.data == "") {
            toast.error("no subscription available for this account ");
          }
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
        });
    }
    if (JSON.parse(localStorage.getItem("data")).role === "ROLE_ADMIN") {
      const usersub = await axios
        .get(
          `/api/user/subscription/username=${
            JSON.parse(localStorage.getItem("data")).username
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )

        .then((res) => {
          if (res.data == "") {
            toast.error("no subscription available for this account ");
            setLoading(false);
          }
          setAdminsubscriptionbilling(usersub.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
        });
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }


  useEffect(() => {
    getData();
  }, []);
  return (
    <main>
      <div>
        <Dashboard />
      </div>{" "}
      <header className="bg-dark"></header>
      <div
        className="container-xl p-10 px-11"
        style={{ position: "relative", left: "100px" }}
      >
        &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <mwc-tab-bar style={{ marginBottom: "-1px" }} activeIndex="3">
          <Link
            to="/users/subscriptionbilling"
            style={{ textDecoration: "none" }}
          >
            <mwc-tab
              label="Subscriptions"
              icon="account_balance"
              stacked
              onClick='location.href="app-account-billing.html"'
            ></mwc-tab>
          </Link>{" "}
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link to="/users/notification" style={{ textDecoration: "none" }}>
            {" "}
            <mwc-tab
              label="Notifications"
              icon="notifications"
              stacked
              onClick='location.href="app-account-notifications.html"'
            ></mwc-tab>
          </Link>
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link to="/users/security" style={{ textDecoration: "none" }}>
            <mwc-tab
              label="Security"
              icon="security"
              stacked
              activeIndex="3"
            ></mwc-tab>
          </Link>
        </mwc-tab-bar>
        <hr className="mt-0 mb-5" />
        <div className="row gx-5">
          <div className="container-xl px-4">
            <h1 className="text-black py-3 mb-0 display-6">
              Account Settings - Subscriptions
            </h1>
          </div>

          <table className="table table-bordered ">
            <thead>
              <tr
                style={{
                  fontSize: "17px",
                  backgroundColor: "#6200ea",
                  color: "white",
                }}
              >
                <th>User Name</th>

                <th>Subscription Code</th>
                <th>Subscription Date</th>
                <th>Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              {JSON.parse(localStorage.getItem("data")).role === "ROLE_SUPER" &&
                subscriptionbilling.map((accd) => {
                  return (
                    <tr key={accd.id}>
                      <td>{accd.username}</td>

                      <td>{accd.subcode}</td>
                      <td>{accd.subdate}</td>
                      <td>{accd.expires}</td>
                    </tr>
                  );
                })}
              {JSON.parse(localStorage.getItem("data")).role === "ROLE_ADMIN" &&
                adminsubscriptionbilling.map((accd) => {
                  return (
                    <tr key={accd.id}>
                      <td>{accd.username}</td>

                      <td>{accd.subcode}</td>
                      <td>{accd.subdate}</td>
                      <td>{accd.expires}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}

export default Subscriptionbilling;
