import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

function UpdateService({ isOpen, onRequestClose, Id }) {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  
  const [uproduct1, setUproduct1] = useState({
    serviceName: "",
    points: "",
    date: currentDate,
    serviceCost: {
      cost: 0,
    },
    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const response = await axios.get(`/api/product-services/${Id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const productData = response.data;
      setUproduct1(productData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Id) {
      getData();
    }
  }, [Id]);

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .post(
        "/api/product-services/update",
        uproduct1,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Service updated successfully");
        onRequestClose();
        getData();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  const rhandle = (event, field) => {
    const value = event.target.value;
    if (field === "serviceCost") {
      setUproduct1({
        ...uproduct1,
        serviceCost: {
          ...uproduct1.serviceCost,
          cost: parseFloat(value) || 0,
        },
      });
    } else {
      setUproduct1({
        ...uproduct1,
        [field]: value,
      });
    }
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Service</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={(e) => rsubmit(e)}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="serviceName">Service Name</label>
                    <input
                      className="form-control"
                      id="serviceName"
                      type="text"
                      placeholder="serviceName"
                      value={uproduct1.serviceName}
                      onChange={(e) => rhandle(e, "serviceName")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="points">Points</label>
                    <input
                      className="form-control"
                      id="points"
                      type="text"
                      placeholder="points"
                      value={uproduct1.points}
                      onChange={(e) => rhandle(e, "points")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="date">Date</label>
                    <input
                      className="form-control"
                      id="date"
                      type="text"
                      placeholder="date"
                      value={uproduct1.date}
                      onChange={(e) => rhandle(e, "date")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="serviceCost">Service Cost</label>
                    <input
                      className="form-control"
                      id="serviceCost"
                      type="number"
                      placeholder="serviceCost"
                      value={uproduct1.serviceCost.cost}
                      onChange={(e) => rhandle(e, "serviceCost")}
                    />
                  </div>
                </div>
                <br />
                <input type="submit" value="Update" className="btn btn-primary" />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default UpdateService;
