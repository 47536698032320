import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "../components/admindashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import img from "../images/report.png";

function AgentMappingReport() {
  const [reportParams, setReportParams] = useState({
    month: "",
    year: new Date().getFullYear(),
  });

  const [mappingReport, setMappingReport] = useState(null);
  const [totalAgents, setTotalAgents] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchMappingReport = async () => {
    if (!reportParams.month || !reportParams.year) {
      toast.error("Please select a month and year.");
      return;
    }

    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/agentMappingReport/${reportParams.month}/${reportParams.year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const dailyTotals = response.data.agent_daily_totals;
      let total = 0;
      dailyTotals.forEach((dailyTotal) => {
        const agentsCount = Object.values(dailyTotal).filter(value => value !== "" && value !== "date").length;
        total += agentsCount;
      });
      setTotalAgents(total);

      setMappingReport(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching agent mapping report:", error);
      toast.error("Failed to load agent mapping report.");
      setLoading(false);
    }
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setReportParams((prevState) => ({ ...prevState, [field]: value }));
  };

  const generatePDF = () => {
    if (!mappingReport) {
      toast.error("No data available to generate PDF.");
      return;
    }
  
    try {
      const doc = new jsPDF();
  
      // Adding the logo to the left side
      const imgData = img;
      const logoWidth = 60;
      const logoHeight = 25;
      const logoX = 10;
      const logoY = 10;
      doc.addImage(imgData, 'PNG', logoX, logoY, logoWidth, logoHeight);
  
      // Adding the address to the right side
      const pageWidth = doc.internal.pageSize.getWidth();
      const textX = pageWidth - 90; // Adjusted to position on the right side
      const textY = logoY + 5;
  
      doc.setFontSize(12); // Decreased font size
      doc.setTextColor(86, 150, 214);
      doc.text("Vijay Gas Agencies", textX, textY);
  
      doc.setFontSize(10); // Decreased font size for address
      doc.setTextColor(0, 0, 0);
      const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
      doc.text(addressText, textX, textY + 10);
  
      // Adding the report title below the logo and address
      doc.setFontSize(14); // Decreased font size for title
      const reportTitleY = logoY + logoHeight + 20;
      doc.text("Agent Mapping Report (Monthly)", logoX, reportTitleY);
  
      // Adding month, year, and total agents mapped below the title
      doc.setFontSize(12); // Decreased font size for details
      const detailsY = reportTitleY + 10;
      doc.text(`Month: ${reportParams.month}, Year: ${reportParams.year}`, logoX, detailsY);
      doc.text(`Total Agents Mapped: ${totalAgents}`, logoX, detailsY + 10);
  
      // Adding the table with agent mapping details
      const tableColumn = ["Date", "KA01", "KA02", "KA04", "ka16", "KA102312"];
      const tableRows = [];
  
      mappingReport.agent_daily_totals.forEach((dailyTotal) => {
        const data = [
          dailyTotal.date,
          dailyTotal.KA01 || "",
          dailyTotal.KA02 || "",
          dailyTotal.KA04 || "",
          dailyTotal.ka16 || "",
          dailyTotal.KA102312 || "",
        ];
        tableRows.push(data);
      });
  
      doc.autoTable(tableColumn, tableRows, { startY: detailsY + 20 });
  
      doc.save("agent_mapping_report.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Failed to generate PDF.");
    }
  };
  

  return (
    <div>
      <AdminDashboard />
      <br/><br/><br/>
      <div className="card card-raised mt-4">
        <div className="card card-raised">
          <br />
          <br />
          <div className="card-body">
            <br />
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="d-flex align-items-center">
                      <img className="top-bar__logo" src={img} alt="logo" />
                      <div className="ml-3">
                        <h3 style={{ fontFamily: 'Sans-serif' }}>Vijay Gas Agencies</h3>
                        <p style={{ fontFamily: 'Sans-serif' }}>1010/27 Mysore Road, Gundlupete</p>
                      </div>
                    </div><br/><br/>
                    <div>
                      <label htmlFor="month">Month:</label>
                      <input
                        id="month"
                        type="text"
                        value={reportParams.month}
                        onChange={(e) => handleInputChange(e, "month")}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <label htmlFor="year">Year:</label>
                      <input
                        id="year"
                        type="number"
                        value={reportParams.year}
                        onChange={(e) => handleInputChange(e, "year")}
                      />
                    </div>
                    <br />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={fetchMappingReport}
                      disabled={loading}
                    >
                      Generate Report
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={generatePDF}
                      disabled={!mappingReport}
                    >
                      Download PDF
                    </button>
                  </form>
                  {loading && <div className="loading"></div>}
                  {mappingReport && (
                    <div>
                      <br />
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>KA01</th>
                            <th>KA02</th>
                            <th>KA04</th>
                            <th>ka16</th>
                            <th>KA102312</th>
                          </tr>
                        </thead>
                        <tbody>
                          {mappingReport.agent_daily_totals.map((dailyTotal, index) => (
                            <tr key={index}>
                              <td>{dailyTotal.date}</td>
                              <td>{dailyTotal.KA01 || "N/A"}</td>
                              <td>{dailyTotal.KA02 || "N/A"}</td>
                              <td>{dailyTotal.KA04 || "N/A"}</td>
                              <td>{dailyTotal.ka16 || "N/A"}</td>
                              <td>{dailyTotal.KA102312 || "N/A"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="mt-3">
                        <strong>Total Agents Mapped: {totalAgents}</strong>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AgentMappingReport;
