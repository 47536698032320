import React, { useEffect, useState, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import moment from "moment";
import ManagerSidebar from "./managerSidebar";
import { useNavigate, useParams } from "react-router-dom";

import "./Spinner.css";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}

function ConsumableLiveGodownupdate() {
  const [openstock, setOpenstock] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    prodDo: [{ pid: "", qty: "", status: "", productname: "" }],
  });
  const [loader8, setLoader8] = useState(false);
  const [projectedValues, setProjectedValues] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoader8(true); // Start loading
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
 
        const projectedValues = await axios.get(
          `/api/CloseStockDo/live-godown-stock/orgcode=${orgcode}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
         const filteredProducts = projectedValues.data.prodDo.filter(product =>
          product.product.productTypeId === 3
        );
        setProjectedValues(filteredProducts);
        setErrorMessage("");

        setLoader8(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        handleError(error);
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.message);
        }
      } finally {
        setLoader8(false); // End loading
      }
    };
 
    fetchData();
 
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
 
    return () => clearInterval(interval); 
  }, []);
  const handleRefresh = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
    setLoader8(true);
 
    try {
   
 
     
      const projectedValues = await axios.get(
        `/api/CloseStockDo/live-godown-stock/orgcode=${orgcode}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
 const filteredProducts = projectedValues.data.prodDo.filter(product =>
  product.product.productTypeId === 3
);

setProjectedValues(filteredProducts); 
setErrorMessage("");
     
} catch (error) {
  setLoader8(false);

  // handleError(error);
  if (error.response && error.response.data) {
    setErrorMessage(error.response.data.message);
  }
} finally {
  setLoader8(false);
}
};
 
 
  function handleError(error) {
    if (error.response?.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      // toast.error(error.response?.data?.message || 'An error occurred.');
    }
  }
  const tableCellStyle = {
    padding: '10px',  // Increased padding
    fontSize: '14px' // Increased font size
  };

  const headerCellStyle = {
    padding: '12px',  
    fontSize: '14px' 
  };

  return (
    // <div style={{ position: "fixed", padding: "50px" }}>
    // <div
    //   className="card card-raised"
    //   style={{
    //     maxWidth: "800px",
    //     position: "absolute", // Changed to fixed
    //     // bottom: "160px",
    //     justifyContent: "flex-end",
    //     // left: "1020px",
    //     left:"1020px",
    //     bottom:"-270px"
    //   }}
    // >
    <div className="col-8 mx-xl-auto ps-xl-9" style={{ position: 'absolute', top: '360px' }}>
    <div className="card card-raised" style={{ maxWidth: '600px',  marginLeft: '470px',  marginRight: 'auto',left:"400px"}}> {/* Further reduced card size */}
        <div className="card-body">
          <div className="d-flex justify-content-center align-items-center mb-2">
          <div className="card-title" style={{ fontSize: '18px' }}>
          Consumable Stock
              {loader8 && (
                <div className="loader" style={{ position: 'relative', left: '15px' }}></div>
              )}
              <button
                className="btn btn-primary"
                style={{
                    fontSize: '9px',
                    padding: '7px 5px',
                  marginLeft: '20px',
                }}
                onClick={handleRefresh}
              >
                Refresh
              </button>
              <br/>
              {/* <div style={{ fontSize: '15px', marginTop: '10px',textAlign:"center" }}>
              Consumable
            </div> */}
            </div>
          </div>
          <br />
          <br />
         
          
          <div className="table-responsive col-md-10 mx-auto" style={{ padding: '0 10px', maxHeight: '350px',  overflowY: 'auto',   display: 'flex', }}>
          <table className="table table-bordered" style={{ width: '100%' }}>
              <thead>
                <tr style={{ fontSize: "16px", backgroundColor: "#6200ea", color: "white", textAlign: "center" }}>
                  <th style={headerCellStyle}>Product</th>
                  <th style={headerCellStyle}>Quantity</th>
                 
                </tr>
              </thead>
              <tbody>
                {projectedValues.map((products) => (
                  <tr key={products.product.id}>
 <th style={tableCellStyle}>
                       <div style={{ display: 'flex', alignItems: 'center' }}>
                       <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {products.product.image && products.product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${products.product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "30px", height: "30px", borderRadius: "50%"}}></div>
                          )}
                        </div>
                        <span style={{fontSize: '12px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                        {products.product.productname}
                          </span>
                       
                        </div>
                      </th>               
                           {products.pdo
                             .filter((ps) => ps.status === "full")

                           .map((ps, index) => (
                      <td style={tableCellStyle} key={index}>{ps.qty}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {errorMessage && (
            <div style={{ color: 'red', marginTop: '20px', textAlign: 'center' }}>
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(ConsumableLiveGodownupdate);