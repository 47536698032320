import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

function UpdateOfficeExpenses({ isOpen, onRequestClose, expensesDetails }) {
  const [uproduct1, setUproduct1] = useState({
    id: "",
    amount: "",
    description: "",
    expensesFor: "office",
    date: new Date().toISOString().split("T")[0],
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const Id = expensesDetails?.id;

  useEffect(() => {
    if (Id) {
      getData();
    }
  }, [Id]);

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const response = await axios.get(`/api/cashpaymentexpenses/${Id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUproduct1(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      if (uproduct1.id) {
        // Update existing record
        await axios.post(
          "/api/cashpaymentexpenses/update",
          uproduct1,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        toast.success("Expenses updated successfully");
      } else {
        // Create new record
        await axios.post(
          "/api/cashpaymentexpenses/create",
          uproduct1,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        toast.success("Expenses created successfully");
      }
      setLoading(false);
      onRequestClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error('An error occurred while saving expenses.');
    }
  };

  const handleChange = (event, field) => {
    const value = event.target.value;
    setUproduct1((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{uproduct1.id ? "Update Expenses" : "Create Expenses"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="date">Date</label>
                    <input
                      className="form-control"
                      id="date"
                      type="date"
                      placeholder="Date"
                      value={uproduct1.date}
                      onChange={(e) => handleChange(e, "date")}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="amount">Amount</label>
                    <input
                      className="form-control"
                      id="amount"
                      type="text"
                      placeholder="Amount"
                      value={uproduct1.amount}
                      onChange={(e) => handleChange(e, "amount")}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="description">Description</label>
                    <textarea
                      className="form-control"
                      id="description"
                      type="text"
                      value={uproduct1.description}
                      placeholder="Description"
                      onChange={(e) => handleChange(e, "description")}
                      rows="2"
                    />
                  </div>
                </div>

                <br />
                <input
                  type="submit"
                  value={uproduct1.id ? "Update" : "Create"}
                  className="btn btn-primary"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default UpdateOfficeExpenses;
