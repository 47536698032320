import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./Spinner.css";
import { useNavigate } from "react-router";
import { error } from "jquery";
function Exemptedreport() {
  const [exemptedreport, setExemptedreport] = useState({
    startdate: "",
    enddate: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });

  const [servicereport, setServicereport] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    const date = new Date();

    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;
try{
    const vehicles = await axios.get(
      `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(vehicles);

    setVehicle(vehicles.data);
  }catch(error){
    handleError(error);
  }
  };

  useEffect(() => {
    getData();
  }, []);

  function rhandle(e, field) {
    let actualValue = e.target.value;

    setExemptedreport({ ...exemptedreport, [field]: actualValue });
  }
  const rSubmit = async (e, sd, ed) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios
      .get(
        `/api/ExemptedDetailsDO/orgcode=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/startdate=${sd}/enddate=${ed}`,

        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setLoading(false);
        setServicereport(response.data);
        const doc = new jsPDF();

        const tableColumn = [
          "Customer Name",
          "Productname",
          "Delivered Quantity",
          "Date",
        ];
        const tableRows = [];
        const total = ["Service header"];
        // const tableRows1 = [];
        // let totalamount = 0;
        // let totalquantity = 0;

        response.data.forEach((item) => {
          const data = [
            item.customername,
            item.productname,
            item.deliveredqty,
            item.date,
          ];
          console.log(data);
          tableRows.push(data);
          // totalamount = totalamount + item.amount;
          // totalquantity = totalquantity + item.quantity;
        });
        doc.setFontSize(14);
        doc.setTextColor(86, 150, 214);
        doc.text(16, 20, "Exempted Report ");
        doc.setFontSize(10);
        doc.text(80, 20, `StartDate:${sd}`);
        doc.text(150, 20, `EndDate:${ed}`);

        doc.autoTable(tableColumn, tableRows, { startY: 25 });
        footer();
        function footer() {
          doc.text(150, 285, "***this is a e-generated report"); //print number bottom right
          doc.page++;
        }

        doc.save("data.pdf");
      })
      .catch((error)=>{
        setLoading(false);
        handleError(error);
      })
      ;
  };

  const navigate = useNavigate();
      function handleError(error) {
        if (error.response && error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else {
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error('An error occurred.');
          }
        }
      }

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br /> <br />
      <div className="col-xl-6 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4 ">
            <div className="card-title">Exempted Report</div>
            <br />
            <form>
              <div>
                <label for="StartDate">StartDate</label>
                <input
                  id="startdate"
                  type="date"
                  placeholder="StartDate"
                  value={exemptedreport.startdate}
                  onChange={(e) => rhandle(e, "startdate")}
                />{" "}
                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                <label for="EndDate">EndDate</label>
                <input
                  id="enddate"
                  type="date"
                  placeholder="EndDate"
                  value={exemptedreport.enddate}
                  onChange={(e) => rhandle(e, "enddate")}
                />{" "}
              </div>
              <br />
              <br />

              <button
                type="submit"
                value="Download"
                disabled={loading}
                className="btn btn-primary"
                onClick={(e) =>
                  rSubmit(
                    e,

                    exemptedreport.startdate,
                    exemptedreport.enddate
                  )
                }
              >
                Download
              </button>
            </form>{" "}
            {loading && <div className="loading"></div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Exemptedreport;
