import AdminDashboard from "./admindashboard";
import Sidebar from "./adminsidebar";
import TransactionIncoming from "./transactionincomingdetails";

function AdminTransactionincoming() {
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <div>
        <TransactionIncoming />
      </div>
    </div>
  );
}
export default AdminTransactionincoming;
