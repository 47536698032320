import React, { useState } from "react";
import { Link } from "react-router-dom";
import "@material/mwc-textfield/mwc-textfield.js";
import axios from "../api/baseurl";
import "./Spinner.css";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./footer";
function Forgotpassword() {
  const navigate = useNavigate();

  const url = "/api/forgotPassword";
  const [admindata, setAdata] = useState({
    username: "",
  });
  const [loading, setLoading] = useState(false);
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(url, {
        username: admindata.username,
      })

      .then(
        (res) => {
          console.log(res.data);
          setLoading(false);
          navigate("/otpverification");
          toast("OTP sent to your email");
        },
        (error) => {
          this.setState({ error });
        }
      );
  }
  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setAdata({ ...admindata, [field]: actualValue });
  };
  function cynefianwebsite(e) {
    window.open("https://www.cynefian.com", "_blank");
  }
  return (
    <div className="bg-primary">
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-lg-4">
                  <div className="card card-raised shadow-10 mt-5 mt-xl-10 mb-4">
                    <div className="row g-0">
                      <div className="col-lg-12 col-lg-12">
                        <div className="card-body p-5">
                          <div className="text-center">
                            <img
                              className="mb-3"
                              src="assets/img/icons/background.svg"
                              alt="..."
                              style={{ height: "48px" }}
                            />
                            <h1 className="display-5 mb-0">Forgotpassword</h1>
                          </div>
                          <br />
                          <form onSubmit={(e) => rsubmit(e)}>
                            <div className="mb-4">
                              <input
                                className="form-control"
                                type="email"
                                placeholder="enter username"
                                id="username"
                                value={admindata.username}
                                onChange={(e) => handleChange(e, "username")}
                              ></input>
                            </div>
                            <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                              <Link
                                className="small fw-500 text-decoration-none"
                                to="/signin"
                              >
                                Return to login
                              </Link>
                              <input
                                type="submit"
                                className="btn btn-primary"
                                value="Forgotpassword"
                              />
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="text-center mb-5">
                        <Link
                          className="small fw-500 text-decoration-none link-white"
                          to="/register"
                        >
                          Need an account? Sign up!
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-7 col-md-6 d-none d-md-block"
                    style={{
                      backgroundImage:
                        "url('https://source.unsplash.com/-uHVRvDr7pg/1600x900')",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </main>
        </div>

        <div id="layoutAuthentication_footer">
         <Footer/>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}
export default Forgotpassword;
