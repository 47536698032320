import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import {FiInfo } from "react-icons/fi";  
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MdDeleteForever } from "react-icons/md";
import {useNavigate} from "react-router-dom";
import ConfirmationModal from "./endtransactionpopup";
import TransactionConfirmationModal from "./transactionDeleteCnfmModal";

const TransactionOutgoing = ({ isOpen, onRequestClose, transactionOutDetails, products,toutDate}) => {
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [transOutDeleteModal, setTransOutDeleteModal] = useState(false);
  const [transOutDetails, setTransOutDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteTransactionOutCnfm = async (date,time,vehicle) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
  
      // Make the first API request
      const res = await axios.get(`/api/transactionoutgoing/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/time=${time}/vehicle=${vehicle}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTransOutDetails(res.data);

      setTransOutDeleteModal(true);
    } catch (error) {
     handleError(error);
    }
  };


  const closeCnfmModal = () => {
    setTransOutDeleteModal(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false); // Assuming you want to close the modal when cancel is clicked
  };
  
  const handleDeleteAllTransOut = () => {
   setIsModalOpen(true);
  };

  const handleConfirm = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;

    // const confirmDelete = window.confirm("Deleting Outgoing transactions, will also delete all Incoming transactions that have already been saved for this vehicle and date. Do you want to continue?");
    // if (!confirmDelete) {
    //   return; 
    // }
   
    const entities = transOutDetails.map(item => ({
      id: item.id,
      // Add other properties as needed
    }));

    try {
      // Call the API to delete all items with IDs in allIds
      const response = await axios.delete(
        '/api/transactionoutgoing/deleteTransactionOutgoingList',
        {
          headers: { Authorization: `Bearer ${token}` },
          data: entities, // Pass the list of entities as data in the request body
        }
      );
      console.log(response.data); // Log the response from the API
      toast.success("Transaction out deleted successfully");
      closeCnfmModal();
      onRequestClose();
    } catch (error) {
       handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  useEffect(() => {
  
  }, []);

  return (
    <>
    <Modal show={isOpen} onHide={onRequestClose} centered size="xl">
    <Modal.Header closeButton>
      <Modal.Title>Transactions Outgoing Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div>
    {transactionOutDetails.length > 0 && (
  <div>
    <label style={{ fontSize: '1em', color: "#000000" }}>Vehicle Id: {transactionOutDetails[0].vehicle}</label>
    </div>
    )}
    <label style={{ fontSize: '1em', color: "#000000" }}>Date: {toutDate}</label>
      </div>
      <div className="table-responsive">
      <table className="table table-bordered ">
        <thead>
        <tr>
                <th>Time</th>
                {products.map((ps, index) => (
            <th key={index}>{ps.productname}</th>
          ))}
                <th>Approval Status</th>
                <th>Actions</th>
                
              </tr>
            </thead>
            <tbody>
            {transactionOutDetails.map((ttin, index) => (
            <tr key={index}>
                    <th>{ttin.time}</th>
                    {products.map((ps, pIndex) => {
            const productQty = ttin.pdo.find(item => item.product === ps.productname);
            return (
              <th key={pIndex}>
                {productQty ? productQty.qty : 0}
              </th>
            );
          })}
                    <th>{ttin.status}</th>
                    <th> 
                      {/* <AiFillEdit style={{  cursor: 'pointer' ,fontSize:"18px"}}  title="Edit"  
                    //onClick={() => handleSubModelEdit(subscription.id)}
                    /> */}
    <MdDeleteForever style={{ marginLeft: '25px',cursor: 'pointer', fontSize:"18px" }}  title="Delete"
    onClick={() => handleDeleteTransactionOutCnfm(toutDate,ttin.time,ttin.vehicle)}
    /></th>
                    </tr>
          ))}
              </tbody>
            </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onRequestClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

{/* delete modal */}
<Modal show={transOutDeleteModal} onHide={closeCnfmModal} centered size="lg">
<Modal.Header closeButton>
  {/* <Modal.Title>Create Subscription Model</Modal.Title> */}
</Modal.Header>
<Modal.Body>
<div>
{transOutDetails.length > 0 && (
<div>
<label style={{ fontSize: '1em', color: "#000000" }}>Vehicle Id: {transOutDetails[0].vehicle}</label>
<div>
<label style={{ fontSize: '1em', color: "#000000" }}>Date: {transOutDetails[0].date}</label>
</div>
<div>
<label style={{ fontSize: '1em', color: "#000000" }}>Time: {transOutDetails[0].time}</label>
</div>
</div>
)}

  </div>
  <table className="table table-bordered ">
    <thead>
    <tr>
            <th>Id</th>
           <th>Product name</th>
           <th>Product code</th>
           <th>Quantity</th>
            <th>Approval Status</th>
            {/* <th>Actions</th> */}
            
          </tr>
        </thead>
        <tbody>
        {transOutDetails.map((ttin, index) => (
        <tr key={index}>
                <th>{ttin.id}</th>
                <th>{ttin.productname}</th>
                <th>{ttin.productcode}</th>
                <th>{ttin.quantity}</th>
                <th>{ttin.approvalstatus}</th>
                {/* <th>  */}
                  {/* <AiFillEdit style={{  cursor: 'pointer' ,fontSize:"18px"}}  title="Edit"  
                //onClick={() => handleSubModelEdit(subscription.id)}
                /> */}
{/* <MdDeleteForever style={{ marginLeft: '25px',cursor: 'pointer', fontSize:"18px" }}  title="Delete"
 onClick={() => handleDeleteTrandsactionOut(ttin.id)}
/></th> */}
                </tr>
      ))}
          </tbody>
        </table>
          <div className="card-title form-group d-flex align-items-center justify-content-between mt-5"> 
<Button variant="btn btn-primary" onClick={closeCnfmModal} >
           Cancel
           </Button> 

    <button className="btn btn-primary" 
   onClick={ handleDeleteAllTransOut}
    >Delete All</button>
    </div>
    <div>
    {isModalOpen && (
  <TransactionConfirmationModal
    message="Deleting Outgoing transactions, will also delete all Incoming transactions that have already been saved for this vehicle and date. Do you want to continue?"
    onCancel={handleCancel}
    onConfirm={handleConfirm}
  />
)}
</div>

  </Modal.Body>
  <Modal.Footer>
   </Modal.Footer>
</Modal>
</>
      );
    }
export default TransactionOutgoing;
