import React, { useEffect, useState, useReducer } from "react";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import Sidebar from "./adminsidebar";
import { BsCheckCircle } from "react-icons/bs";
import { BsXCircle } from "react-icons/bs";
import "./Spinner.css";
import "./Loader.css";
import "./approval.css";

function AdminApproval() {
  const [approvals, setApprovals] = useState([]);
  const [status, setStatus] = useState({
    id: "",
    approvalstatus: "",
    username: "",
    role: "",
    orgid: "",
  });
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(false); // State to manage loader visibility
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const url = "/api/authority/orgid";

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true); // Show loader
    try {
      let prof1;
      if (!JSON.parse(localStorage.getItem("data")).role == "ROLE_SUPER") {
        prof1 = await axios.get(
          `/api/authority/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        prof1 = await axios.get("/api/authority", {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
      setApprovals(prof1.data);
    } catch (error) {
      console.error("Error fetching approvals:", error);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  useEffect(() => {
    getData();
  }, [reducerValue]);

  function rsubmit(e, id, user, astatus, orgid, role) {
    e.preventDefault();
    setLoading(true); // Show loader
    const newdata = { ...status };
    newdata[e.target.id] = e.target.value;
    setStatus(newdata);

    const token = JSON.parse(localStorage.getItem("data")).token;
    const endpoint = !JSON.parse(localStorage.getItem("data")).role == "ROLE_SUPER"
      ? "/api/admin/approvals"
      : "/api/superadmin/authority";

    axios
      .post(
        endpoint,
        {
          id: id,
          approvalstatus: astatus,
          username: user,
          role: role,
          orgid: orgid,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        forceUpdate();
      })
      .catch((error) => {
        console.error("Error submitting approval:", error);
      })
      .finally(() => {
        setLoading(false); // Hide loader
      });
  }

  function rhandle(e) {
    const newdata = { ...status };
    newdata[e.target.id] = e.target.value;
    setStatus(newdata);
  }

  // Logic for displaying current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = approvals.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  return (
    <div>
      <AdminDashboard />
      <Sidebar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div style={{ position: "relative", top: "40px" }}>
        <div className="col-10 align-items-center mx-xl-auto ps-xl-10">
          <div
            className="card-title"
            style={{ color: "black", fontSize: "20px", marginLeft: "5px" }}
          >
            Approval Details
          </div>
          {currentItems.map((approval) => (
            <div key={approval.id} className="card card-raised mb-4">
              <div className="card-body p-0">
                <br />
                <div className="d-flex justify-content-between">
                  <div style={{ width: "20%", marginLeft: "90px" }}>
                    USERNAME:
                  </div>
                  <div style={{ width: "80%" }}>{approval.username}</div>
                </div>
                <br />
                <br />
                <div className="d-flex justify-content-between">
                  <div style={{ width: "20%", marginLeft: "90px" }}>ROLE:</div>
                  <div style={{ width: "80%" }}>{approval.role}</div>
                </div>
                <br />
                <br />
                <div className="d-flex justify-content-between">
                  <div style={{ width: "20%", marginLeft: "90px" }}>
                    ORGANIZATION ID:
                  </div>
                  <div style={{ width: "80%" }}>{approval.orgid}</div>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: "30px" }}
                >
                  <div
                    style={{
                      width: "20%",
                      marginLeft: "550px",
                      marginTop: "-165px",
                    }}
                  >
                    APPROVALSTATUS:
                  </div>
                  <div
                    style={{
                      width: "80%",
                      marginTop: "-165px",
                      marginLeft: "10px",
                    }}
                  >
                    {approval.approvalstatus === "REQUESTED" ? (
                      <>
                        {approval.approvalstatus} &nbsp;
                        <BsCheckCircle
                          className="icon"
                          style={{ backgroundColor: "green" }}
                          id="approval.approvalstatus"
                          value="APPROVED"
                          onClick={(e) =>
                            rsubmit(
                              e,
                              approval.id,
                              approval.username,
                              "APPROVED",
                              approval.orgid,
                              approval.role
                            )
                          }
                        />
                        &ensp;
                        <BsXCircle
                          className="icon"
                          value="REJECTED"
                          style={{ backgroundColor: "red" }}
                          id="approval.approvalstatus"
                          onClick={(e) =>
                            rsubmit(
                              e,
                              approval.id,
                              approval.username,
                              "REJECTED",
                              approval.orgid,
                              approval.role
                            )
                          }
                        />
                      </>
                    ) : (
                      approval.approvalstatus
                    )}
                    <br />
                    <br />
                    <div className="d-flex justify-content-between">
                      <div
                        style={{
                          width: "40%",
                          marginLeft: "-136px",
                          marginTop: "70px",
                        }}
                      >
                        ID:
                      </div>
                      <div
                        style={{ width: "280px", marginTop: "70px", marginLeft: "-10px" }}
                      >
                        {approval.id}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      {loading && <div className="loading"></div>}
      {/* Pagination */}
      <ul style={{ display: "flex", listStyle: "none", justifyContent: "center" }}>
        <li style={{ marginRight: "10px" }} className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <a onClick={prevPage} className="page-link" style={{ cursor: "pointer" }}>
            Previous
          </a>
        </li>
        {Array.from({ length: Math.ceil(approvals.length / itemsPerPage) }).map((_, index) => (
          <li key={index} style={{ marginRight: "10px" }} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
            <a onClick={() => paginate(index + 1)} className="page-link" style={{ cursor: "pointer" }}>
              {index + 1}
            </a>
          </li>
        ))}
        <li style={{ marginLeft: "10px" }} className={`page-item ${currentPage === Math.ceil(approvals.length / itemsPerPage) ? "disabled" : ""}`}>
          <a onClick={nextPage} className="page-link" style={{ cursor: "pointer" }}>
            Next
          </a>
        </li>
      </ul>
    </div>
  );
}

export default AdminApproval;
