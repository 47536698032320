import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaCircle } from "react-icons/fa"; // Importing FontAwesome circle icon
import AdminDashboard from "../components/admindashboard";

function Reportspage() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/users/Reportspage");
  };

  return (
    <div>
      <br/><br/><br/>
      <AdminDashboard />
      <div></div>
      <br />
      <br />
      <br />

      <div className="col-9 mx-xl-auto ps-xl-10">
        {/* Operational Report Card */}
        <div className="card card-raised mb-5">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Operational Report
            </div>
            <br />
            <div className="row">
              <div className="col-6 mb-3">
                <Link to="/users/loadinreport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Loadin Report
                </Link>
              </div>
              <div className="col-6 mb-3">
                <Link to="/users/loadoutReport1" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Loadout Report
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mb-3">
                <Link to="/users/Invoicereport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Invoice Report
                </Link>
              </div>
              <div className="col-6 mb-3">
                <Link to="/users/TotalErvReport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> ERV Report
                </Link>
              </div>

              <div className="col-6 mb-3">
                <Link to="/users/Deliveryreports" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Delivery Report
                </Link>
              </div>

              <div className="col-6 mb-3">
                <Link to="/users/Taxreports" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Exempted Delivery Report
                </Link>
              </div>

              <div className="col-6 mb-3">
                <Link to="/users/Taxreports" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Exempted Financials Report
                </Link>
              </div>

              <div className="col-6 mb-3">
                <Link to="/users/Taxreports" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Exempted Financials & Abstract Report
                </Link>
              </div>

              <div className="col-6 mb-3">
                <Link to="/users/Newconnectionreport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> New Connection Report
                </Link>
              </div>

              <div className="col-6 mb-3">
                <Link to="/users/Additionalreport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Additional Report
                </Link>
              </div>

              <div className="col-6 mb-3">
                <Link to="/users/Consumabelsalesreport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Consumables Sales Report
                </Link>
              </div>

              <div className="col-6 mb-3">
                <Link to="/users/Onlinedetailsreport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Online Pay Report
                </Link>
              </div>

              <div className="col-6 mb-3">
                <Link to="/users/Transactionoutgoingreport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Monthly Transaction Outgoing Report
                </Link>
              </div>

              <div className="col-6 mb-3">
                <Link to="/users/TransactionIncomingReport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Monthly Transaction Incoming Report
                </Link>
              </div>

              <div className="col-6 mb-3">
                <Link to="/users/Taxreports" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> SQC Report
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Personnel Report Card */}
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Personnel Report
            </div>
            <br />
            <div className="row">
              <div className="col-6 mb-3">
                <Link to="/users/rolereport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Role Report
                </Link>
              </div>
              <div className="col-6 mb-3">
                <Link to="/users/personnelreport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Personnel Report
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mb-3">
                <Link to="/users/agentmappingreport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Agent Mapping Report
                </Link>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />

        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Vehicles Report
            </div>
            <br />
            <div className="row">
              <div className="col-6 mb-3">
                <Link to="/users/VehicleMaintenanceReport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Vehicle Maintenance Reports
                </Link>
              </div>
              <div className="col-6 mb-3">
                <Link to="/users/FuelLog" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Fuellog Reports
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mb-3">
                <Link to="/users/vehiclemileagereport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Vehicle Mileage Report
                </Link>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />

        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Tax Report
            </div>
            <br />
            <div className="row">
              <div className="col-6 mb-3">
                <Link to="/users/monthlysalesreport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Monthly sales & stock abstract
                </Link>
              </div>
              <div className="col-6 mb-3">
                <Link to="/users/salaryreport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Salary Reports
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mb-3">
                <Link to="/users/esimappingreport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> ESI mapping report
                </Link>
              </div>
            </div>
          </div>
        </div>
<br/><br/>
<div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Darpan Report
            </div>
            <br />
            <div className="row">
              <div className="col-6 mb-3">
                <Link to="/users/targetachivementreport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Target Achievement Report
                </Link>
              </div>
              <div className="col-6 mb-3">
                <Link to="/users/proratedachivementreport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Pro-Rated Reports
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mb-3">
                <Link to="/users/Actualreport" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Actual report
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mb-3">
                <Link to="/users/percentage" className="btn btn-link w-100">
                  <FaCircle className="me-2" size={8} /> Percentage report
                </Link>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
}

export default Reportspage;
