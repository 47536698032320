import React, { useState, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import "./Loader.css";
import { Modal, Button } from "react-bootstrap";

function RedeemPopup({ isOpen, onRequestClose, consumerNumber }) {
  const token = JSON.parse(localStorage.getItem("data")).token;
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  var curr_hour = date.getHours();
  var curr_min = date.getMinutes();
  var curr_sec = date.getSeconds();

  let currentDate = `${year}-${month}-${day}`;
  let currTime = `${curr_hour}:${curr_min}:${curr_sec}`;

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [total, setTotal] = useState([]);
  const [denom, setDenom] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  const [redeemDetails, setRedeemDetails] = useState({
    date: currentDate,
    pointsRedeemed: "",
    consumerNumber: consumerNumber,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });

  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [availablePoints, setAvailablePoints] = useState(null);

  function rsubmit() {
    setLoading(true);

    axios
      .post("/api/redeemedconsumerPoints/create", redeemDetails, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Updated successfully");
        onRequestClose();
      })
      .catch((error) => {
        console.log(error);
        handleError(error);
        setLoading(false);
      });
  }

  const rhandle = (event, field) => {
    let actualValue = event.target.value;
     // Check if pointsRedeemed exceeds availablePoints
     if (field === "pointsRedeemed" && parseInt(actualValue, 10) > availablePoints) {
      toast.warning("You cannot redeem more points than available.");
      return; 
    }

    setRedeemDetails({ ...redeemDetails, [field]: actualValue });
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred.");
      }
    }
  }

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const res = await axios.get(
        `/api/consumerloyalty/get-points/consumernumber/${consumerNumber}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setAvailablePoints(res.data.netPoints);
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [consumerNumber]);

  const handleConfirm = () => {
    setShowConfirmation(true);
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleConfirmationSubmit = () => {
    setShowConfirmation(false);
    rsubmit();
  };

  return (
    <>
      <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Redeem Points</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="text-center">
          <h5>
    Available Points: {availablePoints}
    </h5>
  </div>
          <div className="col-xl-10 mx-xl-auto">
            <div className="card card-raised mb-3">
              <div className="card-body p-4">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="date">Date</label>
                      <input
                        className="form-control"
                        id="date"
                        type="date"
                        placeholder="date"
                        value={redeemDetails.date}
                        onChange={(e) => rhandle(e, "date")}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="consumerNumber">Consumer Number</label>
                      <input
                        className="form-control"
                        id="consumerNumber"
                        type="text"
                        placeholder="Consumer Number"
                        value={redeemDetails.consumerNumber}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="pointsRedeemed">Points Redeemed</label>
                      <input
                        className="form-control"
                        id="pointsRedeemed"
                        type="text"
                        placeholder="0"
                        value={redeemDetails.pointsRedeemed}
                        onChange={(e) => rhandle(e, "pointsRedeemed")}
                      />
                    </div>
                  </div>

                  <br />

                  <Button className="btn btn-primary" onClick={handleConfirm}>
                    Submit
                  </Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal show={showConfirmation} onHide={handleConfirmationClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to submit the form?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleConfirmationClose}>
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button variant="primary" onClick={handleConfirmationSubmit}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RedeemPopup;
