import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../api/baseurl";

function PrintSQC({  }) {  
    const { id } = useParams();

    const [product, setProduct] = useState({});
    const [loading, setLoading] = useState(false);
    const [approvalStatus, setApprovalStatus] = useState(null); // New state for approval status

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const token = JSON.parse(localStorage.getItem("data")).token;
                const response = await axios.get(`/api/sqcformDo/sqcid=${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setProduct(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [id, approvalStatus]);
    const approveHandler = async () => {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem("data")).token;
        try {
            const response = await axios.post(
                "/api/admin/approvals/sqcform",
                { id: id, approvalStatus: "APPROVED" },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            console.log(response.data);
            setApprovalStatus("approved");
            localStorage.setItem("approvalStatus", "APPROVED"); // Persist approval status in local storage
            setProduct(response.data);
        } catch (error) {
            console.error("Error approving:", error);
        } finally {
            setLoading(false);
        }
    };

   const rejectHandler = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
        const response = await axios.post(
            "/api/admin/approvals/sqcform",
            { id: id, approvalStatus: "REJECTED" },
            { headers: { Authorization: `Bearer ${token}` } }
        );
        console.log(response.data);
        setApprovalStatus("rejected");
        localStorage.setItem("approvalStatus", "REJECTED"); // Persist approval status in local storage
        setProduct(response.data);
    } catch (error) {
        console.error("Error rejecting:", error);
    } finally {
        setLoading(false);
    }
};
    function getBase64FromFile(file) {
        return new Promise((resolve, reject) => {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            const [metadata, content] = reader.result.split(",");
            resolve({ metadata, content });
          };
          reader.onerror = function (error) {
            reject(error);
          };
        });
      }
      
    return (
        <div>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <div>
                    <div className="col-15 mx-xl-auto ps-xl-11 ">
                        <div className="card card-raised">
                            <div className="card-body">
                            <div style={{ position: "fixed", top: "20px",right:"10px" }}>
                   <button onClick={() => window.print()} className="btn btn-primary">Print</button>
                           </div>
                                <div style={{ textAlign: "center", margin: "0 auto" }}>
                                    <div style={{ textAlign: "left", marginBottom: "0px", width: "100px" }}>
                                    <img
                                      className="img-fluid rounded-circle mb-1"
                                      id="image"
                                      src={`${product.orgDetails?.imageMeta},${product.orgDetails?.logo}`}
                                      alt="..."
                                   
                                    />                                   
                             </div>
                            

     <h2 style={{ fontSize: "1.4rem", marginTop: "-80px" }}>Company Name: {product.orgDetails && product.orgDetails.name}</h2>
    <h2 style={{ fontSize: "1.4rem" }}>Address: {product.orgDetails && product.orgDetails.addressLine1}</h2>
    <h2 style={{ fontSize: "1.4rem" }}>City: {product.orgDetails && product.orgDetails.city}</h2>
    <h2 style={{ fontSize: "1.4rem" }}>State: {product.orgDetails && product.orgDetails.state}</h2>
    <h2 style={{ fontSize:"1.4rem" }}>Pincode: {product.orgDetails && product.orgDetails.pincode}</h2>
    <h2 style={{ fontSize: "1.4rem" }}>Contact: {product.orgDetails && product.orgDetails.contact}</h2>
</div>
<br/>
                                        <br/>
<div style={{ textAlign: "center", margin: "0 auto" }}>
<div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
<h4 style={{ marginRight: "20px", fontSize: "1.2rem" }}>SQC number: {product.sqcId}</h4>

<div style={{ position: "absolute", top: "230px", right: "30px", display: "flex", alignItems: "center" }}>
{/* <h4 style={{ fontSize: "1.2rem", marginRight: "10px", }}>Approval Status: {approvalStatus}</h4> */}

{product.approvalStatus === "REQUESTED" ? (
    <div  >
<h4 style={{fontSize: "1.2rem"}}>Approval Status:
        <button onClick={approveHandler} disabled={loading}>
            Approve
           
        
        </button>
        <button onClick={rejectHandler} disabled={loading}>
            Reject
            
        </button>
        </h4>
        {approvalStatus && (
        <div>
          <p style={{ color: approvalStatus === 'approved' ? 'green' : 'red' }}>
            {approvalStatus === 'approved' ? 'Approved' : 'Rejected'}
          </p>
        </div>
      )}
        
    </div>
) : (

<div style={{ position: "absolute", top: "20px", right: "60px", display: "flex", alignItems: "center", fontSize: "1.2rem" }}>
    <h4 style={{ marginRight: "10px",fontSize: "1.2rem" }}> Status:</h4>
    
    <h4 style={{ marginRight: "10px",fontSize: "1.2rem" }}>{product.approvalStatus}</h4>
</div>
)}
</div>
{/* <h4 style={{ fontSize: "1.2rem" }}>Date: {product.date}</h4> */}
</div>
<div style={{ display: "flex", justifyContent: "space-between" }}>
<h4 style={{ marginRight: "20px", fontSize: "1.2rem" }}>Truck Number: {product.truckNumber}</h4>
<h4 style={{ fontSize: "1.2rem", marginRight: "50px"}}>Invoice number: {product.invoiceNumber}</h4>

                                    </div>
                                    <div>
                                        <br/>
                                        <br/>
                                        <br/>
    {product.sqcLevelDetails && product.sqcLevelDetails.length > 0 ? (
        <table  >
            <thead>
                <tr
                 style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}>
                    <th>Product Name</th>
                    <th>Product Code</th>
                    <th>Serial Number</th>
                    <th>Seal Intact</th>
                    <th>Date</th>

                    <th>Gross Weight</th>
                    <th>Tare Weight</th>
                    <th>Weight Of Product in KG</th>
                    <th>Variation in Grams</th>
                    <th>Remarks</th>
                    <th>Return Product</th>
                    <th>ERV Number</th>
                    <th>ERV Date</th>
                </tr>
            </thead>
            <tbody>
                {product.sqcLevelDetails.map((detail, index) => (
                    <tr key={index}>
                        <td>{detail.productName}</td>
                        <td>{detail.productCode}</td>
                        <td>{detail.serialNumber}</td>
                        <td>{detail.sealIntact}</td>
                        <td>{detail.date}</td>

                        <td>{detail.grossWeight}</td>
                        <td>{detail.tareWeight}</td>
                        <td>{detail.actualWeight}</td>
                        <td>{detail.difference}</td>
                        <td>{detail.remarks}</td>
                        <td>{detail.productReturned}</td>
                        <td>{detail.ervNumber}</td>
                        <td>{detail.ervDate}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    ) : (
        <div>No details available</div>
    )}
</div>

                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PrintSQC;
