import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./transaction.css";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router";
import { Pagination } from "react-bootstrap";

function TruckInfo() {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0); // Current page
  const [totalPages, setTotalPages] = useState(0); // Total pages
  const [pageSize] = useState(40); // Number of items per page
  const token = JSON.parse(localStorage.getItem("data")).token;
  const navigate = useNavigate();

  useEffect(() => {
    fetchVehicles(currentPage);
  }, [currentPage]);

  const fetchVehicles = async (page = 0) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/loadin/get-truck/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}?page=${page}&size=${pageSize}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setVehicles(response.data.content); // Assuming response.data contains paginated data in `content`
      setTotalPages(response.data.totalPages); // Assuming response.data contains total pages
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleViewTruckinfo = (truckinfo) => {
    navigate(`/users/view-truckinfo/${truckinfo}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    const startPage = Math.max(currentPage - 2, 0);
    const endPage = Math.min(currentPage + 3, totalPages);

    if (currentPage > 0) {
      paginationItems.push(
        <Pagination.First key="first" onClick={() => handlePageChange(0)} />
      );
      paginationItems.push(
        <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />
      );
    }

    for (let page = startPage; page < endPage; page++) {
      paginationItems.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page + 1}
        </Pagination.Item>
      );
    }

    if (currentPage < totalPages - 1) {
      paginationItems.push(
        <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />
      );
      paginationItems.push(
        <Pagination.Last key="last" onClick={() => handlePageChange(totalPages - 1)} />
      );
    }

    return paginationItems;
  };

  return (
    <div>
      <AdminDashboard />
      {loading && <div className="loading">Loading...</div>}
      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex justify-content-between w-100">
              <div className="card-title form-group mb-4">Trucks</div>
            </div>
            <div className="row">
              {vehicles.length === 0 ? (
                <div className="col text-center">No Data Available</div>
              ) : (
                vehicles.map((vehicle, index) => (
                  <div key={index} className="col-md-3 mb-4">
                    <Card
                      className="shadow-sm border-2"
                      style={{ cursor: 'pointer', marginRight: "10px" }}
                      title="View Details"
                      onClick={() => handleViewTruckinfo(vehicle)}
                    >
                      <Card.Body>
                        <Card.Text className="font-weight-bold">
                          <h6>{vehicle}</h6>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                ))
              )}
            </div>
            <div className="d-flex justify-content-center">
              <Pagination>
                {renderPaginationItems()}
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TruckInfo;
