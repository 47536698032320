import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminDashboard from "../components/admindashboard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import img from "../images/report.png";

function Transactionoutgoingreport() {
  const [reportData, setReportData] = useState({});
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [vehicleFilter, setVehicleFilter] = useState("");

  // Fetch vehicles from API
  const fetchVehicles = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;

    try {
      const response = await axios.get(`/api/vehicle/orgid=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const vehiclesData = response.data.map((vehicle) => ({
        id: vehicle.id,
        truckinfo: vehicle.truckinfo,
        licenceNo: vehicle.licenceNo,
      }));

      setVehicles(vehiclesData);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch vehicles");
    }
  };

  // Fetch data from API
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;

    

    setLoading(true);

    try {
      const response = await axios.get(
        `/api/outgoingtransactions/orgcode=${orgcode}/vehicle=${vehicleFilter}/${month.getMonth() + 1}/${year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("Full API Response:", response);  // Log entire response
      console.log("Product Summary:", response.data.productSummary);  // Log productSummary

      setReportData(response.data.productSummary || {});
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  };

  // Generate PDF report
  const generatePDF = () => {
    if (Object.keys(reportData).length === 0) {
      toast.error("No data available to generate PDF.");
      return;
    }

    try {
      const doc = new jsPDF();

      // Add logo to the left side
      const imgData = img;
      const logoWidth = 70;
      const logoHeight = 40;
      const logoX = 10;
      const logoY = 10;
      doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);

      // Add address and company name to the right side
      doc.setFontSize(18);
      doc.setTextColor(86, 150, 214);
      const textX = doc.internal.pageSize.width - 10; // Position on the right side
      const textY = logoY + 10;
      doc.text("Vijay Gas Agencies", textX, textY, { align: "right" });

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
      const addressY = textY + 10;
      doc.text(addressText, textX, addressY, { align: "right" });

      // Prepare table data
      const tableData = [];
      Object.keys(reportData).forEach((date) => {
        reportData[date].forEach((item) => {
          tableData.push([
            item.date,
            item.productname,
            item.quantity,
            item.noofsealintact || 0,
            item.noofcorrectweight || 0,
            item.noofbungleak || 0,
            item.noofbodyleak || 0,
            item.noofappearance || 0,
          ]);
        });
      });

      // Generate the table in the PDF
      doc.autoTable({
        startY: addressY + 20, // Start the table after the address
        head: [
          [
            "Date",
            "Product Name",
            "Quantity",
            "No of Seal Intact",
            "No of Correct Weight",
            "No of Bung Leak",
            "No of Body Leak",
            "No of Appearance",
          ],
        ],
        body: tableData,
        theme: "striped",
        styles: { overflow: "linebreak" },
      });

      // Save the generated PDF
      doc.save("service_report.pdf");
    } catch (error) {
      console.error("Error generating PDF: ", error);
      toast.error("Failed to generate report.");
    }
  };

  const handleMonthChange = (date) => {
    setMonth(date);
    if (date) {
      setYear(date.getFullYear());
    }
  };

  const handleVehicleChange = (event) => {
    setVehicleFilter(event.target.value);
  };

  useEffect(() => {
    fetchVehicles();  // Fetch vehicles on component mount
  }, []);

  useEffect(() => {
    if (month && year && vehicleFilter) {
      getData();
    }
  }, [month, year, vehicleFilter]);  // Add vehicleFilter to dependency array

  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <div className="container mt-4">
        <h3>Transaction Outgoing Report</h3>
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex justify-content-center align-items-center">
              <img className="top-bar__logo" src={img} alt="logo" />
              <div className="ml-3">
                <h3 className="report-title">Vijay Gas Agencies</h3>
                <p className="report-address">
                  1010/27 Mysore Road, Gundlupete
                </p>
              </div>
            </div>
            <br />
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4">
                  <div>
                    <label htmlFor="month">Month:</label>
                    <DatePicker
                      selected={month}
                      onChange={handleMonthChange}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  </div>

                  <div
                    className="filter-dropdown"
                    style={{ marginRight: "20px" }}
                  >
                    <label>Vehicle:</label>
                    <select
                      value={vehicleFilter}
                      onChange={handleVehicleChange}
                    >
                      <option value="">Select Vehicle</option>
                      {vehicles.map((vehicle) => (
                        <option key={vehicle.id} value={vehicle.truckinfo}>
                          {vehicle.truckinfo || vehicle.licenceNo}
                        </option>
                      ))}
                    </select>
                  </div>

                  <br />
                  <button
                    type="button"
                    disabled={loading}
                    className="btn btn-primary"
                    onClick={getData}
                  >
                    Generate Report
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    disabled={loading || !Object.keys(reportData).length}
                    className="btn btn-secondary"
                    onClick={generatePDF}
                  >
                    {loading ? "Generating PDF..." : "Download PDF"}
                  </button>
                  {loading && <div className="loading">Loading...</div>}
                </div>
              </div>
              <div className="card card-raised mb-3">
                <div className="card-body p-4">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th>No of Seal Intact</th>
                        <th>No of Correct Weight</th>
                        <th>No of Bung Leak</th>
                        <th>No of Body Leak</th>
                        <th>No of Appearance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(reportData).length > 0 ? (
                        Object.keys(reportData).map((date) =>
                          reportData[date].map((item, index) => (
                            <tr key={index}>
                             <td>{item.date}</td>
          <td>{item.productname || "0"}</td>
          <td>{item.quantity || 0}</td>
          <td>{item.noofsealintact || 0}</td>
          <td>{item.noofcorrectweight || 0}</td>
          <td>{item.noofbungleak || 0}</td>
          <td>{item.noofbodyleak || 0}</td>
          <td>{item.noofappearance || 0}</td>
                            </tr>
                          ))
                        )
                      ) : (
                        <tr>
                          <td colSpan="8" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Transactionoutgoingreport;
