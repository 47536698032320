import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import "./Spinner.css";
function LoadIncoming() {
  const [transactions, setTransaction] = useState([]);
  const [Loader, setLoader] = useState([false]);
  const [searchload, setSearchload] = useState(true);
  const getData = async () => {
    const date = new Date();

    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    const trans = await axios.get(
      `/api/loadin/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${currentDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(trans);
    setLoader(false);
    setTransaction(trans.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="col-12 mx-xl-auto ps-xl-10 ">
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="d-flex">
              <div
                className="card-title"
                style={{
                  fontSize: "20px",
                  // backgroundColor: "blue",
                }}
              >
                Load Incoming Details
              </div>
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/users/updateloadin">
                <button className="btn btn-primary">Create Invoice</button>
              </Link>
            </div>
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Truck Id</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((ttin) => {
                  return (
                    <tr>
                      <td>{ttin.productname}</td>
                      <td>{ttin.quantity}</td>
                      <td>{ttin.truckinfo}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {Loader && <div className="loading"></div>}
    </div>
  );
}

export default LoadIncoming;
