import Pro from "./pro";
import Dashboard from "./dashboard";
import Sidebar from "./managerSidebar";
import SuperSidebar from "./supersidebar";
function SuperProfile() {
  return (
    <div>
      <Dashboard></Dashboard>
      <SuperSidebar />
      <Pro></Pro>
    </div>
  );
}
export default SuperProfile;
