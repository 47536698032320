import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "../components/admindashboard";
import "./Rolereport.css";
import img from "../images/report.png";


function FuelLog() {
  const [reportParams, setReportParams] = useState({
    vehicleid: "",
    month: new Date().getMonth() + 1, // Current month
    year: new Date().getFullYear(),
  });

  const [fuelLog, setFuelLog] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);

  const [month, setMonth] = useState(null);
  const [year, setYear] = useState("");
  // Fetch available vehicles
  const fetchVehicles = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const response = await axios.get(`/api/vehicle/orgid=${orgCode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVehicles(response.data);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
      toast.error("Failed to load vehicles.");
    }
  };

  // Fetch fuel log data
  const fetchFuelLog = async () => {
    if (!reportParams.vehicleid) {
      toast.error("Please select a vehicle.");
      return;
    }

    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      
      const response = await axios.get(
        `/api/fuellog/monthly/${reportParams.vehicleid}/${reportParams.month}/${reportParams.year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setFuelLog(response.data);
    } catch (error) {
      console.error("Error fetching fuel log:", error);
      toast.error("Failed to load fuel log.");
    } finally {
      setLoading(false);
    }
  };

  // Handle form input changes
  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setReportParams((prevState) => ({
      ...prevState,
      [field]: field === "month" || field === "year" ? parseInt(value, 10) : value,
    }));
  };

  // Generate PDF report
  const generatePDF = () => {
    if (!fuelLog) {  
      toast.error("No data available to generate PDF.");
      return;
    }
  
    const doc = new jsPDF();
    const tableColumn = ["Date", "Vehicle", "Rate", "Quantity", "Amount", "Deliveryagent"];
    const tableRows = [];
  
    // Assume fuelLog.entries is an array of objects
    fuelLog.fuel_entries.forEach(entry => {
      const data = [entry.date, entry.vehicleid, entry.rate, entry.quantity, entry.amount, entry.deliveryagentid];
      tableRows.push(data);
    });
  
    const margins = { top: 20, bottom: 20, left: 10, right: 10 };
    const logoWidth = 40;
    const logoHeight = 25;
    const titleOffsetY = 60;
    const tableStartY = titleOffsetY + 20;
  
    // Add logo image
    doc.addImage(img, 'PNG', margins.left, margins.top, logoWidth, logoHeight);
  
    // Add address text on the right side
    const addressX = doc.internal.pageSize.width - margins.right - 100; // Adjust X position to fit the address
    doc.setFontSize(16);
    doc.setTextColor(86, 150, 214);
    doc.text("Vijay Gas Agencies", addressX, margins.top);
  
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text("1010/27 Mysore Road, Gundlupete - 571111", addressX, margins.top + 10);
  
    // Add report title
    doc.setFontSize(14);
    doc.setTextColor(86, 150, 214);
    doc.text("Monthly Fuel Log Report", margins.left, titleOffsetY);
  
    // Add month and year
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(`Month: ${month ? month.toLocaleString('default', { month: 'long' }) : ''}, Year: ${year}`, margins.left, titleOffsetY + 10);
  
    // Add table
    doc.autoTable(tableColumn, tableRows, { startY: tableStartY });
  
    doc.save("fuel_log_report.pdf");
  };
  
  useEffect(() => {
    fetchVehicles();
  }, []);

  return (
    <div>
      <AdminDashboard/>
      <div className="col-8 mx-xl-auto px-xl-10">
      <br/><br/><br/><br/><br/><br/>
        <div className="card card-raised">
      
          <div className="card-body">
            
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
              <br/>
              <div className="card-title">Fuel Log Report</div>

                <div className="card-body p-4">
                <form onSubmit={(e) => e.preventDefault()} className="text-center">
                <div className="d-flex justify-content-center align-items-center">
                    <img className="top-bar__logo" src={img} alt="logo" />
                    <div className="ml-3">
                      <h3 className="report-title">Vijay Gas Agencies</h3>
                      <p className="report-address">1010/27 Mysore Road, Gundlupete</p>
                    </div>
                  </div>
                  <br/>
                    <div>
                      <select
                        id="vehicle"
                        value={reportParams.vehicleid}
                        onChange={(e) => handleInputChange(e, "vehicleid")}
                      >
                        <option value="">Select Vehicle</option>
                        {vehicles.map((v) => (
                          <option key={v.id} value={v.truckinfo}>
                            {v.truckinfo}
                          </option>
                        ))}
                      </select>{" "}
                      &nbsp;&nbsp;&nbsp;
                      <label htmlFor="month">Month:</label>
                      <input
                        id="month"
                        type="number"
                        min="1"
                        max="12"
                        value={reportParams.month}
                        onChange={(e) => handleInputChange(e, "month")}
                      />{" "}
                      &nbsp;&nbsp;&nbsp;
                      <label htmlFor="year">Year:</label>
                      <input
                        id="year"
                        type="number"
                        value={reportParams.year}
                        onChange={(e) => handleInputChange(e, "year")}
                      />
                    </div>
                    <br />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={fetchFuelLog}
                      disabled={loading}
                    >
                      Generate Report
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={generatePDF}
                      disabled={!fuelLog}
                    >
                      Download PDF
                    </button>
                  </form>
                  {loading && <div className="loading">Loading...</div>}
                  {fuelLog && (
                    <div>
                      <br />
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Vehicle</th>
                            <th>Rate</th>
                            <th>Quantity</th>
                            <th>Amount</th>
                            <th>Deliveryagent</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fuelLog.fuel_entries.map((entry) => (
                            <tr key={entry.date}>
                              <td>{entry.date}</td>
                              <td>{entry.vehicleid}</td>
                              <td>{entry.rate}</td>
                              <td>{entry.quantity}</td>
                              <td>{entry.amount}</td>
                              <td>{entry.deliveryagentid}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default FuelLog;
