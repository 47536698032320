import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import "./Spinner.css";
import TeritorydetailsModal from "./Addteritory";
import SalesareadetailsModal from "./Createsalesarea";
import Salesareacomponent from "./Salesarea";
import TerritoryeditModalPopup from "./Territoryedit";
import { MdDeleteForever } from "react-icons/md";

function TeritoryComponent() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [issalesModalOpen, setIssalesModalOpen] = useState(false);
  const [userProfileDetails, setUserProfileDetails] = useState(null); // Declare and initialize userProfileDetails
  const [userProfileDetails1, setUserProfileDetails1] = useState(null); // Declare and initialize userProfileDetails
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);

;   const [product1, setProduct1] = useState([]);

  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedTerritoryId, setSelectedTerritoryId] = useState(null); // State to store selected territory ID

  const navigate = useNavigate();
 
  const [profile, setProfile] = useState({
    id:"",
    companyName:"",
    companyLogo: null,
    imageMeta: "",
  });
  const [profile1, setProfile1] = useState({
    id:"",
    logo: null,
    imageMeta: "",
  });
  

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/territory`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct([...response.data]);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };
  const getData1= async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/salesarea`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct1(response.data);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
    }
    setLoader(false);
  };

  useEffect(() => {
    setLoading(true);
    
    const fetchProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/companydetails`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userProfileDetails = response.data[0];
        setProfile({
          ...profile,
          ...userProfileDetails
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching company details:", error);
      }
    };
  
    fetchProfile();
  }, []);
  useEffect(() => {
    setLoading(true);
    
    const fetchProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/printlogo`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userProfileDetails1 = response.data[0];
        setProfile1({
          ...profile1,
          ...userProfileDetails1
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching company details:", error);
      }
    };
  
    fetchProfile();
  }, []);
  
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const [metadata, content] = reader.result.split(",");
        resolve({ metadata, content });
      };
      reader.onerror = error => reject(error);
    });
  }

 
  const handleImageChange=async(event)=>{
    const convertedImage= await getBase64FromFile(event.target.files[0]);
    const imageObject = {...profile,
     imageMeta: convertedImage.metadata,
     companyLogo: convertedImage.content,};
          setProfile(imageObject);
          
          
  }
  const handleImageChange1=async(event)=>{
    const convertedImage= await getBase64FromFile(event.target.files[0]);
    const imageObject = {...profile1,
     imageMeta: convertedImage.metadata,
     logo: convertedImage.content,};
          setProfile1(imageObject);
          
          
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const requestBody = {
        id: profile.id, 
        companyLogo: profile.companyLogo,
        imageMeta: profile.imageMeta,
        companyName: profile.companyName,
      };
      await axios.post(`/api/companydetails/create-or-update`, requestBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Logo Updated Successfully");
      setLoading(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error: Failed to update profile");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userProfileDetails) {
      setProfile({
        companyName:userProfileDetails.companyName,
        companyLogo: userProfileDetails.companyLogo || null,
        imageMeta: userProfileDetails.imageMeta || "",
       
      });
    }
  }, [userProfileDetails]);
  const handleSubmit1 = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const requestBody = {
        id: profile1.id, 
        logo: profile1.logo,
        imageMeta: profile1.imageMeta,
      };
      await axios.post(`/api/printlogo/create-or-update`, requestBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Logo Updated Successfully");
      setLoading(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error: Failed to update profile");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userProfileDetails1) {
      setProfile1({
        logo: userProfileDetails1.logo || null,
        imageMeta: userProfileDetails1.imageMeta || "",
       
      });
    }
  }, [userProfileDetails1]);
  const handleChange = (event, field) => {
    let actualValue = event.target.value;

    setProfile({ ...profile, [field]: actualValue });
    
  };


  useEffect(() => {
    getData();
    getData1();
  }, [isModalOpen]);
 
 

  
 
 
  const handleAddProduct = () => {
    setIsModalOpen(true);
    setProduct([]);


  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleTerritoryClick = (Id) => {
    setSelectedTerritoryId(Id);
    navigate(`/users/Salesarea/${Id}`);
  };
  const handleEdit = async (id) => {
    setIsProductEditModalOpen(true);

    setSelectedProductId(id);
   
  };
 
  const closeProductEditModal = () => {
    setIsProductEditModalOpen(false);
    getData();
 
  };

  const onClickhandler = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try{
      await axios({
      url: "/api/territory",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
     
        orgid: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("deleted successfully");
    setLoading(false); 
    getData();
  } catch (error) {
    if (error.response && error.response.status === 500) {
        const errorMessage = error.response.data.message;
        console.error("Error deleting territory:", errorMessage);
        toast.error(errorMessage);
    } else {
        console.error("Error deleting territory:", error);
    }
    setLoading(false);
}
};

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      
      {loader && <div className="loading"></div>} 
      <div className="col-12 mx-xl-auto ps-xl-10 mt-4">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex">
              <div className="card-title">Company Details</div>
            </div>
            <br />
            {loading && <div className="loading"></div>}
            <form onSubmit={handleSubmit}>
          <div className="d-flex flex-column">
            <div className="form-floating-sm mb-2">
              <label htmlFor="companyName">Company Name :</label>
              <input
                className="form-control"
                id="companyName"
                type="text"
                value={profile.companyName}
                onChange={(e) => handleChange(e, "companyName")}
                style={{ width: '200px' }}
              />
            </div>
            <div className="form-floating-sm mb-2">
              <label htmlFor="companyLogo">VM Logo:</label>
              <div className="d-flex align-items-center">
                {profile.companyLogo ? (
                  <img
                    className="img-fluid circular-image mb-1"
                    id="companyLogo"
                    src={`${profile.imageMeta},${profile.companyLogo}`}
                    alt="Company Logo"
                    style={{
                      width: "100px",
                      height: "50px",
                      background: "#ccc",
                    }}
                  />
                ) : (
                  <div
                    className="img-fluid circular-image mb-1"
                    id="companyLogo"
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                      background: "#ccc",
                    }}
                  ></div>
                )}
                <input
                  className="col-7 mt-2"
                  type="file"
                  id="companyLogo"
                  onChange={handleImageChange}
                  accept="image/*"
                />
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-center">
            <input
              type="submit"
              value="Save Changes"
              className="btn btn-primary mt-3"
              style={{marginRight: "85%"}}
            />
          </div>
        </form>
       
        <form onSubmit={handleSubmit1}>
          <div   className="d-flex flex-column">
           
            <div  className="form-floating-sm mb-2">
              {/* <label htmlFor="'logo">Print Logo:</label> */}
              <label htmlFor="logo" style={{ position: 'absolute', bottom: '200px', right: '45%' }}>Print Logo:</label>
              <br/>

              
              <div className="d-flex align-items-center" style={{ position: 'absolute', bottom: '145px', left: '48%' }}  >
                {profile1.logo ? (
                  <img
                    className="img-fluid circular-image mb-1"
                    id="logo"
                    src={`${profile1.imageMeta},${profile1.logo}`}
                    alt=" Logo"
                    style={{
                      width: "100px",
                      height: "50px",
                      background: "#ccc",
                    }}
                  />
                ) : (
                  <div
                    className="img-fluid circular-image mb-1"
                    id="logo"
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                      background: "#ccc",
                    }}
                  ></div>
                )}
                <input
                  className="col-7 mt-2"
                  type="file"
                  id="logo"
                  onChange={handleImageChange1}
                  accept="image/*"
                />
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-center">
            <input
              type="submit"
              value="Save Changes"
              className="btn btn-primary mt-3"
              style={{ position: 'absolute', bottom: '70px', left: '48%' }}            />
          </div>
        </form>
        
      </div>
    </div>
  </div>
  <br/>
  <br/>
      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Territory Details
             
              <button className="btn btn-primary"onClick={handleAddProduct}>
               ADD
            </button>
            </div>
  
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >

                  <th>Teritory Name</th>
                  <th>Actions</th>


                </tr>
              </thead>
              <tbody>
              {product.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (

                product.map((product) => {
                  return (
                    <tr key={product.id}>
 <td
                          onClick={() => handleTerritoryClick(product.id)}
                          style={{ cursor: "pointer" }}
                        >
                          {product.territoryname}
                        </td>
                        <td> 
                     <button
                      onClick={() => handleEdit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              product.id,
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>
                   </td>
                     
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
           
            <div>
           
           <TeritorydetailsModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
           {isProductEditModalOpen && (
              < TerritoryeditModalPopup
                isOpen={isProductEditModalOpen}
                onRequestClose={closeProductEditModal}
                Id={selectedProductId}
              />
            )}

      </div>


          </div>
        </div>{" "}
      </div>
      <br/>
      
    </div>   
  );
}

export default TeritoryComponent;
