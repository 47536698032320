import LoadIncoming from "./loadin";
import LoadOutgoing from "./loadoout";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import TransactionIncoming from "./transactionincomingdetails";
function ManagerLoadout() {
  return (
    <div>
      <div>
        <ManagerDashboard />
      </div>
      <div>
        <ManagerSidebar />
      </div>

      <div>
        <LoadOutgoing />
      </div>
    </div>
  );
}
export default ManagerLoadout;
