import React, { useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import "./Spinner.css";
import Addcsvfil from "./Addcsvfile";
import { useEffect } from "react";
import Search from "./search";
import FileSaver from 'file-saver';
import CsvFormat from "./csvFormatImage";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Viewcsv from "./Viewcsvupload";
import AreaMappedVehicles from "./areaMappedVehicles";
import BulkUplaodCsvFile from "./bulkUplaodCsvFile";


function Csvfile() {
 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBulkUplaodModalOpen, setIsBulkUplaodModalOpen] = useState(false);
  const [isVehicleModalOpen, setIsVehicleModalOpen] = useState(false);
  const [product, setProduct] = useState([]);
  const [searchbydate, setSearchbydate] = useState(true);
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [areaNames, setAreaNames] = useState([]);
  const [selectedId, setSelectedId] = useState(null); // State to store selected territory ID
  const [selecteddate, setSelecteddate] = useState(null);
  const [selectedarea, setSelectedarea] = useState(null);
  const [selectedorgcode, setSelectedorgcode] = useState(null);
  const [vehiclesData, setVehiclesData] = useState([]);

  const navigate = useNavigate();
  const date = new Date();
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
    setLoader(true);
    const areaUrl = `/api/AreaMaster/orgcode=${orgCode}`;

    try {
     
      const url = `/api/consumerupload/orgcode=${orgCode}/date=${currentDate}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct([...response.data]);

      const area = await axios.get(areaUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAreaNames(area.data);
      setLoader(false);
    } catch (error) {
    }
  };
  const searchName = async (text) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbydate(false);

    setLoader(true);
      axios
      .get( `/api/consumerupload/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${text}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      })

    .then((res) => {
      setProduct([...res.data]);
        setLoader(false);
      })
      .catch((error)=>{
        setLoader(false);
      })
      
    }
  useEffect(() => {
    getData();
  }, [isModalOpen]);

  

  const handleAddProduct = () => {
    setIsModalOpen(true);


  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const handleBulkUpload = () => {
    setIsBulkUplaodModalOpen(true);
  };

  const handleCloseBulkUplaodModal = () => {
    setIsBulkUplaodModalOpen(false);
  };


  const handleVehicleModalOpen = (data) => {
    setVehiclesData(data);
    setIsVehicleModalOpen(true);

  };

  const handleCloseVehicleModal = () => {
    setIsVehicleModalOpen(false);
  };

  const [showImageModal, setShowImageModal] = useState(false);

  const handleShowImageModal = () => setShowImageModal(true);
  const handleCloseImageModal = () => setShowImageModal(false);

  const onClickdownloadCsv = async (e, id) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      // Make a request to the backend endpoint to get the CSV file
      const response = await axios.get(`/api/consumerupload/download-csv/${id}`, {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const blob = new Blob([response.data], { type: 'text/csv' });
    FileSaver.saveAs(blob, 'consumer_data.csv');
    toast.success("CSV downloaded successfully");
  } catch (error) {
  }
};
const filteredData = product.filter((product) =>
  product.area.toLowerCase().includes((searchTerm || '').toLowerCase())
);
const onClick = (date, area, orgcode) => {
  setSelecteddate(date);
  setSelectedarea(area);
  setSelectedorgcode(orgcode);
 
  navigate(`/users/Viewcsvupload`, {
    state: {date, area , orgcode}
  });
};


const handleVehicleArea =async (e,date,area)=> {
  e.preventDefault();
  setSelecteddate(date);
  setSelectedarea(area);
  try {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

    const entity = {
        orgcode: orgcode,
        date: date,
        areaname: area,
    };

    const response = await axios.post(
        '/api/VehicleAreaMapping/getByArea', entity, {
          headers: { Authorization: `Bearer ${token}` },
      })

      const data = response.data;
      if (data.length === 1) {
        const vehicle = data[0];
        navigate(`/users/OpenGoogleMap/${vehicle.vehicleid}/${vehicle.vehicles.deviceid}`);
      } else {
        handleVehicleModalOpen(data);
      }
  
    } catch (error) {
      console.error("Error fetching vehicle area data:", error);
      // Handle the error as needed
    }
  };
  
 




  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      
      {loader && <div className="loading"></div>} 
      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">CSV Details
            <div style={{ position: "relative" ,fontSize:"14px"}}>
        <Search searchName={searchName} />{" "}
       
      </div>
      <div style={{ position: "relative" ,fontSize:"14px"}}>

      {/* <select
                      className="form-control"
                      id="area"
                    >
                      <option value="">Select Area Name</option>
                      {areaNames.map((area) => (
                        <option key={area.id} value={area.areaname}>
                          {area.areaname}
                        </option>
                      ))}
                    </select> */}
      <input
      type='text'
      // data-kt-user-table-filter='search'
      className='form-control form-control w-450px ps-14'
      placeholder='Search Areaname'
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
    </div>

   
              <button className="btn btn-primary" onClick={handleShowImageModal}>
                View Format
              </button>
          
              <button className="btn btn-primary"onClick={handleAddProduct}>
               ADD
            </button>

            <button className="btn btn-primary"onClick={handleBulkUpload}>
               BULK UPLOAD
            </button>
           
            </div>
           
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                   <th>Date</th>

                  <th>Area Name</th>
                  {/* <th>Orgcode</th> */}
                  <th>Delivered Percentage</th>
                  <th>Action</th>
                  <th>Vehicle Location</th>
                </tr>
              </thead>
              <tbody>
              {!searchbydate && filteredData.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
           ! searchbydate && 
           filteredData.map((product) => {
                  return (
                    <tr key={product.id}>
                     <td>{product.date}</td>

                      <td>{product.area}</td>
                      {/* <td>{product.orgcode}</td> */}
                      <td>{product.delievredPer} %</td>
                      <td>
                      <button className="btn btn-primary" onClick={(e) => onClick(product.date,product.area,product.orgcode)}>
      VIEW
    </button>
  </td>


                    <td>
                      <button className="btn btn-primary" onClick={(e) => handleVehicleArea(e, product.date,product.area)}>
                        VIEW Location
                      </button>
                    </td>

                    </tr>
                  );
                }))}
           {searchbydate && filteredData.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchbydate && 
            filteredData.map((product) => {
                  return (
                    <tr key={product.id}>
                     <td>{product.date}</td>

                      <td>{product.area}</td>
                      {/* <td>{product.orgcode}</td> */}
                      <td>{product.delievredPer} %</td>
                      <td>
                      <button className="btn btn-primary" onClick={(e) => onClick(product.date,product.area,product.orgcode)}>
      VIEW
    </button>
  </td>
 
 
                     <td>
                       <button className="btn btn-primary" onClick={(e) => handleVehicleArea(e, product.date,product.area)}>
                         VIEW Location
                       </button>
                     </td>
 
                     
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
            <div>
           
            {isModalOpen && (
           <Addcsvfil isOpen={isModalOpen} onRequestClose={handleCloseModal} />
            )}
           {showImageModal && (
           <CsvFormat isOpen={showImageModal} onRequestClose={handleCloseImageModal} />
           )}

{isVehicleModalOpen && (
           <AreaMappedVehicles isOpen={isVehicleModalOpen} onRequestClose={handleCloseVehicleModal}
           vehiclesData={vehiclesData} 
           />
           )}
            {isBulkUplaodModalOpen && (
           <BulkUplaodCsvFile isOpen={isBulkUplaodModalOpen} onRequestClose={handleCloseBulkUplaodModal} />
            )}

           
      </div>


          </div>
        </div>{" "}
      </div>
    </div>   
  );
}

export default Csvfile;
