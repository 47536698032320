import React from "react";
import AdminDashboard from "../components/admindashboard";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

function Operationalreport() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleBack = () => {
    navigate("/users/Reportspage");
  };

  return (
    <div>
      <div>
        <br/>
        <br/>
        <AdminDashboard />
      </div>
      <br />
      <br />

      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group">
              <h3>Operational Reports</h3>
              <br/>
              <div className="row">
                <div className="col-4">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => handleNavigation("/users/Loadinoutreport")}
                  >
                    Load Reports
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => handleNavigation("/users/Deliveryreport")}
                  >
                    Delivery Reports
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => handleNavigation("/users/Invoicereports")}
                  >
                    Invoice Reports
                  </button>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-4">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => handleNavigation("/users/TotalErvReport")}
                  >
                    ERV Reports
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => handleNavigation("/users/Teritoryleaderboard")}
                  >
                    Exempted Reports
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => handleNavigation("/users/Teritoryleaderboard")}
                  >
                    New Connection Reports
                  </button>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-4">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => handleNavigation("/users/Teritoryleaderboard")}
                  >
                    Additional Reports
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => handleNavigation("/users/Teritoryleaderboard")}
                  >
                    Consumable Sales Reports
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => handleNavigation("/users/Teritoryleaderboard")}
                  >
                    Online Pay Reports
                  </button>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-4">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => handleNavigation("/users/Teritoryleaderboard")}
                  >
                    Monthly Transaction Reports
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => handleNavigation("/users/Teritoryleaderboard")}
                  >
                    SQC Reports
                  </button>
                </div>
              </div>

              <br/>
              

              <Button
                style={{ marginLeft: "5%" }}
                variant="secondary"
                onClick={handleBack}
              >
                Back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Operationalreport;
