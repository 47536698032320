import React, { useState, useEffect, useReducer } from "react";
import "./Loader.css";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { Button } from "react-bootstrap"; 
import { AiFillDelete } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
//import { token } from "../api/baseurl";
import AddExemptedcustomer from "./addexemptedcustomer";
import Exemptedcustomeredit from "./exemptedcustomeredit";
import { error } from "jquery";
function Exemptedcustomer() {
  const token = JSON.parse(localStorage.getItem("data")).token;
  const date = new Date();
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  <div className="card card-raised mb-5"></div>;
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState('');
    const [selectedStatusFilter, setSelectedStatusFilter] = useState('');
  const [exemptedcustomercategory, setExemptedcustomercategory] = useState([]);
  const [exemptedcustomer, setExemptedcustomer] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const uniqueCategories = [...new Set(exemptedcustomer.map(ecustomer => ecustomer.customercategory))];

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [exemptedcustomercategory1, setexemptedcustomercategory1] = useState({
    customername: "",
    customercategory: "",
    deliverydate:currentDate,
    orgcode:JSON.parse(localStorage.getItem("data")).orgcode,
    distance: "",
    tariff: "",
    disable: false,
  });
  const [User, setUser] = useState([]);
 
  const navigate = useNavigate();
 
  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setexemptedcustomercategory1({
      ...exemptedcustomercategory1,
      [field]: actualValue,
    });
  };
 
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
 
    axios
      .post("/api/exemptedcustomer/create", exemptedcustomercategory1, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        toast.success("Exempted Customer added");
        setLoading(false);
        getData();
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };
  const getData = async () => {
    setLoader(true);
    const date = new Date();
 
    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;
 
    const trans = await axios
    .get(
      `/api/exemptedcustomercategories/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setExemptedcustomercategory(res.data);
        setLoader(false);
      });
 
      const trans1 = await axios
      .get(
        `/api/exemptedcustomer/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        })
      .then((res) => {
        setExemptedcustomer(res.data);
        setLoader(false);
      })
      .catch((error)=>{
        setLoader(error);
        handleError(error);
      });
  };

 
      function handleError(error) {
        if (error.response && error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else {
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error('An error occurred.');
          }
        }
      }
 
  useEffect(() => {
    getData();
  }, []);
 
  const handleAddProduct = () => {
    setIsModalOpen(true);
 
 
  };
 
  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items to show per page


  const handleFilterChange = () => {
   setCurrentPage(1); 
 };
  const handlePrevPage = () => {
   if (currentPage > 1) {
     setCurrentPage(currentPage - 1);
   }
 };
 
 const handleNextPage = () => {
   if (currentPage < pageNumbers.length) {
     setCurrentPage(currentPage + 1);
   }
 };
  

 const getCurrentItems = () => {  
  
  const filteredData = exemptedcustomer.filter(customercategory => {
    const categoryFilterPass = selectedCategoryFilter === '' || customercategory.customercategory.includes(selectedCategoryFilter);
    const statusFilterPass = selectedStatusFilter === '' ||
      (selectedStatusFilter === 'enabled' && !customercategory.disable ) ||
      (selectedStatusFilter === 'disabled' && customercategory.disable );

    return categoryFilterPass && statusFilterPass;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
     return filteredData.slice(indexOfFirstItem, indexOfLastItem);
};
const pageNumbers = [];
for (let i = 1; i <= Math.ceil(exemptedcustomer.length / itemsPerPage); i++) {
  pageNumbers.push(i);
}

  function onClickhandler(e, id, disabled) {
    setLoading(id);
    const checked = e.target.checked;
    const token = JSON.parse(localStorage.getItem("data")).token;
    console.log(token);
    axios.post(`/api/exemptedcustomer/update`, {
      id: id,
      disable: disabled
    }, {
      headers: { Authorization: `Bearer ${token}` },
    })
   
      .then((response) => {
        setLoading(null);
        toast.success("status updated");
      })
      .catch((error)=>{
        setLoading(null);
        handleError(error);
      });
  }

  
  const handleEdit = (id) => {
    console.log(id);
    setSelectedCustomer(id);
    setIsModalOpen1(true);


  };
  const closeExemptedEditModal = () => {
    setIsModalOpen1(false);
    getData();
  };
  
  
  const handleDelete = (id) => {
    
    if (window.confirm("Are you sure you want to delete this category?")) {
      axios
        .delete(`/api/exemptedcustomer`, {
          data: { id }, 
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Exempted Customer  deleted");
            getData(); 
          }
        })
        .catch((error) => {
         handleError(error);
        });
    }
   
    
  };
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
     
      {loader && <div className="loader"></div>}
      <div
        className="col-10 mx-xl-auto ps-xl-9 mt-10"
        style={{ position: "relative", left: "100px"}}
      >
        <div className="card card-raised ">
          <div className="card-body ">
          <div className="card-title form-group d-flex align-items-center justify-content-between">Exempted Customer List
            <button className="btn btn-primary"onClick={handleAddProduct}>
               ADD
            </button>
            </div>
            <br/>
            <div className="filter-dropdowns">
<div className="row">
<div
        className="col-7">
  <div controlId="categoryFilter">
    <label>FILTER BY CATEGORY </label>
    
    <select
      as="select"
      value={selectedCategoryFilter}
      onChange={(e) =>
        { setSelectedCategoryFilter(e.target.value);

      handleFilterChange(); 
      }}
    >
      <option value="">All</option>
      {uniqueCategories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}

      
    </select>
    </div>

  </div>

  <div
        className="col-4">
  <div controlId="statusFilter">
  <label>FILTER BY STATUS</label>
                    <select as="select" value={selectedStatusFilter}  onChange={(e) => 
        {setSelectedStatusFilter(e.target.value);
        handleFilterChange(); 
      }}>
                      <option value="">All</option>
                      <option value="enabled">Enabled</option>
                      <option value="disabled">Disabled</option>
                    </select>
   
  </div>
  </div>
  </div>
</div>
<br/>
<br/>
            <table
              className="table table-bordered "
              style={{ position: "relative" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "#6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Customer Number</th>
                  <th>Customer Name</th>
                  <th>Category Name</th>
                  <th>Distance</th>
                  <th>Tariff</th>
                  <th>Status</th>
                  <th>Action</th>
 
                </tr>
              </thead>
              <tbody>
                {getCurrentItems().map((ecustomer) => {
                  return (
                    <tr key={ecustomer.id} 
                    style={{
                      textAlign: "center",
                    }} 
                  >    
                      <th>{ecustomer.customerNumber}</th>
 
                      <th>{ecustomer.customername}</th>
 
                      <th>{ecustomer.customercategory}</th>
         
                      <th>{ecustomer.distance}</th>
 
                      {/* <th>{ecustomer.deliveryrate}</th> */}
                      <th>{ecustomer.tariff}</th>
 
                      <td>
                        <div className="form-check form-switch">
                          {loading == ecustomer.id && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={!ecustomer.disable}
                            onClick={(e) => onClickhandler(e, ecustomer.id, !ecustomer.disable)}
                            value={ecustomer.id}
                          />
                        </div>
                      </td>

                      
                      <td>
                      <div style={{ display: "flex", justifyContent: "center" }}>

                         {/* Edit button  */}
                         <Button variant="light" style={{ color: "blue" }}  onClick={() => handleEdit(ecustomer.id)}>
                            <CiEdit />
                          </Button>{" "}
          

                          <span style={{ margin: "0 5px" }}></span>

                           {/* Delete button  */}
                           <Button variant="light"style={{ color: "black" }} onClick={() => handleDelete(ecustomer.id)}>
                            <AiFillDelete />
                          </Button>

                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
             {/* Pagination controls */}
             <nav>
            <ul className="pagination justify-content-center">
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={`page-item ${number === currentPage ? "active" : ""}`}
                >
                 
                </li>
              ))}
            </ul>
          
  <ul className="pagination justify-content-center">
    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
      <button className="page-link" onClick={handlePrevPage}>
        Previous
      </button>
    </li>
    {pageNumbers.map((number) => (
      <li
        key={number}
        className={`page-item ${number === currentPage ? "active" : ""}`}
      >
        <button className="page-link" onClick={() => setCurrentPage(number)}>
          {number}
        </button>
      </li>
    ))}
    <li className={`page-item ${currentPage === pageNumbers.length ? "disabled" : ""}`}>
      <button className="page-link" onClick={handleNextPage}>
        Next
      </button>
    </li>
  </ul>
</nav>
          </div>
        </div>
        {isModalOpen && (
        <AddExemptedcustomer isOpen={isModalOpen} onRequestClose={handleCloseModal} />
        )}

        {isModalOpen1 && (
              <Exemptedcustomeredit
                isOpen={isModalOpen1}
                onRequestClose={closeExemptedEditModal}
                Id={selectedCustomer}
              />
            )}
      </div>
    </div>
  );
}
 
export default Exemptedcustomer;