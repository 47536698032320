import React, { useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import axios from "../api/baseurl";
import "react-toastify/dist/ReactToastify.css";
import { getCurrentUserDetail, isLoggedIn } from "../auth";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import AdminDashboard from "./admindashboard";

function Notification() {
  const [notification, setNotification] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    const date = new Date();

    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    const trans = await axios
      .get(
        `/api/user/notifications/username=${
          JSON.parse(localStorage.getItem("data")).username
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setNotification(res.data);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <main>
      <div>
        <AdminDashboard />
      </div>{" "}
      <header className="bg-dark"></header>
      <div
        className="container-xl p-10 px-11"
        style={{ position: "relative", left: "100px" }}
      >
        &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <mwc-tab-bar style={{ marginBottom: "-1px" }} activeIndex="3">
          <Link
            to="/users/subscriptionbilling"
            style={{ textDecoration: "none" }}
          >
            <mwc-tab
              label="Subscriptions"
              icon="account_balance"
              stacked
              onClick='location.href="app-account-billing.html"'
            ></mwc-tab>
          </Link>{" "}
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link to="/users/notification" style={{ textDecoration: "none" }}>
            {" "}
            <mwc-tab
              label="Notifications"
              icon="notifications"
              stacked
              onClick='location.href="app-account-notifications.html"'
            ></mwc-tab>
          </Link>
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp; &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link to="/users/security" style={{ textDecoration: "none" }}>
            <mwc-tab
              label="Security"
              icon="security"
              stacked
              activeIndex="3"
            ></mwc-tab>
          </Link>
        </mwc-tab-bar>
        <hr className="mt-0 mb-5" />
        <div className="row gx-5">
          <div className="container-xl px-4">
            <h1 className="text-black py-3 mb-0 display-6">
              Account Settings - Notification
            </h1>
          </div>

          <table className="table table-bordered ">
            <thead>
              <tr
                style={{
                  fontSize: "17px",
                  backgroundColor: " #6200ea",
                  color: "white",
                  textAlign: "center",
                  border: "2px",
                }}
              >
                <th>Subject</th>
                <th>message</th>
                <th>timestamp</th>
              </tr>
            </thead>
            <tbody>
              {notification.map((notifications) => {
                return (
                  <tr
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <th>{notifications.subject}</th>
                    <th> {notifications.message}</th>
                    <th> {notifications.timestamp}</th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}
export default Notification;
