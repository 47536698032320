import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
 
function InsuranceeditModalPopup({ isOpen, onRequestClose, Id }) {
  const { userid } = useParams();

  const [uproduct1, setUproduct1] = useState({
    underWriter: "",
    policyNumber: "",
    policyDate: "",
    expiryDate: "",
    fileSize: "",
    file:null,
    fileMeta:"",
    userid:userid,
 orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
 
  const [loading, setLoading] = useState(false);
 
  const navigate = useNavigate();
 
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const response = await axios.get(`/api/employeeinsurance/${Id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUproduct1(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {
    if (Id) {
      getData();
    }
  }, [Id]);
 
 
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .post(
        "/api/employeeinsurance/update",
        uproduct1,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Insurance Details updated successfully");
        onRequestClose();
        getData();
 
      })
      .catch((error) => {
        console.log(error);
      });
  }
 
  const rhandle = (event, field) => {
    if (field === "file") {
      handlePdfFileChange(event);
    } else {
      const actualValue = event.target.value;
      setUproduct1({
        ...uproduct1,
        [field]: actualValue,
      });
    }
  };
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }
  const handlePdfFileChange = async (e) => {
    const file = e.target.files[0];
    const convertedPdf = await getBase64FromFile(file);
    const pdfObject = {
      ...uproduct1,

      file: convertedPdf.content,
      fileSize: file.size,
      fileMeta:convertedPdf.metadata
    };

    setUproduct1(pdfObject);
  };
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update Insurance</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4">
            <form onSubmit={(e) => rsubmit(e)}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="underWriter"> underWriter</label>
                  <input
                    className="form-control"
                    id="underWriter"
                    type="text"
                    placeholder="underWriter"
                    value={uproduct1.underWriter}
                    onChange={(e) => rhandle(e, "underWriter")}
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="policyNumber">policyNumber</label>
                  <input
                    className="form-control"
                    id="policyNumber"
                    type="number"
                    placeholder="policyNumber"
                    value={uproduct1.policyNumber}
                    onChange={(e) => rhandle(e, "policyNumber")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="policyDate">policyDate</label>
                  <input
                    className="form-control"
                    id="policyDate"
                    type="date"
                    placeholder="policyDate"
                    value={uproduct1.policyDate}
                    onChange={(e) => rhandle(e, "policyDate")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="expiryDate">expiryDate</label>
                  <input
                    className="form-control"
                    id="expiryDate"
                    type="date"
                    placeholder="expiryDate"
                    value={uproduct1.expiryDate}
                    onChange={(e) => rhandle(e, "expiryDate")}
                  />
                  </div>
              </div>
              <div className="row">
                              <div className="form-floating-sm mb-2 d-flex align-items-center">
                                <label htmlFor="file" className="me-1">Upload Document:</label>
                                <input
                                  className="form-control smaller-input flex-grow-1"
                                  id="file"
                                  type="file"
                                  placeholder="Upload document"
                                  onChange={(e) => handlePdfFileChange(e)}
                                />
                               
                               
                              </div>
                            </div>
               
             
                  <br />
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                
            </form>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
export default InsuranceeditModalPopup;