import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import TransactionIncoming from "./transactionincomingdetails";
function ManagerIncomingTransaction() {
  return (
    <div>
      <div>
        <ManagerDashboard />
      </div>
      <div>
        <ManagerSidebar />
      </div>
      <div>
        <TransactionIncoming />
      </div>
    </div>
  );
}
export default ManagerIncomingTransaction;
