import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddconsumerModal from "./Addconsumerlist";
import Addcsvfil from "./Addcsvfile";
import { Card, Button, Pagination } from "react-bootstrap"; // Import Pagination from react-bootstrap
import Bulkupload from "./Bulkupload";
import EditConsumerModal from "./Updateconsumerlist";
import { FaEdit, FaEye, FaFontAwesome, FaSearch } from "react-icons/fa";
import ConsumerPointsPopup from "./consumerPointsPopup";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function Consumerlist() {
  const [product, setProduct] = useState([]);
  const [refillProducts, setRefillProducts] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [iscsvModalOpen, setIscsvModalOpen] = useState(false);
  const [iscsvbulkModalOpen, setIscsvbulkModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedConsumer, setSelectedConsumer] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 9;
  const [isShow, setIsShow] = useState(false);
  const [selectedCust, setSelectedCust] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState('');
  useEffect(() => {
    if (searchTerm) {
      searchData();
    } else {
      getData(currentPage, pageSize); // Load the original data when searchTerm is empty
    }
  }, [searchTerm, currentPage]);

  useEffect(() => {
   getRefillProducts();
  }, []);

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
   const searchData = async () => {
  const token = JSON.parse(localStorage.getItem("data")).token;

  try {
    const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
    const searchUrl = `/api/consumerdetails/orgcodes=${orgCode}`;
    const response = await axios.get(searchUrl, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const filtered = response.data.filter((consumer) => {
      const consumerNumberStr = consumer.consumernumber ? consumer.consumernumber.toString() : "";
      const phoneStr = consumer.phone ? consumer.phone.toString() : "";
      const consumerNameStr = consumer.consumername ? consumer.consumername.toLowerCase() : "";

      return (
        consumerNumberStr.includes(searchTerm.toLowerCase()) ||
        phoneStr.includes(searchTerm.toLowerCase()) ||
        consumerNameStr.includes(searchTerm.toLowerCase())
      );
    });

    setProduct(filtered);
    setLoader(false);
  } catch (error) {
    handleError(error);
  }
};
  const getData = async (page, size) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/consumerdetails/orgcode=${orgCode}?page=${page}&size=${size}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct([...response.data.content]);
      setTotalPages(response.data.totalPages);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };
  
  const getRefillProducts = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
    setLoader(true);
    try{
    const trans = await axios.get(`/api/product/orgcode=${orgcode}/productstatus=true/productTypeId=${1}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(trans);

    setRefillProducts(trans.data);
    setLoader(false);
  }catch(error){
    setLoader(false);
    handleError(error);
  }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred.");
    }
    setLoader(false);
  };

  const handleAddProduct = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAdd = () => {
    setIscsvModalOpen(true);
  };

  const handleCloseModal1 = () => {
    setIscsvModalOpen(false);
  };

  const handlebulkupload = () => {
    setIscsvbulkModalOpen(true);
  };

  const handleCloseModal2 = () => {
    setIscsvbulkModalOpen(false);
  };

  const handleEditProduct = (id) => {
    setSelectedConsumer(id);
    setEditModalOpen(true);
  };

  const navigate = useNavigate();
  const handleViewConsumerDetails = (consumernumber) => {
    setSelectedConsumer(consumernumber);
    navigate(`/users/consumer-bio/${consumernumber}`);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedConsumer(null);
  };

  const handlePageChange = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  const handleShow = (consumer) => {
    setSelectedCust(consumer);
    setIsShow(true);
  };

  const handleClose = () => {
    setIsShow(false);
    setSelectedCust(null);
  };

  const filteredData = product.filter((consumer) => {
    const consumerNumberStr = consumer.consumernumber ? consumer.consumernumber.toString().toLowerCase() : "";
    const phoneStr = consumer.phone ? consumer.phone.toLowerCase() : "";
    const consumerNameStr = consumer.consumername ? consumer.consumername.toLowerCase() : "";
    const productStr = consumer.product ? consumer.product.toLowerCase() : "";
    
    // Check if the product matches the selected product or if no product is selected
    const productFilter = selectedProduct ? productStr.includes(selectedProduct.toLowerCase()) : true;
  
    return (
      productFilter &&
      (consumerNumberStr.includes(searchTerm.toLowerCase()) ||
      phoneStr.includes(searchTerm.toLowerCase()) ||
      consumerNameStr.includes(searchTerm.toLowerCase()))
    );
  });
  
  const renderPaginationItems = () => {
    const paginationItems = [];
    const startPage = Math.max(currentPage - 2, 0);
    const endPage = Math.min(currentPage + 3, totalPages);

    if (currentPage > 0) {
      paginationItems.push(
        <Pagination.First key="first" onClick={() => handlePageChange(0)} />
      );
      paginationItems.push(
        <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />
      );
    }

    for (let page = startPage; page < endPage; page++) {
      paginationItems.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page + 1}
        </Pagination.Item>
      );
    }

    if (currentPage < totalPages - 1) {
      paginationItems.push(
        <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />
      );
      paginationItems.push(
        <Pagination.Last key="last" onClick={() => handlePageChange(totalPages - 1)} />
      );
    }

    return paginationItems;
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />

      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body">
          <div className="card-title form-group d-flex align-items-center justify-content-between">
            <span>Consumer Details</span>
            <div>
              <Button variant="primary" className="btn btn-primary me-3" onClick={handlebulkupload}>
                Bulk Upload
              </Button>
              <Button variant="primary" className="btn btn-primary me-3" onClick={handleAddProduct}>
                ADD
              </Button>
            </div>
          </div>
          <br/>
          <div className="d-flex align-items-center" style={{ gap: "40px", fontSize: "14px" }}>
            <div className="d-flex align-items-center">
              <label className="me-2">FILTER BY PRODUCT NAME:</label>
              <select
                value={selectedProduct}
                onChange={(e) => setSelectedProduct(e.target.value)}
                className="form-select"
                style={{ width: "200px" }}
              >
                <option value="">All</option>
                {refillProducts.map((p) => (
                  <option key={p.id} value={p.productname}>
                    {p.productname}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group position-relative" style={{ width: '300px' }}> {/* Adjust width as needed */}
  <input
    type="search"
    className="form-control pl-4"
    placeholder="Search by company name or company code"
    value={searchTerm}
    onChange={handleSearchInputChange}
    style={{ paddingLeft: "2rem", width: "100%" }} // Ensure the input takes the full width of the parent div
  />
  <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
    <FontAwesomeIcon icon={faSearch} />
  </span>
</div>
          </div>

            <br />
            <div className="row">
              {filteredData.length === 0 ? (
                <div className="col text-center">No Data Available</div>
              ) : (
                filteredData.map((consumer, index) => (
                  <div key={index} className="col-lg-4 mb-4">
                    <Card style={{ cursor: 'pointer' ,marginRight:"10px"}}
                  title="View Details" onClick={() => handleViewConsumerDetails(consumer.consumernumber)}>
                      <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <Card.Title className="mb-1">{consumer.consumername}  </Card.Title>
                        <div className="ml-auto d-flex align-items-center">
                        <FaEdit  
                  className="ml-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditProduct(consumer.id);
                  }}
                  style={{ cursor: 'pointer' ,marginRight:"10px"}}
                  title="Edit"
                />
                        <FaEye  
                  className="ml-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShow(consumer.consumernumber);
                  }}
                  style={{ cursor: 'pointer' }}
                  title="View Points"
                />
                        </div>
                        </div>
                        <Card.Text>Consumer Number: {consumer.consumernumber}</Card.Text>
                        <Card.Text>Phone: {consumer.phone}</Card.Text>
                        <Card.Text>Product: {consumer.product}</Card.Text>
                        {/* <Card.Text>Area: {consumer.area}</Card.Text>
                        <Card.Text>Product: {consumer.product}</Card.Text>
                        <Card.Text>Latitude: {consumer.latitude}</Card.Text>
                        <Card.Text>Longitude: {consumer.longitude}</Card.Text>
                        <Card.Text>Address: {consumer.address1}, {consumer.address2}</Card.Text> */}
                      </Card.Body>
                    </Card>
                  </div>
                ))
              )}
            </div>
            <div className="d-flex justify-content-center">
              <Pagination>
                {renderPaginationItems()}
              </Pagination>
            </div>
            <div>
              <AddconsumerModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
              {iscsvModalOpen && <Addcsvfil isOpen={iscsvModalOpen} onRequestClose={handleCloseModal1} />}
              {iscsvbulkModalOpen && <Bulkupload isOpen={iscsvbulkModalOpen} onRequestClose={handleCloseModal2} />}
              {editModalOpen && (
                <EditConsumerModal
                  isOpen={editModalOpen}
                  onRequestClose={handleCloseEditModal}
                  id={selectedConsumer}
                />
              )}

{isShow && selectedCust &&(
                <ConsumerPointsPopup
                  isOpen={isShow}
                  onRequestClose={handleClose}
                  consumerNumber={selectedCust}
                />
              )}
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default Consumerlist;
