import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, Navigate } from "react-router-dom";
import CreateAdminAccount from "./createAdminAccount";
import ViewAdminAccount from "./Viewadminpopup";
import "./Spinner.css";
import "./Loader.css";
import { useNavigate } from "react-router-dom";


function SuperNotification() {
  const [organizationdetails, setOrganizationdetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isorgModalOpen, setIsorgModalOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [loader, setLoader] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const navigate = useNavigate();

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios.get("/api/organizationprofiles", {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(trans);

    setOrganizationdetails(trans.data);
    setLoader(false);
  };

  

  useEffect(() => {
    getData();
  }, []);

  const handleClick = (orgcode) => {
    navigate( `/users/detailsofsubscription/${orgcode}`);
  };



  return (
    <div>
      <div>
        <Dashboard />
      </div>
     
     
      <div>
        <div className="col-10  mx-xl-auto ps-xl-10" style={{marginTop:"10%"}}>
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="card-title">Subscription Details
              {""}
              {loader && (
        <div
          className="loader"
          style={{ position: "absolute", left: "210px", top: "15px" }}
        ></div>
      )}
             
      
          
              </div>

              <br />
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    <th>Organization Name </th>
                    <th>Orgcode </th>

                   
                  </tr>
                </thead>
                <tbody>
                  {organizationdetails.map((accd) => {
                    return (
                      <tr key={accd.name}>
              <td>
        <button onClick={() => handleClick(accd.orgcode)}>{accd.name}
        </button>

      </td>
      <td>{accd.orgcode}</td>
       

                       
                        
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperNotification;
 ;
