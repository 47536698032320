import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Card, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";

function Salesareaconsumable() {
  const [reward, setReward] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [searchOption, setSearchOption] = useState("MONTH");
  const [products, setProducts] = useState([]);
  const [selectedProductName, setSelectedProductName] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [salesAreas, setSalesAreas] = useState([]);
  const [selectedterritory, setSelectedterritory] = useState("");
  const [orgCode, setOrgCode] = useState("");
  const [code, setcode] = useState([]);

  const navigate = useNavigate();

  
    const fetchProduct = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      try {
        // Set loader to true before fetching data
        setLoader(true);
  
        // Fetch organization profiles
        const orgProfilesResponse = await axios.get('/api/organizationprofiles', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setcode(orgProfilesResponse.data);
  console.log(orgProfilesResponse.data);
        // Fetch products
        const fetchedOrgCode = orgProfilesResponse.data?.orgcode; 
    console.log('Fetched Org Code:', fetchedOrgCode); // Add this line to check the fetched org code
    setOrgCode(fetchedOrgCode); // Set default orgcode
    if (fetchedOrgCode) {
        const productResponse = await axios.get(
          `/api/product/productstatus=true/orgcode=${fetchedOrgCode}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
    
        const consumableProducts = productResponse.data.filter(product => product.productType === "Consumables");
          console.log("Consumable Products:", consumableProducts);
      
          setProducts(consumableProducts);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    };
    useEffect(() => {
        fetchProduct();
      }, []);
  
    const fetchProducts = async (orgCode) => {
        const token = JSON.parse(localStorage.getItem("data")).token;
        setLoader(true);
        try {
          const productResponse = await axios.get(
            `/api/product/productstatus=true/orgcode=${orgCode}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const consumableProducts = productResponse.data.filter(product => product.productType === "Consumables");
          console.log("Consumable Products:", consumableProducts);
      
          setProducts(consumableProducts);
        } catch (error) {
          console.error("Error fetching products:", error);
          toast.error("Error fetching products");
        } finally {
          setLoader(false);
        }
      };
      
      // In your useEffect hook for fetching products, pass the selected orgCode directly
      useEffect(() => {
        if (orgCode) {
          fetchProducts(orgCode); // Fetch products for the selected orgCode   
         
        }
      }, [orgCode]);
    
  const fetchSalesAreas = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get('/api/salesarea', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSalesAreas(response.data);
      
    } catch (error) {
    }
  };
  useEffect(() => {
    fetchSalesAreas();
  }, []);
  useEffect(() => {
    if (selectedterritory) {
    //   getData();
    }
  }, [selectedterritory]);

  const searchByMonth = async (month, year) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      let searchResponse;
     
      if (typeFilter && selectedProductName) {
        const selectedProduct = products.find(
            (product) => product.productname === selectedProductName
        );
        const productid = selectedProduct?.id;
        searchResponse = await axios.get(
            `/api/transactionsquantity/salesmonth/${selectedterritory}/productid=${productid}/activity=${typeFilter}/${month}/${year}`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
    } else if (typeFilter) {        searchResponse = await axios.get(
          `/api/transactionsquantity/salesmonth/${selectedterritory}/activity=${typeFilter}/${month}/${year}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else if (selectedProductName) {
        const selectedProduct = products.find(
          (product) => product.productname === selectedProductName
        );
        const productid = selectedProduct?.id;
        searchResponse = await axios.get(
          `/api/transactionsquantity/salesarea/${selectedterritory}/productid=${productid}/${year}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
      setReward([...searchResponse.data]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const searchByYear = async (year) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      let searchResponse;
      if (typeFilter && selectedProductName) {
        const selectedProduct = products.find(
            (product) => product.productname === selectedProductName
        );
        const productid = selectedProduct?.id;
        searchResponse = await axios.get(
            `/api/transactionsquantity/salesmonth/${selectedterritory}/productid=${productid}/activity=${typeFilter}/${year}`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
    } else if (typeFilter) {
        searchResponse = await axios.get(
          `/api/transactionsquantity/salesarea/${selectedterritory}/activity=${typeFilter}/${year}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else if (selectedProductName) {
        const selectedProduct = products.find(
          (product) => product.productname === selectedProductName
        );
        const productid = selectedProduct?.id;
        searchResponse = await axios.get(
          `/api/transactionsquantity/salesarea/${selectedterritory}/productid=${productid}/${year}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
      setReward([...searchResponse.data]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  

  useEffect(() => {
    if (typeFilter || selectedProductName) {
      if (searchOption === "MONTH" && selectedYear && selectedMonth !== null) {
        searchByMonth(selectedMonth + 1, selectedYear);
      } else if (searchOption === "YEAR" && selectedYear) {
        searchByYear(selectedYear);
      }
    }
  }, [typeFilter, selectedProductName, searchOption, selectedMonth, selectedYear,selectedterritory]);

  const handleProductChange = (event) => {
    setSelectedProductName(event.target.value);
    // setTypeFilter(""); // Reset type filter when product is selected
  };

  const handleTypeChange = (event) => {
    setTypeFilter(event.target.value);
    // setSelectedProductName(""); // Reset product filter when type is selected
  };

  const handleFilterChange = (event) => {
    const selectedOption = event.target.value;
    setSearchOption(selectedOption);
    setSelectedMonth(null);
    setSelectedYear(null);
  };
  const handleOrgCodeChange = (e) => {
    const selectedOrgCode =(e.target.value);
    console.log('Selected Org Code:', selectedOrgCode);
    setOrgCode(selectedOrgCode);
  };
  const handleBack = () => {
    navigate("/users/Consumablebutton");
  };
  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <br />

      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10">
        <Card>
          <Card.Body>
            <Card.Title>Consumable Sales
                <Button style={{ marginLeft:"90%"}} variant="secondary" onClick={handleBack}>
              Back
            </Button>
            </Card.Title>
            <br/>
            <div className="d-flex align-items-center">
          
            <div className="filter-dropdown">
                <label> Sales Area:</label>
                <select value={selectedterritory} onChange={(e) => setSelectedterritory(e.target.value)}>
                  <option value="">Select sales Area</option>
                  {salesAreas.map((area) => (
                    <option key={area.id} value={area.salesarea}>{area.salesarea}</option>
                  ))}
                </select>
              </div>
            <div className="filter-dropdown" style={{ marginRight: "20px" }}>
                <label> Type:</label>
                <select value={typeFilter} onChange={handleTypeChange}>
                  <option value="">Select Type</option>
                  <option value="sales">Sales</option>
                  <option value="additional">Additional</option>
                  {/* <option value="Exempted">Exempted</option> */}
                  <option value="new connection">New Connection</option>
                  {/* <option value="Online">Online</option> */}
                </select>
              </div>
              <div className="filter-dropdown">
  <label>Orgcode:</label>
  <select value={orgCode} onChange={handleOrgCodeChange}>
    
  <option value="">Select Orgcode</option>
  {code.map((area) => (
    <option key={area.id} value={area.orgcode}>{area.orgcode}</option>
  ))}
</select>
</div>
            <div className="filter-dropdown" style={{ marginRight: "20px" }}>
            <div className="row mb-2">
            <div className="form-floating-sm">
        <label htmlFor="product">Product </label>
        <select value={selectedProductName} onChange={handleProductChange}>
          <option value="">Select Product</option>
          {products.map((product, index) => (
            <option key={index} value={product.productname}>
              {product.productname}
            </option>
          ))}
        </select>
  </div>
</div>

              </div>
              <div className="filter-dropdown" style={{ marginRight: "20px" }}>
                <label>FILTER :</label>
                <select value={searchOption} onChange={handleFilterChange}>
                  <option value="MONTH">Month</option>
                  <option value="YEAR">Year</option>
                </select>
              </div>
              {searchOption === "MONTH" && (
                <React.Fragment>
                  <Form.Label>Month:</Form.Label>
                  <DatePicker
                    selected={new Date(selectedYear || new Date().getFullYear(), selectedMonth || new Date().getMonth())}
                    onChange={(date) => {
                      setSelectedYear(date.getFullYear());
                      setSelectedMonth(date.getMonth());
                      searchByMonth(date.getMonth() + 1, date.getFullYear());
                    }}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                  />
                </React.Fragment>
              )}
              {searchOption === "YEAR" && (
                <React.Fragment>
                  <Form.Label>Year:</Form.Label>
                  <DatePicker
                    selected={new Date(selectedYear || new Date().getFullYear(), 0)}
                    onChange={(date) => {
                      setSelectedYear(date.getFullYear());
                      searchByYear(date.getFullYear());
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                  />
                </React.Fragment>
              )}
            </div>

            <br />
            <br />
            <br />
            <br />
            <Row xs={1} md={1} className="g-4">
            { reward.length === 0 && (
                <div style={{ textAlign: 'center', marginTop: '20px', width: '100%' }}>
                  No data available for the selected filter.
                </div>
              )}
            { reward.map((product, index) => (
  <Col key={index}>
    
    <Card>
      <Card.Body>
  
      
        <div style={{ display: 'flex', alignItems: 'center' }}>
        {index === 0 && <FontAwesomeIcon icon={faMedal} style={{ color: 'gold' }} />}
      {index === 1 && <FontAwesomeIcon icon={faMedal} style={{ color: 'silver' }} />}

{index === 2 && <FontAwesomeIcon icon={faMedal} style={{ color: 'bronze' }} />}
        <div  style={{marginLeft:"50px"}}>
        <Card.Text style={{ fontSize: '18px' }}>Quantity: {product.totalQty}</Card.Text>
        </div>
        <div  style={{marginLeft:"50px"}}>
        <Card.Text style={{ fontSize: '18px' }}>Orgcode: {product.orgcode}</Card.Text>
        </div>
          </div>
        </Card.Body>
      </Card>
    
  </Col>
))}
            </Row>
            <br />
            <br />
            
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default Salesareaconsumable;
