import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Spinner.css";

const ConsumerPointsPopup = ({ isOpen, onRequestClose, consumerNumber }) => {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;  
  
  const [consumerPoints, setConsumerPoints]=useState("");
  
  useEffect(() => {
    const fetchPoints = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const PointsUrl = `/api/consumerdetails/consumer/${consumerNumber}/points`;

      try {
        const response = await axios.get(PointsUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setConsumerPoints(response.data);
      } catch (error) {
       handleError(error);
      }
    };

    fetchPoints();
  }, []);
 

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 400) {
      toast.error(error.response.data.code);
    } else {
      toast.error("An unexpected error occurred");
    }
   
  };


  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="sm">
      <Modal.Header closeButton>
        <Modal.Title>Consumer Total Points</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div>Consumer Number :{consumerNumber}</div>
       <div>Points :{consumerPoints}</div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ConsumerPointsPopup;
