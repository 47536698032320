import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
 
function EditConsumerModal({ isOpen, onRequestClose, id }) {
    const date = new Date();
    date.setDate(date.getDate());
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`; 
  const [uproduct1, setUproduct1] = useState({
    
        consumernumber: "",
        consumername: "",
        phone: "",
        longitude: "",
        latitude: "",
        area: "",
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
        address1: "",
        address2: "",
        address3: ""
    
      
   
  });
 
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
 
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const response = await axios.get(`/api/consumerdetails/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUproduct1(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);
 
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .post(
        "/api/consumerdetails/update",
        uproduct1,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Consumer Details updated successfully");
        onRequestClose();
        getData();
 
      })
      .catch((error) => {
        console.log(error);
      });
  }
 
  const rhandle = (e) => {
    const { id, value } = e.target;
  
    setUproduct1((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
 
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update Consumer Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4">
            <form onSubmit={(e) => rsubmit(e)}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="consumernumber">Consumer Number</label>
                    <input
                      className="form-control"
                      id="consumernumber"
                      type="text"
                      placeholder="Consumer Number"
                      value={uproduct1.consumernumber}
                    onChange={(e) => rhandle(e, "consumernumber")}
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="consumername">Consumer Name</label>
                    <input
                      className="form-control"
                      id="consumername"
                      type="text"
                      placeholder="Consumer Name"
                      value={uproduct1.consumername}
                    onChange={(e) => rhandle(e, "consumername")}
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="phone">Phone</label>
                    <input
                      className="form-control"
                      id="phone"
                      type="text"
                      placeholder="Phone"
                      value={uproduct1.phone}
                    onChange={(e) => rhandle(e, "phone")}
                  />
                  </div>
                  {/* <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="area">Area</label>
                    <input
                      className="form-control"
                      id="area"
                      type="text"
                      placeholder="area"
                      value={uproduct1.area}
                    onChange={(e) => rhandle(e, "area")}
                  />
                  </div>
                  </div> */}
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="latitude">Latitude</label>
                    <input
                      className="form-control"
                      id="latitude"
                      type="number"
                      placeholder="Latitude"
                      value={uproduct1.latitude}
                    onChange={(e) => rhandle(e, "latitude")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="longitude">Longitude</label>
                    <input
                      className="form-control"
                      id="longitude"
                      type="number"
                      placeholder="Longitude"
                      value={uproduct1.longitude}
                    onChange={(e) => rhandle(e, "longitude")}
                  />
                  </div>
              </div>
              {/* <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="product">Product</label>
                    <input
                      className="form-control"
                      id="product"
                      type="text"
                      placeholder="Product"
                      value={uproduct1.product}
                    onChange={(e) => rhandle(e, "product")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="cashmemono">Cash Memo No</label>
                    <input
                      className="form-control"
                      id="cashmemono"
                      type="text"
                      placeholder="Cash Memo No"
                      value={uproduct1.deliveryDetails.cashmemono}
                    onChange={(e) => rhandle(e, "cashmemono")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="cashmemodate">Cash Memo Date</label>
                    <input
                      className="form-control"
                      id="cashmemodate"
                      type="date"
                      placeholder="Cash Memo Date"
                      value={uproduct1.deliveryDetails.cashmemodate}
                    onChange={(e) => rhandle(e, "cashmemodate")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="bookingdate">Booking Date</label>
                    <input
                      className="form-control"
                      id="bookingdate"
                      type="date"
                      placeholder="Booking Date"
                      value={uproduct1.deliveryDetails.bookingdate}
                    onChange={(e) => rhandle(e, "bookingdate")}
                  />
                  </div>
              </div> */}
              {/* <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="address1">Address</label>
                    <input
                      className="form-control"
                      id="address1"
                      type="text"
                      placeholder="Address"
                      value={uproduct1.address1}
                    onChange={(e) => rhandle(e, "address1")}
                  />
                  </div>
              </div> */}
                  <br />
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                
            </form>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
export default EditConsumerModal;