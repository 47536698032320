import React from "react";

function ConfirmationModal({ message, onCancel, onConfirm }) {
  return (
    <>
      <style>
        {`
          .modal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start; /* Align to the top */
          }


          .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .modal-body {
            padding: 20px 0;
          }

          .modal-footer {
            display: flex;
            justify-content: flex-end;
          }

          /* Show the modal when its display property is set to block */
          .modal.show {
            display: block;
          }
        `}
      </style>

      <div className="modal show"> {/* Add 'show' class to display the modal */}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirmation</h5>
              <button
                type="button"
                className="btn-close"
                onClick={onCancel}
              ></button>
            </div>
            <div className="modal-body">
              <p>{message}</p>
            </div>
            <div className="modal-footer">
             
              <button
                type="button"
                className="btn btn-primary"
                onClick={onConfirm}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmationModal;
