import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import "./Spinner.css";
import PriceHistoryModal from "./PriceHistoryModal";
import $ from 'jquery';
import ProductdetailsModal from "./updateproducts";
import ProducteditModalPopup from "./productedit";
import { FiInfo } from "react-icons/fi";
import AdditionalPriceHistoryModal from "./additionalPriceHistoryModal";
import NewConPriceHistoryModal from "./newConPriceHistoryModal";
import ProductPriceUpdate from "./productPriceUpdate";
import Modalpopup from "./Modalpopup";
import ProductPointsHistory from "./productPointsHistoryModal";
function Productdetails() {
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [priceHistory, setPriceHistory] = useState([]); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [isProductPriceUpdateModal, setIsProductPriceUpdateModal] = useState(false);

  const [isPointsHistoryModalOpen, setIsPointsHistoryModalOpen] = useState(false);
  const [isPriceHistoryModalOpen, setIsPriceHistoryModalOpen] = useState(false);
  const [isAdditionalPriceHistoryModalOpen, setIsAdditionalPriceHistoryModalOpen] = useState(false);
  const [isNewConPriceHistoryModalOpen, setIsNewConPriceHistoryModalOpen] = useState(false);
  const [additionalPriceHistory, setAdditionalPriceHistory] = useState([]); 
  const [newConPriceHistory, setNewConPriceHistory] = useState([]); 
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [superProducts, setSuperProducts] = useState([]);
  const [productTypeFilter, setProductTypeFilter] = useState("All"); // New state for product type filter

  const navigate = useNavigate();
 

  

  
    const getData = async () => {
      setLoader(true);
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;

        const productResponse = await axios.get(`/api/product/orgcode=${orgCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProduct([...productResponse.data]);

        const superProductsResponse = await axios.get(`/api/super-products`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSuperProducts(superProductsResponse.data);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        handleError(error);
      }
    };

   

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };
  useEffect(() => {
    getData();
    
  }, [isCreateModalOpen]);
  const filteredProducts = product.filter(p => 
    productTypeFilter === "All" || p.productType === productTypeFilter
  );
 
  const onClickhandler = async (e, id) => {
    setLoading(id);
    const checked = e.target.checked;
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      await axios.get(`/api/product/productUpdate/id=${id}/value=${checked}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(null);
      toast.success("Product status updated");
      getData();

    } catch (error) {
      handleError(error);
    }
  };

  const handleProductEdit = async (productId) => {
    setSelectedProductId(productId);
      setIsProductEditModalOpen(true);
  };
 
  const closeProductEditModal = () => {
    setIsProductEditModalOpen(false);
    getData();
 
  };

  const handleProductPriceUpdate = async (productId) => {
    setSelectedProductId(productId);
   setIsProductPriceUpdateModal(true);
  };
 
  const closeProductPriceUpdateModal = () => {
    setIsProductPriceUpdateModal(false);
    getData();
 
  };
 
  const openPriceHistoryModal = async (productId) => {
    setSelectedProductId(productId);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/ProductPrice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsPriceHistoryModalOpen(true);
  };
 
  const closePriceHistoryModal = () => {
    setSelectedProductId(null);
    setIsPriceHistoryModalOpen(false);
  };

  const openAdditionalPriceHistoryModal = async (productId) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/additionalprice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdditionalPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsAdditionalPriceHistoryModalOpen(true);
  };
 
  const closeAdditionalPriceHistoryModal = () => {
    setIsAdditionalPriceHistoryModalOpen(false);
  };

  const openNewConPriceHistoryModal = async (productId) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/newconnectionprice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setNewConPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsNewConPriceHistoryModalOpen(true);
  };
 
  const closeNewConPriceHistoryModal = () => {
    setIsNewConPriceHistoryModalOpen(false);
  };

const [salesPoints, setSalesPoints] = useState([]);
const [additionalPoints, setAdditionalPoints] = useState([]);
const [newConPoints, setNewConPoints] = useState([]);
const [pointsType, setPointsType] = useState(null); // Track which points type is being displayed

const openPointsHistoryModal = async (productId, pointsType) => {
  setSelectedProductId(productId);
  setPointsType(pointsType); // Set the points type
  try {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const response = await axios.get(`/api/product-points/admin-productid=${productId}/pointstype=${pointsType}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    
    // Update state based on pointsType
    if (pointsType === 'sales') {
      setSalesPoints(response.data);
    } else if (pointsType === 'additional') {
      setAdditionalPoints(response.data);
    } else if (pointsType === 'new connection') {
      setNewConPoints(response.data);
    }
  } catch (error) {
    handleError(error);
  }
  setIsPointsHistoryModalOpen(true);
};

const closePointsHistoryModal = () => {
  setIsPointsHistoryModalOpen(false);
};

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
    setProduct([]);

  };

  // Function to handle closing create modal
  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
    setProduct([]);
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      
      {loader && <div className="loading"></div>} 
      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Product Details
            <div className="filter-container d-flex align-items-center">

            <select
  value={productTypeFilter}
  
  onChange={(e) => setProductTypeFilter(e.target.value)}
  style={{
    width: '200px', 
    height: '45px',
    fontSize: '16px',
    padding: '10px 14px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#f8f9fa',
    color: '#495057',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    cursor: 'pointer',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s, box-shadow 0.3s, border-color 0.3s',
    outline: 'none',
    appearance: 'none',
    backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'none\'><path d=\'M8 12a1 1 0 0 1-.707-1.707L11.586 6 7.293 1.707A1 1 0 0 1 8 0a1 1 0 0 1 .707 1.707L5.414 6l3.293 3.293A1 1 0 0 1 8 12z\' fill=\'#495057\'/></svg>")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px center',
    backgroundSize: '16px 16px',
    textOverflow: 'ellipsis', 
    overflow: 'hidden', 
    whiteSpace: 'nowrap',
  }}
  onFocus={(e) => {
    e.target.style.backgroundColor = '#e9ecef';
    e.target.style.boxShadow = '0px 6px 8px rgba(0, 0, 0, 0.2)';
  }}
  onBlur={(e) => {
    e.target.style.backgroundColor = '#f8f9fa';
    e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
  }}
>
  <option value="All">All Product Types</option>
  <option value="Refill Sales">Refill Sales</option>
  <option value="Consumables">Consumables</option>
</select>
</div>
              <button className="btn btn-primary" onClick={handleCreateModalOpen}>
                ADD
              </button>
              {/* <button className="btn btn-primary"onClick={handleAddProduct}>
               ADD
            </button> */}
            </div>
  
            <br />
            <div className="table-responsive">

            {productTypeFilter === 'All'  ? (
            <>
            {/* <h6>All Product Types</h6> */}

            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                   <th>Product Type</th>

                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>HSN Code</th>
                  <th>GST</th>
                  <th>Net weight</th>
                  <th>Sales Price</th> 
                  <th>Additional Price</th> 
                  <th>New Connection Price</th> 
                  <th>Sales Points</th> 
                  <th>Additional Points</th> 
                  <th>New Connection Points</th> 
                  <th>Edit</th>
                  <th>Status</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>
              {product.length === 0 ? (
                    <tr>
                      <td colSpan="15" className="text-center">
                        No Data Available
                      </td>
                    </tr>
          ) : (

            product.map((product) => {      
              const isTracked = superProducts.some(
                (superProduct) => superProduct.productName === product.productname
              );
              const trackingClass = isTracked ? "pill-green" : "pill-yellow";
              const salesPoints = product.productPoints.filter(point => point.pointsType === "sales").map(point => point.points).join(", ");
              const additionalPoints = product.productPoints.filter(point => point.pointsType === "additional").map(point => point.points).join(", ");
              const newConPoints = product.productPoints.filter(point => point.pointsType === "new connection").map(point => point.points).join(", ");
            
              return (
                <tr key={product.productid}>
                  <td>{product.productType}</td>
                  <td>
                    <div style={{ textAlign: "center" }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: "70px", height: "70px", marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {product.image && product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "50px", height: "50px", borderRadius: "50%" }}></div>
                          )}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                          <span style={{ marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                            {product.productname}
                          </span>
                          <span
                            style={{
                              display: "inline-block",
                              padding: "0.2em 0.4em",
                              borderRadius: "0.4em",
                              fontSize: "0.8em",
                              color: "white",
                              backgroundColor: isTracked ? "green" : "black",
                              minWidth: '60px',
                              textAlign: 'center',
                            }}
                          >
                            {isTracked ? "Tracked" : "Untracked"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                    <td>{product.productcode}</td>
                      <td>{product.hsnCode}</td>
                      <td>{product?.gst} %</td>
                      <td>{product.netWeight} Kg</td>
                      <td>
                          <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                          {product.pprice?.price && (
                                <>
                                  {product.pprice.price}
                                  <span>
                                    <FiInfo onClick={() => openPriceHistoryModal(product.productid)} style={{ cursor: 'pointer' }} />
                                  </span>
                                </>
                              )}
                          </div>
                        </td>
                        <td>
                          <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                          {product.addPrice?.additionalPrice && (
                                <>
                            {product.addPrice.additionalPrice}
                            <span>
                              <FiInfo onClick={() => openAdditionalPriceHistoryModal(product.productid)} style={{cursor: 'pointer' }}/>
                            </span>
                            </>
                          )}
                          </div>
                        </td>
                        <td>
                          <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                          {product.newConPrice?.newConPrice && (
                            <>
                            {product.newConPrice.newConPrice}
                            <span>
                              <FiInfo onClick={() => openNewConPriceHistoryModal(product.productid)} style={{cursor: 'pointer' }}/>
                            </span>
                            </>
                          )}
                          </div>
                        </td>
                        <td>
                          <div style={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
                          {salesPoints && (
                            <>
                            {salesPoints}
                            <span>
                              <FiInfo onClick={() => openPointsHistoryModal(product.productid, 'sales')} style={{cursor: 'pointer' }} />
                            </span>
                            </>
                          )}
                          </div>
                        </td>
                        <td>
                          <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                          {additionalPoints && (
                            <>
                            {additionalPoints}
                            <span>
                              <FiInfo onClick={() => openPointsHistoryModal(product.productid, 'additional')} style={{cursor: 'pointer' }}/>
                            </span>
                            </>
                          )}
                          </div>
                        </td>
                        <td>
                          <div style={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
                          {newConPoints && (
                            <>
                            {newConPoints}
                            <span>
                              <FiInfo onClick={() => openPointsHistoryModal(product.productid, 'new connection')} style={{cursor: 'pointer' }} />
                            </span>
                            </>
                          )}
                          </div>
                        </td>
                      <td> 
                     <button
                      onClick={() => handleProductEdit(product.productid)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td>
                      <td>
                        <div className="form-check form-switch">
                          {loading == product.productid && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={product.productstatus}
                            onClick={(e) => onClickhandler(e, product.productid)}
                            value={product.productid}
                          />
                        </div>
                      </td>
                      <td>
      <button className="btn btn-primary btn-sm" onClick={() => handleProductPriceUpdate(product.productid)}>
        Update Price/Points
      </button>

              </td>
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
            </>
          ) : null}

{productTypeFilter === 'Refill Sales' ? (
  <>
            {/* <h6>Refill Sales</h6> */}

            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                   <th>Product Type</th>

                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>HSN Code</th>
                  <th>GST</th>
                  <th>Net weight</th>
                  <th>Sales Price</th> 
                  <th>Additional Price</th> 
                  <th>New Connection Price</th> 
                  <th>Sales Points</th> 
                  <th>Additional Points</th> 
                  <th>New Connection Points</th> 
                  <th>Edit</th>
                  <th>Status</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>
                  {filteredProducts.length === 0 ? (
                    <tr>
                      <td colSpan="15" className="text-center">
                        No Data Available
                      </td>
                    </tr>
          ) : (

            filteredProducts
            .filter((p) => p.productType === "Refill Sales")
            .map((product) => {   
              const isTracked = superProducts.some(
                (superProduct) => superProduct.productName === product.productname
              );
              const trackingClass = isTracked ? "pill-green" : "pill-yellow";
              const salesPoints = product.productPoints.filter(point => point.pointsType === "sales").map(point => point.points).join(", ");
              const additionalPoints = product.productPoints.filter(point => point.pointsType === "additional").map(point => point.points).join(", ");
              const newConPoints = product.productPoints.filter(point => point.pointsType === "new connection").map(point => point.points).join(", ");
            
              return (
                <tr key={product.productid}>
                  <td>{product.productType}</td>
                  <td>
                    <div style={{ textAlign: "center" }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: "70px", height: "70px", marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {product.image && product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "50px", height: "50px", borderRadius: "50%" }}></div>
                          )}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                          <span style={{ marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                            {product.productname}
                          </span>
                          <span
                            style={{
                              display: "inline-block",
                              padding: "0.2em 0.4em",
                              borderRadius: "0.4em",
                              fontSize: "0.8em",
                              color: "white",
                              backgroundColor: isTracked ? "green" : "black",
                              minWidth: '60px',
                              textAlign: 'center',
                            }}
                          >
                            {isTracked ? "Tracked" : "Untracked"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                    <td>{product.productcode}</td>
                      <td>{product.hsnCode}</td>
                      <td>{product?.gst} %</td>
                      <td>{product.netWeight} Kg</td>
                      <td>
                          <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                          {product.pprice?.price && (
                                <>
                                  {product.pprice.price}
                                  <span>
                                    <FiInfo onClick={() => openPriceHistoryModal(product.productid)} style={{ cursor: 'pointer' }} />
                                  </span>
                                </>
                              )}
                          </div>
                        </td>
                        <td>
                          <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                          {product.addPrice?.additionalPrice && (
                                <>
                            {product.addPrice.additionalPrice}
                            <span>
                              <FiInfo onClick={() => openAdditionalPriceHistoryModal(product.productid)} style={{cursor: 'pointer' }}/>
                            </span>
                            </>
                          )}
                          </div>
                        </td>
                        <td>
                          <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                          {product.newConPrice?.newConPrice && (
                            <>
                            {product.newConPrice.newConPrice}
                            <span>
                              <FiInfo onClick={() => openNewConPriceHistoryModal(product.productid)} style={{cursor: 'pointer' }}/>
                            </span>
                            </>
                          )}
                          </div>
                        </td>
                        <td>
                          <div style={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
                          {salesPoints && (
                            <>
                            {salesPoints}
                            <span>
                              <FiInfo onClick={() => openPointsHistoryModal(product.productid, 'sales')} style={{cursor: 'pointer' }} />
                            </span>
                            </>
                          )}
                          </div>
                        </td>
                        <td>
                          <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                          {additionalPoints && (
                            <>
                            {additionalPoints}
                            <span>
                              <FiInfo onClick={() => openPointsHistoryModal(product.productid, 'additional')} style={{cursor: 'pointer' }}/>
                            </span>
                            </>
                          )}
                          </div>
                        </td>
                        <td>
                          <div style={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
                          {newConPoints && (
                            <>
                            {newConPoints}
                            <span>
                              <FiInfo onClick={() => openPointsHistoryModal(product.productid, 'new connection')} style={{cursor: 'pointer' }} />
                            </span>
                            </>
                          )}
                          </div>
                        </td>
                      <td> 
                     <button
                      onClick={() => handleProductEdit(product.productid)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td>
                      <td>
                        <div className="form-check form-switch">
                          {loading == product.productid && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={product.productstatus}
                            onClick={(e) => onClickhandler(e, product.productid)}
                            value={product.productid}
                          />
                        </div>
                      </td>
                      <td>
      <button className="btn btn-primary btn-sm" onClick={() => handleProductPriceUpdate(product.productid)}>
        Update Price
      </button>

              </td>
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
            </>
          ) : null}
            {productTypeFilter === 'Consumables' ? (
            <>
            {/* <h6>Consumables</h6> */}

            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                   <th>Product Type</th>

                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>HSN Code</th>
                  <th>GST</th>
                  {/* <th>Net weight</th> */}
                  <th>Sales Price</th> 
                  {/* <th>Additional Price</th>  */}
                  {/* <th>New Connection Price</th>  */}
                  <th>Sales Points</th> 
                  {/* <th>Additional Points</th>  */}
                  {/* <th>New Connection Points</th>  */}
                  <th>Edit</th>
                  <th>Status</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>
                  {filteredProducts.length === 0 ? (
                    <tr>
                      <td colSpan="15" className="text-center">
                        No Data Available
                      </td>
                    </tr>
          ) : (

            filteredProducts
            .filter((p) => p.productType === "Consumables")
            .map((product) => {
              const isTracked = superProducts.some(
                (superProduct) => superProduct.productName === product.productname
              );
              const trackingClass = isTracked ? "pill-green" : "pill-yellow";
              const salesPoints = product.productPoints.filter(point => point.pointsType === "sales").map(point => point.points).join(", ");
              const additionalPoints = product.productPoints.filter(point => point.pointsType === "additional").map(point => point.points).join(", ");
              const newConPoints = product.productPoints.filter(point => point.pointsType === "new connection").map(point => point.points).join(", ");
            
              return (
                <tr key={product.productid}>
                  <td>{product.productType}</td>
                  <td>
                    <div style={{ textAlign: "center" }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: "70px", height: "70px", marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {product.image && product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "50px", height: "50px", borderRadius: "50%" }}></div>
                          )}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                          <span style={{ marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                            {product.productname}
                          </span>
                          <span
                            style={{
                              display: "inline-block",
                              padding: "0.2em 0.4em",
                              borderRadius: "0.4em",
                              fontSize: "0.8em",
                              color: "white",
                              backgroundColor: isTracked ? "green" : "black",
                              minWidth: '60px',
                              textAlign: 'center',
                            }}
                          >
                            {isTracked ? "Tracked" : "Untracked"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                    <td>{product.productcode}</td>
                      <td>{product.hsnCode}</td>
                      <td>{product?.gst} %</td>
                      {/* <td>{product.netWeight} Kg</td> */}
                      <td>
                          <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                          {product.pprice?.price && (
                                <>
                                  {product.pprice.price}
                                  <span>
                                    <FiInfo onClick={() => openPriceHistoryModal(product.productid)} style={{ cursor: 'pointer' }} />
                                  </span>
                                </>
                              )}
                          </div>
                        </td>
                        {/* <td>
                          <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                          {product.addPrice?.additionalPrice && (
                                <>
                            {product.addPrice.additionalPrice}
                            <span>
                              <FiInfo onClick={() => openAdditionalPriceHistoryModal(product.productid)} style={{cursor: 'pointer' }}/>
                            </span>
                            </>
                          )}
                          </div>
                        </td> */}
                        {/* <td>
                          <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                          {product.newConPrice?.newConPrice && (
                            <>
                            {product.newConPrice.newConPrice}
                            <span>
                              <FiInfo onClick={() => openNewConPriceHistoryModal(product.productid)} style={{cursor: 'pointer' }}/>
                            </span>
                            </>
                          )}
                          </div>
                        </td> */}
                        <td>
                          <div style={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
                          {salesPoints && (
                            <>
                            {salesPoints}
                            <span>
                              <FiInfo onClick={() => openPointsHistoryModal(product.productid, 'sales')} style={{cursor: 'pointer' }} />
                            </span>
                            </>
                          )}
                          </div>
                        </td>
                        {/* <td>
                          <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                          {additionalPoints && (
                            <>
                            {additionalPoints}
                            <span>
                              <FiInfo onClick={() => openPointsHistoryModal(product.productid, 'additional')} style={{cursor: 'pointer' }}/>
                            </span>
                            </>
                          )}
                          </div>
                        </td> */}
                        {/* <td>
                          <div style={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
                          {newConPoints && (
                            <>
                            {newConPoints}
                            <span>
                              <FiInfo onClick={() => openPointsHistoryModal(product.productid, 'new connection')} style={{cursor: 'pointer' }} />
                            </span>
                            </>
                          )}
                          </div>
                        </td> */}
                      <td> 
                     <button
                      onClick={() => handleProductEdit(product.productid)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td>
                      <td>
                        <div className="form-check form-switch">
                          {loading == product.productid && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={product.productstatus}
                            onClick={(e) => onClickhandler(e, product.productid)}
                            value={product.productid}
                          />
                        </div>
                      </td>
                      <td>
      <button className="btn btn-primary btn-sm" onClick={() => handleProductPriceUpdate(product.productid)}>
        Update Price
      </button>

              </td>
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
            </>
          ) : null}
            </div>


            <div>
            {isPriceHistoryModalOpen && (
              <PriceHistoryModal
                onClose={closePriceHistoryModal}
                initialPriceHistory={priceHistory}
                productId={selectedProductId}
              />
            )}
              {isAdditionalPriceHistoryModalOpen && (
              <AdditionalPriceHistoryModal
                onClose={closeAdditionalPriceHistoryModal}
                additionalPriceHistory={additionalPriceHistory}
              />
            )}
              {isNewConPriceHistoryModalOpen && (
              <NewConPriceHistoryModal
                onClose={closeNewConPriceHistoryModal}
                newConPriceHistory={newConPriceHistory}
              />
            )}
            {isProductEditModalOpen && (
              <ProducteditModalPopup
                isOpen={isProductEditModalOpen}
                onRequestClose={closeProductEditModal}
                productId={selectedProductId}
              />
            )}

           {isProductPriceUpdateModal && (
              <ProductPriceUpdate
                isOpen={isProductPriceUpdateModal}
                onRequestClose={closeProductPriceUpdateModal}
                productId={selectedProductId}
              />
            )}
            {isCreateModalOpen && (
           <Modalpopup isOpen={isCreateModalOpen} onRequestClose={handleCreateModalClose} />
            )}

           {isPointsHistoryModalOpen && (
      <ProductPointsHistory
        onClose={closePointsHistoryModal}
        salesPoints={pointsType === 'sales' ? salesPoints : []}
        additionalPoints={pointsType === 'additional' ? additionalPoints : []}
        newConPoints={pointsType === 'new connection' ? newConPoints : []}
      />
    )}

      </div>


          </div>
        </div>{" "}
      </div>
    </div>   
  );
}

export default Productdetails;
