import React, { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import axios from "../api/baseurl";
import { toast, ToastContainer } from "react-toastify";
import { Stepper, Step, StepLabel } from "@mui/material";
import "./Spinner.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";

const CreateAdminAccount = ({ isOpen, onRequestClose }) => {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  const steps = ["Register", "Organization Details", "Address"];


  const url = "/api/createAdminAccount";
  const [admindata, setAdata] = useState([
    {
      username: "",
      role: "",
      existOrg: "",
      organization: {
        name: "",
        orgcode: "",
        gstin: "",
        pan: "",
        tan: "",
        contact: "",
        userid: "",
        logo:"",
        imageMeta: "",
        addressLine1:"",
        addressLine2:"",
        city:"",
        pincode:"",
        state:""
      },
    },
  ]);
  const [loading, setLoading] = useState(false);
  

  function handleNext() {
    let hasErrors = false; // Variable to track if there are validation errors
  
    if (activeStep === 0) {
      if (
        admindata.username
      ) {
        if (admindata.password !== admindata.passwordConfirm) {
          toast.error("Passwords do not match.");
          hasErrors = true; // Set the flag to true if there's an error
        }
      } else {
        toast.error("Please fill out all the required fields.");
        hasErrors = true; // Set the flag to true if there's an error
      }
    } else if (activeStep === 1) {
      if (
        admindata.name &&
        admindata.orgcode &&
        admindata.pan 
      ) {
        // No errors, do nothing
      } else {
        toast.error("Please fill out all the required fields.");
        hasErrors = true; // Set the flag to true if there's an error
      }
    }
  
    // Only move to the next step if there are no errors
    if (!hasErrors) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    if (activeStep === 2) {
        if (
          admindata.contact &&
          admindata.addressLine1 &&
          admindata.city &&
          admindata.pincode &&
          admindata.state 
        ) {
    axios
      .post(url, {
        username: admindata.username,
        role: "ROLE_ADMIN",
        organization: {
          name: admindata.name,
          orgcode: admindata.orgcode,
          gstin: admindata.gstin,
          pan: admindata.pan,
          tan: admindata.tan,
          contact: admindata.contact,
          logo: admindata.logo,
          imageMeta:admindata.imageMeta,
          addressLine1: admindata.addressLine1,
          addressLine2: admindata.addressLine2,
          city: admindata.city,
          pincode: admindata.pincode,
          state: admindata.state,
        }
      }, {
          headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        console.log(res.data.roleRequested);
        setLoading(false);
        const regiteredusername = {
          username: admindata.username,
          orgcode: admindata.orgcode,
        };
        localStorage.setItem(
          "RegistrationData",
          JSON.stringify(regiteredusername)
        );
        setAdata([
          {
            username: "",
            role: "",
            existOrg: "",
            organization: {
              name: "",
              orgcode: "",
              gstin: "",
              pan: "",
              tan: "",
              contact: "",
              userid: "",
              logo:"",
              imageMeta: "",
              addressLine1:"",
              addressLine2:"",
              city:"",
              pincode:"",
              state:""
            },
          },
        ]);
        onRequestClose();
        setAdata([]);
        toast("Admin account created successfully and Password sent to registered email");
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          if (error.response.status === 400) {
            toast.error("Bad Request: " + error.response.data.message);
          } else if (error.response.status === 500) {
            toast.error("Email already Registered");
          } else if (error.response.status === 401) {
            toast.error("Unauthorized: " + error.response.data.message);
            navigate("/signin");
          } else {
            toast.error("Error: " + error.response.data.message);
          }
        } else if (error.message) {
          toast.error("Error: " + error.message);
        } else {
          toast.error("An error occurred.");
        }
        setLoading(false);
        setAdata([]);
      });
    } else {
        setLoading(false);
        toast.error("Please fill out all the required fields.");
    }
} else {
    setLoading(false);
    // Handle other steps if needed
}
}
  

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const handleImageChange = async (event) => {
    const convertedImage = await getBase64FromFile(event.target.files[0]);

    const imageObject = {
      ...admindata,

      imageMeta: convertedImage.metadata,

      logo: convertedImage.content,
    };

    setAdata(imageObject);
  };
  function rhandle(e) {
    const newdata = { ...admindata };
    newdata[e.target.id] = e.target.value;
    setAdata(newdata);
  }
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      {/* <Modal.Title>Create Product</Modal.Title> */}
    </Modal.Header>
    <Modal.Body>
              <div className="row justify-content-center">
                <div className="col-xxl-10 col-xl-10">
                  {/* <div className="card card-raised shadow-10 mt-5 mt-xl-10 mb-5"> */}
                  <div>
                    <div className="card-body p-2">
                      <div className="text-center">
                        <Link to="/">
                          {" "}
                          <img
                            className="mb-3"
                            src="assets/img/icons/background.svg"
                            alt="..."
                            style={{ height: "48px" }}
                          />
                        </Link>
                        <h1 className="display-5 mb-0">Create Admin Account</h1>
                        <br/>
                      </div>

                           {/* Stepper */}
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>   

         {/* Form sections */}                        <br></br><br></br>

         <div className="form-section">
          {activeStep === 0 && (

                       <form onSubmit={(e) => rsubmit(e)}>
                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label for="username">Email <span style={{color:"red"}}>* </span>
                               </label>
                              <input
                                className="form-control"
                                id="username"
                                type="email"
                                placeholder="username"
                                value={admindata.username}
                                onChange={(e) => rhandle(e)}
                                required
                              />
                            </div>
                          </div>
                        
                          <br></br>
                         <div className="form-group d-flex  justify-content-end">
<input
  className="btn btn-primary"
  type="button"
  onClick={handleNext}
  value="Next ->"
/>
</div>
</form>
)}

       {activeStep ===1 && (
       <form onSubmit={(e) => rsubmit(e)}>

                          <img
                    className="img-fluid circular-image mb-1"
                    id="image"
                    src={`${admindata?.imageMeta},${admindata?.logo}`}
                    style={{
                      width: "100px",
                      height: "100px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      justifyItems: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                    }}
                  />
                      <div className="caption fst-italic text-muted mb-2">
                        JPG or PNG no larger than 5 MB
                      </div>
                      <input
                        className="col-7"
                        type="file"
                        id="image"
                        onChange={(event) => {
                          console.log(event.target.files[0]);
                          //setProfile({...profile, image: event.target.files[0] });
                          handleImageChange(event)
                        }}
                        accept="image/*"
                      />
<br/><br/>
                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label for="organisationname">
                                Organization Name <span style={{color:"red"}}>* </span>
                              </label>
                              <input
                                className="form-control"
                                id="name"
                                type="text"
                                placeholder="enter organization name"
                                value={admindata.name}
                                onChange={(e) => rhandle(e)}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label for="organisationcode">
                                Organisation Code <span style={{color:"red"}}>* </span>
                              </label>
                              <input
                                className="form-control"
                                id="orgcode"
                                type="text"
                                placeholder="enter organization code"
                                value={admindata.orgcode}
                                onChange={(e) => rhandle(e)}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label for="gstin">GSTIN
                              </label>
                              <input
                                className="form-control"
                                id="gstin"
                                type="text"
                                placeholder="enter GSTIN Number"
                                value={admindata.gstin}
                                onChange={(e) => rhandle(e)}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label for="pan">PAN <span style={{color:"red"}}>* </span>
                              </label>
                              <input
                                className="form-control"
                                id="pan"
                                type="text"
                                placeholder="enter PAN number"
                                value={admindata.pan}
                                onChange={(e) => rhandle(e)}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label for="tan">TAN 
                               </label>
                              <input
                                className="form-control"
                                id="tan"
                                type="text"
                                placeholder="enter TAN number"
                                value={admindata.tan}
                                onChange={(e) => rhandle(e)}
                              />
                            </div>
                          </div>

                          <br></br>
                          <div className="form-group d-flex align-items-center justify-content-between">
                          <input
                      className="btn btn-primary"
                      type="button"
                      onClick={handleBack}
                      value="Back"
                    />
                         
                          
<input
  className="btn btn-primary"
  type="button"
  onClick={handleNext}
  value="Next ->"
/>
</div>

                          </form>
      )}
                          {activeStep ===2 && (
       <form onSubmit={(e) => rsubmit(e)}>
                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label for="contact">Phone Number<span style={{color:"red"}}>* </span>
                              </label>
                              <input
                                className="form-control"
                                id="contact"
                                type="number"
                                placeholder="enter phone number"
                                value={admindata.contact}
                                onChange={(e) => rhandle(e)}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label for="addressLine1">
                                Address Line 1 <span style={{color:"red"}}>* </span>
                              </label>
                              <input
                                className="form-control"
                                id="addressLine1"
                                type="text"
                                placeholder="enter address"
                                value={admindata.addressLine1}
                                onChange={(e) => rhandle(e)}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label for="addressLine2">
                                Address Line 2 
                              </label>
                              <input
                                className="form-control"
                                id="addressLine2"
                                type="text"
                                placeholder="enter address"
                                value={admindata.addressLine2}
                                onChange={(e) => rhandle(e)}
                              />
                            </div>
                          </div>

                          
                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label for="city">
                                City <span style={{color:"red"}}>* </span>
                              </label>
                              <input
                                className="form-control"
                                id="city"
                                type="text"
                                placeholder="enter your city"
                                value={admindata.city}
                                onChange={(e) => rhandle(e)}
                              />
                            </div>
                          </div>

                          
                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label for="pincode">
                                Pincode <span style={{color:"red"}}>* </span>
                              </label>
                              <input
                                className="form-control"
                                id="pincode"
                                type="number"
                                placeholder="enter your city pincode"
                                value={admindata.pincode}
                                onChange={(e) => rhandle(e)}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label for="state">
                                State <span style={{color:"red"}}>* </span>
                              </label>
                              <input
                                className="form-control"
                                id="state"
                                type="text"
                                placeholder="enter state"
                                value={admindata.state}
                                onChange={(e) => rhandle(e)}
                              />
                            </div>
                          </div>    
                          <br/>      
                                    
                  <div className="form-group d-flex align-items-center justify-content-between">
                      <input
                      className="btn btn-primary"
                      type="button"
                      onClick={handleBack}
                      value="Back"
                    />
  
                      <input
                      className="btn btn-primary"
                      type="submit"
                      value="Create"
                    />
                    </div>
        </form>
      )}
      
      </div>
                    </div>
                  </div>
                </div>
              </div>
              </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
}
export default CreateAdminAccount;
