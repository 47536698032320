import AdminDashboard from "./admindashboard";
import Sidebar from "./adminsidebar";
import TransactionIncoming from "./transactionincomingdetails";
import TransactionOutgoing from "./TransactionOutgoingDetails";

function AdminTransactionoutgoing() {
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <div>
        <TransactionOutgoing />
      </div>
    </div>
  );
}
export default AdminTransactionoutgoing;
