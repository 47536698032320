import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import { FaRegUserCircle } from "react-icons/fa";

function Logo() {
  const [userProfileDetails, setUserProfileDetails] = useState(null); // Declare and initialize userProfileDetails
  const [userProfileDetails1, setUserProfileDetails1] = useState(null); // Declare and initialize userProfileDetails

  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({
    id:"",
    companyName:"",
    companyLogo: null,
    imageMeta: "",
  });
  const [profile1, setProfile1] = useState({
    id:"",
    logo: null,
    imageMeta: "",
  });

  useEffect(() => {
    setLoading(true);
    
    const fetchProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/companydetails`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userProfileDetails = response.data[0];
        setProfile({
          ...profile,
          ...userProfileDetails
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching company details:", error);
      }
    };
  
    fetchProfile();
  }, []);
  useEffect(() => {
    setLoading(true);
    
    const fetchProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/printlogo`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userProfileDetails1 = response.data[0];
        setProfile1({
          ...profile1,
          ...userProfileDetails1
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching company details:", error);
      }
    };
  
    fetchProfile();
  }, []);
  
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const [metadata, content] = reader.result.split(",");
        resolve({ metadata, content });
      };
      reader.onerror = error => reject(error);
    });
  }

 
  const handleImageChange=async(event)=>{
    const convertedImage= await getBase64FromFile(event.target.files[0]);
    const imageObject = {...profile,
     imageMeta: convertedImage.metadata,
     companyLogo: convertedImage.content,};
          setProfile(imageObject);
          
          
  }
  const handleImageChange1=async(event)=>{
    const convertedImage= await getBase64FromFile(event.target.files[0]);
    const imageObject = {...profile1,
     imageMeta: convertedImage.metadata,
     logo: convertedImage.content,};
          setProfile1(imageObject);
          
          
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const requestBody = {
        id: profile.id, 
        companyLogo: profile.companyLogo,
        imageMeta: profile.imageMeta,
        companyName: profile.companyName,
      };
      await axios.post(`/api/companydetails/create-or-update`, requestBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Logo Updated Successfully");
      setLoading(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error: Failed to update profile");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userProfileDetails) {
      setProfile({
        companyName:userProfileDetails.companyName,
        companyLogo: userProfileDetails.companyLogo || null,
        imageMeta: userProfileDetails.imageMeta || "",
       
      });
    }
  }, [userProfileDetails]);
  const handleSubmit1 = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const requestBody = {
        id: profile1.id, 
        logo: profile1.logo,
        imageMeta: profile1.imageMeta,
      };
      await axios.post(`/api/printlogo/create-or-update`, requestBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Logo Updated Successfully");
      setLoading(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error: Failed to update profile");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userProfileDetails1) {
      setProfile1({
        logo: userProfileDetails1.logo || null,
        imageMeta: userProfileDetails1.imageMeta || "",
       
      });
    }
  }, [userProfileDetails1]);
  const handleChange = (event, field) => {
    let actualValue = event.target.value;

    setProfile({ ...profile, [field]: actualValue });
    
  };
  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <div className="col-12 mx-xl-auto ps-xl-10 mt-4">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex">
              <div className="card-title">Company Details</div>
            </div>
            <br />
            {loading && <div className="loading"></div>}
            <form onSubmit={handleSubmit}>
          <div className="d-flex flex-column">
            <div className="form-floating-sm mb-2">
              <label htmlFor="companyName">Company Name :</label>
              <input
                className="form-control"
                id="companyName"
                type="text"
                value={profile.companyName}
                onChange={(e) => handleChange(e, "companyName")}
                style={{ width: '200px' }}
              />
            </div>
            <div className="form-floating-sm mb-2">
              <label htmlFor="companyLogo">VM Logo:</label>
              <div className="d-flex align-items-center">
                {profile.companyLogo ? (
                  <img
                    className="img-fluid circular-image mb-1"
                    id="companyLogo"
                    src={`${profile.imageMeta},${profile.companyLogo}`}
                    alt="Company Logo"
                    style={{
                      width: "100px",
                      height: "50px",
                      background: "#ccc",
                    }}
                  />
                ) : (
                  <div
                    className="img-fluid circular-image mb-1"
                    id="companyLogo"
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                      background: "#ccc",
                    }}
                  ></div>
                )}
                <input
                  className="col-7 mt-2"
                  type="file"
                  id="companyLogo"
                  onChange={handleImageChange}
                  accept="image/*"
                />
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-center">
            <input
              type="submit"
              value="Save Changes"
              className="btn btn-primary mt-3"
              style={{marginRight: "85%"}}
            />
          </div>
        </form>
       
        <form onSubmit={handleSubmit1}>
          <div   className="d-flex flex-column">
           
            <div  className="form-floating-sm mb-2">
              {/* <label htmlFor="'logo">Print Logo:</label> */}
              <label htmlFor="logo" style={{ position: 'absolute', top: '30px', right: '300px' }}>Print Logo:</label>
              <br/>

              
              <div className="d-flex align-items-center" style={{ position: 'absolute', top: '60px', right: '0' }}  >
                {profile1.logo ? (
                  <img
                    className="img-fluid circular-image mb-1"
                    id="logo"
                    src={`${profile1.imageMeta},${profile1.logo}`}
                    alt=" Logo"
                    style={{
                      width: "100px",
                      height: "50px",
                      background: "#ccc",
                    }}
                  />
                ) : (
                  <div
                    className="img-fluid circular-image mb-1"
                    id="logo"
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                      background: "#ccc",
                    }}
                  ></div>
                )}
                <input
                  className="col-7 mt-2"
                  type="file"
                  id="logo"
                  onChange={handleImageChange1}
                  accept="image/*"
                />
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-center">
            <input
              type="submit"
              value="Save Changes"
              className="btn btn-primary mt-3"
              style={{ position: 'absolute', top: '120px', right: '220px' }}            />
          </div>
        </form>
      </div>
    </div>
  </div>
  <br />
    </div>
  );
}

export default Logo;