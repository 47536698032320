import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";
import { Modal,Button } from "react-bootstrap";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let params = useParams();

    return <Component {...props} params={params} />;
  }
  return ComponentWithRouterProp;
}
function Exemptedcustomeredit({ isOpen, onRequestClose, Id }) {

  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const id=useParams();
  const exemptedid=id.id;
  const [exemptedcustomercategory2, setexemptedcustomercategory2] = useState({
    id:0,
    orgcode:"",
    customername: "",
    customercategory: "",
    distance: "",
    tariff: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    customerNumber:""
  });
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tariffFreight, setTariffFreight] = useState(null);

  const navigate = useNavigate();
  const getData = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    try {
      const res = await axios.get(`/api/exemptedcustomer/${Id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setexemptedcustomercategory2(res.data);

      const tariffFreight = await axios.get(
        `/api/tariffFrieght/last-updated/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTariffFreight(tariffFreight.data);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  useEffect(() => {
    getData();
    setLoader(true);
  }, []);

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        "/api/exemptedcustomer/update",
        exemptedcustomercategory2,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("updated successfully");
        navigate("/users/exemptedcustomer");
        onRequestClose();
      })

      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  }

  const rhandle = (event, field) => {
    let actualValue = event.target.value;

    setexemptedcustomercategory2((prevState) => {
      const updatedState = { ...prevState, [field]: actualValue };
      if (field === "distance" && tariffFreight) {
        let calculatedTariff = actualValue * tariffFreight.tariff;
        updatedState.tariff = (Math.ceil(calculatedTariff * 100) / 100).toFixed(2);
      }
      return updatedState;
    });
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } 
    else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {loader && <div className="loading"></div>}


            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                  {/* <label htmlFor="productname">Id</label>

                  <input
                    
                    class="form-control"
                    id="id"
                    type="text"
                    placeholder="id"
                    value={exemptedcustomercategory2.id}
                    readOnly
                  />
                  <br /> */}
                  <label htmlFor="productname">Customer Name</label>

                  <input
                    
                    class="form-control"
                    id="customername"
                    type="text"
                    placeholder="customername"
                    value={exemptedcustomercategory2.customername}
                    onChange={(e) => rhandle(e, "customername")}

                  />
                  <br />
                  <label htmlFor="productname">Category Name</label>

                 
                 
                  <input
                      class="form-control"
                      id="customercategory"
                      type="text"
                      placeholder="customercategory"
                      value={exemptedcustomercategory2.customercategory}
                      onChange={(e) => rhandle(e, "customercategory")}

                    />
                    <br />
                    <label htmlFor="customernumber">Customer Number</label>

                    <input
                      
                      class="form-control"
                      id="customerNumber"
                      type="text"
                      placeholder="Customer Number"
                      value={exemptedcustomercategory2.customerNumber}
                      onChange={(e) => rhandle(e, "customerNumber")}

                    />
                    <br />
                    
                    <label htmlFor="productname">Distance</label>

                 
                 
                  <input
                      class="form-control"
                      id="distance"
                      type="text"
                      placeholder="distance"
                      value={exemptedcustomercategory2.distance}
                      onChange={(e) => rhandle(e, "distance")}

                    />
                    <br />
                   
                    <label htmlFor="productname">Tariff</label>
                      <input
                      class="form-control"
                      id="tariff"
                      type="text"
                      placeholder="0"
                      value={exemptedcustomercategory2.tariff}
                      // onChange={(e) => rhandle(e, "tariff")}

                    />
  <br />
                    <input
                      type="submit"
                      value="Update"
                      className="btn btn-primary"
                    />
                     &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                  </form>
                </div>
              </div>
            </div>

         </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}

export default Exemptedcustomeredit;
