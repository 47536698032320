import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
function Openstock() {
  const [openstock, setOpenstock] = useState([]);

  const url = "/api/Openingstock/orgcode";

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios.get(
      `/api/Openingstock/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(trans);

    setOpenstock(trans.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br /> <br />
      <div className="col-10  mx-xl-auto ps-xl-10">
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="card-title">Openstock Details</div>
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr>
                  <th>productid</th>
                  <th>orgcode</th>
                  <th>datetime</th>
                  <th>quantity</th>
                  <th>status</th>
                </tr>
              </thead>
              <tbody>
                {openstock.map((openstock) => {
                  return (
                    <tr key={openstock.id}>
                      <td>{openstock.productid}</td>
                      <td>{openstock.orgcode}</td>
                      <td>{openstock.datetime}</td>
                      <td>{openstock.quantity}</td>
                      <td>{openstock.status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Openstock;
