import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AdminDashboard from "../components/admindashboard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import img from "../images/report.png";

function TransactionIncomingReport() {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [vehicleFilter, setVehicleFilter] = useState("");
  const [products, setProducts] = useState([]);
  const [productFilter, setProductFilter] = useState("");

  // Fetch vehicles from API
  const fetchVehicles = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;

    try {
      const response = await axios.get(`/api/vehicle/orgid=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const vehiclesData = response.data.map((vehicle) => ({
        id: vehicle.id,
        truckinfo: vehicle.truckinfo,
        licenceNo: vehicle.licenceNo,
      }));

      setVehicles(vehiclesData);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch vehicles");
    }
  };

  // Fetch products from API
  const fetchProducts = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;

    try {
      const response = await axios.get(`/api/product`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const productsData = response.data.map((product) => ({
        id: product.id,
        productname: product.productname,
      }));

      setProducts(productsData);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch products");
    }
  };

  // Fetch data from API
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;
    const orgcode = JSON.parse(localStorage.getItem("data"))?.orgcode;

    setLoading(true);

    try {
      const response = await axios.get(
        `/api/incomingtransactions/orgcode=${orgcode}/vehicle=${vehicleFilter}/productname=${productFilter}/${month.getMonth() + 1}/${year}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("Full API Response:", response); // Log entire response
      console.log("Data:", response.data.data); // Log data

      setReportData(response.data.data || {});
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  // Generate PDF report
  const generatePDF = () => {
    if (reportData.length === 0) {
      toast.error("No data available to generate PDF.");
      return;
    }

    try {
      const doc = new jsPDF();

      // Load the image asynchronously
      const imgData = new Image();
      imgData.src = img;

      imgData.onload = () => {
        // Add logo to the left side
        const logoWidth = 70;
        const logoHeight = 40;
        const logoX = 10;
        const logoY = 10;
        doc.addImage(imgData, "PNG", logoX, logoY, logoWidth, logoHeight);

        // Add address and company name to the right side
        doc.setFontSize(18);
        doc.setTextColor(86, 150, 214);
        const textX = doc.internal.pageSize.width - 10; // Position on the right side
        const textY = logoY + 10;
        doc.text("Vijay Gas Agencies", textX, textY, { align: "right" });

        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        const addressText = "1010/27 Mysore Road, Gundlupete - 571111";
        const addressY = textY + 10;
        doc.text(addressText, textX, addressY, { align: "right" });

        // Prepare table data
        const tableData = reportData.map((item) => [
          item.date,
          item.quantity,
          item.onlinepay || 0,
          item.neworg || 0,
          item.exempted || 0,
          item.additional || 0,
          item.returnedproduct || 0,
          item.transfer || 0,
        ]);

        // Generate the table in the PDF
        doc.autoTable({
          startY: addressY + 20, // Start the table after the address
          head: [
            [
              "Date",
              "Quantity",
              "Online Pay",
              "New Org",
              "Exempted",
              "Additional",
              "Returned Product",
              "Transfer",
            ],
          ],
          body: tableData,
          theme: "striped",
          styles: { overflow: "linebreak" },
        });

        // Save the generated PDF
        doc.save("transaction_incoming_report.pdf");
      };

      imgData.onerror = () => {
        toast.error("Failed to load logo image.");
      };
    } catch (error) {
      console.error("Error generating PDF: ", error);
      toast.error("Failed to generate report.");
    }
  };

  const handleMonthChange = (date) => {
    setMonth(date);
    if (date) {
      setYear(date.getFullYear());
    }
  };

  const handleVehicleChange = (event) => {
    setVehicleFilter(event.target.value);
  };

  const handleProductChange = (event) => {
    setProductFilter(event.target.value);
  };

  useEffect(() => {
    fetchVehicles(); // Fetch vehicles on component mount
    fetchProducts(); // Fetch products on component mount
  }, []);

  useEffect(() => {
    if (month && year && vehicleFilter && productFilter) {
      getData();
    }
  }, [month, year, vehicleFilter, productFilter]);

  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <div className="container mt-4">
        <h3>Transaction Incoming Report</h3>
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex justify-content-center align-items-center">
              <img className="top-bar__logo" src={img} alt="logo" />
              <div className="ml-3">
                <h3 className="report-title">Vijay Gas Agencies</h3>
                <p className="report-address">
                  1010/27 Mysore Road, Gundlupete
                </p>
              </div>
            </div>
            <br />
            <div className="form-group">
              <label>Vehicle:</label>
              <select
                className="form-control"
                value={vehicleFilter}
                onChange={handleVehicleChange}
              >
               <option value="">Select Vehicle</option>
                      {vehicles.map((vehicle) => (
                        <option key={vehicle.id} value={vehicle.truckinfo}>
                          {vehicle.truckinfo || vehicle.licenceNo}
                        </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Product:</label>
              <select
                className="form-control"
                value={productFilter}
                onChange={handleProductChange}
              >
                <option value="">Select Product</option>
                {products.map((product) => (
                  <option key={product.id} value={product.productname}>
                    {product.productname}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Month:</label>
              <DatePicker
                selected={month}
                onChange={handleMonthChange}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                className="form-control"
              />
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={getData}
              disabled={loading || !vehicleFilter || !productFilter}
            >
              {loading ? "Loading..." : "Generate Report"}
            </button>
            {reportData.length > 0 && (
              <button
                className="btn btn-success mt-3 ml-2"
                onClick={generatePDF}
              >
                Download PDF
              </button>
            )}
            <br />
            <br />
            {reportData.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Quantity</th>
                      <th>Online Pay</th>
                      <th>New Org</th>
                      <th>Exempted</th>
                      <th>Additional</th>
                      <th>Returned Product</th>
                      <th>Transfer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.date}</td>
                        <td>{item.quantity}</td>
                        <td>{item.onlinepay || 0}</td>
                        <td>{item.neworg || 0}</td>
                        <td>{item.exempted || 0}</td>
                        <td>{item.additional || 0}</td>
                        <td>{item.returnedproduct || 0}</td>
                        <td>{item.transfer || 0}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No data available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionIncomingReport;
