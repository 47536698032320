import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import "./Spinner.css";
import PriceHistoryModal from "./PriceHistoryModal";
import $ from 'jquery';
import ProductdetailsModal from "./updateproducts";
import ProducteditModalPopup from "./productedit";
import { FiInfo } from "react-icons/fi";
import AdditionalPriceHistoryModal from "./additionalPriceHistoryModal";
import NewConPriceHistoryModal from "./newConPriceHistoryModal";
import ProductPriceUpdate from "./productPriceUpdate";
import { Modal, Button } from "react-bootstrap";

const Superproduct= ({ isOpen, onRequestClose, typeId}) => {
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [priceHistory, setPriceHistory] = useState([]); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [isProductPriceUpdateModal, setIsProductPriceUpdateModal] = useState(false);
  const [isPriceHistoryModalOpen, setIsPriceHistoryModalOpen] = useState(false);
  const [isAdditionalPriceHistoryModalOpen, setIsAdditionalPriceHistoryModalOpen] = useState(false);
  const [isNewConPriceHistoryModalOpen, setIsNewConPriceHistoryModalOpen] = useState(false);
  const [additionalPriceHistory, setAdditionalPriceHistory] = useState([]); 
  const [newConPriceHistory, setNewConPriceHistory] = useState([]); 
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
 

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const superProductUrl = `/api/super-products/productstatus=true/producttypeid=${typeId}`;

      const [ superProductResponse] = await Promise.all([
       
        axios.get(superProductUrl, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      const standardizedSuperProducts = superProductResponse.data.map((sp) => ({
        productid: sp.id,
        productType: sp.proType.productType,
        productname: sp.productName,
        productcode: sp.productCode,
        hsnCode: sp.hsnCode,
        gst: sp.gst,
        netWeight: sp.netWeight,
        image:sp.image,
        imageMeta: sp.imageMeta,
       productTypeId: sp.productTypeId,
        productstatus: sp.productStatus,
        isSuperProduct: true,
      }));

      const mergedProducts = [ ...standardizedSuperProducts];
      setProduct(mergedProducts);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };
  useEffect(() => {
    getData();
  }, [isModalOpen]);
 
  const onClickhandler = async (e, id) => {
    setLoading(id);
    const checked = e.target.checked;
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      await axios.get(`/api/product/productUpdate/id=${id}/value=${checked}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(null);
      toast.success("Product status updated");
      getData();

    } catch (error) {
      handleError(error);
    }
  };

  const handleProductEdit = async (productId) => {
    setSelectedProductId(productId);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/ProductPrice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPriceHistory(response.data);
      setIsProductEditModalOpen(true);
    } catch (error) {
      handleError(error);
    }
  };
 
  const closeProductEditModal = () => {
    setIsProductEditModalOpen(false);
    getData();
 
  };

  const handleProductPriceUpdate = async (productId) => {
    setSelectedProductId(productId);
   setIsProductPriceUpdateModal(true);
  };
 
  const closeProductPriceUpdateModal = () => {
    setIsProductPriceUpdateModal(false);
    getData();
 
  };
 
  const openPriceHistoryModal = async (productId) => {
    setSelectedProductId(productId);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/ProductPrice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsPriceHistoryModalOpen(true);
  };
 
  const closePriceHistoryModal = () => {
    setSelectedProductId(null);
    setIsPriceHistoryModalOpen(false);
  };

  const openAdditionalPriceHistoryModal = async (productId) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/additionalprice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdditionalPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsAdditionalPriceHistoryModalOpen(true);
  };
 
  const closeAdditionalPriceHistoryModal = () => {
    setIsAdditionalPriceHistoryModalOpen(false);
  };

  const openNewConPriceHistoryModal = async (productId) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/newconnectionprice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setNewConPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsNewConPriceHistoryModalOpen(true);
  };
 
  const closeNewConPriceHistoryModal = () => {
    setIsNewConPriceHistoryModalOpen(false);
  };

  const handleAddProduct = () => {
    setIsModalOpen(true);
    setProduct([]);


  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleCreateAdminProduct = async (products) => {
    try {
      console.log('Function called with products:', products); // Debug log
    
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const date = new Date();
      date.setDate(date.getDate());
      let day = date.getDate().toString().padStart(2, "0");
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let year = date.getFullYear();
      let currentDate = `${year}-${month}-${day}`;  
  
      // Constructing the payload as a list of products with all required fields
      const productEntities = products.map(product => ({
        id: "",
        productId: product.productid,
        orgcode: orgCode,
        productname: product.productName,
        productcode: product.productCode,
        mfddate: product.mfddate || null,
        expdate: product.expdate || null,
        productstatus: product.productStatus || false,
        categoriesid: product.categoriesid || null,
        subcategoriesid: product.subcategoriesid || null,
        quantity: product.quantity || null,
        date: currentDate,
        netWeight: product.netWeight,
        productTypeId: product.productTypeId,
        hsnCode: product.hsnCode,
        gst: product.gst,
        image:product.image || null,
        imageMeta:product.imageMeta || null,
      }));
  
      console.log('Payload:', productEntities); // Debug log to check the payload
  
      await axios.post(
        `/api/product/create-admin-product`,
        productEntities,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success(" product Added successfully To   Your Account");
      setSelectedProducts(prevSelected => [...prevSelected, ...products.map(p => p.productid)]);
      console.log('Selected Products:', selectedProducts);

    } catch (error) {
      if (error.response && error.response.status === 500) {
        if (error.response.data && error.response.data.message.includes("Product Already Exist For this Account")) {
          toast.error("Product Already Exist For this Account");
        } else {
          toast.error("Product Already Exist For this Account");
        }
      } else {
      }
    }
  };
    
    

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Admin Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
  
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                   <th>Product Type</th>

                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>HSN Code</th>
                  <th>GST</th>
                  <th>Net weight</th>
                  {/* <th>Sales Price</th> 
                  <th>Additional Price</th> 
                  <th>New Connection Price</th> 
                  <th>Edit</th> */}
                   <th>Status</th>
                  {/* <th>Action</th> */}
                  <th>Action</th>


                </tr>
              </thead>
              <tbody>
                {product.length === 0 ? (
                  <tr>
                    <td colSpan="13" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  product.map((product) => {
                    
                    const isSuperProduct = product.isSuperProduct === true;
                    const isSelected = selectedProducts.includes(product.productid);
                    console.log('Is Selected:', isSelected);

                    return (
                      <tr key={product.productid}>
                        <td>{product.productType}</td>
                        
                        <td>
  <div style={{ textAlign: "center" }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {product.image && product.imageMeta ? (
        <img
          className="img-fluid rounded-circle mb-1"
          src={`data:image/png;base64,${product.image}`}
          alt="Product"
          style={{ maxWidth: "40px", maxHeight: "60px", marginRight: '10px' }}
        />
      ) : (
        <div style={{ width: "50px", height: "70px", marginRight: '10px' }}></div>
      )}
      <span style={{ marginRight: '10px' }}>{product.productname},{product.productName}</span>
     
    </div>
  </div>
</td>
                         
                        <td>
                          {product.productcode},{product.productCode}
                        </td>
                        <td>{product.hsnCode}</td>
                        <td>{product?.gst} %</td>
                        <td>{product.netWeight} Kg</td>
                        {/* <td>
                          {product.pprice?.price}
                          <span style={{ marginLeft: "40px" }}>
                            <FiInfo
                              onClick={() => openPriceHistoryModal(product.productid)}
                            />
                          </span>
                        </td> */}
                        {/* <td>
                          {product.addPrice?.additionalPrice}
                          <span style={{ marginLeft: "40px" }}>
                            <FiInfo
                              onClick={() =>
                                openAdditionalPriceHistoryModal(product.productid)
                              }
                            />
                          </span>
                        </td> */}
                        {/* <td>
                          {product.newConPrice?.newConPrice}
                          <span style={{ marginLeft: "40px" }}>
                            <FiInfo
                              onClick={() =>
                                openNewConPriceHistoryModal(product.productid)
                              }
                            />
                          </span>
                        </td> */}
                        {/* <td>
                          <button
                            onClick={() => handleProductEdit(product.productid)}
                            style={{
                              border: "none",
                              background: "white",
                              size: "150px",
                            }}
                          >
                            <BiEditAlt />
                          </button>
                        </td> */}
                        <td>
                          <div className="form-check form-switch">
                            {loading == product.productid && (
                              <div
                                className="loader"
                                style={{
                                  position: "relative",
                                  left: "55px",
                                  top: "8px",
                                }}
                              ></div>
                            )}
                            <input
                              className="form-check-input"
                              id="flexSwitchCheckDefault"
                              type="checkbox"
                              defaultChecked={product.productstatus}
                              onClick={(e) => onClickhandler(e, product.productid)}
                              value={product.productid}
                            />
                          </div>
                        </td>
                        {/* <td>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleProductPriceUpdate(product.productid)}
                          >
                            Update Price
                          </button>
                        </td> */}
                        <td>
                        {isSuperProduct && !isSelected && (

    <button
      className="btn btn-success btn-sm" style={{fontSize:"8px"}}
      onClick={() => handleCreateAdminProduct([{
        productId: product.productid,
        productName: product.productname,
        productCode: product.productcode,
        mfddate: product.mfddate,
        expdate: product.expdate,
        productStatus: product.productstatus,
        categoriesid: product.categoriesid,
        subcategoriesid: product.subcategoriesid,
        quantity: product.quantity,
        netWeight: product.netWeight,
        productTypeId: product.productTypeId,
        hsnCode: product.hsnCode,
        gst: product.gst,
        image:product.image ,
        imageMeta:product.imageMeta ,
      }])}
    >
      Select Product
    </button>
  )}
    {isSelected && (
                            <span>Product already selected</span>
                          )}
</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            {/* <Modal.Footer> */}
          <Button variant="secondary" onClick={onRequestClose}>
            Close
          </Button>
        {/* </Modal.Footer> */}
            <div>
           
            {isProductEditModalOpen && (
              <ProducteditModalPopup
                isOpen={isProductEditModalOpen}
                onRequestClose={closeProductEditModal}
                productId={selectedProductId}
              />
            )}
            {isModalOpen && (
           <ProductdetailsModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
            )}
       </div>
      
      </Modal.Body>
    </Modal> 
  );
}

export default Superproduct;
