import React, { useEffect, useState } from "react";
import Dash from "./dash";
import { useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Spinner.css";

import { Pagination, Form } from "react-bootstrap";
import SubModule from "./Subscriptionmodule";
import ConfirmationPage from "./SubModelDeleteCnfm";
import AdminDashboard from "./admindashboard";
import { MdDeleteForever, MdEdit } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
function Supersubscriptiondetails() {
  const [checkedmap, setCheckedmap] = useState(false);
  const [sub, setSub] = useState([]);
  const [subfeatur, setSubfeatur] = useState([]);
  const [subfeaturmodel, setSubfeaturmodel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCnfmModalOpen, setIsCnfmModalOpen] = useState(false);
  const [subRegions, setSubRegions] = useState([]);
  const [subUsers, setSubUsers] = useState([]);
  const [subModel, setSubModel] = useState("");
  const [selcetedModel, setSelectedModel] = useState("");
  const [selectedModelId, setSelectedModelId] = useState([]);
  const [filterStatus, setFilterStatus] = useState(""); // State variable for filter status
const [filteredSubFeaturModel, setFilteredSubFeaturModel] = useState([]); //

  const user = JSON.parse(localStorage.getItem("data"));
  const url = "/api/subscription-model";

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
try{
    setLoading(true);
    // const sf = await axios.get("/api/subscription-features");

    // console.log(sf.data);

    // setSubfeatur(sf.data);

    const sfmm = await axios.get("/api/SubmodelandfeatureDo", {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(sfmm.data.features);

    setSubfeaturmodel(sfmm.data);
    const prof1 = await axios.get(url);

    console.log(prof1.data);

    setSub(prof1.data);
    setLoading(false);
  }catch(error){
    setLoading(false);
    handleError(error);
  }
  };

  const getSubRegions = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/subscription-region`, { // Pass status as a query parameter
        headers: { Authorization: `Bearer ${token}` },
      });
      setSubRegions(response.data);
    } catch (error) {
      handleError(error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9); // Set default items per page to 9
  const totalPages = Math.ceil(subfeaturmodel.length / itemsPerPage);

  // Calculate the current items to display based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
 // const currentItems = subfeaturmodel.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => setCurrentPage(page);

  useEffect(() => {
    getData();
    getSubRegions();
   
  }, []);
  const handleAddSubModules = () => {
    setIsModalOpen(true);
    setSubModel();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const handleDeleteSubModules = async (modelId) => {
    setSelectedModelId(modelId);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
  
      // Make the first API request
      const subModelResponse = await axios.get(`/api/SubmodelandfeatureDo/modelId=${modelId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSelectedModel(subModelResponse.data);
  
      // Make the second API request
      const subUsersResponse = await axios.get(`/api/user/subscription/modelid=${modelId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSubUsers(subUsersResponse.data);
  
      // Open the confirmation modal
      setIsCnfmModalOpen(true);
    } catch (error) {
       handleError(error);
    }
  };
  

  const closeCnfmModal = () => {
    setIsCnfmModalOpen(false);
    getData();
  };

  const handleSubModelEdit = async (modelId) => {
    setSelectedModelId(modelId);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/SubmodelandfeatureDo/modelId=${modelId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSubModel(response.data);
      setIsModalOpen(true);
    } catch (error) {
      handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

 
  const closeProductEditModal = () => {
    setIsModalOpen(false);
    getData();
 
  };

  const [selectedRegion, setSelectedRegion] = useState("");
  
  const filteredItems = subfeaturmodel.filter(item => {
  return selectedRegion === "" || item.region === selectedRegion;
});
const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  

  return (
    <div>
    <div>
      <AdminDashboard />
    </div>
    <div id="layoutDrawer_content">
      {loading && <div className="loading"></div>} 

      <main>
        <div className="col-11 mx-xl-auto ps-xl-10 mt-10" >
          <div className="card card-raised ">
            <div className="card-body ">
                <div className="container">
                <div className="card-title form-group d-flex align-items-center justify-content-between"> Subscription Model Details
                <button className="btn btn-primary" onClick={handleAddSubModules}>
               ADD
            </button>
            </div>
            <div className="mt-4">
              <div class="single-shorter">
  <label>Filter By Region:</label>
  <select
   value={selectedRegion}
   onChange={(e) => setSelectedRegion(e.target.value)}
  >
    <option value="">All</option>
    {subRegions.map((status) => (
      <option key={status.region} value={status.region}>
        {status.region}
      </option>
    ))}
  </select>
  {/* <button onClick={handleFilter}>Apply Filter</button> */}
</div>

              <div className="row mt-4" style={{ maxWidth: "120%" }}>
  {currentItems.map((subscription, index) => (
    <div className="col-sm-4 mb-4" key={index}>
      <div className="card card-raised h-100">
        <div className="card-body">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  <h2 className="overline">{subscription.submodel}</h2>
  <div>
    <AiFillEdit style={{ marginRight: '20px', cursor: 'pointer' }}  title="Edit Model"  onClick={() => handleSubModelEdit(subscription.id)}/>
    <MdDeleteForever style={{ cursor: 'pointer' }}  title="Delete Model"
    onClick={() => handleDeleteSubModules(subscription.id)}
    />
  </div>
</div>
          <div className="d-flex align-items-center mb-3">
            <div className="display-5">{subscription.cost}</div>
            <div className="text-muted ms-2">
              {subscription.region === "INDIA" && "INR"}
              {subscription.region === "USA" && "USD"}
              &ensp;&nbsp;{subscription.region}
            </div>
          </div>
          <p>For most businesses that want to optimize web queries</p>
          {subscription.features.map((sbf, idx) => (
            <ul className="list-group list-group-flush" key={idx}>
              <li className="list-group-item px-6">
                <div className="d-flex align-items-center">
                  <i className="material-icons icon-xs me-4">check</i>
                  {sbf.features}
                </div>
              </li>
            </ul>
          ))}
        </div>
      </div>
    </div>
  ))}
</div>
</div>
              </div>
            </div>
          </div>
          {isModalOpen && (
          <SubModule 
      isOpen={isModalOpen} 
      onRequestClose={handleCloseModal}
      subModelDetails={subModel}
       />
          )}

          <ConfirmationPage
      isOpen={isCnfmModalOpen} 
      onRequestClose={closeCnfmModal}
      subModelDetails={selcetedModel}
      subUsers={subUsers}
       />
       
    
<br></br>
         {/* Pagination component */}
         <div className="container">
      <div className="row align-items-center justify-content-center mb-3">
        <div className="col-auto">
          {/* Pagination component */}
          <Pagination>
            <Pagination.First onClick={() => handlePageChange(1)} />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} />
          </Pagination>
        </div>
        <div className="col-auto ms-3">
      {/* Items per Page label and select box */}
      <div className="d-flex align-items-center">
        <Form.Label className="me-2 mb-0" style={{ whiteSpace: "nowrap" }}>
          Items Per Page:
        </Form.Label>
        <Form.Control
          as="select"
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(parseInt(e.target.value, 10));
            setCurrentPage(1); 
          }}
        >
            <option value={9}>9</option>
            <option value={12}>12</option>
            <option value={15}>15</option>
          </Form.Control>
        </div>
      </div>
     </div>
  </div>
      </div>
      </main>
      
</div>
    </div>
    
  );
}

export default Supersubscriptiondetails;
