import React, { useState, useEffect, useReducer } from "react";
import "./Loader.css";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { Button } from "react-bootstrap"; 
import { AiFillDelete } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import Exemptedcustomercategoryedit from "./Exemptededit";
import AddExemptedcategory from "./Addexemptedcategory";
import { error } from "jquery";

function Exemptedcustomercategory() {
  
  const token = JSON.parse(localStorage.getItem("data")).token;

  <div className="card card-raised mb-5"></div>;
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModaladdOpen, setIsModaladdOpen] = useState(false);

  const [exemptedcustomercategory, setExemptedcustomercategory] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [exemptedcustomercategory1, setexemptedcustomercategory1] = useState({
    categoryname: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    disable:false,
  });

    const [selectedCategoryFilter, setSelectedCategoryFilter] = useState('');
    const [selectedStatusFilter, setSelectedStatusFilter] = useState('');
    const uniqueCategories = [...new Set(exemptedcustomercategory.map(ecategory => ecategory.categoryname))];
  
    // // Filtered and mapped data for rendering
    // const filteredData = exemptedcustomercategory.filter(ecategory => {
    //   const categoryFilterPass = selectedCategoryFilter === '' || ecategory.categoryname.includes(selectedCategoryFilter);
    //   const statusFilterPass = selectedStatusFilter === '' || (selectedStatusFilter === 'enabled' ? ecategory.actionEnabled : !ecategory.actionEnabled);
    //   return categoryFilterPass && statusFilterPass;
    // });
  
   const [currentPage, setCurrentPage] = useState(1);
   const [itemsPerPage] = useState(10); // Number of items to show per page
 

   const handleFilterChange = () => {
    setCurrentPage(1); 
  };
   const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  
  const handleNextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };
   
 
   const getCurrentItems = () => {
    const filteredData = exemptedcustomercategory.filter(ecategory => {
      const categoryFilterPass = selectedCategoryFilter === '' || ecategory.categoryname.includes(selectedCategoryFilter);
      const statusFilterPass = selectedStatusFilter === '' ||
  (selectedStatusFilter === 'enabled' && !ecategory.disable ) ||
  (selectedStatusFilter === 'disabled' && ecategory.disable );
    return categoryFilterPass && statusFilterPass;
    });
     const indexOfLastItem = currentPage * itemsPerPage;
     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
     return filteredData.slice(indexOfFirstItem, indexOfLastItem);
      
  
   };
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(exemptedcustomercategory.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const [User, setUser] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);


  const navigate = useNavigate();

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setexemptedcustomercategory1({
      ...exemptedcustomercategory1,
      [field]: actualValue,
    });
  };

 
  const getData = async () => {
    setLoader(true);
    const date = new Date();

    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios
    .get(
      `/api/exemptedcustomercategories/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setExemptedcustomercategory(res.data);
        setLoader(false);
      })
      .catch((error)=>{
        setLoader(false);
        handleError(error);
      });
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } 
    else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  useEffect(() => {
    getData();
  }, []);
  function onClickhandler(e, id, disabled) {
    setLoading(id);
    const checked = e.target.checked;
    const token = JSON.parse(localStorage.getItem("data")).token;
    console.log(token);
    axios.post(`/api/exemptedcustomercategories/update`, {
      id: id,
      disable: disabled
    }, {
      headers: { Authorization: `Bearer ${token}` },
    })
   
      .then((response) => {
        setLoading(null);
        toast.success(" status updated");
      })
      .catch((error)=>{
        handleError(error);
      });
  }

  
  const handleEdit = (id) => {
    console.log(id);
    setSelectedCategory(id);
    setIsModalOpen(true);


  };
  const closeExemptedEditModal = () => {
    setIsModalOpen(false);
    getData();
  };
  
  
  const handleDelete = (id) => {
    
    if (window.confirm("Are you sure you want to delete this category?")) {
      axios
        .delete(`/api/exemptedcustomercategories`, {
          data: { id }, 
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Exempted Customer Category deleted");
            getData(); 
          }
        })
        .catch((error) => {
         handleError(error);
        });
    }
   
    
  };
  const handleAddProduct = () => {
    setIsModaladdOpen(true);


  };

  const handleCloseModal = () => {
    setIsModaladdOpen(false);
    getData();
  };

  


  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
     
      {loader && <div className="loading"></div>}
      <div
        className="col-10 mx-xl-auto ps-xl-9 mt-10"
        style={{ position: "relative", left: "100px"}}
      >
        <div className="card card-raised ">
          <div className="card-body ">
          <div className="card-title form-group d-flex align-items-center justify-content-between">Exempted Category List
<button className="btn btn-primary"onClick={handleAddProduct}>
   ADD
</button>
</div>
            <br/>
            <br/>
          
             
<div className="filter-dropdowns">
<div className="row">
<div
        className="col-7">
  <div controlId="categoryFilter">
    <label>FILTER BY CATEGORY </label>
    
    <select
      as="select"
      value={selectedCategoryFilter}
      onChange={(e) =>
        { setSelectedCategoryFilter(e.target.value);

      handleFilterChange(); 
      }}
    >
      <option value="">All</option>
      {uniqueCategories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}

      
    </select>
    </div>

  </div>

  <div
        className="col-4">
  <div controlId="statusFilter">
    <label>FILTER BY STATUS</label>
    
    <select
      as="select"
      value={selectedStatusFilter}
      onChange={(e) => 
        {setSelectedStatusFilter(e.target.value);
        handleFilterChange(); 
      }}
    >
      <option value="">All</option>
      <option value="enabled">Enabled</option>
      <option value="disabled">Disabled</option>
    </select>
   
  </div>
  </div>
  </div>
</div>
<br/>
<br/>

            
            <table
              className="table table-bordered "
              style={{ position: "relative" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "#6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Id</th>

                  <th>Category Name</th>
                  <th>Status</th>
                  <th>Action</th>


                </tr>
              </thead>
              
              <tbody>
              {getCurrentItems().map((ecategory) => {
                  return (
                    <tr key={ecategory.id} 
                      style={{
                        textAlign: "center",
                      }} 
                    >    
                      
                      <td>{ecategory.id}</td>

                      <td>{ecategory.categoryname}</td>
                      <td>
                        <div className="form-check form-switch">
                          {loading == ecategory.id && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={!ecategory.disable}
                            onClick={(e) => onClickhandler(e, ecategory.id, !ecategory.disable)}
                            value={ecategory.id}
                          />
                        </div>
                      </td>

                      
                      <td>
                      <div style={{ display: "flex", justifyContent: "center" }}>

                         {/* Edit button  */}
                         <Button variant="light" style={{ color: "blue" }}  onClick={() => handleEdit(ecategory.id)}>
                            <CiEdit />
                          </Button>{" "}
          

                          <span style={{ margin: "0 5px" }}></span>

                           {/* Delete button  */}
                           <Button variant="light"style={{ color: "black" }} onClick={() => handleDelete(ecategory.id)}>
                            <AiFillDelete />
                          </Button>

                        </div>
                      </td>
                    </tr>
                    
                  );
                })}
              </tbody>
            </table>
             {/* Pagination controls */}
          <nav>
            <ul className="pagination justify-content-center">
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={`page-item ${number === currentPage ? "active" : ""}`}
                >
                 
                </li>
              ))}
            </ul>
          
  <ul className="pagination justify-content-center">
    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
      <button className="page-link" onClick={handlePrevPage}>
        Previous
      </button>
    </li>
    {pageNumbers.map((number) => (
      <li
        key={number}
        className={`page-item ${number === currentPage ? "active" : ""}`}
      >
        <button className="page-link" onClick={() => setCurrentPage(number)}>
          {number}
        </button>
      </li>
    ))}
    <li className={`page-item ${currentPage === pageNumbers.length ? "disabled" : ""}`}>
      <button className="page-link" onClick={handleNextPage}>
        Next
      </button>
    </li>
  </ul>
</nav>

          </div>
        </div>
        <AddExemptedcategory isOpen={isModaladdOpen} onRequestClose={handleCloseModal} />


        {isModalOpen && (
              <Exemptedcustomercategoryedit
                isOpen={isModalOpen}
                onRequestClose={closeExemptedEditModal}
                Id={selectedCategory}
              />
            )}
      </div>
    </div>
  );
}

export default Exemptedcustomercategory;
