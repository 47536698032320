import Pro from "./pro";
import Dashboard from "./managerdashboard";
import Sidebar from "./managerSidebar";
function ManagerProfile() {
  return (
    <div>
      <Dashboard></Dashboard>

      <Pro></Pro>
    </div>
  );
}
export default ManagerProfile;
