import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";

import TransactionOutgoing from "./TransactionOutgoingDetails";
function ManagerTransactionOutgoing() {
  return (
    <div>
      <div>
        <ManagerDashboard />
      </div>
      <div>
        <ManagerSidebar />
      </div>
      <div>
        <TransactionOutgoing />
      </div>
    </div>
  );
}
export default ManagerTransactionOutgoing;
