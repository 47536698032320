import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "../api/baseurl";
import "./Spinner.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./footer";
function ResetPassword() {
  const navigate = useNavigate();
  const url = "/api/resetPassword";
  const [admindata, setAdata] = useState({
    username: "",
    password: "",
    passwordConfirm: "",
  });
  const [loading, setLoading] = useState(false);
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(url, {
        username: admindata.username,
        password: admindata.password,
        passwordConfirm: admindata.passwordConfirm,
      })

      .then(
        (res) => {
          console.log(res.data);
          setLoading(false);
          navigate("/signin");
          toast("Password reset Successfully");
        },
        (error) => {
          this.setState({ error });
        }
      );
  }

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setAdata({ ...admindata, [field]: actualValue });
  };
  return (
    <div className="bg-pattern-waihou">
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-lg-4">
                  <div className="card card-raised shadow-10 mt-5 mt-xl-10 mb-4">
                    <div className="row g-0">
                      <div className="col-lg-12 col-lg-12">
                        <div className="card-body p-5">
                          <div className="text-center">
                            <Link to="/">
                              {" "}
                              <img
                                className="mb-3"
                                src="assets/img/icons/background.svg"
                                alt="..."
                                style={{ height: "48px" }}
                              />
                            </Link>
                            <h1 className="display-5 mb-0">ResetPassword</h1>
                          </div>
                          <form onSubmit={(e) => rsubmit(e)}>
                            <div class="form-floating">
                              <input
                                class="form-control"
                                type="email"
                                placeholder="name@example.com"
                                id="username"
                                value={admindata.username}
                                onChange={(e) => handleChange(e, "username")}
                              />
                              <label for="username">username</label>
                            </div>
                            <br />
                            <br />
                            <div class="form-floating">
                              <input
                                class="form-control"
                                type="password"
                                placeholder="name@example.com"
                                id="password"
                                value={admindata.password}
                                onChange={(e) => handleChange(e, "password")}
                              />
                              <label for="new password">new password</label>
                            </div>
                            <br />
                            <br />
                            <div class="form-floating">
                              <input
                                class="form-control"
                                type="password"
                                placeholder="passwordConfirm"
                                id="passwordConfirm"
                                value={admindata.passwordConfirm}
                                onChange={(e) =>
                                  handleChange(e, "passwordConfirm")
                                }
                              />
                              <label for="Confirm Password">
                                Confirm Password
                              </label>
                            </div>

                            <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                              <Link
                                className="small fw-500 text-decoration-none"
                                to="/forgotpassword"
                              >
                                Forgot Password?
                              </Link>

                              <input
                                type="submit"
                                value="ResetPassword"
                                className="btn btn-primary"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mb-5">
                      <Link
                        className="small fw-500 text-decoration-none link-white"
                        to="/register"
                      >
                        Need an account? Sign up!
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div id="layoutAuthentication_footer">
          <Footer/>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default ResetPassword;
