import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
function AddExempteddelivery() {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [product, setProduct] = useState([]);
  const [exemptedcustomercategory, setExemptedcustomercategory] = useState([]);
  const [exemptedcustomercategory1, setexemptedcustomercategory1] =useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const [AddExempteddelivery, setAddExempteddelivery] = useState({
    id: 0,
    customerid: 0,
    customername: "",
    date: "",
    deliveredqty:0,
    orgcode:JSON.parse(localStorage.getItem("data")).orgcode,
    productcode:"",
    productname:"",
    categoryname:"",
    // categoryid:0,
    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const [loading, setLoading] = useState(false);
  const url = "/api/ExemptedDetails/create";
  const navigate = useNavigate();
  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setexemptedcustomercategory1({
      ...exemptedcustomercategory1,
      [field]: actualValue,
    });
  };
  useEffect(() => {

  const getData = async () => {
    try{
      const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios
      .get(
        `/api/product/productstatus=true/orgcode=${AddExempteddelivery.orgid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setProduct(trans.data);
      const res = await axios
      .get("/api/exemptedcustomercategories", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setExemptedcustomercategory(res.data);

        // Extract category names from the response and update categoryOptions state
        const categories = res.data.map(category => category.categoryname);
        setCategoryOptions(categories);
            });
    } catch (error) {
      handleError(error);
    }
  };

  getData();
}, [AddExempteddelivery.orgid]); // Added orgid as a dependency
function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
       [ AddExempteddelivery],

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Exempted Delivery added successfully");
        navigate("/users/Exempteddelivery");
      })

      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  }

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  function rhandle(e) {
    const newdata1 = { ...AddExempteddelivery };
    newdata1[e.target.id] = e.target.value;
    setAddExempteddelivery(newdata1);
  }
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="col-8  mx-xl-auto px-xl-5 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title"> Exempted Delivery Details</div>
            <br />

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    
                    {/* <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="deliveryagentid">Id</label>
                        <input
                          className="form-control"
                          id="id"
                          type="text"
                          placeholder="id"
                          value={AddExempteddelivery.id}
                          onChange={(e) => rhandle(e)}
                          
                        />
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="status"> Customer Id</label>
                        <input
                          className="form-control"
                          id="customerid"
                          type="text"
                          placeholder="customerid"
                          value={AddExempteddelivery.customerid}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="date">Customer Name</label>
                        <input
                          className="form-control"
                          id="customername"
                          type="text"
                          placeholder="customername"
                          value={AddExempteddelivery.customername}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    {/* <div className="form-floating-sm mb-2">
                      <label for="categoryname ">customercategory </label>
                      <select   

                        id="categoryname"
                        type="text"
                        className="form-control"
                        placeholder="categoryname"
                        onChange={(e) => handleChange(e, "categoryname")}
                      >
                        <option>select customercategory</option>
                        {exemptedcustomercategory.map((ec) => {
                          return (
                            <option value={ec.categoryname}>
                              {ec.categoryname}
                            </option>
                          );
                        })}
                      </select>{" "}
                    </div> */}
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                      <label htmlFor="categoryname">Category Name</label>
                      <select
                  className="form-control"
                    id="categoryname"
                value={AddExempteddelivery.categoryname}
                  onChange={(e) => rhandle(e)}
                   >
                 <option value="">Select Category Name</option>
                {categoryOptions.map((category, index) => (
                 <option key={index} value={category}>
                 {category}
                  </option>
                   ))}
                   </select>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="orgcode">Category id</label>
                        <input
                          className="form-control"
                          id="categoryid"
                          type="text"
                          placeholder="categoryid"
                          value={AddExempteddelivery.categoryid}
                          onChange={(e) => rhandle(e)}
                        />
                    </div>
                    </div> */}
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="orgcode">Date</label>
                        <input
                          className="form-control"
                          id="date"
                          type="date"
                          placeholder="date"
                          value={AddExempteddelivery.date}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                  
                   
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="orgcode">Orgcode</label>
                        <input
                          className="form-control"
                          id="orgcode"
                          type="text"
                          placeholder="orgcode"
                          value={AddExempteddelivery.orgcode}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="id">Product</label>
                        <select
                          className="form-control"
                          id="productname"
                          type="text"
                          placeholder="vehicle"
                          value={AddExempteddelivery.productname}
                          onChange={(e) => rhandle(e)}
                        >
                          <option>select product</option>
                          {product.map((pro) => {
                            return (
                              <option value={pro.productname}>
                                {pro.productname}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="id">Product Code</label>
                        <select
                          className="form-control"
                          id="productcode"
                          type="text"
                          placeholder="vehicle"
                          value={AddExempteddelivery.productcode}
                          onChange={(e) => rhandle(e)}
                        >
                          <option>select product</option>
                          {product.map((pro) => {
                            return (
                              <option value={pro.productcode}>
                                {pro.productcode}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                   
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="orgcode">Quantity</label>
                        <input
                          className="form-control"
                          id="deliveredqty"
                          type="text"
                          placeholder="deliveredqty"
                          value={AddExempteddelivery.deliveredqty}
                          onChange={(e) => rhandle(e)}
                        />
                    </div>
                    </div>
                   
                   
                    {/* <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="orgcode">Status</label>
                        <input
                          className="form-control"
                          id="status"
                          type="text"
                          placeholder="status"
                          value={AddExempteddelivery.status}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                  </div> */}
                  

                    <input
                      type="submit"
                      value="SAVE"
                      className="btn btn-primary"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default AddExempteddelivery;
