import React from "react";
import AdminDashboard from "./admindashboard";
import { useNavigate } from "react-router-dom";
import "./Loader.css";
import "./Spinner.css";
import { Button } from "react-bootstrap";

function Refillbutton() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleBack = () => {
    navigate("/users/Superrewardpoints");
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />

      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
            Refill Sales
           

              {/* <button
                className="btn btn-primary"
                onClick={() => handleNavigation("/users/Leaderboard")}
              >
                Organisation
              </button> */}
               <button
                className="btn btn-primary"
                onClick={() => handleNavigation("/users/Supersalesrefill")}
              >
                Sales Area
              </button>
              <button
                className="btn btn-primary"
                onClick={() => handleNavigation("/users/Superrefillsales")}
              >
                Territory
              </button>
              <Button  variant="secondary" onClick={handleBack}>
              Back
            </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Refillbutton;
