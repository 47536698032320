import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate ,useParams} from "react-router-dom";
import "./Spinner.css";
import CreaterecordModal from "./Createrecord";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import SQCleveleditModalPopup from "./sqcleveledit";
import "./Loader.css";
import SqccreaterecordModal from "./sqccreaterecord";

function Sqcedit() {
  const date = new Date();
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [product, setProduct] = useState([]);
  // const [productsqc, setProductsqc] = useState([]);
  // const [hasRecords, setHasRecords] = useState(false);
  const [selectedDate, setSelectedDate] = useState(''); // Define selectedDate state

  const [exemptedcustomercategory, setExemptedcustomercategory] = useState([]);
  const [exemptedcustomercategory1, setexemptedcustomercategory1] =useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  
  const { sqcId } = useParams();
  const [AddExempteddelivery, setAddExempteddelivery] = useState({

    id:"",
    invoiceNumber: "",
    truckNumber: "",
    date: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const onClickhandler = async (e, id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    const vehicles = await axios({
      url: "/api/sqcleveldetails",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
      },
    }).then((res) => {
      toast.success("deleted successfully");
      fetchData();
      setLoading(null);
    });
  };
  useEffect(() => {
    if (sqcId) {
      getData();

    }
  }, [sqcId]);

  const getData = async () => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/sqcform/${sqcId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAddExempteddelivery(response.data);
      setLoading(false);
    } catch (error) {
     setLoading(error);
    }
  };

  const rsubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      await axios.post(
        "/api/sqcform/update",
        AddExempteddelivery,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setLoading(false);
      toast.success("SQC updated successfully");
      
    } catch (error) {
      console.log(error);
    }
  };

  const rhandle = (event, field) => {
    const value = event.target.value;
    setAddExempteddelivery((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  
  const fetchData = async () => {
    try {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;
      const url = `/api/sqcleveldetails/sqcid=${sqcId}`;

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("Response Data:", response.data);
     
      setProduct(response.data);
      setLoading(false);
     
        // const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
        // const url1 = `/api/sqcform/orgcode=${orgCode}/date=${currentDate}`;
        // const response1 = await axios.get(url1, {
        //   headers: { Authorization: `Bearer ${token}` },
        // });
        // setProductsqc(response1.data);
        // setHasRecords(response1.data.length > 0);
       
      

      } catch (error) {
        console.error("Error fetching data:", error);
        // handleError(error);
      }
    };
  
  useEffect(() => {

    fetchData();

  }, []);


  const handlecreaterecord = (sqcId,date) => {
    console.log("Date:", date);
    setSelectedId(sqcId);
    setSelectedDate(date);
    setIsModalOpen(true);

  };

  const handleCloseModal = (sqcId) => {
    setIsModalOpen(false);
    fetchData();
    
  };
  const handleEdit = (sqcId) => {
    setSelectedProductId(sqcId);
    setIsProductEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsProductEditModalOpen(false);
    fetchData();
    
  };
  
  
  
  
  return (
    <div className="attendance-page" style={{marginTop:'100px'}}>
    <div className="dashboard-container">
        <AdminDashboard />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div >
      <div className="row">

<div className="col-md-9">
      <div className="col-12 mx-xl-auto px-xl-30 "
      style={{ position: 'relative' , left: '10px' }}
      >
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title">Update SQC Details</div>
            <br />

            <div className="col-xl-10 mx-xl-auto">
              <div>
                <div>
                  <form onSubmit={rsubmit}>
                  <div className="row">
                <div className="form-floating-sm mb-2">
                  <label htmlFor="productname">SQC Number</label>
                  <input
                    className="form-control"
                    id="id"
                    type="text"
                    placeholder="id"
                    value={AddExempteddelivery.id}
                    readOnly
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="invoiceNumber">Invoice Number</label>
                  <input
                    className="form-control"
                    id="invoiceNumber"
                    type="text"
                    placeholder="invoiceNumber"
                    value={AddExempteddelivery.invoiceNumber}
                    onChange={(e) => rhandle(e, "invoiceNumber")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="date">Date</label>
                  <input
                    className="form-control"
                    id="date"
                    type="text"
                    placeholder="date"
                    value={AddExempteddelivery.date}
                    onChange={(e) => rhandle(e, "date")}
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="truckNumber">Truck Number</label>
                  <input
                    className="form-control"
                    id="truckNumber"
                    type="text"
                    placeholder="truckNumber"
                    value={AddExempteddelivery.truckNumber}
                    onChange={(e) => rhandle(e, "truckNumber")}
                  />
                  </div>
              </div>
                    <br/>
                    <input
                      type="submit"
                      value="SAVE"
                      className="btn btn-primary"
                    />
                     &nbsp;
             
             
                  </form>
                  
        
                    <button className="btn btn-primary float-end" onClick={() => handlecreaterecord(AddExempteddelivery.id,AddExempteddelivery.date)}>
                    Create Record
  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && (
        <SqccreaterecordModal isOpen={isModalOpen} onRequestClose={handleCloseModal}  sqcFormId={sqcId}  sqcDate={selectedDate}
 />
        )}

      </div>{" "}
      </div>
      </div>
      
      {loading && <div className="loading"></div>}

      <br />
      <br/>
      <br />
      <div>
      <div >
      <div className="col-12 mx-xl-auto px-xl-30 " >
      <div className="card card-raised">
          <div className="card-body">
        <div className="d-flex">
          <div className="card-title">Create record Details</div>
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


              
        </div>
        <br />
<div className="table-responsive">
            <table className="table table-bordered horizontal-table" >      
                  <thead>
            <tr
             style={{
              fontSize: "17px",
              backgroundColor: "blue",
              color: "white",
            }}>
              <th>SQC Id</th>
              <th>Product Name</th>
              <th>Product Code</th>
              <th>Serial Number</th>
              <th>Seal Intact</th>
              {/* <th>Date</th> */}
              <th>Gross weight</th>            
              <th>Tare Weight</th>
              <th>Remarks</th>
              <th>Returned product</th>
              <th>Erv Number</th>
               <th>Erv Date</th>
               <th>Actual Weight in kg</th>
               <th>Variation in grams</th>
               <th>Action</th>




            </tr>
          </thead>
          <tbody>
            {product.length === 0 ? (
              <tr>
                <td colSpan="15" className="text-center">
                  No Data Available
                </td>
              </tr>
            ) : (
              product.map((product) => {
                return (
                  <tr >
                    <td>{product.sqcId}</td>
                    <td>{product.productName}</td>
                    <td>{product.productCode}</td>
                    <td>{product.serialNumber}</td>
                    <td>{product.sealIntact}</td>
                    {/* <td>{product.date}</td> */}
                    <td>{product.grossWeight}</td>
                    <td>{product.tareWeight}</td>
                    <td>{product.remarks}</td>
                    <td>{product.productReturned}</td>
                    <td>{product.ervNumber}</td>

                    <td>{product.ervDate}</td>
                    <td>{product.actualWeight}</td>

                    <td>{product.difference}</td>

                    <td>
                     
                     <button
                      onClick={() => handleEdit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     
                        <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              product.id
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                        {loading == product.id && (
                          <div
                            className="loader"
                            style={{
                              position: "relative",
                              left: "40px",
                              top: "8px",
                            }}
                          ></div>
                        )}{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                         <button
              className="btn btn-primary"
              disabled={product.freeze}
              style={{ backgroundColor: product.freeze ? "red" : "green" }}
            >
              Freeze
            </button>

                   </td>
                   
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        </div>
        </div>
        </div>
      </div>
      {isProductEditModalOpen && (
              <SQCleveleditModalPopup
                isOpen={isProductEditModalOpen}
                onRequestClose={closeEditModal}
                productId={selectedProductId}
              />
            )}
</div>

    </div>
    </div>
    </div>
  );
}

export default Sqcedit;
