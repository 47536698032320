import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import Search from "./search";
import CustomerpaymentModal from "./AddCustomerpayment";
import { error } from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Customerpayment() {
  const [Customerpayment, setCustomerpayment] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchbydate, setSearchbydate] = useState(true);

  const [loader, setLoader] = useState(true);
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const getData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
  
      const trans = await axios.get( `/api/customerpayment/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${currentDate}`, 
    {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setCustomerpayment(trans.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };
  function searchName(text) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbydate(false);
    setLoader(true);
    axios
      .get(`/api/customerpayment/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setCustomerpayment(res.data);
        setLoader(false);
      })
      .catch((error)=>{
        setLoader(false);
        handleError(error);
      })
      
  }

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } 
    else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }
 

  useEffect(() => {
    getData();
  }, []);
  const handleAddCustomerPayment = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
 

  return (
    <div>
      <div>
        <Dashboard />
      </div>
      <br />
      <br />
      <br />
      <div>
        <div  style={{ position: "relative", top: "40px" }} className="col-10  mx-xl-auto ps-xl-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="card-title">Customer Payment Details{""}
              {loader && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}</div>
                <div style={{ position: "relative", left: "260px" }}>
        <Search searchName={searchName}/>{" "}
      </div>
              <button className="btn btn-primary float-end" onClick={handleAddCustomerPayment}>
               ADD
            </button>
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    <th>Amount Paid</th>
                    <th>Customer Name</th>
                    <th>Mode Of Payment Id</th>
                    <th>Payment Reference Number</th>
                  




                  </tr>
                </thead>
                <tbody>
                {searchbydate && Customerpayment.length === 0 ? (
            <tr>
              <td colSpan="4" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchbydate &&  Customerpayment.map((accd) => {
                    return (
                      <tr key={accd.id}>
                        <td>{accd.amountpaid}</td>
                        <td>{accd.customerName}</td>
                        <td>{accd.modeofpaymentid}</td>
                        <td>{accd.paymentreferencenumber}</td>
                      </tr>
                    );
                    }))}
                     {!searchbydate && Customerpayment.length === 0 ? (
            <tr>
              <td colSpan="4" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            !searchbydate &&  Customerpayment.map((accd) => {
                    return (
                      <tr key={accd.id}>
                        <td>{accd.amountpaid}</td>
                        <td>{accd.customerName}</td>
                        <td>{accd.modeofpaymentid}</td>
                        <td>{accd.paymentreferencenumber}</td>
                      </tr>
                    );
                    }))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <CustomerpaymentModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />

      </div>
    </div>
  );
}

export default Customerpayment;




