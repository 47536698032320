import React, { useState } from "react";

import { IoSearchOutline } from "react-icons/io5";

const Search = (props) => {
  const date = new Date();

  date.setDate(date.getDate());

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [searchTerm, setSearchTerm] = useState(currentDate);
  const [searchTerm1, setSearchTerm1] = useState(currentDate);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.searchName(searchTerm);
    props.searchName1(searchTerm);
    // setSearchTerm("");
  };

  return (
    <div className="px-xl-5">
      <form onSubmit={handleSubmit}>
        <div id="search">
          <input
            type="date"
            className="search"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
          &nbsp;&nbsp;&nbsp;
          <button
            type="submit"
            value="search"
            className="btn btn-primary"
            id="btn"
          >
            <IoSearchOutline id="IoSearchOutline" />
          </button>
        </div>
      </form>
    </div>
  );
};
export default Search;
