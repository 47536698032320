import React, { useState, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
//import { token } from "../api/baseurl";
import "./Loader.css";
import { Modal, Button } from "react-bootstrap";
import DepositAmountModal from "./depositAmountModal";
import DepositAmountModal1 from "./depositAmountModal1";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}
function Dacashamount1(props) {
  const token = JSON.parse(localStorage.getItem("data")).token;
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  var curr_hour = date.getHours();
  var curr_min = date.getMinutes();

  var curr_sec = date.getSeconds();

  let currentDate = `${year}-${month}-${day}`;
  let currTime = `${curr_hour}:${curr_min}:${curr_sec}`;
  let finaltotal = 0;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [total, setTotal] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [denom, setDenom] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(""); 
  const [savedDepositData, setSavedDepositData] = useState([]);
  const [selectedDepositImage, setSelectedDepositImage] = useState("");
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [denomination, setDenomination] = useState({
    denominationnumber: "",
  });
  const [cash, setCash] = useState({
    vehicleid: "",
    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
    totalamount: finaltotal,
    datetime: props.params.date,
    time: currTime,
    paymentFrom :"office",
    bankDeposit:[{
      referenceNumber:"",
      depositAmount: 0,
      image:"",
      imageMeta:""
    }],
    denomoinationvalue: [
      {
        facevalue: "",
        denominationnumber: "",
      },
    ],
  });

  const navigate = useNavigate();

  function selectVehicle(e) {
    const selectedValue = e.target.value;
  
    setSelectedVehicle(selectedValue);
    setCash((prevCash) => ({
      ...prevCash,
      vehicleid: selectedValue,
      
    }));
  }

  const handleSaveDepositData = (data) => {
    console.log("sd",data);
    setSavedDepositData([...savedDepositData, data]);
  };

  const handleChange = (event, field, index, n) => {
    const actualValue = event.target.value;

    // Update denomination values
    if (field === "denominationnumber") {
        const newDenominationValues = [...cash.denomoinationvalue];
        newDenominationValues[index] = {
            ...newDenominationValues[index],
            [field]: actualValue,
        };
        setCash({ ...cash, denomoinationvalue: newDenominationValues });
    }


    // Update total amount based on denomination values
    const newitem = [...total];
    if (actualValue !== "") {
        newitem[index] = { total: parseFloat(actualValue) * n };
    } else {
        delete newitem[index]; // Remove item if value is empty
    }
    setTotal(newitem);
};


  console.log("total",total);
  total.forEach((item) => {
    if (item && item.total) {
      finaltotal += item.total;
    }
  });
  let totalDepositAmounts = 0;
  savedDepositData.forEach((deposit) => {
    if (!isNaN(parseFloat(deposit.depositAmount))) {
      totalDepositAmounts += parseFloat(deposit.depositAmount);
    }
  });
  finaltotal += totalDepositAmounts;
  
  function handleChange1(e, ta) {
    setCash({ ...cash, totalamount: `${ta}` });
  }
  const handleSubmit = (event, finaltotal) => {
    event.preventDefault();
   
    setLoading(true);

    const bankDepositArray = savedDepositData.map((deposit) => ({
      depositAmount: deposit.depositAmount,
      referenceNumber: deposit.referenceNumber,
      image: deposit.image,
      imageMeta: deposit.imageMeta,
    }));

    const totalcash = {
      vehicleid: selectedVehicle,
      orgid: JSON.parse(localStorage.getItem("data")).orgcode,
      totalamount: `${finaltotal}`,
      datetime: props.params.date,
      time: currTime,
      paymentFrom: "office",
      bankDeposit: bankDepositArray,
    denomoinationvalue: cash.denomoinationvalue,
    };
    axios
      .post("/api/DeliveryAgentCashPayment/create", totalcash, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        //    navigate("/users/");
        toast.success("financials updated successfully");

        setLoading(false);
        navigate(`/users/Counter`);
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const uid = JSON.parse(localStorage.getItem("data")).userid;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

    const vehicles = await axios.get(
      `/api/vehicle/orgid=${orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      setVehicle(vehicles.data);
    
    await axios
      .get("/api/denominations/status=true", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setDenom(res.data);
        setCash({
          ...cash,
          denomoinationvalue: res.data.map((item) => ({
            facevalue: item.denominations,
          })),
        });
      })
      .catch((error)=>{
      handleError(error);
    })

  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } 
    else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  
  const handleDeposit = () => {
    setIsModalOpen(true);
   };

   const handleClose = () => {
    setIsModalOpen(false);
   };

   const [showImageModal, setShowImageModal] = useState(false);

   const handleShowImageModal = (image) => {
    setSelectedDepositImage(image);
    setShowImageModal(true);
  };
  const handleCloseImageModal = () => setShowImageModal(false);

 useEffect(() => {
    getData();
  }, []);

  
 
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
        <div className="row">

<div className="col-md-8">
          <div
            className="col-9 mx-xl-auto ps-xl-8"
            style={{ position: "relative", left: "130px" }}
          >
            <div className="card card-raised ">
              <div className="card-body ">
                <div className="card-title">Denomination</div>

                <br></br>

                <form onSubmit={(e) => handleSubmit(e, finaltotal)}>
                <div className="form-floating-sm mb-2 d-flex align-items-center">
                      <label for="date">Date:</label>
                      <input
                        id="datetime"
                        type="date"
                        value={cash.datetime}
                        onChange={(e) => handleChange(e, "datetime")}
                      />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                     
                      </div>
                {/* <div className="row">
  <div className="col-md-6">
    <div className="form-floating-sm mb-2 d-flex align-items-center">
      <label htmlFor="referenceNumber" className="me-2">Deposit Reference Number:</label>
      <input
        className="form-control form-control-sm"
        id="referenceNumber"
        style={{ width: "50%" }}
        onChange={(e) => handleChange(e, "referenceNumber")}
      />
    </div>
  </div>
  <div className="col-md-6">
    <div className="form-floating-sm mb-2 d-flex align-items-center">
      <label htmlFor="depositAmount" className="me-2">Deposit Amount:</label>
      <input
        className="form-control form-control-sm"
        id="depositAmount"
        style={{ width: "50%" }} 
        onChange={(e) => {
          handleChange(e, "depositAmount");
        }}
      />
    </div>
  </div>
</div> */}
<br/>
                  {denom.map((denom, index) => {
                    return (
                      <div className="d-flex">
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <input
                              className="form-control"
                              id="facevalue"
                              value={denom.denominations}
                              readOnly
                              onChange={(e) =>
                                handleChange(e, "facevalue", index)
                              }
                            />
                          </div>
                        </div>
                        <div>&nbsp;&nbsp;&nbsp;*&nbsp;</div>
                        &nbsp; &nbsp;
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <input
                              className="form-control"
                              id="denominationnumber"
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  "denominationnumber",
                                  index,
                                  denom.denominations
                                )
                              }
                            />
                          </div>
                        </div>
                        <div>&nbsp;&nbsp;&nbsp;=&nbsp;</div>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <input
                              className="form-control"
                              id="total"
                              key={index}
                              value={total[index]?.total}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="row">
                    <div className="form-floating-sm mb-2 d-flex">
                      <h3>Total: </h3>{" "}
                      <input
                        className="form-control"
                        id="totalamount"
                        value={finaltotal}
                       
                      />
                    </div>
                  </div>

                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      value="Submit"
                    />
                  </div>
                 
                </form>
              </div>
            </div>

            {isModalOpen && (
               <DepositAmountModal1
               onRequestClose={handleClose}
               isOpen={isModalOpen}
               onSave={handleSaveDepositData}
             />
            )
            } 
          </div>
          </div>
          <div className="col-md-4">
                
    <div className="card card-raised">
      <div className="card-body" >
        <div className="card-title form-group d-flex align-items-center justify-content-between" >
          Deposit Amount
          <Button variant="primary" onClick={handleDeposit} >
                     ADD
                 </Button>
          </div>

          <div className="card card-raised" style={{height: "60vh", overflow: "auto" }}>
          <div className="card-body">
  {savedDepositData.length > 0 && savedDepositData.map((deposit, index) => (
    <div key={index} className="card card-raised mt-4">
      <div className="card-body p-3">
        <div className="d-flex flex-column">
          <div>Deposit Reference Number: {deposit.referenceNumber}</div>
          <div className="mt-3">Deposit Amount: ₹{deposit.depositAmount}</div>
          {deposit.image && (
            <div className="mt-3">
              <Button variant="secondary" onClick={() => handleShowImageModal(deposit.image)}>
                View Image
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  ))}
</div>
</div>
</div>
      </div>
       {/* Image Modal */}
       <Modal show={showImageModal} onHide={handleCloseImageModal}>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {selectedDepositImage && (
      <img
        src={`data:image/jpeg;base64, ${selectedDepositImage}`}
        alt="Deposit Receipt"
        style={{ width: "100%" }}
      />
    )}
</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseImageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    
   
    </div>
          </div>
        
        </main>
      </div>
    </div>
  );
}
export default withRouter(Dacashamount1);
