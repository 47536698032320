import Dashboard from "./dashboard";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import SuperSidebar from "./supersidebar";
import TransactionIncoming from "./transactionincomingdetails";

import TransactionOutgoing from "./TransactionOutgoingDetails";
function SuperTransactionIncoming() {
  return (
    <div>
      <div>
        <Dashboard />
      </div>
      <div>
        <SuperSidebar />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div>
        <TransactionIncoming />
      </div>
    </div>
  );
}
export default SuperTransactionIncoming;
