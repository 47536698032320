import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import CreateAdminAccount from "./createAdminAccount";
import ViewAdminAccount from "./Viewadminpopup";
import "./Spinner.css";
import "./Loader.css";
import { useParams } from "react-router-dom";
import ExpirydateModal from "./Expirydate";
import HistoryModal from "./historymodal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrgUpdateModal from "./orgUpdateModal";

function Detailsofsubscription() {
    const { orgcode } = useParams(); 
    console.log(orgcode); 

  const [organizationdetails, setOrganizationdetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOrgUpdateModalOpen, setIsOrgUpdateModalOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [loader, setLoader] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [ishistoryModalOpen, setIshistoryModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  // const [orgDetails, setOrgDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});
  const [activeTab, setActiveTab] = useState('Agentprofile');
  const [loading, setLoading] = useState(null);
  const [userProfileDetails, setUserProfileDetails] = useState(null); // Declare and initialize userProfileDetails
  const [userProfileDetails1, setUserProfileDetails1] = useState(null); // Declare and initialize userProfileDetails
    const [orgProfile, setOrgProfile] = useState({
        name: "",
        orgcode: "",
        gstin: "",
        pan: "",
        tan: "",
        contact: "",
        userid: "",
        logo: "",
        imageMeta: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        pincode: "",
        state: "",
        officeLatitude:"",
        officeLongitude:"",
        godownLatitude:"",
        godownLongitude:"",
 
    });

    function getBase64FromFile(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
         const [metadata, content] = reader.result.split(",");
         resolve({ metadata, content });};
         reader.onerror = function (error) {
         reject(error);};}); }
 
   const handleImageChange0=async(event)=>{
     const convertedImage= await getBase64FromFile(event.target.files[0]);
     const imageObject = {...orgProfile,
           imageMeta: convertedImage.metadata,
           logo:convertedImage.content,};
           setOrgProfile(imageObject);
   }
 
   const [profile, setProfile] = useState({
    id:"",
    companyName:"",
    companyLogo: null,
    imageMeta: "",
  });
  const [profile1, setProfile1] = useState({
    id:"",
    logo: null,
    imageMeta: "",
  });
  
  const handleImageChange=async(event)=>{
    const convertedImage= await getBase64FromFile(event.target.files[0]);
    const imageObject = {...profile,
     imageMeta: convertedImage.metadata,
     companyLogo: convertedImage.content,};
          setProfile(imageObject);
          
          
  }
  const handleImageChange1=async(event)=>{
    const convertedImage= await getBase64FromFile(event.target.files[0]);
    const imageObject = {...profile1,
     imageMeta: convertedImage.metadata,
     logo: convertedImage.content,};
          setProfile1(imageObject);
          
          
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const requestBody = {
        id: profile.id, 
        companyLogo: profile.companyLogo,
        imageMeta: profile.imageMeta,
        companyName: profile.companyName,
      };
      await axios.post(`/api/companydetails/create-or-update`, requestBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Logo Updated Successfully");
      setLoading(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error: Failed to update profile");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userProfileDetails) {
      setProfile({
        companyName:userProfileDetails.companyName,
        companyLogo: userProfileDetails.companyLogo || null,
        imageMeta: userProfileDetails.imageMeta || "",
       
      });
    }
  }, [userProfileDetails]);
  const handleSubmit1 = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const requestBody = {
        id: profile1.id, 
        logo: profile1.logo,
        imageMeta: profile1.imageMeta,
      };
      await axios.post(`/api/printlogo/create-or-update`, requestBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Logo Updated Successfully");
      setLoading(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error: Failed to update profile");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userProfileDetails1) {
      setProfile1({
        logo: userProfileDetails1.logo || null,
        imageMeta: userProfileDetails1.imageMeta || "",
       
      });
    }
  }, [userProfileDetails1]);
  const handleChange = (event, field) => {
    let actualValue = event.target.value;

    setProfile({ ...profile, [field]: actualValue });
    
  };


  
 



  useEffect(() => {
    setLoading(true);
    
    const fetchProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/companydetails`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userProfileDetails = response.data[0];
        setProfile({
          ...profile,
          ...userProfileDetails
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching company details:", error);
      }
    };
  
    fetchProfile();
  }, []);
  useEffect(() => {
    setLoading(true);
    
    const fetchProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/printlogo`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userProfileDetails1 = response.data[0];
        setProfile1({
          ...profile1,
          ...userProfileDetails1
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching company details:", error);
      }
    };
  
    fetchProfile();
  }, []);
 
    const handleSubmit0 = async (event) => {
      event.preventDefault();
      setLoader(true);
 
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        await axios.post(`/api/organizationprofiles/update`, orgProfile, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Organization Profile Updated Successfully");
      } catch (error) {
        console.error("Error updating profile:", error);
        toast.error("Error: Failed to update profile");
      } finally {
        setLoader(false);
      }
    };
 
 
   
  const handleChange0 = (event, field) => {
      let actualValue = event.target.value;
 
      setOrgProfile({ ...orgProfile, [field]: actualValue });
     
    };

  const getData = async () => {
    try {
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios.get(`/api/user/subscription/orgcode=${orgcode}`, {
      headers: { Authorization: `Bearer ${token}` },
    }
    );

    console.log(trans);

    setOrganizationdetails(trans.data);

  const orgResponse = await axios.get(
        `/api/organizationprofiles/Orgcode=${orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const userProfileDetails0 = orgResponse.data;
      setOrgProfile(userProfileDetails0);
      // setOrgDetails(orgResponse.data);

      const paymentResponse = await axios.get(`/api/user/userpayment/username=${userProfileDetails0.username}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (paymentResponse.data.length > 0) {
        setPaymentDetails(paymentResponse.data);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
     handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } 
    else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }
  const handleHistory = (subModelId) => {
    
    setSelectedId(subModelId);
    setIshistoryModalOpen(true);
};

const handleClosehistoryModal = () => {
    setIshistoryModalOpen(false);
};
  
  const handleUpdate = (expires,id) => {
    console.log("Expires:", expires);
    setSelectedProductId(expires);
    setSelectedUserId(id);
    setIsModalOpen (true);

  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const handleUpdateOrg = (id) => {
    setSelectedOrgId(id);
    setIsOrgUpdateModalOpen (true);

  };

  const handleCloseOrgUpdateModal = () => {
    setIsOrgUpdateModalOpen(false);
    getData();
  };
  
  

  useEffect(() => {
    getData();
  }, [orgcode]);




  const [itemsPerPage, setItemsPerPage] = useState(10); 
  const [currentPage, setCurrentPage] = useState(1);

  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const changeItemsPerPage = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); 
  };

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  
  const Currentorganizationdetails = organizationdetails.slice(firstIndex, lastIndex);

  
  const totalPages = Math.ceil(organizationdetails.length / itemsPerPage);

  const [itemsPerPage1, setItemsPerPage1] = useState(10); 
  const [currentPage1, setCurrentPage1] = useState(1);

  const changePage1 = (pageNumber1) => {
    setCurrentPage1(pageNumber1);
  };

  const changeItemsPerPage1 = (e) => {
    setItemsPerPage1(parseInt(e.target.value));
    setCurrentPage1(1); 
  };

  const lastIndex1 = currentPage1 * itemsPerPage1;
  const firstIndex1 = lastIndex1 - itemsPerPage1;

  const CurrentpaymentDetails = Array.isArray(paymentDetails) ? paymentDetails.slice(firstIndex1, lastIndex1) : [];

  const totalPages1 = Math.ceil((Array.isArray(paymentDetails) ? paymentDetails.length : 0) / itemsPerPage1); 
  
  
  return (
    <div className="attendance-page" style={{marginTop:'100px'}}>
    <div className="dashboard-container">
        <Dashboard />
      </div>
      {loader && <div className="loading"></div>} 

      <div className="content-container">

      <div className="col-12 mx-xl-auto ps-xl-30">
        <div className="card card-raised">
          <div className="card-body">
              <div className="card-title">Organization Profile Details</div>
              <div className="d-flex flex-column align-items-center">
           
              <form onSubmit={handleSubmit0} className="w-100">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organizationname">Organization Name:</label>
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        value={orgProfile.name}
                        onChange={(e) => handleChange0(e, "name")}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organization">Organization Code:</label>
                      <input
                        className="form-control"
                        id="orgcode"
                        type="text"
                        value={orgProfile.orgcode}
                        onChange={(e) => handleChange0(e, "orgcode")}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organization">Contact Number:</label>
                      <input
                        className="form-control"
                        id="contact"
                        type="text"
                        value={orgProfile.contact}
                        onChange={(e) => handleChange0(e, "contact")}
                      />
                    </div>
                  </div>
                </div>
 
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organization">Email ID:</label>
                      <input
                        className="form-control"
                        id="username"
                        type="email"
                        value={orgProfile.username}
                        onChange={(e) => handleChange0(e, "username")}
                      />
                    </div>
                  </div>
 
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="territoryname">Territory Name:</label>
                      <input
                        className="form-control"
                        id="territoryname"
                        type="text"
                        value={orgProfile.territoryname}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="salesarea">Sales Area:</label>
                      <input
                        className="form-control"
                        id="salesarea"
                        type="text"
                        value={orgProfile.salesarea}
                        readOnly
                      />
                    </div>
                  </div>
                  </div>
                  <br/>
                  <div>
                  <label htmlFor="Tax" >
        Tax Details
      </label> 
     
      
               
                  <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">GSTIN:</label>
                      <input
                        className="form-control"
                        id="gstin"
                        type="text"
                        value={orgProfile.gstin}
                        onChange={(e) => handleChange0(e, "gstin")}
                      />
                    </div>
                  </div>
                 
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="state">PAN:</label>
                      <input
                        className="form-control"
                        id="pan"
                        type="text"
                        value={orgProfile.pan}
                        onChange={(e) => handleChange0(e, "pan")}
                      />
                    </div>
                  </div>
 
                <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">TAN:</label>
                      <input
                        className="form-control"
                        id="tan"
                        type="text"
                        value={orgProfile.tan}
                        onChange={(e) => handleChange0(e, "tan")}
                      />
                    </div>
                  </div>
                 </div>

                 </div>
<br/>
                 <div>
                 <label htmlFor="address" >
        Address Details
      </label>
 
                 <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="addressLine1">Address 1:</label>
                      <input
                        className="form-control"
                        id="addressLine1"
                        type="text"
                        value={orgProfile.addressLine1}
                        onChange={(e) => handleChange0(e, "addressLine1")}
                      />
                    </div>
                  </div>
 
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="addressLine2">Address 2:</label>
                      <input
                        className="form-control"
                        id="addressLine2"
                        type="text"
                        value={orgProfile.addressLine2}
                        onChange={(e) => handleChange0(e, "addressLine2")}
                      />
                    </div>
                  </div>
 
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">City:</label>
                      <input
                        className="form-control"
                        id="city"
                        type="text"
                        value={orgProfile.city}
                        onChange={(e) => handleChange0(e, "city")}
                      />
                    </div>
                  </div>
                  </div>

                  
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="pincode">Pincode:</label>
                      <input
                        className="form-control"
                        id="pincode"
                        type="text"
                        value={orgProfile.pincode}
                        onChange={(e) => handleChange0(e, "pincode")}
                      />
                    </div>
                  </div>
               
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="state">State:</label>
                      <input
                        className="form-control"
                        id="state"
                        type="text"
                        value={orgProfile.state}
                        onChange={(e) => handleChange0(e, "state")}
                      />
                    </div>
                  </div>
                  </div>
                  </div>
                  <br />
                  <div>
  <div className="row">
    {/* Left section for Office */}
    <div className="col-md-4">
      <label htmlFor="Office">Office</label>
      <div className="form-floating-sm mb-2" style={{ marginTop: '10px' }}>
        <label htmlFor="officeLatitude">Office Latitude:</label>
        <input
          className="form-control"
          id="officeLatitude"
          type="text"
          value={orgProfile.officeLatitude}
          onChange={(e) => handleChange0(e, "officeLatitude")}
        />
      </div>
      <div className="form-floating-sm mb-2">
        <label htmlFor="officeLongitude">Office Longitude:</label>
        <input
          className="form-control"
          id="officeLongitude"
          type="text"
          value={orgProfile.officeLongitude}
          onChange={(e) => handleChange0(e, "officeLongitude")}
        />
      </div>
    </div>
    {/* Right section for Godown */}
    <div className="col-md-4">
      <label htmlFor="Godown">Godown</label>
      <div className="form-floating-sm mb-2" style={{ marginTop: '10px' }}>
        <label htmlFor="godownlatitude">Godown Latitude:</label>
        <input
          className="form-control"
          id="godownLatitude"
          type="text"
          value={orgProfile.godownLatitude}
          onChange={(e) => handleChange0(e, "godownLatitude")}
        />
      </div>
      <div className="form-floating-sm mb-2">
        <label htmlFor="godownLongitude">Godown Longitude:</label>
        <input
          className="form-control"
          id="godownLongitude"
          type="text"
          value={orgProfile.godownLongitude}
          onChange={(e) => handleChange0(e, "godownLongitude")}
        />
      </div>
    </div>
  </div>
</div>

 
 
                <br />
                <input
                  type="submit"
                  value="Save Changes"
                  className="btn btn-primary"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <br/>

      <div className="col-12 mx-xl-auto ps-xl-30">
  <div className="card card-raised">
    <div className="card-body">
      <div className="card-title">Logo Details</div>
      <div className="d-flex justify-content-between align-items-start">
        <br/>
        {/* Organization Logo Section */}
        <form onSubmit={handleSubmit0} className="d-flex flex-column align-items-center">
          <div className="mb-4 text-center">
          <label htmlFor="orgLogo">Organization Logo</label>

            {orgProfile.logo ? (
              <img
                className="img-fluid mb-1"
                id="image"
                src={`${orgProfile?.imageMeta},${orgProfile?.logo}`}
                alt="User Profile"
                style={{
                  width: "50px",
                  height: "50px",
                  display: "block",
                  background: "#ccc",
                  marginTop: "20px",
                }}
              />
            ) : (
              <div
                className="img-fluid mb-1"
                id="image"
                style={{
                  width: "50px",
                  height: "50px",
                  display: "block",
                  background: "#ccc",
                  marginTop: "20px",
                }}
              ></div>
            )}
            <input
              className="col-7"
              type="file"
              id="image"
              onChange={(event) => {
                console.log(event.target.files[0]);
                handleImageChange0(event);
              }}
              accept="image/*"
            />
          </div>
          <input
            type="submit"
            value="Save Changes"
            className="btn btn-primary"
          />
        </form>
        
        {/* VM Logo Section */}
        <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
          <div className="mb-4 text-center">
          <label htmlFor="companyLogo">VM Logo</label>

          {/* <div className="form-floating-sm mb-2">
              <label htmlFor="companyName">Company Name :</label>
              <input
                className="form-control"
                id="companyName"
                type="text"
                value={profile.companyName}
                onChange={(e) => handleChange(e, "companyName")}
                style={{ width: '200px' }}
              />
            </div> */}
            {profile.companyLogo ? (
              <img
                className="img-fluid mb-1"
                id="companyLogo"
                src={`${profile.imageMeta},${profile.companyLogo}`}
                alt="Company Logo"
                style={{
                  width: "50px",
                  height: "50px",
                  display: "block",
                  background: "#ccc",
                  marginTop: "20px",
                }}
              />
            ) : (
              <div
                className="img-fluid mb-1"
                id="companyLogo"
                style={{
                  width: "50px",
                  height: "50px",
                  display: "block",
                  background: "#ccc",
                  marginTop: "20px",
                }}
              ></div>
            )}
            <input
              className="col-7"
              type="file"
              id="companyLogo"
              onChange={handleImageChange}
              accept="image/*"
            />
          </div>
          <input
            type="submit"
            value="Save Changes"
            className="btn btn-primary"
          />
        </form>
        
        {/* Print Logo Section */}
        <form onSubmit={handleSubmit1} className="d-flex flex-column align-items-center">
          <div className="mb-4 text-center">
          <label htmlFor="printLogo">Print Logo</label>

            {profile1.logo ? (
              <img
                className="img-fluid mb-1"
                id="logo"
                src={`${profile1.imageMeta},${profile1.logo}`}
                alt="Print Logo"
                style={{
                  width: "50px",
                  height: "50px",
                  display: "block",
                  background: "#ccc",
                  marginTop: "20px",
                }}
              />
            ) : (
              <div
                className="img-fluid mb-1"
                id="logo"
                style={{
                  width: "50px",
                  height: "50px",
                  display: "block",
                  background: "#ccc",
                  marginTop: "20px",
                }}
              ></div>
            )}
            <input
              className="col-7"
              type="file"
              id="logo"
              onChange={handleImageChange1}
              accept="image/*"
            />
          </div>
          <input
            type="submit"
            value="Save Changes"
            className="btn btn-primary"
          />
        </form>
      </div>
    </div>
  </div>
</div>



      

<div className="col-12  mx-xl-auto ps-xl-30" style={{marginTop:"2%"}}>
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="card-title">Organization Territory Map
              {""}
              {loader && (
        <div
          className="loader"
          style={{ position: "absolute", left: "210px", top: "15px" }}
        ></div>
      )}
             
      
          
              </div>

              <br />
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    <th>Territory Name</th>
                    <th>Sales Area</th>
                    <th>Actions</th>

                  </tr>
                </thead>
                <tbody>
                  
                {orgProfile && (
                      <tr key={orgProfile.id}>
                      <td>{orgProfile.territoryname ? orgProfile.territoryname : 'No Territory Added'}</td>
                      <td>{orgProfile.salesarea ? orgProfile.salesarea : 'No Sales Area Added'}</td>
                        <td>
        <button className="btn btn-primary" onClick={() => handleUpdateOrg(orgProfile.id)}>Update</button>
      </td>

                      </tr>
                    )}
                </tbody>
              </table>
              <div>

              </div>
            </div>
          </div>

        </div>

        {isOrgUpdateModalOpen && selectedOrgId &&(
        <OrgUpdateModal isOpen={isOrgUpdateModalOpen} onRequestClose={handleCloseOrgUpdateModal} orgid={selectedOrgId} />
        )}


<div className="col-12  mx-xl-auto ps-xl-30" style={{marginTop:"2%"}}>
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="card-title">Subscription Details
              {""}
              {loader && (
        <div
          className="loader"
          style={{ position: "absolute", left: "210px", top: "15px" }}
        ></div>
      )}
             
      
          
              </div>

              <br />
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    <th>Subscription Code</th>
                    <th>Subscription Date</th>
                    <th>Expiry Date</th>
                    <th>Actions</th>

                  </tr>
                </thead>
                <tbody>
                  {Currentorganizationdetails.map((accd) => {
                    return (
                      <tr key={accd.id}>
                        <td>
                          <div>{accd.subcode}</div>
                        {accd.subModelId === null && (
                         <div
                         style={{
                           display: "inline-block",
                           padding: "0.25em 0.5em",
                           borderRadius: "10px",
                           backgroundColor: "#d4edda",
                           color: "#155724",
                           border: "1px solid #c3e6cb",
                           marginTop: "0.5em",
                         }}
                       >
                          Free Trial</div>
          )}
                        </td>
                        <td>{accd.subdate}</td>
                        <td>{accd.expires}</td>
                        <td>
        <button onClick={() => handleHistory(accd.subModelId)}>History</button>
        <span style={{ marginRight: '5px' }}></span>
      
        <button onClick={() => handleUpdate(accd.expires,accd.id)}>Update</button>
      </td>


                       
                       
                        
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div>

              </div>
            </div>
          </div>
          <div className="pagination-container">
        {currentPage > 1 && (
          <button className="btn btn-primary" onClick={() => changePage(currentPage - 1)}>
            Previous
          </button>
        )}

        <div className="pagination-current">
          &lt;&lt;{currentPage}&gt;&gt;
        </div>

        {currentPage < Math.ceil(organizationdetails.length / itemsPerPage) && (
          <button className="btn btn-primary" onClick={() => changePage(currentPage + 1)}>
            Next
          </button>
        )}
        <div style={{marginLeft:"5%"}}>
        <label>Rows per page</label>
        <select value={itemsPerPage} onChange={changeItemsPerPage}>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20 </option>
          <option value={25}>25 </option>
          <option value={30}>30 </option>
        </select>
        </div>
        </div>
        </div>

        {isModalOpen && (
        <ExpirydateModal isOpen={isModalOpen} onRequestClose={handleCloseModal}  expires={selectedProductId}   id={selectedUserId} />
        )}

        {ishistoryModalOpen && (
        <HistoryModal isOpen={ishistoryModalOpen} onRequestClose={handleClosehistoryModal}  subModelId={selectedId} />
        )}


        <div className="col-12  mx-xl-auto ps-xl-30" style={{marginTop:"2%"}}>
          <div className="card card-raised ">
            <div className="card-body ">
        
                                <div className="card-title">Payment Details</div>
                                <table className="table table-bordered ">
                                    <thead>
                                        <tr   style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}>
                       <th>Payment Date</th>
                       <th>Razorpay payment Id</th>
                       <th>Payment Currency</th>
                        <th>Amount</th>
                        <th>Payment Status</th>
                                           
                        

                                        </tr>
                                    </thead>
                                    <tbody>
                {CurrentpaymentDetails.map((payment) => (
                  <tr key={payment.username}>
                    <td>{payment.paymentDate}</td>
                    <td>{payment.razorpay_payment_id}</td>
                    <td>{payment.paymentCurrency}</td>
                    <td>{payment.amount}</td>
                    <td>{payment.paymentStatus}</td>
                    
                  </tr>
                ))}
              </tbody>
            </table>
                            </div>
                            </div>

                            <div className="pagination-container">
        {currentPage1 > 1 && (
          <button className="btn btn-primary" onClick={() => changePage1(currentPage1 - 1)}>
            Previous
          </button>
        )}

        <div className="pagination-current">
          &lt;&lt;{currentPage1}&gt;&gt;
        </div>

        {currentPage1 < Math.ceil(paymentDetails.length / itemsPerPage1) && (
          <button className="btn btn-primary" onClick={() => changePage1(currentPage1 + 1)}>
            Next
          </button>
        )}
        <div style={{marginLeft:"5%"}}>
        <label>Rows per page</label>
        <select value={itemsPerPage1} onChange={changeItemsPerPage1}>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20 </option>
          <option value={25}>25 </option>
          <option value={30}>30 </option>
        </select>
        </div>
        </div>
        </div>
          </div>
        </div>
   
  );
}


export default Detailsofsubscription;
 
