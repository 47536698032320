import React, { useEffect, useState, useReducer } from "react";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import Sidebar from "./adminsidebar";

import "./Spinner.css";
import "./Loader.css";
import "./approval.css";
function SuperDashboard() {
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <div>
        <Sidebar />
      </div>{" "}
      <br />
      <br />
      <br />
      <br />
      <br />
      <div
        style={{ position: "relative", top: "100px" }}
        className="d-flex align-items-right "
      >
        <div className="col-10  align-items-center mx-xl-auto  ps-xl-10">
          <div className="card card-raised mb-5">
            <div className="card-body p-5">
              <div className="card-title">Super Dashboard</div>
              <br />

           
            </div>
          </div>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}


export default SuperDashboard;
