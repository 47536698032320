import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { doLogout, getCurrentUserDetail, isLoggedIn } from "../auth";
import { useNavigate } from "react-router-dom";
import Navigationdrawer from "./adminsidebar";
import Sidebar from "./Deliveryagentsidebar";
import Dashboard from "./admindashboard";

function DeliveryagentDashboard() {
  const navigate = useNavigate();
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState(undefined);
  const removeData = () => {
    try {
      localStorage.removeItem("data");
      setLogin([]);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLogin(isLoggedIn());
    setUser(getCurrentUserDetail());
  }, [login]);
  return (
    <div>
      <div>
        <Dashboard />
      </div>
    </div>
  );
}
export default DeliveryagentDashboard;
