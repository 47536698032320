import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
 

function SQCleveleditModalPopup({ isOpen, onRequestClose, productId }) {
    const [uproduct1, setUproduct1] = useState({
    sqcId: "",
    productName: "",
    productCode: "",
    serialNumber: "",
    sealIntact: "",
    grossWeight: "",
    tareWeight: "",
    remarks: "",
    productReturned: "",
    ervNumber: "",

    ervDate: "",
    actualWeight: "",
    difference: "",
    approvalStatus:"",
      date: new Date().toISOString().split("T")[0], 
      orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    });
    const [loading, setLoading] = useState(false);
   

    useEffect(() => {
      if (productId) {
        getData();
      }
    }, [productId]);
  
    const getData = async () => {
      setLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/sqcleveldetails/${productId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUproduct1(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
  
    const rsubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        await axios.post(
          "/api/sqcleveldetails/update",
          uproduct1,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setLoading(false);
        toast.success(" updated successfully");
        onRequestClose();
      } catch (error) {
        console.log(error);
      }
    };
  
    const rhandle = (event, field) => {
      const value = event.target.value;
      setUproduct1((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    };
 
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update  Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4">
            <form onSubmit={(e) => rsubmit(e)}>
              <div className="row">
                <div className="form-floating-sm mb-2">
                  <label htmlFor="sqcId">SQC Number</label>
                  <input
                    className="form-control"
                    id="sqcId"
                    type="text"
                    placeholder="sqcId"
                    value={uproduct1.sqcId}
                    readOnly
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="serialNumber">Serial Number</label>
                  <input
                    className="form-control"
                    id="serialNumber"
                    type="text"
                    placeholder="serialNumber"
                    value={uproduct1.serialNumber}
                    onChange={(e) => rhandle(e, "serialNumber")}
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="grossWeight">Gross Weight</label>
                  <input
                    className="form-control"
                    id="grossWeight"
                    type="text"
                    placeholder="grossWeight"
                    value={uproduct1.grossWeight}
                    onChange={(e) => rhandle(e, "grossWeight")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="tareWeight">Tare Weight</label>
                  <input
                    className="form-control"
                    id="tareWeight"
                    type="text"
                    placeholder="tareWeight"
                    value={uproduct1.tareWeight}
                    onChange={(e) => rhandle(e, "tareWeight")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="remarks">Remarks</label>
                  <input
                    className="form-control"
                    id="remarks"
                    type="text"
                    placeholder="remarks"
                    value={uproduct1.remarks}
                    onChange={(e) => rhandle(e, "remarks")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="ervNumber">Erv Number</label>
                  <input
                    className="form-control"
                    id="ervNumber"
                    type="text"
                    placeholder="ervNumber"
                    value={uproduct1.ervNumber}
                    onChange={(e) => rhandle(e, "ervNumber")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="ervDate">Erv Date</label>
                  <input
                    className="form-control"
                    id="ervDate"
                    type="date"
                    placeholder="ervDate"
                    value={uproduct1.ervDate}
                    onChange={(e) => rhandle(e, "ervDate")}
                  />
                  </div>
              </div>
              
                 
                  <br />
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                
            </form>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
export default SQCleveleditModalPopup;