import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import "./Spinner.css";
import PriceHistoryModal from "./PriceHistoryModal";
import $ from 'jquery';
import ProductdetailsModal from "./updateproducts";
import ProducteditModalPopup from "./productedit";
import { FiInfo } from "react-icons/fi";
import AdditionalPriceHistoryModal from "./additionalPriceHistoryModal";
import NewConPriceHistoryModal from "./newConPriceHistoryModal";
import Addstaffrewards from "./Addstaffrewards";

function Viewrewards() {
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [priceHistory, setPriceHistory] = useState([]); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [productEditModalIsOpen, setProductEditModalIsOpen] = useState(false); // corrected variable name
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
 
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [producteditDetails, setproducteditDetails] = useState([]);
  const [isModalproducteditOpen, setIsModalproducteditOpen] = useState(false);
  const [isPriceHistoryModalOpen, setIsPriceHistoryModalOpen] = useState(false);
  const [isAdditionalPriceHistoryModalOpen, setIsAdditionalPriceHistoryModalOpen] = useState(false);
  const [isNewConPriceHistoryModalOpen, setIsNewConPriceHistoryModalOpen] = useState(false);
  const [additionalPriceHistory, setAdditionalPriceHistory] = useState([]); 
  const [newConPriceHistory, setNewConPriceHistory] = useState([]); 
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
 

  

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/reward`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct([...response.data]);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };
  useEffect(() => {
    getData();
  }, [isModalOpen]);
 
 

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      
      {loader && <div className="loading"></div>} 
      <div className="col-12  mx-xl-auto ps-xl-10  " >
        <div className="card card-raised"
        >
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Rewards Details
             
             
            </div>
  
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                    
                  }}
                >
                   <th>Product Type</th>

                  <th>Sales</th>
                  <th>New Connection</th>
                  <th>Additional</th>
                  <th>Exempted</th> 
                  <th>QR Pay</th> 
                  <th>Pay & Book</th> 
                  <th> Book & Pay</th> 

                  <th>Hose</th>
                  <th>Inspection</th>
                   <th>Safety Check </th> 
                   <th>Bank Deposit </th> 

                </tr>
              </thead>
              <tbody>
              {product.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (

                product.map((product) => {
                  return (
                    <tr key={product.id}>
                     <td>{product.producttype}</td>

                      <td>{product.sales}</td>
                      <td>{product.newconnection}</td>
                      <td>{product.additional}</td>
                     <td>{product.excempted}
                    
                      </td>
                      <td>{product.qrPay}
                      
                      </td>
                      <td>{product.payAndbook}
                     
                      </td>
                      <td>{product.bookAndpay}</td>
                      <td>{product.hose}</td>
                      <td>{product.inception}</td>
                      <td>{product.safetycheck}</td>
                      <td>{product.bankdeposite}</td>

                      

                    
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
           
           


          </div>
        </div>{" "}
      </div>
    </div>   
  );
}

export default Viewrewards;
