import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./Spinner.css";
function Servicereport() {
  const [servicelogreport, setServicelogreport] = useState({
    vehicleid: "",
    startdate: "",
    enddate: "",
  });

  const [servicereport, setServicereport] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [loading, setLoading] = useState(false);

  const url = "/api/Returntransaction/create";
  const getData = async () => {
    const date = new Date();

    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;

    const vehicles = await axios.get(
      `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(vehicles);

    setVehicle(vehicles.data);
  };

  useEffect(() => {
    getData();
  }, []);

  function rhandle(e, field) {
    let actualValue = e.target.value;

    setServicelogreport({ ...servicelogreport, [field]: actualValue });
  }
  const rSubmit = async (e, id, sd, ed) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios
      .get(
        `/api/MaintainanceDo/vehicleid=${id}/startdate=${sd}/enddate=${ed}`,

        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setLoading(false);
        setServicereport(response.data);
        const doc = new jsPDF();

        const tableColumn = ["Date", "Vehicleid", "Amount"];
        const tableRows = [];
        const total = ["Service header"];
        // const tableRows1 = [];
        // let totalamount = 0;
        // let totalquantity = 0;

        response.data.forEach((item) => {
          const data = [item.date, item.vehicleid, item.amount];
          console.log(data);
          tableRows.push(data);
          // totalamount = totalamount + item.amount;
          // totalquantity = totalquantity + item.quantity;
        });
        doc.setFontSize(14);
        doc.setTextColor(86, 150, 214);
        doc.text(16, 20, "Service Report ");
        doc.setFontSize(10);
        doc.text(80, 20, `StartDate:${sd}`);
        doc.text(150, 20, `EndDate:${ed}`);

        doc.autoTable(tableColumn, tableRows, { startY: 25 });
        footer();
        function footer() {
          doc.text(150, 285, "***this is a e-generated report"); //print number bottom right
          doc.page++;
        }

        doc.save("data.pdf");
      });
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br /> <br />
      <div className="col-8  mx-xl-auto px-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Servicelog Report</div>
            <br />

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form>
                    <div>
                      <select
                        id="vehicle"
                        type="text"
                        placeholder="vehicle"
                        value={servicelogreport.vehicleid}
                        onChange={(e) => rhandle(e, "vehicleid")}
                      >
                        <option>select vehicle</option>
                        {vehicle.map((vehicle) => {
                          return (
                            <option value={vehicle.truckinfo}>
                              {vehicle.truckinfo}
                            </option>
                          );
                        })}
                      </select>{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <label for="StartDate">StartDate</label>
                      <input
                        id="startdate"
                        type="date"
                        placeholder="StartDate"
                        value={servicelogreport.startdate}
                        onChange={(e) => rhandle(e, "startdate")}
                      />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <label for="EndDate">EndDate</label>
                      <input
                        id="enddate"
                        type="date"
                        placeholder="EndDate"
                        value={servicelogreport.enddate}
                        onChange={(e) => rhandle(e, "enddate")}
                      />{" "}
                    </div>
                    <br />
                    <br />

                    <button
                      type="submit"
                      value="Download"
                      disabled={loading}
                      className="btn btn-primary"
                      onClick={(e) =>
                        rSubmit(
                          e,
                          servicelogreport.vehicleid,
                          servicelogreport.startdate,
                          servicelogreport.enddate
                        )
                      }
                    >
                      Download
                    </button>
                  </form>{" "}
                  {loading && <div className="loading"></div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Servicereport;
