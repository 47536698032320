import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import Search from "./search";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Pagination, Form, Card, Row, Col,Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";

function Superleaderboard() {
  const [reward, setReward] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [searchbydate, setSearchbydate] = useState(true);
  const [searchOption, setSearchOption] = useState("DATE");
  const [salesAreas, setSalesAreas] = useState([]);
  const [selectedSalesArea, setSelectedSalesArea] = useState("");
  const navigate = useNavigate();

  const fetchSalesAreas = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get('/api/salesarea', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSalesAreas(response.data);
      
    } catch (error) {
      handleError(error);
    }
  };

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
    const date = new Date();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const currentDate = `${year}-${month}-${day}`;

    setLoader(true);

    try {
      const url = `/api/rewardpoints/sales/${selectedSalesArea}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setReward([...response.data]);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const searchName = (text) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbydate(false);
    setLoader(true);
    axios
    .get(`/api/rewardpoints/salesareas/${selectedSalesArea}/date=${text}`, {
      headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setReward([...res.data]);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        handleError(error);
      });
  };

  const searchByMonth = async (month, year) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbydate(false);

    setLoader(true);
    try {
      const searchResponse = await axios.get(`/api/rewardpoints/salesmonth/${selectedSalesArea}/${month}/${year}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setReward([...searchResponse.data]);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const searchByYear = async (year) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbydate(false);

    setLoader(true);
    try {
      const searchResponse = await axios.get(`/api/rewardpoints/salesareas/${selectedSalesArea}/${year}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setReward([...searchResponse.data]);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
    }
    setLoader(false);
  };

  useEffect(() => {
    fetchSalesAreas();
    getData();
  }, []);
  useEffect(() => {
    if (selectedSalesArea) {
      getData();
    }
  }, [selectedSalesArea]);

  const handleFilterChange = (event) => {
    const selectedOption = event.target.value;
    setSearchOption(selectedOption);
  };
  const handleBack = () => {
    navigate("/users/Adminleaderboard");
  };
  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <br />

      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10">
        <Card>
          <Card.Body>
            <Card.Title>Sales Area Detailswerty <Button style={{ marginLeft:"90%"}} variant="secondary" onClick={handleBack}>
              Back
            </Button>
            </Card.Title>
            <br/>
            <div className="d-flex align-items-center">
              <div className="filter-dropdown">
                <label>FILTER By Sales Area :</label>
                <select value={selectedSalesArea} onChange={(e) => setSelectedSalesArea(e.target.value)}>
                  <option value="">Select Sales Area</option>
                  {salesAreas.map((area) => (
                    <option key={area.id} value={area.salesarea}>{area.salesarea}</option>
                  ))}
                </select>
              </div>
            <div className="filter-dropdown">
            <label>FILTER :</label>
                <select value={searchOption} onChange={handleFilterChange}>
                  <option value="DATE">Date</option>
                  <option value="MONTH">Month</option>
                  <option value="YEAR">Year</option>
                </select>
              </div>
              {searchOption === "DATE" && (
                <React.Fragment>
            <span style={{ marginLeft: '10px' }}></span>
              <Form.Label  style={{ position: 'relative', left: '40px' }}>Date:</Form.Label>
              <Search searchName={searchName} />
              </React.Fragment>
              )}
                 {searchOption === "MONTH" && (
                <React.Fragment>

               
              <Form.Label>Month:</Form.Label>
              <DatePicker
                selected={new Date(selectedYear || new Date().getFullYear(), selectedMonth || new Date().getMonth())}
                onChange={(date) => {
                  setSelectedYear(date.getFullYear());
                  setSelectedMonth(date.getMonth());
                  searchByMonth(date.getMonth() + 1, date.getFullYear());
                }}
                showMonthYearPicker
                dateFormat="MM/yyyy"
              />
               </React.Fragment>
              )}
               {searchOption === "YEAR" && (
                <React.Fragment>

                          <span style={{ marginLeft: '10px' }}></span>

              <Form.Label>Year:</Form.Label>
              <DatePicker
                selected={new Date(selectedYear || new Date().getFullYear(), 0)}
                onChange={(date) => {
                  setSelectedYear(date.getFullYear());
                  searchByYear(date.getFullYear());
                }}
                showYearPicker
                dateFormat="yyyy"
              />
                              </React.Fragment>
               )}
            </div>

            <br />
            <br />
            <Row xs={1} md={1} className="g-4">
            { searchbydate && reward.length === 0 && (
                <div style={{ textAlign: 'center', marginTop: '20px', width: '100%' }}>
                  No data available for the selected filter.
                </div>
              )}
            { searchbydate && reward.map((product, index) => (
  <Col key={index}>

    <Card>
      <Card.Body>
    
        {/* <Card.Title>{product.date}</Card.Title> */}
        {/* <Card.Text>Name: {product.username}</Card.Text>
        <Card.Text>Delivery Agent: {product.name}</Card.Text> */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
        {index === 0 && <FontAwesomeIcon icon={faMedal} style={{ color: 'gold' }} />}
      {index === 1 && <FontAwesomeIcon icon={faMedal} style={{ color: 'silver' }} />}

{index === 2 && <FontAwesomeIcon icon={faMedal} style={{ color: 'bronze' }} />}
<div  style={{marginLeft:"50px"}}>
      <Card.Text style={{ fontSize: '18px' }}>ORGCODE: {product.orgcode}</Card.Text>
    </div>
    <div style={{marginLeft:"50px"}}>
      <Card.Text style={{ fontSize: '18px' }}>POINTS: {product.totalpoints}</Card.Text>
    </div>
  </div>
      </Card.Body>
    </Card>
  </Col>
))}
            </Row>
            <br />
            <br />
            <Row xs={1} md={1} className="g-4">
            { !searchbydate && reward.length === 0 && (
                <div style={{ textAlign: 'center', marginTop: '20px', width: '100%' }}>
                  No data available for the selected filter.
                </div>
              )}
            { !searchbydate && reward.map((product, index) => (
  <Col key={index}>
    <Card>
      <Card.Body>
     
        {/* <Card.Title>{product.date}</Card.Title> */}
        {/* <Card.Text>Name: {product.username}</Card.Text>
        <Card.Text>Delivery Agent: {product.name}</Card.Text> */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
      {index === 0 && <FontAwesomeIcon icon={faMedal} style={{ color: 'gold' }} />}
      {index === 1 && <FontAwesomeIcon icon={faMedal} style={{ color: 'silver' }} />}

{index === 2 && <FontAwesomeIcon icon={faMedal} style={{ color: 'bronze' }} />}
<div  style={{marginLeft:"50px"}}>
      <Card.Text style={{ fontSize: '18px' }}>ORGCODE: {product.orgcode}</Card.Text>
    </div>
    <div style={{marginLeft:"50px"}}>
      <Card.Text style={{ fontSize: '18px' }}>POINTS: {product.totalpoints}</Card.Text>
    </div>
  </div>
      </Card.Body>
    </Card>
  </Col>
))}
            </Row>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default Superleaderboard;
