import React from "react";

import "react-toastify/dist/ReactToastify.css";

import AdminDashboard from "./admindashboard";

import Productanalytics from "./productanalytics";
function AdminDashboardwithanalytics() {
  return (
    <div>
      <AdminDashboard />
      <Productanalytics />
    </div>
  );
}
export default AdminDashboardwithanalytics;
