import React, { useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import axios from "../api/baseurl";
import "react-toastify/dist/ReactToastify.css";
import { getCurrentUserDetail, isLoggedIn } from "../auth";
import { useNavigate } from "react-router-dom";
import Sidebar from "./adminsidebar";
import logo from "../images/energynow.gif";
import Badge from "react-bootstrap/Badge";
import Productanalytics from "./productanalytics";
function AdminDashboard() {
  const navigate = useNavigate();
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState(undefined);
  const [show, setShow] = useState(true);
  const [count, setCount] = useState(0);
  const [notification, setNotification] = useState([]);
  const [notificationstate, setNotificationstate] = useState([]);
  const [topnotification, setTopnotification] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const counter = 0;
  const removeData = () => {
    try {
      localStorage.removeItem("data");
      setLogin([]);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  // var myinterval = 120 * 60 * 1000;
  // useEffect(() => {
  //   setInterval(removeData, myinterval);
  // }, []);

  useEffect(() => {
    setLogin(isLoggedIn());
    setUser(getCurrentUserDetail());
  }, [login]);
  const getData = async () => {
    const date = new Date();

    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios.get(
      `/api/user/notifications/orgid=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(trans);
    var countstatus = 0;
    setNotification(trans.data);

    {
      countstatus = trans.data.map((trans, index) => {
        return trans.read === false
          ? setCount((countstatus = countstatus + 1))
          : setCount(0);
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  console.log("count is", count);
  const notificationstates = async (e) => {
    e.preventDefault();

    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios.get("/api/user/notifications/read=true", {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(trans);

    setNotificationstate(trans.data);
  };
  const notificationcounter = notification.length;
  return (
    <div>
      <nav className="top-app-bar navbar navbar-expand navbar-dark bg-dark">
        <div className="container-fluid px-4">
          <button
            className="btn btn-lg btn-icon order-1 order-lg-0"
            id="drawerToggle"
            href="javascript:void(0);"
            onClick={() => setShow(!show)}
          >
            <i className="material-icons">menu</i>
          </button>

          <div className="navbar-brand me-auto">
            <img src={logo} width="55px" />
          </div>



          <div className="d-flex align-items-center mx-3 me-lg-0">
            <div className="d-flex">
            {user && user.role === "ROLE_SUPER" && (
                <div className="dropdown dropdown-notifications d-none d-sm-block">
                  <Link
                    to="/users/Superdashboard"
                    style={{ textDecoration: "none" }}
                    className="btn btn-lg btn-icon me-3"
                  >
                    <i className="material-icons">home</i>
                  </Link>
                </div>
              )}
               {user && user.role === "ROLE_ADMIN" && (
              <div className="dropdown dropdown-notifications d-none d-sm-block">
                <Link
                  to="/users/admindashboardwithanalytics"
                  style={{ textDecoration: "none" }}
                  className="btn btn-lg btn-icon me-3"
                  id="dropdownMenuMessages"
                >
                  <i className="material-icons">home</i>
                </Link>
              </div>
              )}

              <div className="dropdown dropdown-notifications d-none d-sm-block">
                {" "}
                <button
                  className="btn btn-lg btn-icon dropdown-toggle me-3"
                  id="dropdownMenuNotifications"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={(e) => notificationstates(e)}
                >
                  <i className="material-icons">notifications</i>{" "}
                  <sup>
                    {" "}
                    <div
                      style={{
                        borderRadius: "50%",
                        width: "16px",
                        fontSize: "10px",
                      }}
                    >
                      {count > 0 && <Badge bg="danger">{count}</Badge>}
                    </div>
                  </sup>
                </button>{" "}
                <ul
                  className="dropdown-menu dropdown-menu-end me-3 mt-3 py-0 overflow-show"
                  aria-labelledby="dropdownMenuNotifications"
                >
                  <li>
                    <h6 className="dropdown-header bg-primary text-white fw-500 py-3">
                      Alerts
                    </h6>
                  </li>
                  <li>
                    <hr className="dropdown-divider my-0" />
                  </li>
                  {notification.map((notifications) => {
                    return (
                      notifications.id > notificationcounter - 3 && (
                        <li>
                          <div className="dropdown-item unread">
                            <i className="material-icons leading-icon">
                              chevron_right
                            </i>{" "}
                            <div className="dropdown-item-content me-2">
                              {notifications.subject}
                              <div className="dropdown-item-content-text">
                                {notifications.message}
                              </div>
                              <div className="dropdown-item-content-subtext">
                                {notifications.timestamp}&middot; Performance
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                    );
                  })}
                  <li>
                    <hr class="dropdown-divider my-0" />
                  </li>

                  <li>
                    <Link class="dropdown-item py-3" to="/users/notification">
                      <div class="d-flex align-items-center w-100 justify-content-end text-primary">
                        <div class="fst-button small">View all</div>

                        <i class="material-icons icon-sm ms-1">chevron_right</i>
                      </div>{" "}
                    </Link>{" "}
                  </li>
                </ul>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-lg btn-icon dropdown-toggle"
                  id="dropdownMenuProfile"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="material-icons">person</i>
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end mt-3"
                  aria-labelledby="dropdownMenuProfile"
                >
                  <li>
                    <Link className="dropdown-item" to="/users/adminprofile">
                      <i className="material-icons leading-icon">person</i>
                      <div className="me-3">Profile</div>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/users/security">
                      <i className="material-icons leading-icon">settings</i>
                      <div className="me-3">Settings</div>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/users/adminapproval">
                      <i className="material-icons leading-icon">help</i>
                      <div className="me-3">Help</div>
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <div className="dropdown-item" onClick={() => removeData()}>
                      <i className="material-icons leading-icon">logout</i>
                      <div className="me-3">Logout</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>{" "}
      {show && <Sidebar />}
    </div>
  );
}
export default AdminDashboard;
