import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link } from "react-router-dom";
import CreateAdminAccount from "./createAdminAccount";
import ViewAdminAccount from "./Viewadminpopup";
import "./Spinner.css";
import "./Loader.css";
import { useNavigate } from "react-router-dom";
import {FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



function Organizationdetails() {
  const [organizationdetails, setOrganizationdetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isorgModalOpen, setIsorgModalOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [loader, setLoader] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchTruckNumber, setSearchTruckNumber] = useState(""); 
  const [product, setProduct] = useState([]);
  const [searchtruck, setSearchtruck] = useState([]);
  const [searchDeviceNumber, setSearchDeviceNumber] = useState(""); 
  const [searchDevice, setSearchDevice] = useState([]);
  const [errorMessage, setErrorMessage] = useState(""); // Added this line


  const url = "/api/Returntransaction";
  const navigate = useNavigate();

  function searchByOrganization(organization) {
    console.log("Searching by organization name:", organization);

    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchtruck(false);
    setLoader(true);
    axios
      .get(`api/organizationprofiles/organization=${organization}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log("Search response:", res.data);
        if (res.data.length === 0) {
          setErrorMessage("No data available for this organization name.");
        } else {

        setOrganizationdetails(res.data);
        setErrorMessage("");
        }
        setLoader(false);
            })
      .catch((error) => {
        console.error("Error searching by organization name:", error);
        setLoader(false);
      });
  }
  function searchByOrgcode(orgcode) {
    console.log("Searching by org code:", orgcode);
  
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchDevice(false);
    setLoader(true);
    axios
      .get(`api/organizationprofiles/Orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log("Search response:", res.data);
        
        // Check if the response is an object
        if (typeof res.data === 'object' && !Array.isArray(res.data)) {
          // If it's an object, convert it to an array
          if (res.data.length === 0) {
            setErrorMessage("No data available for this organization code.");
          } else {
          setOrganizationdetails([res.data]);
          setErrorMessage("");
          }
          setLoader(false);
        } else {
          // If it's an array, set it directly
          setOrganizationdetails(res.data);
        }
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error searching by org code:", error);
        toast.error("No data on this org code..");
        setLoader(false);
      });
  }
  

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios.get("/api/organizationprofiles", {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(trans);

    setOrganizationdetails(trans.data);
    setLoader(false);
  };

  const handleCreateAccount = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };
  const handleOrganizationDetailsClick = (orgcode,userid,username) => {
   
    navigate(`/users/Viewadminpopup/${orgcode}/${userid}/${username}`);


  };
  const handleorgCloseModal = () => {
  };
  const handleClick = (orgcode) => {
    navigate( `/users/detailsofsubscription/${orgcode}`);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div>
        <Dashboard />
      </div>
     
     
      <div>
        <div className="col-10  mx-xl-auto ps-xl-10" style={{marginTop:"10%"}}>
        <input  type="text" value={searchTruckNumber} placeholder="search organization name..."
 onChange={(e) => {
  setSearchTruckNumber(e.target.value);
  console.log("Search truck number value:", e.target.value);
}}
list="name"
style={{ marginRight: '10px', height:"30px" }}
/>         
         <datalist id="name" >
          {organizationdetails.map((item) => (
          <option key={item.id} value={item.name} />
        ))}
        </datalist>
        <button onClick={() => searchByOrganization(searchTruckNumber)}
           className="btn btn-primary ml-2" style={{ border:"2px solid #6200ea",background: "#6200ea", size: "150px", borderRadius:"5px", width:"50px", height:"30px"}}
        ><search><FaSearch style={{background:"#6200ea", color:"white"}}/></search></button>

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


<input type="text" value={searchDeviceNumber} placeholder="search org code..."
onChange={(e) => {
setSearchDeviceNumber(e.target.value);
console.log("Search org code:", e.target.value);
}}
list="orgcode"
style={{ marginRight: '10px', height:"30px" }}
/>         
<datalist id="orgcode" >
{organizationdetails.map((item) => (
<option key={item.id} value={item.orgcode} />
))}
</datalist>
<button onClick={() => searchByOrgcode(searchDeviceNumber)}
className="btn btn-primary ml-2" style={{ border:"2px solid #6200ea",background: "#6200ea", size: "150px", borderRadius:"5px", width:"50px", height:"30px"}}
><search><FaSearch style={{background:"#6200ea", color:"white"}}/></search></button>
        <span style={{ marginRight: '25px' }}></span>

<br />
<br />
          <div className="card card-raised ">
            
            
            <div className="card-body ">
           
              <div className="card-title">Organization Details
             
                <button className="btn btn-primary" style={{ position: "relative", marginLeft: "50%" }}  onClick={handleCreateAccount}>Create Account</button>
      
          
              </div>

              <br />
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    <th>Organization Name </th>

                    <th>Contact</th>
                    <th>username</th>
                    <th>orgcode</th>

                    {/* <th>Status</th> */}
                  </tr>
                </thead>
                <tbody>
                {organizationdetails.length === 0 ? (
                  
            <tr>
               {/* setLoader(false); */}
              <td colSpan="7" className="text-center">
                No Data Available
              </td>
            </tr>
          
          ) : (

                  organizationdetails.map((accd) => {
                    return (
                      <tr key={accd.name}>
 <td 
  style={{ 
    cursor: 'pointer'
  }}
  onClick={() => handleClick(accd.orgcode)}
  onMouseOver={(e) => { e.target.style.color = 'blue'; e.target.style.textDecoration = 'underline'; }}
  onMouseOut={(e) => { e.target.style.color = ''; e.target.style.textDecoration = ''; }}
>
  {accd.name}
</td>
                        <td>{accd.contact}</td>
                        <td>{accd.username}</td>
                        <td>{accd.orgcode}</td>

                        {/* <td  style={{ cursor: 'pointer' }}>
                          <button className="btn btn-link" onClick={() => handleOrganizationDetailsClick(accd.orgcode,accd.userid,accd.username)}>
                            View Profile
                          </button>
                        </td> */}

                       
                        
                      </tr>
                    );
                  }))}
                </tbody>
              </table>
              
              <div>
              <CreateAdminAccount isOpen={isModalOpen} onRequestClose={handleCloseModal} />
              {/* <ViewAdminAccount isOpen={isorgModalOpen} onRequestClose={handleorgCloseModal} selectedOrganization={selectedOrganization} selectedUserId={selectedUserId} /> */}

              </div>
            </div>
          </div>
        </div>
        {loader && <div className="loading"></div>} 
      </div>
    </div>
  );
}

export default Organizationdetails;
