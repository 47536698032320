import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import "./Spinner.css";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Modal,Button } from "react-bootstrap";
 
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();
 
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}
function Dagentandvehiclemapping({ isOpen, onRequestClose, params }) {
  const date = new Date();
  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [davehicle, setDavehicle] = useState({
    userid:"",
    vehicleid: params.vid,
    date: new Date().toISOString().split('T')[0],
  });
  const [vehicle, setVehicle] = useState([]);
  const [dAdetails, setDadetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState("");
  const url = "/api/DeliveryAgentVehicleMapping/create";
  const navigate = useNavigate();
  useEffect(() => {
    const currentDate = new Date().toISOString().split('T')[0];
    setDavehicle((prev) => ({ ...prev, date: currentDate }));
    
    getData();
  }, []);
  function rsubmit(e) {
    e.preventDefault();
    if (!selectedAgent) {
      toast.error("Please select an agent.");
      return;
    }
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
 
    axios
 
      .post(
        url,
        davehicle,
 
        { headers: { Authorization: `Bearer ${token}` } }
      )
 
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Agent Added successfully");
        // navigate("/users/vehicledetail");
        onRequestClose();
      })
 
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  }
  const rhandle = (e) => {
    const { id, value } = e.target;

    if (id === "date") {
      setDavehicle((prev) => ({
        ...prev,
        [id]: value,
      }));
    } else if (id === "userid") {
      setDavehicle((prev) => ({
        ...prev,
        userid: value,
      }));
      setSelectedAgent(value);
    }
  };
  const getData = async () => {
    
    const token = JSON.parse(localStorage.getItem("data")).token;
 try{
    const trans = await axios.get(
      `/api/authority/role=ROLE_DELIVERY_AGENTS/orgid=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/isavailable=true`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
 
    console.log(trans);
 
    setDadetails(trans.data);
 
    const vehicles = await axios.get(
      `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
 
    console.log(vehicles);
 
    setVehicle(vehicles.data);
  }catch(error){
    handleError(error);
  }
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } 
    else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }
 
  useEffect(() => {
    getData();
  }, []);
 
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Agents Vehicle Mapping</Modal.Title>
    </Modal.Header>
    <Modal.Body>
 
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="id">Truckinfo</label>
                        <input
                          className="form-control"
                          id="vehicleid"
                          type="text"
                          placeholder="truckinfo"
                          value={params.truckinfo}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                     <div className="row">
                     <div className="form-floating-sm mb-2">
                        <label for="date">Date</label>
                        <input
                          className="form-control"
                          id="date"
                          type="date"
                          placeholder="date"
                          value={davehicle.date}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                      </div>
                      <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="userid">Delivery Agent</label>
                    <select
                      className="form-control"
                      id="userid"
                      value={selectedAgent}
                      onChange={(e) => rhandle(e)}
                    >
                      <option value="">Select Delivery Agent</option>
                      {dAdetails.map((dadetails) => {
                        return (
                          <option
                            key={dadetails.userid}
                            value={dadetails.userid}
                          >
                            {dadetails.name}
                          </option>
                        );
                      })}
                    </select>
                      </div>
                    </div>
 
                    <input
                      type="submit"
                      value="ADD"
                      className="btn btn-primary"
                    />
                     &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
 
                  </form>
                </div>
              </div>
            </div>
 
 
           </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
 
export default Dagentandvehiclemapping;