import React, { useEffect, useState, useReducer } from "react";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import Sidebar from "./adminsidebar";
import { BsCheckCircle } from "react-icons/bs";
import { BsXCircle } from "react-icons/bs";
import Search from "./search";
import "./approval.css";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
import './Loader.css'; // Import Loader CSS
import DaDenominations from "./daDenominations";
import { FiInfo } from "react-icons/fi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Financeapproval({ selectedDate }) {
  const [approvals, setApprovals] = useState([]);
  const [denominations, setDenominations] = useState([]);
  const [depositDetails, setDepositDetails] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [denominationsModalIsOpen, SetDenominationsModalIsOpen] = useState(false);
  const [status, setStatus] = useState({
    id: "",
    approvalstatus: "",
    orgcode: "",
    quantity: "",
  });
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [searchbydate, setSearchbydate] = useState(false);
 
  const [filterVehicleId, setFilterVehicleId] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  const [outgoingCurrentPage, setOutgoingCurrentPage] = useState(1);
  const [incomingCurrentPage, setIncomingCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items to show per page

  const applyFilters = () => {
    let filteredApprovals = [...approvals];
    if (filterVehicleId) {
      filteredApprovals = filteredApprovals.filter(approval => approval.vehicleid === filterVehicleId);
    }
    if (filterStatus) {
      filteredApprovals = filteredApprovals.filter(approval => approval.approvalstatus === filterStatus);
    }
    return filteredApprovals;
  };

  const handleFilterChange = () => {
    setOutgoingCurrentPage(1);
  };

  const handleOutgoingPrevPage = () => {
    if (outgoingCurrentPage > 1) {
      setOutgoingCurrentPage(outgoingCurrentPage - 1);
    }
  };

  const handleOutgoingNextPage = () => {
    if (outgoingCurrentPage < Math.ceil(applyFilters().length / itemsPerPage)) {
      setOutgoingCurrentPage(outgoingCurrentPage + 1);
    }
  };

  const filteredApprovals = applyFilters();
  const indexOfLastItem = outgoingCurrentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentApprovals = filteredApprovals.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(approvals.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
   // Calculate page numbers based on total items and items per page
  const getData = async () => {
    if (!selectedDate) return;
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    const orgid = JSON.parse(localStorage.getItem("data")).orgcode;
  
    try {
      const prof1 = await axios.get(
        `/api/DeliveryAgentCashPayment/orgid=${orgid}/datetime=${selectedDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setApprovals(prof1.data);

      const vehicles = await axios.get(
        `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setVehicles(vehicles.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

 
  useEffect(() => {
    getData();
  }, [selectedDate,reducerValue]);

  function trsubmit(e, id, status) {
    e.preventDefault();
    setLoading(true);
    const newdata = { ...status };
    newdata[e.target.id] = e.target.value;
    setStatus(newdata);

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        "/api/admin/approvals/finance",
        {
          id: id,
          approvalstatus: status,
        },

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        forceUpdate();
      })

      .catch((error) => {
       setLoading(error);
       handleError(error);
      });
  }

  const openDenominationsModal = async (daCashId) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/DeliveryagentDenomination/daCashPaymentId=${daCashId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setDenominations(response.data);

      const depositResponse = await axios.get(
        `/api/bankdepositdetails/daCashPaymentId=${daCashId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setDepositDetails(depositResponse.data);

      SetDenominationsModalIsOpen(true);
    } catch (error) {
     handleError(error);
    }
  };
  
  const closeDenominationModal = () => {
    SetDenominationsModalIsOpen(false);
   
  };

  const navigate = useNavigate();
      function handleError(error) {
        if (error.response && error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else {
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error('An error occurred.');
          }
        }
      }

  return (

    <div className="d-flex align-items-right ">
    <div className="col-12  align-items-center mx-xl-auto  ps-xl-10">
   <div className="card card-raised mb-5" style={{ marginTop:'-50px' }}>
        <div className="card-body p-5"> 
           <div className="card-title">
            Financial Details
            {loader && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "10px",
                  }}
                ></div>
              )}{" "} 
          </div>
          <br />

          <div className="mb-4 d-flex align-items-center">
  <label htmlFor="vehicleId" className="me-2">FILTER BY VEHICLE ID:</label>
  <select
    id="vehicleId"
    value={filterVehicleId}
    onChange={(e) => setFilterVehicleId(e.target.value)}
  >
    <option value="">ALL</option>
    {vehicles.map((vehi) => (
              <option key={vehi.id} value={vehi.truckinfo}>
                {vehi.truckinfo}
              </option>
            ))}
  </select>

  <label htmlFor="status" className="ms-6 me-2">FILTER BY STATUS:</label>
  <select
    id="status"
    value={filterStatus}
    onChange={(e) => setFilterStatus(e.target.value)}
  >
    <option value="">ALL</option>
    <option value="APPROVED">APPROVED</option>
    <option value="REQUESTED">REQUESTED</option>
    <option value="REJECTED">REJECTED</option>
  </select>
</div>

<table className="table table-bordered ">
                <thead>
                  <tr style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}>
                    <th>Agent</th>
                    <th>Vehicle Id</th>
                    <th>Total Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                {currentApprovals.length === 0 ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      NO DATA AVAILABLE
                    </td>
                  </tr>
                ) : (
                  currentApprovals.map((rapprovals, index) => {
                    return (
                      <tr key={rapprovals.id}>
                        <td>{rapprovals.name ? rapprovals.name : "By Admin"}</td>
                        <td>{rapprovals.vehicleid}</td>
                        <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{rapprovals.totalamount}</span>
              <FiInfo
                onClick={() => openDenominationsModal(rapprovals.id)}
                style={{
                  cursor: 'pointer'
                }}
              />
            </td>
                        {rapprovals.approvalstatus === "REQUESTED" ? (
                          <td id="icon" value={rapprovals.approvalstatus}>
                            &nbsp;
                            {rapprovals.approvalstatus} &nbsp;
                            {loading && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "10px",
                  }}
                ></div>
              )}{" "}
                            <BsCheckCircle
                              className="icon"
                              id="rapprovals.approvalstatus"
                              style={{ backgroundColor: "green" }}
                              value="APPROVED"
                              onClick={(e) =>
                                trsubmit(
                                  e,
                                  rapprovals.id,
                                  "APPROVED",
                                  rapprovals.orgcode,
                                  rapprovals.quantity
                                )
                              }
                            />
                            &ensp;
                            <BsXCircle
                              className="icon"
                              value="REJECTED"
                              style={{ backgroundColor: "red" }}
                              id="rapprovals.approvalstatus"
                              onClick={(e) =>
                                trsubmit(
                                  e,
                                  rapprovals.id,
                                  "REJECTED",
                                  rapprovals.orgcode,
                                  rapprovals.quantity
                                )
                              }
                            />
                          </td>
                        ) : (
                            <td id="icon" value={rapprovals.approvalstatus}>
                              {" "}
                              {rapprovals.approvalstatus}
                            </td>
                          )}
                      </tr>
                    );
                  }))}
                </tbody>
              </table>
            </div>
           {/* Pagination controls */}
<nav>
<ul className="pagination justify-content-center">
{pageNumbers.map((number) => (
  <li
    key={number}
    className={`page-item ${number === outgoingCurrentPage ? "active" : ""}`}
  >
   
  </li>
))}
</ul>

<ul className="pagination justify-content-center">
    <li className={`page-item ${outgoingCurrentPage === 1 ? "disabled" : ""}`}>
      <button className="page-link" onClick={handleOutgoingPrevPage}>
        Previous
      </button>
    </li>
    {pageNumbers.map((number) => (
      <li
        key={number}
        className={`page-item ${number === outgoingCurrentPage ? "active" : ""}`}
      >
        <button className="page-link" onClick={() => setOutgoingCurrentPage(number)}>
          {number}
        </button>
      </li>
    ))}
    <li className={`page-item ${outgoingCurrentPage === pageNumbers.length ? "disabled" : ""}`}>
      <button className="page-link" onClick={handleOutgoingNextPage }>
        Next
      </button>
    </li>
  </ul>
  </nav>
          </div>
        </div>
        <DaDenominations
        isOpen={denominationsModalIsOpen}
        onRequestClose={closeDenominationModal}
        denominations={denominations}
        depositDetails={depositDetails}
      />
      </div>
   
  );
}

export default Financeapproval;
