import React from "react";
import Sidebar from "./adminsidebar";
import Dashboard from "./supersidebar";
function DashboardSidebar() {
  return (
    <div>
      <Dashboard />
      <Sidebar />
    </div>
  );
}
export default DashboardSidebar;
