import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import "./Loader.css";

function OfficePaymentReceiveModal({ isOpen, onRequestClose, receiveDate }) {
  const token = JSON.parse(localStorage.getItem("data"))?.token;
  const orgid = JSON.parse(localStorage.getItem("data"))?.orgcode;

  const date = new Date();
  const currentDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  const currTime = `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState([]);
  const [denom, setDenom] = useState([]);
  const [denomination, setDenomination] = useState({ denominationnumber: "" });
  const [cash, setCash] = useState({
    paymentFrom: "office",
    orgid: orgid || "",
    totalamount: 0,
    datetime: receiveDate,
    time: currTime,
    referenceNumber: "",
    depositAmount: 0,
    denomoinationvalue: [
      {
        facevalue: "",
        denominationnumber: "",
      },
    ],
  });

  const navigate = useNavigate();

  const handleChange = (event, field, index, n) => {
    const actualValue = event.target.value;
    const newValue = actualValue !== "" ? parseFloat(actualValue) : 0;

    if (field === "depositAmount") {
      setCash((prev) => ({ ...prev, depositAmount: newValue }));
      return;
    }
    if (field === "referenceNumber") {
      setCash((prev) => ({ ...prev, referenceNumber: actualValue }));
      return;
    }

    setDenomination((prev) => ({ ...prev, [field]: actualValue }));

    const newitem = [...total];
    if (newValue !== "") {
      newitem[index] = { total: newValue * n };
    } else {
      newitem.splice(index, 1); // Remove item if value is empty
    }
    setTotal(newitem);

    const newproduct = [...cash.denomoinationvalue];
    newproduct[index] = { ...newproduct[index], [field]: actualValue };

    setCash((prev) => ({ ...prev, denomoinationvalue: newproduct }));
  };

  const finaltotal = total.reduce((sum, item) => sum + (item?.total || 0), 0) + cash.depositAmount;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const totalcash = {
      paymentFrom: "office",
      orgid: cash.orgid,
      totalamount: finaltotal,
      datetime: receiveDate,
      time: currTime,
      depositAmount: cash.depositAmount,
      referenceNumber: cash.referenceNumber,
      denomoinationvalue: cash.denomoinationvalue,
    };

    try {
      await axios.post("/api/DeliveryAgentCashPayment/create", totalcash, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Financials updated successfully");
      onRequestClose();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    try {
      const res = await axios.get("/api/denominations/status=true", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDenom(res.data || []);
      setCash((prev) => ({
        ...prev,
        denomoinationvalue: (res.data || []).map((item) => ({
          facevalue: item.denominations,
        })),
      }));
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    console.error('Error details:', error);
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    } else {
      toast.error('An unexpected error occurred.');
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Denomination</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2 d-flex align-items-center">
                      <label htmlFor="receiveDate" className="me-2">Receive Date:</label>
                      <input
                        className="form-control form-control-sm"
                        id="receiveDate"
                        style={{ width: "50%" }}
                        value={receiveDate}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <br />
                {denom.map((item, index) => (
                  <div className="d-flex" key={index}>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <input
                          className="form-control"
                          id="facevalue"
                          value={item.denominations}
                          readOnly
                        />
                      </div>
                    </div>
                    <div>&nbsp;&nbsp;&nbsp;*&nbsp;</div>
                    &nbsp; &nbsp;
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <input
                          className="form-control"
                          id="denominationnumber"
                          onChange={(e) =>
                            handleChange(e, "denominationnumber", index, item.denominations)
                          }
                        />
                      </div>
                    </div>
                    <div>&nbsp;&nbsp;&nbsp;=&nbsp;</div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <input
                          className="form-control"
                          id="total"
                          value={total[index]?.total || ''}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="form-floating-sm mb-2 d-flex">
                    <h3>Total: </h3>{" "}
                    <input
                      className="form-control"
                      id="totalamount"
                      value={finaltotal}
                      readOnly
                    />
                  </div>
                </div>
                <br />
                <input
                  className="btn btn-primary"
                  type="submit"
                  value="Submit"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default OfficePaymentReceiveModal;
