import React, { useEffect, useState } from "react";
import {Tab, Tabs } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import "@material/mwc-tab-bar/mwc-tab-bar.js";
import "@material/mwc-tab/mwc-tab.js";
import "@material/mwc-textfield/mwc-textfield.js";
import DeliveryDetailsHistory from "./deliveryDetailsHistory";
import LoyaltyPoints from "./loyaltyPoints";
import RedeemedPoints from "./redeemedPoints";
 
  function ConsumerBio(props) {

  const { consumernumber } = useParams();


  const[loading ,setLoading]=useState(false);
  const [activeTab, setActiveTab] = useState('deliveryDetails');
  const [selectedConsumer, setSelectedConsumer] = useState(consumernumber);
  const [password, setPassword] = useState('');
  const [consumerDetails, setConsumerDetails] = useState([])
  useEffect(() => {
    // Fetch user profile details from the server based on userid
    const fetchConsumerDetails= async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/consumerdetails/consumernumber=${consumernumber}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setConsumerDetails(response.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchConsumerDetails();
  }, [consumernumber]);

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;

      const body = {
        id: consumerDetails.id,
        password: password,
      };

      await axios.post(`/api/consumerdetails/update-password`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast.success("Password Updated Successfully");
      setPassword("");
    } catch (error) {
      console.error("Error updating password:", error);
      toast.error("Error: Failed to update password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <AdminDashboard />
      <br/>
              <br/>
              <br/>
      <br />
<div className="row col-12">
      <div className="col-9 ps-xl-10">
  <div className="card card-raised">
    <div className="card-body">
      <div className="d-flex justify-content-between align-items-center">
        <div className="card-title mb-3">{consumerDetails.consumername}</div>
        <div>
          <div className="d-inline me-8">Latitude: {consumerDetails.latitude}</div>
          <div className="d-inline">Longitude: {consumerDetails.longitude}</div>
        </div>
      </div>
      <div className="mb-2">Consumer Number: {consumerDetails.consumernumber}</div>
      <div className="mb-2">Phone Number: {consumerDetails.phone}</div>
      <div className="mb-2">
        Address: {consumerDetails.address1}, {consumerDetails.address2}, {consumerDetails.address3}
      </div>
      {/* <div className="mt-4 text-center">
        <div className="display-5">Loyalty Points: {consumerDetails.loyaltyPoints}</div>
      </div> */}
    </div>
  </div>
</div>
<div className="col-3">
  <div className="card card-raised">
    <div className="card-body">
       
      <div className="mb-2">Product Name: {consumerDetails.product}</div>
      <div className="mb-2">
      {consumerDetails.productDetails?.image && consumerDetails.productDetails?.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${consumerDetails.productDetails?.image}`}
                              alt={consumerDetails.product}
                              style={{ maxWidth: "18%", maxHeight: "18%" }}
                            />
                          ) : (
                            <div style={{ width: "50px", height: "50px", borderRadius: "50%" }}></div>
                          )}
     </div>
    </div>
  </div>
</div>
</div>
<br/>

<div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <Tabs
              id="consumer-bio-tabs"
              activeKey={activeTab}
              onSelect={(tab) => setActiveTab(tab)}
              className="mb-3"
            >
              <Tab eventKey="deliveryDetails" title="Delivery History">
              {activeTab === 'deliveryDetails' && (
                <DeliveryDetailsHistory selectedConsumer={selectedConsumer} />
              )}
              </Tab>
              <Tab eventKey="loyaltyPoints" title="Loyalty Points">
              {activeTab === 'loyaltyPoints' && (
                <LoyaltyPoints selectedConsumer={selectedConsumer} />
              )}
              </Tab>
              <Tab eventKey="redeemPoints" title="Redeemed Points">
              {activeTab === 'redeemPoints' && (
                <RedeemedPoints selectedConsumer={selectedConsumer} />
              )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <br/>
      <div className="col-12 mx-xl-auto ps-xl-10">
  <div className="card card-raised">
    <div className="card-body">
      <div className="card-title mb-3">Update Password</div>
      <form className="d-flex justify-content-center" onSubmit={handlePasswordSubmit}>
            <div className="col-md-6 mt-1">
              <div className="form-floating-sm mb-2">
                <label htmlFor="password">Password :</label>
                <input
                  className="form-control"
                  id="password"
                  type="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="row justify-content-center">
          <div className="col-md-6 text-center mt-4">
            <button type="submit" className="btn btn-primary">Update</button>
          </div>
        </div>
          </form>
    </div>
  </div>
  </div>
    </div>
  );
}
 
export default ConsumerBio;