import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
const OfficeConsumables = ({ isOpen, onRequestClose,consumables}) => {

  const handleDeleteConsumableSales = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      await axios.delete(`/api/consumableproduct/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      toast.success("Deleted successfully");
      onRequestClose();
    } catch (error) {
     handleError(error);
    }
  };


  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  useEffect(() => {
  
  }, []);

  return (
    <>
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Office Consumable Sales Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
        {consumables.length > 0 && (
  <div>
    <label style={{ fontSize: '1em', color: "#000000" }}>Product Name: {consumables[0].productname}</label>
    <br />
    <label style={{ fontSize: '1em', color: "#000000" }}>Date: {consumables[0].date}</label>
    <br /><br /> 
  </div>
)}
      </div>
        <table className="table table-bordered">
          <thead>
            <tr> 
                  <th>Quantity</th>
                  <th>Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {consumables.map((con, index) => (
              <tr key={index}>
                              <th>{con.quantity}</th>
                              <th>{con.total}</th>
                              <th>   
    <MdDeleteForever style={{ cursor: 'pointer', fontSize:"18px" }}  title="Delete"
    onClick={() => handleDeleteConsumableSales(con.id)}
    /></th>
                          </tr>
            ))}
                </tbody>
              </table>
              </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
      </Modal>
      </>
        );
        
      }
export default OfficeConsumables;
