import React, { useState, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
//import { token } from "../api/baseurl";
import "./Loader.css";
import { Modal ,Button} from "react-bootstrap";

function ReciveopenModal({ isOpen, onRequestClose, receiveDate,vehicleid }) {

  const token = JSON.parse(localStorage.getItem("data")).token;
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  var curr_hour = date.getHours();
  var curr_min = date.getMinutes();

  var curr_sec = date.getSeconds();

  let currentDate = `${year}-${month}-${day}`;
  let currTime = `${curr_hour}:${curr_min}:${curr_sec}`;
  let finaltotal = 0;

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [total, setTotal] = useState([]);

  const [denom, setDenom] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [denomination, setDenomination] = useState({
    denominationnumber: "",
  });
  const [cash, setCash] = useState({
    vehicleid:vehicleid,
    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
    totalamount: finaltotal,
    datetime:receiveDate,
    time: currTime,
    referenceNumber:"",
    depositAmount: 0,
    denomoinationvalue: [
      {
        facevalue: "",
        denominationnumber: "",
      },
    ],
  });

  const navigate = useNavigate();

 const handleChange = (event, field, index, n) => {
    let actualValue = event.target.value;

    let newValue = actualValue !== "" ? parseFloat(actualValue) : 0;

    if (field === "depositAmount") {
      setCash({ ...cash, depositAmount: newValue });
      return;
    }
    if (field === "referenceNumber") {
      setCash({ ...cash, referenceNumber: actualValue });
      return; // Exit the function early for reference number changes
    }
  
    setDenomination({ ...denomination, [field]: actualValue });
  
    const newitem = [...total];
    if (newValue !== "") {
      newitem[index] = { total: newValue * n };
    } else {
      delete newitem[index]; // Remove item if value is empty
    }
    setTotal(newitem);
  
    const newproduct = [...cash.denomoinationvalue];
    newproduct[index] = {
      ...newproduct[index],
      [field]: actualValue,
    };
  
    // Update cash state only if field is not "depositAmount"
    if (field !== "depositAmount") {
      setCash({ ...cash, denomoinationvalue: newproduct });
    }
    else if (field == "vehicleid") {
      setCash({ ...cash, vehicleid: actualValue });
    }else if (field == "totalamount") {
      setCash({ ...cash, totalamount: actualValue });
    }
  };

  console.log("total",total);
  total.forEach((item) => {
    if (item && item.total) {
      finaltotal += item.total;
    }
  });
  finaltotal += cash.depositAmount;
  function handleChange1(e, ta) {
    setCash({ ...cash, totalamount: `${ta}` });
  }
  const handleSubmit = (event, finaltotal) => {
    event.preventDefault();
    setLoading(true);
    const totalcash = {
      vehicleid: vehicleid,
      orgid: JSON.parse(localStorage.getItem("data")).orgcode,
      totalamount: `${finaltotal}`,
      datetime:receiveDate,
      time: currTime,
      depositAmount:cash.depositAmount,
      referenceNumber:cash.referenceNumber,
      denomoinationvalue: cash.denomoinationvalue,
    };
    axios
      .post("/api/DeliveryAgentCashPayment/create", totalcash, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        //    navigate("/users/");
        toast.success("financials updated successfully");

        setLoading(false);
onRequestClose()
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const uid = JSON.parse(localStorage.getItem("data")).userid;

    const { data } = await axios
      .get("/api/denominations/status=true", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setDenom(res.data);
        setCash({
          ...cash,
          denomoinationvalue: res.data.map((item) => ({
            facevalue: item.denominations,
          })),
        });
      })
      .catch((error)=>{
      handleError(error);
    })
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } 
    else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  useEffect(() => {
    getData();
  }, []);
 
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Denomination</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4">

                <form onSubmit={(e) => handleSubmit(e, finaltotal)}>
                <div className="row">
                    
    <div className="col-md-6">
      <div className="form-floating-sm mb-2 d-flex align-items-center">
        <label htmlFor="receiveDate" className="me-2">Receive Date:</label>
        <input
          className="form-control form-control-sm"
          id="receiveDate"
          style={{ width: "50%" }}
          value={receiveDate}
          readOnly
        />
      </div>
    </div>
                    <div className="col-md-6">
      <div className="form-floating-sm mb-2 d-flex align-items-center">
        <label htmlFor="vehicleid" className="me-2">Vehicle ID:</label>
        <input
          className="form-control form-control-sm"
          id="vehicleid"
          style={{ width: "50%" }} 
          value={vehicleid}
          readOnly
        />
      </div>
    </div>
                  </div>
             
<br/>
                  {denom.map((denom, index) => {
                    return (
                      <div className="d-flex">
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <input
                              className="form-control"
                              id="facevalue"
                              value={denom.denominations}
                              readOnly
                              onChange={(e) =>
                                handleChange(e, "facevalue", index)
                              }
                            />
                          </div>
                        </div>
                        <div>&nbsp;&nbsp;&nbsp;*&nbsp;</div>
                        &nbsp; &nbsp;
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <input
                              className="form-control"
                              id="denominationnumber"
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  "denominationnumber",
                                  index,
                                  denom.denominations
                                )
                              }
                            />
                          </div>
                        </div>
                        <div>&nbsp;&nbsp;&nbsp;=&nbsp;</div>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <input
                              className="form-control"
                              id="total"
                              key={index}
                              value={total[index]?.total}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="row">
                    <div className="form-floating-sm mb-2 d-flex">
                      <h3>Total: </h3>{" "}
                      <input
                        className="form-control"
                        id="totalamount"
                        value={finaltotal}
                      />
                    </div>
                  </div>
                  <br/>
                
                  <input
                    className="btn btn-primary"
                    type="submit"
                    value="Submit"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                </form>

              </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
export default ReciveopenModal;
