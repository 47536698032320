import React from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function AddFundsModal({ show, handleClose }) {
  const navigate = useNavigate();

  const handleAdd = (amount) => {
    handleClose();
    navigate(`/users/Addfundsamount/${amount}`); 
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Funds</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formAmount">
            {/* Add buttons for different amounts */}
            <div>
              <Button 
                variant="outline-primary" 
                onClick={() => handleAdd(1000)} // Pass the amount to the handleAdd function
                style={{ margin: '5px' }}>
                ₹ 1000
              </Button>
            </div>
            <div>
              <Button 
                variant="outline-primary" 
                onClick={() => handleAdd(2000)} // Pass the amount to the handleAdd function
                style={{ margin: '5px' }}>
                ₹ 2000
              </Button>
            </div>
            <div>
              <Button 
                variant="outline-primary" 
                onClick={() => handleAdd(3000)} // Pass the amount to the handleAdd function
                style={{ margin: '5px' }}>
                ₹ 3000
              </Button>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddFundsModal;
