import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const DaPaidAmountDetails = ({ isOpen, onRequestClose, cashPaymentIds }) => {
  const [denominations, setDenominations] = useState([]);
  const [depositDetails, setDepositDetails] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchDataForSingleId = async (cashPaymentId, token) => {
    try {
      // Fetch Denominations Data
    //   setLoading(true);
      const response = await axios.get(
        `/api/DeliveryagentDenomination/daCashPaymentId=${cashPaymentId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Fetch Bank Deposit Details Data
      const depositResponse = await axios.get(
        `/api/bankdepositdetails/daCashPaymentId=${cashPaymentId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    //   setLoading(false);
      return { denominations: response.data, depositDetails: depositResponse.data };
    } catch (error) {
        // setLoading(false);
      handleError(error);
      return { denominations: [], depositDetails: [] }; // Return empty data in case of error
    }
  };

  const getData = async () => {
    try {
        setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;
      let accumulatedDenominations = [];
      let accumulatedDepositDetails = [];

      for (const id of cashPaymentIds) {
        const { denominations, depositDetails } = await fetchDataForSingleId(id, token);

        accumulatedDenominations = [...accumulatedDenominations, ...denominations];
        accumulatedDepositDetails = [...accumulatedDepositDetails, ...depositDetails];
      }

      setDenominations(accumulatedDenominations);
      setDepositDetails(accumulatedDepositDetails);
      setLoading(false);
    } catch (error) {
        setLoading(false);
      handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  useEffect(() => {
    if (cashPaymentIds && cashPaymentIds.length > 0) {
      getData();
    }
  }, [cashPaymentIds]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setSelectedImage('');
  };

  return (
    <>
     {loading && <div className="loading"></div>}
      <Modal show={isOpen} onHide={onRequestClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Paid Amount Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card-title">Cash</div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Face Value</th>
                <th>Denomination Number</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {denominations.length > 0 ? (
                denominations.map((d, index) => (
                  <tr key={index}>
                    <td>{d.facevalue}</td>
                    <td>{d.denominationnumber}</td>
                    <td>{d.facevalue * d.denominationnumber}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" style={{ textAlign: 'center' }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <br />
          <div className="card-title">Bank Deposit</div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Reference Number</th>
                <th>Deposit Amount</th>
              </tr>
            </thead>
            <tbody>
              {depositDetails.length > 0 ? (
                depositDetails.map((d, index) => (
                  <tr key={index}>
                    <td>{d.referenceNumber}</td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        {d.depositAmount}
                        <span>
                          {d.image && (
                            <Button
                              variant="link"
                              onClick={() => handleImageClick(d.image)}
                              style={{ cursor: 'pointer' }}
                              title="View Receipt"
                            >
                              <FontAwesomeIcon icon={faImage} />
                            </Button>
                          )}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2" style={{ textAlign: 'center' }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onRequestClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for displaying image */}
      <Modal show={showImageModal} onHide={handleCloseImageModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Deposit Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedImage && (
            <img
              src={`data:image/jpeg;base64, ${selectedImage}`}
              alt="image"
              style={{ width: '100%' }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseImageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DaPaidAmountDetails;
