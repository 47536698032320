import React, { useEffect, useState, useReducer } from "react";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import Search from "./search";
import { Link } from "react-router-dom";
import "./approval.css";
import "./Spinner.css";
import "./Loader.css";
import Financeapproval from "./Financeapproval";
import Transanctionincomingapproval from "./Transactionincomingapproval";
import TransactionOutgoingApproval from "./TransactionOutgoingApprova";
import StockTransferApproval from "./stockTransferApproval";
import ExpensesApproval from "./expensesApproval";

function AdminTransanctionApproval() {
  const [activeTab, setActiveTab] = useState('transactionoutgoing');

  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [selectedDate, setSelectedDate] = useState(currentDate);

  // Function to handle date change
  const searchName = async (date) => {
    setSelectedDate(date);
    console.log("sd",selectedDate);
    // Implement your logic for fetching data based on the selected date
    // For example: Call an API to fetch data for the selected date
  };

  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />{" "}
      
      <div className="d-flex align-items-center ">
        <div className="col-10  align-items-center mx-xl-auto  ps-xl-10">
        <div style={{ position: "relative", left: "260px" }}>
        <Search searchName={searchName} />{" "}
      </div>
   
      <div
        className="container-xl p-10 px-11"
        style={{ position: "relative", left: "100px" }}
      >
        
        <mwc-tab-bar style={{ marginBottom: "-1px", marginLeft:'-220px',marginTop:'-50px'}}  activeIndex="0"
            >
              <mwc-tab
                label="Transaction Outgoing"
                stacked
                active={activeTab === "transactionoutgoing"}
                onClick={() => setActiveTab("transactionoutgoing")}
              ></mwc-tab>
              <mwc-tab
                label="Transaction Incoming"
                stacked
                active={activeTab === "transactionincoming"}
                onClick={() => setActiveTab("transactionincoming")}
              ></mwc-tab>
              <mwc-tab
                label="Finance"
                stacked
                active={activeTab === "finance"}
                onClick={() => setActiveTab("finance")}
              ></mwc-tab>
               <mwc-tab
                label="Expenses"
                stacked
                active={activeTab === "expenses"}
                onClick={() => setActiveTab("expenses")}
              ></mwc-tab>
                <mwc-tab
                label="Stock Transfer"
                stacked
                active={activeTab === "stocktransfer"}
                onClick={() => setActiveTab("stocktransfer")}
              ></mwc-tab>
            </mwc-tab-bar>

        <hr className="mt-0 mb-4" style={{ width: "1100px", marginLeft:'-290px' }} /> {/* Adjust width as needed */}

</div>
</div>
</div>
      {/* Tab content */}
     
          {/* Render content based on activeTab */}
          {activeTab === "transactionoutgoing" && (
            <TransactionOutgoingApproval selectedDate={selectedDate}/>
          )}
          {activeTab === "transactionincoming" && (
            <Transanctionincomingapproval selectedDate={selectedDate} />
          )}
          {activeTab === "finance" && (
          <Financeapproval selectedDate={selectedDate} />)}
           {activeTab === "expenses" && (
          <ExpensesApproval selectedDate={selectedDate} />)}
           {activeTab === "stocktransfer" && <StockTransferApproval selectedDate={selectedDate} />}
        </div>
       
     
  );
}

export default AdminTransanctionApproval;
