import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import axios from "../api/baseurl";
import { TextField, Button, Box } from '@mui/material';

const DriverDetailsModal = ({ isOpen, onRequestClose, onDriverSelect }) => {
  const [driverDetails, setDriverDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAddDriverModalOpen, setIsAddDriverModalOpen] = useState(false);
  const [newDriver, setNewDriver] = useState({ name: '', number: '' });

  const fetchDriverDetails = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const response = await axios.get(`/api/loadin/get-drivers/orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDriverDetails(response.data);
    } catch (error) {
      console.error("Failed to fetch driver details", error);
      setError("Failed to fetch driver details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchDriverDetails();
    }
  }, [isOpen]);

  const handleSelectDriver = (driver) => {
    onDriverSelect(driver);
    onRequestClose();
  };

  const handleAddDriverClick = () => {
    setIsAddDriverModalOpen(true);
  };

  const handleAddDriverClose = () => {
    setIsAddDriverModalOpen(false);
    setNewDriver({ name: '', number: '' });
  };

  const handleNewDriverChange = (e) => {
    const { name, value } = e.target;
    setNewDriver({ ...newDriver, [name]: value });
  };

  const handleAddDriverSubmit = (e) => {
    e.preventDefault();
    const updatedDriverDetails = [...driverDetails, newDriver];
    setDriverDetails(updatedDriverDetails);

    onDriverSelect(newDriver);
    setNewDriver({ name: '', number: '' });

    handleAddDriverClose();
    onRequestClose();

  };

  const modalStyles = {
    content: {
      width: 'auto',
      height: 'auto',
      maxHeight: '70%',
      top: '15%',
      left: '40%',
      right: 'auto',
      bottom: 'auto',
      padding: '15px',
      overflow: 'auto',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

  const tableStyles = {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
    marginTop: '20px',
    fontSize: '1.2em',
  };

  const thStyles = {
    backgroundColor: '#f4f4f4',
    color: '#333',
    padding: '15px',
    borderBottom: '2px solid #ddd',
    fontSize: '0.8em',
    textAlign: 'left',
  };

  const tdStyles = {
    padding: '15px',
    borderBottom: '1px solid #ddd',
    textAlign: 'center',
    fontSize: '0.8em',
    cursor: 'pointer',
    backgroundColor: '#fff',
  };

  const closeButtonStyles = {
    backgroundColor: '#6c757d',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '8px 12px',
    cursor: 'pointer',
    fontSize: '0.9em',
    marginTop: '15px',
  };

  const addButtonStyles = {
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '8px 12px',
    cursor: 'pointer',
    fontSize: '0.9em',
    marginBottom: '15px',
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Driver Details"
        style={modalStyles}
      >
        <h5>Driver Details</h5>
        <button onClick={handleAddDriverClick} style={addButtonStyles}>
          Add Driver
        </button>
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <div>
            {driverDetails.length === 0 ? (
              <div>No data available</div>
            ) : (
              <table style={tableStyles}>
                <thead>
                  <tr>
                    <th style={thStyles}>Driver Name</th>
                    <th style={thStyles}>Phone Number</th>
                  </tr>
                </thead>
                <tbody>
                  {driverDetails.map((driver, index) => (
                    <tr
                      key={index}
                      style={tdStyles}
                      onClick={() => handleSelectDriver(driver)}
                    >
                      <td>{driver.name}</td>
                      <td>{driver.number}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
        <button onClick={onRequestClose} style={closeButtonStyles}>
          Close
        </button>
      </Modal>

      {/* Add Driver Modal */}
      <Modal
        isOpen={isAddDriverModalOpen}
        onRequestClose={handleAddDriverClose}
        contentLabel="Add Driver"
        style={modalStyles}
      >
        {/* <h5>Add Driver</h5> */}
        <Box
      component="form"
      onSubmit={handleAddDriverSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        width: '250px', // Reduced width
        margin: '0 auto',
      }}
    >
      <TextField
        label="Driver Name"
        name="name"
        value={newDriver.name}
        onChange={handleNewDriverChange}
        required
        fullWidth
        size="small" // Reduced size
      />
      <TextField
        label="Phone Number"
        name="number"
        value={newDriver.number}
        onChange={handleNewDriverChange}
        required
        fullWidth
        size="small" // Reduced size
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          type="submit"
          variant="contained"
          color="success"
          sx={{ flexGrow: 1, fontSize: '0.8em', padding: '6px 8px', mr: 1 }} // Reduced size
        >
          Add Driver
        </Button>
        <Button
          type="button"
          variant="outlined"
          color="error"
          sx={{ flexGrow: 1, fontSize: '0.8em', padding: '6px 8px', ml: 1 }} // Reduced size
          onClick={handleAddDriverClose}
        >
          Cancel
        </Button>
      </Box>
    </Box>
      </Modal>
    </>
  );
};

export default DriverDetailsModal;
