import { Link } from "react-router-dom";

function Home() {
  return (
    <nav className="top-app-bar navbar navbar-expand navbar-dark bg-dark">
      <div className="container-fluid px-4">
        <button
          className="btn btn-lg btn-icon order-1 order-lg-0"
          id="drawerToggle"
          href="javascript:void(0);"
        >
          <i className="material-icons">menu</i>
        </button>

        <a className="navbar-brand me-auto" href="index.html">
          <div className="text-uppercase font-monospace">Alpha</div>
        </a>

        <div className="dropdown">
          <Link
            className="btn btn-lg "
            id="dropdownMenuProfile"
            type="button"
            to="/signin"
          >
            <i className="material-icons">person</i>
          </Link>
        </div>
      </div>
    </nav>
  );
}
export default Home;
