import React, { useEffect, useState, useReducer } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import "./Spinner.css";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
function Closestock() {
  const [closestock, setClosestock] = useState([]);
  const [loading, setLoading] = useState(false);
  const url = "/api/CloseStock/orgcode";

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios.get(
      `/api/CloseStock/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(trans);

    setClosestock(trans.data);
  };
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    getData();
  }, [reducerValue]);
  function onclickhandler(e, pid, quantity, status, orgcode) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .post(
        "/api/CloseStock/update",
        {
          productcode: pid,
          quantity: quantity,
          status: status,
          orgcode: orgcode,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setLoading(false);
      });
    forceUpdate();
  }

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br /> <br />
      <div className="col-10  mx-xl-auto ps-xl-10">
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="card-title">CloseStock Details</div>
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr>
                  <th>productid</th>
                  <th>orgcode</th>
                  <th>datetime</th>
                  <th>quantity</th>
                  <th>status</th>
                  <th>closestock</th>
                </tr>
              </thead>
              <tbody>
                {closestock.map((closestock) => {
                  return (
                    <tr key={closestock.id}>
                      <td>{closestock.productcode}</td>
                      <td>{closestock.orgcode}</td>
                      <td>{closestock.datetime}</td>
                      <td>{closestock.quantity}</td>
                      <td>{closestock.status}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={(e) =>
                            onclickhandler(
                              e,
                              closestock.productcode,
                              closestock.quantity,
                              closestock.status,
                              closestock.orgcode
                            )
                          }
                        >
                          closestock
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default Closestock;
