import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./Spinner.css";
function Fuelreport() {
  const [fuellogreport, setFuellogreport] = useState({
    vehicleid: "",
    startdate: "",
    enddate: "",
  });
  const [fuellogreport1, setFuellogreport1] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    const date = new Date();

    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;

    const vehicles = await axios.get(
      `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(vehicles);

    setVehicle(vehicles.data);
  };

  useEffect(() => {
    getData();
  }, []);

  function rhandle(e, field) {
    let actualValue = e.target.value;

    setFuellogreport({ ...fuellogreport, [field]: actualValue });
  }
  const rSubmit = async (e, id, sd, ed) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios
      .get(
        `/api/fuelreportDo/vehicleid=${id}/startdate=${sd}/enddate=${ed}`,

        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setLoading(false);
        setFuellogreport1(response.data);
        const doc = new jsPDF();

        const tableColumn = [
          "Date",
          "Vehicleid",
          "Liter",
          "Rate/ltr",
          "Amount",
        ];
        const tableRows = [];
        const tabletoatal = ["TotalAmount", "TotalQuantity"];
        const tablecol = [];
        let totalamount = 0;
        let totalquantity = 0;
        doc.setDrawColor(255, 0, 0);
        doc.line(35, 30, 100, 30);
        response.data.forEach((item) => {
          const data = [
            item.date,
            item.vehicleid,
            item.quantity,
            item.rate,
            item.amount,
          ];
          console.log(data);
          tableRows.push(data);
          totalamount = totalamount + item.amount;
          totalquantity = totalquantity + item.quantity;
        });
        doc.setFontSize(14);
        doc.setTextColor(86, 150, 214);
        doc.text(16, 20, "Fuel Report ");
        doc.setFontSize(10);
        doc.text(80, 20, `StartDate:${sd}`);
        doc.text(150, 20, `EndDate:${ed}`);

        doc.autoTable(tableColumn, tableRows, { startY: 25 });
        var body = [[totalamount, totalquantity]];
        doc.autoTable(tabletoatal, body, { startY: 70, theme: "striped" });

        function footer() {
          doc.text(150, 285, "***this is a e-generated report"); //print number bottom right
          doc.page++;
        }

        doc.save("data.pdf");
      });
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br /> <br />
      <div className="col-8  mx-xl-auto px-xl-5 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Fuellog Report</div>
            <br />
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form>
                    <div>
                      {" "}
                      <select
                        id="vehicle"
                        type="text"
                        placeholder="vehicle"
                        value={fuellogreport.vehicleid}
                        onChange={(e) => rhandle(e, "vehicleid")}
                      >
                        <option>select vehicle</option>
                        {vehicle.map((vehicle) => {
                          return (
                            <option value={vehicle.truckinfo}>
                              {vehicle.truckinfo}
                            </option>
                          );
                        })}
                      </select>{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <label for="StartDate">StartDate</label>
                      &nbsp;{" "}
                      <input
                        id="startdate"
                        type="date"
                        placeholder="StartDate"
                        value={fuellogreport.startdate}
                        onChange={(e) => rhandle(e, "startdate")}
                      />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <label for="EndDate">EndDate</label>
                      &nbsp;
                      <input
                        id="enddate"
                        type="date"
                        placeholder="EndDate"
                        value={fuellogreport.enddate}
                        onChange={(e) => rhandle(e, "enddate")}
                      />{" "}
                    </div>
                    <br />
                    <br />
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn btn-primary"
                      onClick={(e) =>
                        rSubmit(
                          e,
                          fuellogreport.vehicleid,
                          fuellogreport.startdate,
                          fuellogreport.enddate
                        )
                      }
                    >
                      Download PDF
                    </button>{" "}
                  </form>{" "}
                  {loading && <div className="loading"></div>}
                </div>{" "}
              </div>
            </div>{" "}
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default Fuelreport;
