
import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  // Import Modal and Button from react-bootstrap

import axios from "../api/baseurl";

const AdditionalPriceHistoryModal = ({ onClose, additionalPriceHistory}) => {
  
    useEffect(() => {
    
  }, []);
  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Additional Price History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Additional Price</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(additionalPriceHistory) && additionalPriceHistory.map((entry) => (
              <tr key={entry.id}>
                <td>{entry.date}</td>
                <td>{entry.additionalPrice}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}


export default AdditionalPriceHistoryModal;
