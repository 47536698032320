import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";
import { Modal,Button } from "react-bootstrap";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let params = useParams();

    return <Component {...props} params={params} />;
  }
  return ComponentWithRouterProp;
}
function Exemptedcustomercategoryedit({ isOpen, onRequestClose, Id }) {

  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const id=useParams();
  const exemptedid=id.id;
  const [exemptedcustomercategory2, setexemptedcustomercategory2] = useState({
    id:0,
    orgcode:"",
    categoryname: "",
    

    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    await axios
      .get(`/api/exemptedcustomercategories/${Id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setexemptedcustomercategory2(res.data);
        setLoading(false);
      })
      .catch((error)=>{
        setLoading(false);
        handleError(error);
      })
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  useEffect(() => {
    getData();
  }, []);

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        "/api/exemptedcustomercategories/update",
        exemptedcustomercategory2,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success(" updated successfully");
        navigate("/users/exemptedcategory");
        onRequestClose();
      })

      .catch((error) => {
       setLoading(false);
       handleError(error);
      });
  }

  const rhandle = (event, field) => {
    let actualValue = event.target.value;

    setexemptedcustomercategory2({ ...exemptedcustomercategory2, [field]: actualValue });
  };
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update</Modal.Title>
    </Modal.Header>
    <Modal.Body>
     

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                  <label htmlFor="productname">Id</label>

                  <input
                    
                    class="form-control"
                    id="id"
                    type="text"
                    placeholder="id"
                    value={exemptedcustomercategory2.id}
                    readOnly
                  />
                  <br />
                  <label htmlFor="productname">Orgcode</label>

                  <input
                    
                    class="form-control"
                    id="orgcode"
                    type="text"
                    placeholder="orgcode"
                    value={exemptedcustomercategory2.orgcode}
                    onChange={(e) => rhandle(e, "orgcode")}

                  />
                  <br />
                  <label htmlFor="productname">Category Name</label>

                 
                 
                  <input
                      class="form-control"
                      id="categoryname"
                      type="text"
                      placeholder="categoryname"
                      value={exemptedcustomercategory2.categoryname}
                      onChange={(e) => rhandle(e, "categoryname")}

                    />
                    <br />
                   
                   
                    <input
                      type="submit"
                      value="Update"
                      className="btn btn-primary"
                    />
                     &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                  </form>
                </div>
              </div>
            </div>

         </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}

export default Exemptedcustomercategoryedit;
