import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import { useParams,useNavigate,useLocation } from "react-router-dom";
import { GoogleMap, Marker,Circle,InfoWindow} from '@react-google-maps/api'; // Import GoogleMap and Marker from react-google-maps
import { Link } from "react-router-dom";
import axios from "../auth/baseurl";
import { Button, Grid, TextField } from '@mui/material';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FcHeatMap } from 'react-icons/fc';
import { IoSearchOutline } from "react-icons/io5";
import { styled } from '@mui/material/styles';
import LocationMap from "./Locationmap";
import HeatMap from "./HeatMap";
const centerOfIndia = { lat: 22.3511148, lng: 78.6677428 };

const StyledButton = styled(Button)({
  marginRight: 10,
});
 
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
   let location = useLocation();
   let navigate = useNavigate();
 
  let params = useParams();
 
  return (
 
  <Component
  {...props}
  location={location}
  params={params}
  navigate={navigate}
  />
   );
  }
   return ComponentWithRouterProp;
  }
 
 
 
 
 
 
const MapComponent = (props) => {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const { deviceid } = useParams();
  const { truckinfo } = useParams();
  
  const { userid } = useParams();
  console.log("User ID from useParams:", userid); // Check if userid is correctly fetched
  const [agents, setAgents] = useState([]);
  const [api, setapi] = useState([]);
  const [showLocation, setShowLocation] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [showHeat, setShowHeat] = useState(false);
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Define searchTerm
  const [clickedAgent, setClickedAgent] = useState(null);
  const [image, setImage] = useState(null);
  const [imageMeta, setImageMeta] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

 
 
  useEffect(() => {
    const { state } = location;
    const { image, imageMeta } = state || {};
    setImage(image);
    setImageMeta(imageMeta);
    console.log("image:", image);
    console.log("imageMeta:", imageMeta);
  
  }, [location]);

 
 
 
  // Function to update the marker's position
  const updateMarker = (latitude, longitude) => {
    if (marker && map) {
      marker.setPosition({ lat: latitude, lng: longitude });
      map.panTo({ lat: latitude, lng: longitude });
    }
  };
 
 
  useEffect(() => {
    // Initialize the Google Map
    const initializeMap = async () => {
      // Fetch appname and apikey from the API
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response1 = await axios.get(`/api/Locationtrack/userid=${ JSON.parse(localStorage.getItem("data")).userid}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setapi(response1.data);
        const googleMapScript = document.createElement('script');
        googleMapScript.src = ``;
        googleMapScript.async = true;
        googleMapScript.defer = true;
   
   
    const url =  process.env.REACT_APP_CONVOIZ_URL;
 
    console.log(url);
 
    const fetchData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`${url}/api/location/deviceid=${props.params.deviceid}`, {
          headers: {
            'AppName': `${api.appname}`,
            'APIKey': `${api.apikey}`,
       
              },
        })
      // Check if the response contains data and if it's an array
      setAgents(response.data);
      setFilteredAgents(response.data);

    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };
 
  const initializeMap = () => {
    const googleMap = new window.google.maps.Map(document.getElementById("map"), {
      center: centerOfIndia,
      zoom: 6,
    });
 
    setMap(googleMap);
 
    // Add a marker at the center
    const centerMarker = new window.google.maps.Marker({
      position: centerOfIndia,
      map: googleMap,
    });
 
    setMarker(centerMarker);
 
 
  fetchData();
};
googleMapScript.onload = () => {
  initializeMap();
 
  // Refresh the map and update location every 10 seconds
  const intervalId = setInterval(() => {
    fetchData();
  }, 10000);
 
  // Clear the interval when the component is unmounted
  return () => clearInterval(intervalId);
};
 
 
googleMapScript.onerror = () => {
  console.error("Error loading Google Maps script");
};
 
 
document.head.appendChild(googleMapScript);
return () => {
  document.head.removeChild(googleMapScript);
};
} catch (error) {
  console.error("Error fetching appname and apikey:", error);
}
};
initializeMap();
 
}, [props.params.userid]);
const showLocationMap = () => {
  setShowLocation(true);
  setShowHeat(false);
};

const showHeatmap = () => {
  setShowLocation(false);
  setShowHeat(true);
};

const handleSearchChange = (event) => {
  const term = event.target.value.toLowerCase();
  setSearchTerm(term);
  const filtered = agents.filter(agent =>
    agent.name.toLowerCase().includes(term) || agent.deviceId.toLowerCase().includes(term)
  );
  setFilteredAgents(filtered);
};

const handleMarkerClick = (agent) => {
  if (clickedAgent && clickedAgent.id === agent.id) {
    setClickedAgent(null); // Close the InfoWindow if it's already open for the clicked agent
  } else {
    setClickedAgent(agent); // Open the InfoWindow for the clicked agent
  }
};
return (
 
<div>
<AdminDashboard />
 
<div className="dform-group d-flex align-items-center justify-content-between mt-8 mb-0" >
 <Link  to="/users/vehicledetail" style={{textDecoration:"none", position: "absolute", left: "300px", top: "100px"}}>
 <button className="btn btn-secondary" type="button">Back</button>
  </Link>
</div>
 
<Grid container spacing={1} alignItems="center" justify="flex-end" style={{ position: "absolute", left: "1000px", top: "100px" }}>
        <Grid item>
          <Button
            variant={showLocation ? "contained" : "outlined"}
            color="primary"
            onClick={showLocationMap}
            startIcon={<FaMapMarkerAlt />}
            style={{ width: '100%' }}
          >
            Location Map
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={showHeat ? "contained" : "outlined"}
            color="primary"
            onClick={showHeatmap}
            startIcon={<FcHeatMap />}
            style={{ width: '100%' }}
          >
            Heat Map
          </Button>
        </Grid>
      </Grid>
   
      {/* <div style={{ position: "absolute", left: "300px", top: "150px", width: '80%' }}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
        />
      </div> */}

<div id="map" style={{   position: "absolute", left: "300px", top: "240px", height: '370px', width: '75%' }}>
{showLocation && filteredAgents.map((agent) => (
 
<GoogleMap
      mapContainerStyle={{ height: '100%', width: '100%' }}
      center={  {lat: parseFloat(agent.latitude), lng: parseFloat(agent.longitude)}}
      zoom={10}
      key={agent.id}

>
<React.Fragment >
{console.log("Agent Data:", agent)}  
{console.log("Latitude:", parseFloat(agent.latitude))}    
{console.log("Longitude:", parseFloat(agent.longitude))}
 
<Circle
             key= {`circle_${agent.id}`}
            center={{ lat: parseFloat(agent.latitude), lng: parseFloat(agent.longitude) }}
            radius={3000}
            options={{
              fillColor: 'blue',
              fillOpacity: 0.1,
              strokeColor: 'blue',
              strokeWeight: 1,
            }}
          />
<Marker
            key= {agent.id}
            position={{ lat: parseFloat(agent.latitude), lng: parseFloat(agent.longitude) }}
            icon={{
              // path: window.google.maps.SymbolPath.CIRCLE,
              // fillColor: 'blue',
              // fillOpacity: 0.5,
              // strokeColor: 'blue',
              // strokeWeight: 2,
              // scale: 8,
              url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png', // URL to your custom pointer icon
              scaledSize: new window.google.maps.Size(40, 40), // Adjust the size as needed
 
            }}

            onClick={() => handleMarkerClick(agent)}

          />
           {clickedAgent && (
          <InfoWindow
            position={{ lat: parseFloat(clickedAgent.latitude), lng: parseFloat(clickedAgent.longitude) }}
            onCloseClick={() => setClickedAgent(null)}
          >
            <div>
              <p>DateTime: {clickedAgent.datetime}</p>
              <p>Local DateTime: {clickedAgent.localDateTime}</p>
              <p>Device ID: {clickedAgent.deviceid}</p>
              <p>Longitude: {clickedAgent.longitude}</p>
              <p>Latitude: {clickedAgent.latitude}</p>
            </div>
          </InfoWindow>
        )}
        
</React.Fragment>
</GoogleMap>
  
      ))}
      
    
    
     </div>
     {showLocation && <LocationMap agents={filteredAgents} userid={userid} image={image} imageMeta={imageMeta}/>} 
     {showHeat && <HeatMap userid={userid} image={image} imageMeta={imageMeta}/>} 
</div>
);
};
 
export default withRouter(MapComponent);