import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./Spinner.css";
function Dagentvehiclereport() {
  const [dagentvehiclereport, setDagentvehiclereport] = useState({
    vehicleid: "",
    userid: "",
    startdate: "",
    enddate: "",
  });

  const [vehicle, setVehicle] = useState([]);
  const [dadetails, setDadetails] = useState([]);
  const [dareport, setDareport] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(dadetails);
  const url = "/api/Returntransaction/create";
  const getData = async () => {
    const date = new Date();

    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;

    const vehicles = await axios.get(
      `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(vehicles);

    setVehicle(vehicles.data);
    const trans = await axios.get(
      `/api/authority/role=ROLE_DELIVERY_AGENTS/orgid=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(trans);

    setDadetails(trans.data);
  };

  useEffect(() => {
    getData();
  }, []);

  function rhandle(e, field) {
    let actualValue = e.target.value;

    setDagentvehiclereport({ ...dagentvehiclereport, [field]: actualValue });
  }
  const rSubmit = async (e, id, userid, sd, ed) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios
      .get(
        `/api/DagentandvehicleDo/vehicleid=${id}/startdate=${sd}/enddate=${ed}/userid=${userid}`,

        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setLoading(false);
        setDareport(response.data);
        const doc = new jsPDF();
        const tableColumn = ["Username", "Vehicleid", "Date"];
        const tableRows = [];
        doc.page = 1; // use this as a counter.

        response.data.forEach((item) => {
          const data = [item.deliveryagent, item.vehicleid, item.date];
          tableRows.push(data);
        });
        doc.setFontSize(14);
        doc.setTextColor(86, 150, 214);
        doc.text(16, 20, "Deliveryagent Report ");
        doc.setFontSize(10);
        doc.text(80, 20, `StartDate:${sd}`);
        doc.text(150, 20, `EndDate:${ed}`);

        doc.autoTable(tableColumn, tableRows, { startY: 25 });
        footer();
        function footer() {
          doc.text(150, 285, "***this is a e-generated report"); //print number bottom right
          doc.page++;
        }

        doc.save("data.pdf");
      });
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br /> <br />
      <div className="col-8  mx-xl-auto px-xl-5 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">
              DeliveryAgent Vehicle Mapping Report
            </div>
            <br />

            <div className="col-xl-12 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form>
                    <div>
                      <select
                        id="vehicle"
                        type="text"
                        placeholder="vehicle"
                        value={dagentvehiclereport.vehicleid}
                        onChange={(e) => rhandle(e, "vehicleid")}
                      >
                        <option>select vehicle</option>
                        {vehicle.map((vehicle) => {
                          return (
                            <option value={vehicle.truckinfo}>
                              {vehicle.truckinfo}
                            </option>
                          );
                        })}
                      </select>{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <select
                        id="userid"
                        type="text"
                        placeholder="deliveryagent"
                        value={dagentvehiclereport.userid}
                        onChange={(e) => rhandle(e, "userid")}
                      >
                        <option>select deliveryagent</option>
                        {dadetails.map((dadetails) => {
                          return (
                            <option value={dadetails.userid}>
                              {dadetails.username}
                            </option>
                          );
                        })}
                      </select>{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <label for="StartDate">StartDate</label>
                      <input
                        id="startdate"
                        type="date"
                        placeholder="StartDate"
                        value={dagentvehiclereport.startdate}
                        onChange={(e) => rhandle(e, "startdate")}
                      />{" "}
                      <label for="EndDate">EndDate</label>
                      <input
                        id="enddate"
                        type="date"
                        placeholder="EndDate"
                        value={dagentvehiclereport.enddate}
                        onChange={(e) => rhandle(e, "enddate")}
                      />{" "}
                    </div>
                    <br />
                    <br />

                    <button
                      type="submit"
                      value="Download"
                      disabled={loading}
                      className="btn btn-primary"
                      onClick={(e) =>
                        rSubmit(
                          e,
                          dagentvehiclereport.vehicleid,
                          dagentvehiclereport.userid,
                          dagentvehiclereport.startdate,
                          dagentvehiclereport.enddate
                        )
                      }
                    >
                      Download
                    </button>
                  </form>{" "}
                  {loading && <div className="loading"></div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dagentvehiclereport;
