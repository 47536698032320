import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Search from "./search";

import { doLogout, getCurrentUserDetail, isLoggedIn } from "../auth";
import { useNavigate } from "react-router-dom";
import Navigationdrawer from "./adminsidebar";
import Sidebar from "./managerSidebar";

import axios from "../api/baseurl";
import LayoutWithSideNav from "./layout";
import Dashboard from "./admindashboard";
function ManagerDashboard(props) {
  const navigate = useNavigate();
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState(undefined);
  const [searchterm, setSearchTerm] = useState([]);
  const searchName = async (text) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const res = await axios.get(`/api/search?key=${text}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(res.data);
    setSearchTerm(res.data);
    console.log("search:" + searchterm);
  };
  const removeData = () => {
    try {
      localStorage.removeItem("data");
      setLogin([]);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLogin(isLoggedIn());
    setUser(getCurrentUserDetail());
  }, [login]);
  return (
    <div>
      <div>
        <Dashboard />
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="col-xl-4 l-50  px-1 align-items-center mx-xl-auto">
        <div className="card card-raised d-inline-block">
          <table className="table table-bordered">
            <tbody>
              {searchterm.map((searchTerm) => {
                return (
                  <tr>
                    <td>{searchTerm.productid}</td>

                    <td>{searchTerm.productname}</td>
                    <td>{searchTerm.orgid}</td>
                    <td>{searchTerm.orgname}</td>
                    <td>{searchTerm.producttype}</td>
                    <td>{searchTerm.date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default ManagerDashboard;
