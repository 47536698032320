import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
//import { token } from "../api/baseurl";
import CreatableSelect from "react-select/creatable";
import { Modal, Button } from "react-bootstrap";

function SubModule({ isOpen, onRequestClose, subModelDetails }) {
  const [module, setModule] = useState({
    id: "",
    submodel: "",
    region: "",
    cost: "",
    features: [
      {
        features: "",
      },
    ],
  });
  const [loading, setLoading] = useState(false);
  const [feature, setFeature] = useState([]);
  const [subfeature, setSubfeature] = useState([]);
  const [regions, setRegions] = useState([]);
  const [value, setValue] = useState("");
  const [subfeaturedropdown, setSubfeaturedropdown] = useState([]);
  const [vdevice,setVdevice]= useState([])
  const navigate = useNavigate();

  const token = JSON.parse(localStorage.getItem("data")).token;
  const handleChange1 = (event, field) => {
    let actualValue = event.target.value;

    setModule({ ...module, [field]: actualValue });
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(module);
    const body = {
      ...module,
      features: value.map((v1) => ({
        features: v1.label,
      })),
    };

    let apiEndpoint = addMode ? "/api/submodelfeature" : "/api/submodelfeature/update";

  axios
    .post(apiEndpoint, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      onRequestClose();
      setLoading(false);
      toast.success(addMode ? "Subscription model added Successfully" : "Subscription model updated Successfully");
    })
    .catch((error) => {
      setLoading(false);
      handleError(error);
    });
};

  const handleCreate = (inputValue) => {
    const newValue = [
      ...subfeaturedropdown,
      { value: inputValue, label: inputValue },
    ];
    setSubfeaturedropdown(newValue);
    console.log(inputValue, "inputvalue");
  };

  const getData = async () => {

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;

      const regionResponse = await axios.get(`/api/subscription-region`, {

        headers: { Authorization: `Bearer ${token}` },

      });
      const featuresResponse = await axios.get(`/api/subscription-features`, {

        headers: { Authorization: `Bearer ${token}` },

      });
      const modelResponse = await axios.get(`/api/subscription-model`, {

        headers: { Authorization: `Bearer ${token}` },

      });

      setRegions(regionResponse.data); // Ensure 'regionResponse.data' is an array

      setSubfeature(featuresResponse.data);

      setSubfeaturedropdown(featuresResponse.data.map((sf) => ({ value: sf.features, label: sf.features })));

      setVdevice(modelResponse.data);

    } catch (error) {
      handleError(error);
    }

  };


  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  
  const handleChange = (newValue) => {
    console.log(newValue); // Check if newValue is correct
    setValue(newValue); // Update value state
  };
// Assuming 'addMode' is a state variable that indicates whether you're adding or editing
const addMode = !subModelDetails;

  useEffect(() => {

    getData();
    if (addMode) {
      setValue([]); // Clear selected features when in add mode
      setModule({
        submodel: "",
        cost: "",
        region: "",
        features: [],
      }); // Clear other fields in add mode
    } else if (subModelDetails) {
      setValue(subModelDetails.features?.map((feature) => ({
        value: feature.features,
        label: feature.features,
      })) || []); // Set selected features when in edit mode
  
      setModule({
        id:subModelDetails.smodel?.id || "",
        submodel: subModelDetails.smodel?.submodel || "",
        cost: subModelDetails.smodel?.cost || "",
        region: subModelDetails.smodel?.region || "",
        features: subModelDetails.features?.map((feature) => ({
          value: feature.features,
          label: feature.features,
        })) || [],
      }); // Set other fields in edit mode
    }
  }, [addMode, subModelDetails]);


  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Create Subscription Model</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4 ">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label for="submodel ">Submodel </label>
                      <input
                        className="form-control"
                        id="submodel"
                        value={module.submodel}
                        onChange={(e) => handleChange1(e, "submodel")}
                      />
                    </div>
                  </div>
                  <div className="form-floating-sm mb-2">

                    <label for="region"> Region</label>

                    <select className="form-control"
                      id="Region"
                      value={module.region}
                    onChange={(e) => handleChange1(e, "region")}>

                        <option>select region</option>

                        {regions.length > 0 &&
                      regions.map((reg) => (
                        <option key={reg.region} value={reg.region}>
                          {reg.region}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label for="cost"> Cost</label>

                      <input
                        className="form-control"
                        id="cost"
                        value={module.cost}
                        onChange={(e) => handleChange1(e, "cost")}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label for="features"> Features</label>

                      <CreatableSelect
            value={value}
            onChange={handleChange}
        isClearable
        isMulti
        options={subfeaturedropdown}
      />
 
                      {/* <CreatableSelect
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={(newValue) => setValue(newValue)}
      onCreateOption={handleCreate}
      options={subfeature.map((sf)=> {return `${sf.features}`
    })}
    /> */}
                    </div>
                  </div>

                  <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
    

                />
                 &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  
             
              </form>
            </div>
            {loading && <div className="loading"></div>} 
          </div>
        </div>

      </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
}

export default SubModule;
