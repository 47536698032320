import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import MonthlyTarget from "./Monthlytarget";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

function Productdetails() {
  const [product, setProduct] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null); 
  const [selectedYear, setSelectedYear] = useState(null); 
  const [modalData, setModalData] = useState({
    date: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    products: [], // Initialize products as an empty array
  });


  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
    setLoader(true);

    try {
      const monthlyTargetUrl = `/api/productmonthlytarget/orgcode=${orgCode}`;
      const monthlyTargetResponse = await axios.get(monthlyTargetUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      let filteredData = [...monthlyTargetResponse.data];
      
      if (selectedMonth !== null && selectedYear !== null) {
        filteredData = filteredData.filter(item =>
          new Date(item.date).getMonth() === selectedMonth &&
          new Date(item.date).getFullYear() === selectedYear
        );
      } else if (selectedMonth !== null) {
        filteredData = filteredData.filter(item => new Date(item.date).getMonth() === selectedMonth);
      } else if (selectedYear !== null) {
        filteredData = filteredData.filter(item => new Date(item.date).getFullYear() === selectedYear);
      }
      setProduct(filteredData);

      if (selectedDate !== null) {
        const modalUrl = `/api/productmonthlytarget/orgcode=${orgCode}/date=${selectedDate}`;
        const modalResponse = await axios.get(modalUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setModalData([...modalResponse.data]);
        console.log(modalResponse.data);
      }

      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      // toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [selectedDate, selectedMonth, selectedYear]); 

  const handleViewDetails = (date) => {
    setSelectedDate(date);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalData(null);
  };
  const handleAddProduct = () => {
    setIsModalOpen(true);
    setProduct([]);


  };

  const handleaddCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.toLocaleString("default", { month: "long" }); 
    return `${year} ${month}`;
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      await axios.post(`/api/productmonthlytarget/update`, modalData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Target Updated Successfully");
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error: Failed to update profile");
    } finally {
      setLoader(false);
    }
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    // Update the target value of the corresponding element in modalData
    setModalData(prevData => {
      const newData = [...prevData]; // Create a copy of modalData
      newData[index] = {...newData[index], target: value}; // Update target value
      return newData;
    });
  };
  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="col-10 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex">
              <div className="card-title">Monthly Target</div>
              {""}
              {loader && (
        <div
          className="loader"
          style={{ position: "absolute", left: "180px", top: "15px" }}
        ></div>
      )}
              <span style={{ marginRight: '80px' }}></span>

              <div className="d-flex align-items-center">
              <DatePicker
  selected={new Date(selectedYear || new Date().getFullYear(), selectedMonth || new Date().getMonth())}
  onChange={date => {
    setSelectedYear(date.getFullYear());
    setSelectedMonth(date.getMonth());
  }}
  showMonthYearPicker
  dateFormat="MM/yyyy"
/>
              </div>
            
            <br />
       
              <button className="btn btn-primary" style={{marginLeft:"40%"}} onClick={handleAddProduct}>
               New
            </button>
            </div>
            <br />
            <table className="table table-bordered">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                  <th>Year & Month</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {product.length === 0 ? (
                  <tr>
                    <td colSpan="2" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  product.map((currentProduct, index) => {
                    const isFirstRow = index === 0;
                    const prevDate = isFirstRow
                      ? null
                      : product[index - 1].date;
                    const currentDate = currentProduct.date
                    const showDate = isFirstRow || prevDate !== currentDate;

                    return (
                      <tr key={currentProduct.id}>
                        {showDate && (
                          <>
                  <td>{formatDate(currentProduct.date)}</td>
                            <td>
                              <button
                                onClick={() =>
                                  handleViewDetails(currentProduct.date)
                                }
                              >
                                View
                              </button>
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Monthly Target details </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <div className="modal-body">
                {loader ? (
                  <p>Loading...</p>
                ) : (
                  <div>
                     <form onSubmit={handleSubmit}>

                {modalData && modalData.length > 0 ? (
         <table className="table">
          <thead>
    <tr>
      <th>Date: {modalData[0].date}</th>
      <th>Orgcode: {modalData[0].orgcode}</th>
    </tr>
    <tr>
      <th>Product Code</th>
      <th>Product Name</th>
      <th>Target</th>
    </tr>
    </thead>
    <tbody>
      {modalData.map((data,index) => (
        <tr key={data.id}>
          
          <td>{data.productcode}</td>
          <td>{data.productname}</td>
          <td>
          <input
            className="form-control"
            type="text"
            value={data.target}
            onChange={(e) => handleChange(e, index)}
          />
        </td>  
              </tr>
      ))}
    </tbody>
  </table>
  
) : (
  <p>No data available for the selected date</p>


)}
  <br />
                <input
                  type="submit"
                  value="save changes"
                  className="btn btn-primary"
                />
</form>
                </div>
              )}
            </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

<MonthlyTarget isOpen={isModalOpen} onRequestClose={handleaddCloseModal} />

    </div>
  );
}

export default Productdetails;
