import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { token } from "../auth/service";
import moment from "moment";
import { IoSearchOutline } from "react-icons/io5";
import axios from '../api/baseurl';
import { GoogleMap, LoadScript, Marker, InfoWindow, Circle } from '@react-google-maps/api';
import { calculateSpeed } from './utils';
import { TextField, Button, Box, Typography, Card, CardContent, Grid } from '@mui/material';
import Showtable from './Locationtable';
 
// Center of India coordinates
const centerOfIndia = { lat: 22.3511148, lng: 78.6677428 };
const googleMapsApiKey = "AIzaSyCI0IsM1EWVG5PKM4mCHNdV6NK8Lja4-lM";
 
const LocationMap = ({  userid  , image, imageMeta}) => {
  const { deviceid, truckinfo } = useParams();
  console.log("imagelocationmap:", image);
  console.log("imageMetalocationmap:", imageMeta);

  console.log("deviceid:", deviceid);
  console.log("userid:", userid);
  console.log("truckinfo:", truckinfo);
  const navigate = useNavigate();
  const [mapCenter, setMapCenter] = useState(centerOfIndia);
  const [agents, setAgents] = useState([]);
  const [vehi, setVehi] = useState([]);
  const [date1, setDate1] = useState(moment().format("YYYY-MM-DD"));
  const [date2, setDate2] = useState(moment().format("YYYY-MM-DD"));
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedMarkerSpeed, setSelectedMarkerSpeed] = useState(null);
  const [searchState, setSearchState] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [api, setApi] = useState({});

  const Apiurl = process.env.REACT_APP_CONVOIZ_URL;
  // const Apiurl = process.env.REACT_APP_BASE_URL
 
  useEffect(() => {

    const fetchApiDetails = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response1 = await axios.get(`/api/Locationtrack/userid=${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setApi(response1.data);
        console.log(response1.data);
      } catch (error) {
        console.error("Error fetching API details:", error);
      }
    };

    fetchApiDetails();
  }, [userid]);

  useEffect(() => {
    console.log("appname:", api.appname);
    console.log("apikey:", api.apikey);
    axios.get(`${Apiurl}/api/location/device=${deviceid}`, {
  headers: {
            'AppName': `${api.appname}`,
            'APIKey': `${api.apikey}`,
          },
            })
    .then(response => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        const latestLocation = response.data[response.data.length - 1];
        setAgents(response.data);
        setMapCenter({
          lat: parseFloat(latestLocation.latitude),
          lng: parseFloat(latestLocation.longitude),
        });
      } else {
        console.error("Invalid data format received from the API:", response.data);
      }
    })
    .catch(error => {
      console.error("Error fetching agents:", error);
    });
  }, [deviceid, api.appname, api.apikey, Apiurl]);
 
  useEffect(() => {
    if (vehi.length > 0) {
      const latestLocation = vehi[vehi.length - 1];
      setMapCenter({
        lat: parseFloat(latestLocation.latitude),
        lng: parseFloat(latestLocation.longitude),
      });
    }
  }, [vehi]);
 
  const handleChange = (e, field) => {
    if (field === 'startdate') {
      setDate1(e.target.value);
    } else if (field === 'enddate') {
      setDate2(e.target.value);
    }
  };
 
  const handleStartAndEndDate = async () => {
    setSearchState(true);
    try {
      const response = await axios.get(`${Apiurl}/api/LocationDo/deviceid=${deviceid}/startdate=${moment(date1, "YYYY-MM-DD").format("DD-M-YYYY")}/enddate=${moment(date2, "YYYY-MM-DD").format("DD-M-YYYY")}`, {
        headers: {
          'AppName': `${api.appname}`,
          'APIKey': `${api.apikey}`,
        },
      });
      setVehi(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onClickViewTable = () => {
    setIsModalOpen(true); // Open the modal when the button is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };
 
  const pinSymbol = (color) => {
    return {
      path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
      fillColor: color,
      fillOpacity: 1,
      strokeColor: '#000',
      strokeWeight: 2,
      scale: 1,
    };
  };
 
  const drawMarker = () => {
    if (searchState) {
      return vehi.map((agent, index) => {
        if (index > 0) {
          const prevAgent = vehi[index - 1];
          const speed = calculateSpeed(
            parseFloat(prevAgent.latitude),
            parseFloat(prevAgent.longitude),
            prevAgent.datetime,
            parseFloat(agent.latitude),
            parseFloat(agent.longitude),
            agent.datetime
          );
          return (
            <Marker
              key={agent.id}
              position={{
                lat: parseFloat(agent.latitude),
                lng: parseFloat(agent.longitude),
              }}
              icon={{
                url: `data:image/png;base64,${image}`,
                scaledSize: new window.google.maps.Size(40, 40),
              }}
              label={{
                text: (index + 1).toString(),
                color: 'black',
                fontWeight: 'bold',
              }}
              onClick={() => {
                setSelectedMarker(agent);
                setSelectedMarkerSpeed(speed);
              }}
            />
          );
        } else {
          return null;
        }
      });
    } else {
      return agents.map((agent) => (
        <Marker
          key={agent.id}
          position={{ lat: parseFloat(agent.latitude), lng: parseFloat(agent.longitude) }}
          icon={{
            url: `data:image/png;base64,${image}`,
            scaledSize: new window.google.maps.Size(40, 40),
          }}
          onClick={() => {
            setSelectedMarker(agent);
            setSelectedMarkerSpeed(agent.speed);
          }}
        >
          {selectedMarker === agent && (
            <InfoWindow >
              <div>
                <p>Latitude: {agent.latitude}</p>
                <p>Longitude: {agent.longitude}</p>
                <p>Datetime: {agent.datetime}</p>
                <p>Speed: {agent.speed} km/h</p>
              </div>
            </InfoWindow>
          )}
        </Marker>
      ));
    }
  };
 
  return (
    <div style={{   position: "absolute", left: "300px", top: "140px", height: '370px', width: '75%' }}>
  
     <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Device ID: {deviceid}
        <span style={{marginRight:"12px"}}></span>
        Vehicle : {truckinfo}
      </Typography>
      <Card>
        <CardContent>
          <Typography variant="body1">
            Last Data Date time: {agents.length > 0 && agents[agents.length - 1].datetime}
          </Typography>
          <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
            <Grid item xs={12} md={5}>
              <TextField
                label="Start Date"
                type="date"
                fullWidth
                value={date1}
                onChange={(e) => handleChange(e, "startdate")}
                InputLabelProps={{
                  shrink: true,
                 
                }}
              />
             
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                label="End Date"
                type="date"
                fullWidth
                value={date2}
                onChange={(e) => handleChange(e, "enddate")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleStartAndEndDate}
                startIcon={<IoSearchOutline />}
                style={{width: '100%'}}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2, height: 500, width: '100%' }}>
            <LoadScript googleMapsApiKey={googleMapsApiKey}>
              <GoogleMap
                mapContainerStyle={{ height: '100%', width: '100%' }}
                center={mapCenter}
                zoom={7}
                tilt={45}
              >
                {agents.map((agent) => (
                  <React.Fragment key={agent.id}>
                    <Circle
                      center={{ lat: parseFloat(agent.latitude), lng: parseFloat(agent.longitude) }}
                      radius={3000}
                      options={{
                        fillColor: 'blue',
                        fillOpacity: 0.1,
                        strokeColor: 'blue',
                        strokeWeight: 1,
                      }}
                    />
                    <Marker
                      position={{ lat: parseFloat(agent.latitude), lng: parseFloat(agent.longitude) }}
                      icon={{
                        path: window.google.maps.SymbolPath.CIRCLE,
                        fillColor: 'blue',
                        fillOpacity: 0.5,
                        strokeColor: 'blue',
                        strokeWeight: 2,
                        scale: 8,
                      }}
                    />
                  </React.Fragment>
                ))}
                {drawMarker()}
                {selectedMarker && (
                  <InfoWindow
                    position={{
                      lat: parseFloat(selectedMarker.latitude),
                      lng: parseFloat(selectedMarker.longitude),
                    }}
                   
                  >
                    <div>
                      <p>Latitude: {selectedMarker.latitude}</p>
                      <p>Longitude: {selectedMarker.longitude}</p>
                      <p>Datetime: {selectedMarker.datetime}</p>
                      <p>Speed: {selectedMarker.speed} km/h</p>
                    </div>
                  </InfoWindow>
                )}
              </GoogleMap>
            </LoadScript>
          </Box>
        
        </CardContent>
      </Card>
    </Box>
     <Button
        variant="contained"
        color="primary"
        onClick={onClickViewTable}
        style={{ marginTop: '1rem' }}
      >
        Show Table
      </Button>
      <Showtable isOpen={isModalOpen} onRequestClose={handleCloseModal}  userid={userid}  />

     
    </div>
  );
};

export default LocationMap;