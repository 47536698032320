import { Link, Navigate } from "react-router-dom";

function SuperSidebar() {
  return (
    <div id="layoutDrawer">
      <div id="layoutDrawer_nav">
        <nav
          className="drawer accordion drawer-light bg-white"
          id="drawerAccordion"
        >
          <div className="drawer-menu">
            <div className="nav">
              <div className="drawer-menu-heading d-sm-none">Account</div>

              <a className="nav-link d-sm-none" href="#!">
                <div className="nav-link-icon">
                  <i className="material-icons">notifications</i>
                </div>
                Notifications
              </a>

              <a className="nav-link d-sm-none" href="#!">
                <div className="nav-link-icon">
                  <i className="material-icons">mail</i>
                </div>
                Messages
              </a>

              <div className="drawer-menu-divider d-sm-none"></div>

              <div className="drawer-menu-heading">Interface</div>

              <div className="nav-link">
                <div class="nav-link-icon">
                  <i class="material-icons">language</i>
                </div>
                Overview
              </div>

              <Link
                className="nav-link collapsed"
                href="javascript:void(0);"
                data-bs-target="#collapseDashboards"
                aria-controls="collapseDashboards"
                to="/users/approval"
              >
                <div className="nav-link-icon">
                  <i className="material-icons">dashboard</i>
                </div>
                Super Approval
              </Link>
              <Link
                className="nav-link collapsed"
                href="javascript:void(0);"
                data-bs-target="#collapseDashboards"
                aria-controls="collapseDashboards"
                to="/users/superinoutgoingtransaction"
              >
                <div className="nav-link-icon">
                  <i className="material-icons">dashboard</i>
                </div>
                Transaction Outgoing Details
              </Link>
              <Link
                className="nav-link collapsed"
                href="javascript:void(0);"
                data-bs-target="#collapseDashboards"
                aria-controls="collapseDashboards"
                to="/users/superincomingtransaction"
              >
                <div className="nav-link-icon">
                  <i className="material-icons">dashboard</i>
                </div>
                Transaction Incoming Details
              </Link>
              <Link
                className="nav-link collapsed"
                href="javascript:void(0);"
                data-bs-target="#collapseDashboards"
                aria-controls="collapseDashboards"
                to="/users/superloadin"
              >
                <div className="nav-link-icon">
                  <i className="material-icons">dashboard</i>
                </div>
                Loadin Details
              </Link>
              <Link
                className="nav-link collapsed"
                href="javascript:void(0);"
                data-bs-target="#collapseDashboards"
                aria-controls="collapseDashboards"
                to="/users/superloadout"
              >
                <div className="nav-link-icon">
                  <i className="material-icons">dashboard</i>
                </div>
                Loadout Details
              </Link>
              <div
                className="collapse"
                id="collapseDashboards"
                aria-labelledby="headingOne"
                data-bs-parent="#drawerAccordion"
              ></div>
            </div>
          </div>

          <div className="drawer-footer border-top">
            <div className="d-flex align-items-center">
              <i className="material-icons text-muted">account_circle</i>

              <div className="ms-3">
                <div className="caption">Logged in as:</div>

                <div className="small fw-500">
                  {JSON.parse(localStorage.getItem("data")).username}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default SuperSidebar;
