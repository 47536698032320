import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaTimes } from "react-icons/fa";
import "./Spinner.css";

const UpdateVehicleAreaAgentMap = ({ isOpen, onRequestClose, params, searchdate}) => {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
console.log("d",searchdate)
  const initialProductState = {
    vehicleid: params.vid,
    truckinfo: params.truckinfo,
    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
    date: searchdate,
    deliveryAgent: [
      {
        userid:"",
      }
    ],
    vehicleArea: [
      {
        areaname:""
      }
    ],
  };

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);
  const [dadetails, setDadetails] = useState([]);
  const [area, setArea] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [agentSearch, setAgentSearch] = useState("");
  const [areaSearch, setAreaSearch] = useState("");
  const [selectedAgents, setSelectedAgents] = useState([]);


  const url = "/api/vehicleareaagent/update";
  const navigate = useNavigate();

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      setLoading(true);
      const trans = await axios.get(
        `/api/authority/role=ROLE_DELIVERY_AGENTS/orgid=${JSON.parse(
          localStorage.getItem("data")
        ).orgcode}/isavailable=true`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setDadetails(trans.data);
      const Area = await axios.get(
        `/api/AreaMaster/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setArea(Area.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    // Validation: Ensure at least one area and one agent are selected
    if (selectedAreas.length === 0 || selectedAgents.length === 0) {
      toast.error("Please select at least one area and one agent.");
      setLoading(false);
      return;
    }
  
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      // Create a copy of the newProduct state
      const updatedProduct = {
        ...newProduct,
        vehicleArea: selectedAreas.map((area) => ({ areaname: area })),
        deliveryAgent: selectedAgents.map((agent) => ({ userid: agent.userid })),
      };
  
      console.log("Request Data:", updatedProduct); // Log request data for debugging
  
      const response = await axios.post(url, updatedProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      console.log("Response Data:", response.data); // Log response data for debugging
  
      setLoading(false);
      toast.success("Vehicle Area Agent Mapped successfully");
      onRequestClose();
      setNewProduct(initialProductState);
      setSelectedAreas([]);
      setSelectedAgents([]); // Reset selected agents
    } catch (error) {
      console.error("Error:", error); // Log the full error object for debugging
      handleError(error);
    }
  };
  

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 400) {
      toast.error(error.response.data.code);
    } else {
      toast.error("An unexpected error occurred");
    }
    setLoading(false);
  };

  const rhandle = (e) => {
    const { id, value } = e.target;
    setNewProduct((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleAgentChange = (e) => {
    setAgentSearch(e.target.value);
  };

  const handleAreaChange = (e) => {
    setAreaSearch(e.target.value);
  };

  const handleAddAgent = (e) => {
    const agentName = e.target.value;
    const agent = dadetails.find((dadetail) => dadetail.name === agentName);
    if (agent) {
      setSelectedAgents((prevAgents) => [...prevAgents, agent]);
    }
  };
  
  const handleAddArea = (e) => {
    const area = e.target.value;
    setSelectedAreas((prevAreas) => [...prevAreas, area]);
  };
  const filteredAgents = dadetails.filter((dadetail) =>
    dadetail.name && dadetail.name?.toLowerCase().includes(agentSearch.toLowerCase())
  );
  
  const filteredAreas = area.filter((area) =>
    area.areaname && area.areaname?.toLowerCase().includes(areaSearch.toLowerCase())
  );

  const handleRemoveAgent = (index) => {
    setSelectedAgents((prevAgents) =>
      prevAgents.filter((agent, i) => i !== index)
    );
  };
  
  const handleRemoveArea = (index) => {
    setSelectedAreas((prevAreas) =>
      prevAreas.filter((area, i) => i !== index)
    );
  };
  
  
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Vehicle Area and Agent Mapping</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div>
                  <p>Vehicle Info: {params.truckinfo}</p>
                </div>
                <div>
                  <label htmlFor="date">Date: </label>
                  <input
                    id="date"
                    type="date"
                    placeholder="date"
                    value={newProduct.date}
                    onChange={rhandle}
                   
                  />
                </div>
                <br />
                <div>
                <table className="table table-bordered mt-4">
  <thead>
    <tr>
      <th>Agents</th>
      <th>Areas</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <ul>
          {selectedAgents.map((agent, index) => (
            <li key={`agent-${index}`}>
                {agent.name}{" "}
              <FaTimes
                className="remove-icon"
                title="Remove Agent"
                style={{cursor:"pointer"}}
                onClick={() => handleRemoveAgent(index)}
              />
            </li>
          ))}
        </ul>
      </td>
      <td>
        <ul>
          {selectedAreas.map((area, index) => (
            <li key={`area-${index}`}>
              {area}{" "}
              <FaTimes
                className="remove-icon"
                title="Remove Area"
                style={{cursor:"pointer"}}
                onClick={() => handleRemoveArea(index)}
              />
            </li>
          ))}
        </ul>
      </td>
    </tr>
  </tbody>
</table>

<div className="mt-3 d-flex align-items-center">
  <select
    className="form-select me-2"
    value={selectedAgent}
    onChange={handleAddAgent}
  >
    <option value="">Select Delivery Agent</option>
    {filteredAgents.map((dadetail) => (
      <option key={dadetail.userid} value={dadetail.name}>
        {dadetail.name}
      </option>
    ))}
  </select>
  <select
    className="form-select me-2"
    value={selectedArea}
    onChange={handleAddArea}
  >
    <option value="">Select Area</option>
    {filteredAreas.map((area) => (
      <option key={area.id} value={area.areaname}>
        {area.areaname}
      </option>
    ))}
  </select>
</div>

                </div>
                <br />
                <div className="form-group d-flex align-items-center justify-content-between">
                  <Button variant="secondary" onClick={onRequestClose}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary">
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateVehicleAreaAgentMap;
