import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import "./Spinner.css";
import { Modal, Button } from "react-bootstrap";

const Pullproducts= ({ isOpen, onRequestClose }) => {
    const [product, setProduct] = useState([]);
    const [loading, setLoading] = useState(null);
    const [loader, setLoader] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [superProducts, setSuperProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const navigate = useNavigate();
   
      const getData = async () => {
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
  
          const productResponse = await axios.get(`/api/product`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setProduct([...productResponse.data]);
  
          const superProductsResponse = await axios.get(`/api/super-products`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setSuperProducts(superProductsResponse.data);
        } catch (error) {
          handleError(error);
        }
      };
  
    useEffect(() => {
      getData();
      
    }, [isCreateModalOpen]);
   
    const onClickhandler = async (e, id) => {
        setLoading(id);
        const checked = e.target.checked;
        const token = JSON.parse(localStorage.getItem("data")).token;
      
      
        try {
          const response = await axios.get(`/api/product/productUpdate/id=${id}/value=${checked}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          toast.success("Product status updated");
        } catch (error) {
          handleError(error);
        } finally {
          setLoading(null);
          getData();
        }
      };
  
      const untrackedProducts = product.filter((product) => {
        const isTracked = superProducts.some(
            (superProduct) => superProduct.productName === product.productname
        );
        return !isTracked;
    });
  
    
   
    const handleCreateAdminProduct = async (products) => {
      try {
        console.log('Function called with products:', products); // Debug log
      
        const token = JSON.parse(localStorage.getItem("data")).token;
        const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
        const date = new Date();
        date.setDate(date.getDate());
        let day = date.getDate().toString().padStart(2, "0");
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let year = date.getFullYear();
        let currentDate = `${year}-${month}-${day}`;  
    
        const productEntities = products.map(product => ({
          id: "",
          productName: product.productname,
          productCode: product.productcode,
          productStatus: product.productstatus,
          date: currentDate,
          netWeight: product.netWeight,
          productTypeId: product.productTypeId,
          hsnCode: product.hsnCode,
          gst: product.gst,
          image:product.image,
          imageMeta: product.imageMeta
      }));

          await axios.post(`/api/super-products/create-super-product`, productEntities, {
            headers: { Authorization: `Bearer ${token}` },
          });
        
  
        toast.success("Products added successfully to your account");
        getData();
      } catch (error) {
        handleError(error);
      }
    };
   
  
    const handleSelectProduct = (product) => {
      setSelectedProducts((prevSelectedProducts) => {
        if (prevSelectedProducts.includes(product)) {
          return prevSelectedProducts.filter((p) => p !== product);
        } else {
          return [...prevSelectedProducts, product];
        }
      });
    };
  
 
    const handleError = (error) => {
      if (error.response && error.response.status === 404) {
        toast.error(error.response.data.message);
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred while creating the product");
      }
    };
    

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Pull Products</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {loader && <div className="loading"></div>} 

            <br />
            <table className="table table-bordered" style={{ fontSize: '14px' }}>
            <thead>
                <tr
                  style={{
                    fontSize: "15px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                   <th>Product Type</th>
                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>HSN Code</th>
                  <th>GST</th>
                  <th>Net weight</th>
                 
                  <th>Status</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>
                
                        {untrackedProducts.length === 0 ? (
                            <tr>
                                <td colSpan="8" className="text-center">
                                    No Data Available
                                </td>
                            </tr>
                        ) : (
                          
                            untrackedProducts.map((product) => (

                                <tr key={product.productid}>
                                    <td>{product.prodType.productType}</td>
                                    <td>
  <div style={{ textAlign: "center" }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {product.image && product.imageMeta ? (
        <img
          className="img-fluid rounded-circle mb-1"
          src={`data:image/png;base64,${product.image}`}
          alt="Product"
          style={{ maxWidth: "40px", maxHeight: "60px", marginRight: '10px' }}
        />
      ) : (
        <div style={{ width: "40px", height: "60px", marginRight: '10px' }}></div>
      )}
      <span style={{ marginRight: '10px' }}>{product.productname}</span>
                                            
    </div>
  </div>
</td>
                                    <td>{product.productcode}</td>
                                    <td>{product.hsnCode}</td>
                                    <td>{product.gst} %</td>
                                    <td>{product.netWeight} Kg</td>
                                    <td>
                                        <div className="form-check form-switch">
                                            {loading == product.id && (
                                                <div
                                                    className="loader"
                                                    style={{
                                                        position: "relative",
                                                        left: "55px",
                                                        top: "8px",
                                                    }}
                                                ></div>
                                            )}
                                            <input
                                                className="form-check-input"
                                                id="flexSwitchCheckDefault"
                                                type="checkbox"
                                                defaultChecked={product.productstatus}
                                                onClick={(e) => onClickhandler(e, product.id)}
                                                value={product.id}
                                            />
                                        </div>
                                    </td>
                                    <td>
                    <input
                      type="checkbox"
                      checked={selectedProducts.includes(product)}
                      onChange={() => handleSelectProduct(product)}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <Button
          variant="success"
          size="sm"
          onClick={() => handleCreateAdminProduct(selectedProducts)}
        >
          Pull Products
        </Button>
        <span style={{marginRight:"10px"}}></span>
            {/* <Modal.Footer> */}
          <Button variant="secondary"  size="sm" onClick={onRequestClose}>
            Close
          </Button>
        {/* </Modal.Footer> */}
           
      
      </Modal.Body>
    </Modal> 
  );
}

export default Pullproducts;
