import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const DepositAmountModal = ({ isOpen, onRequestClose, onSave }) => {
  const [validationMessage, setValidationMessage] = useState("");

  const [depositData, setDepositData] = useState([
    {
    referenceNumber: "",
    depositAmount: "",
    image: "",
    imageMeta: "",
  }
]);

  const handleSave = () => {
    // if (!depositData.image) {
    //   setValidationMessage("Please upload a document.");
    //   return;
    // }
    console.log("Saving data:", depositData);
    onSave(depositData);
    console.log("on",onSave);
    onRequestClose();
  };

  const handleChange = (e, field) => {
    const value = e.target.value;
    setDepositData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

 const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const convertedPdf = await getBase64FromFile(file);
      setDepositData((prevData) => ({
        ...prevData,
        image: convertedPdf.content,
        imageMeta: convertedPdf.metadata,
      }));
      setValidationMessage(""); // Clear validation message if file is selected
    }
  };

  const getBase64FromFile = (file) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");
        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Deposit Amount</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
              <form>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="referenceNumber">Deposit Reference Number</label>
                    <input
                      className="form-control"
                      id="referenceNumber"
                      type="text"
                      placeholder="Deposit Reference Number"
                      value={depositData.referenceNumber}
                      onChange={(e) => handleChange(e, "referenceNumber")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="depositAmount">Deposit Amount</label>
                    <input
                      className="form-control"
                      id="depositAmount"
                      type="text"
                      placeholder="0"
                      value={depositData.depositAmount}
                      onChange={(e) => handleChange(e, "depositAmount")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2 d-flex align-items-center">
                    <label htmlFor="file" className="me-1">Upload Document:</label>
                    <input
                      className="form-control smaller-input flex-grow-1"
                      id="image"
                      type="file"
                      placeholder="Upload document"
                      onChange={(e) => handleFileChange(e)}
                    />
                  </div>
                </div>
                {/* {validationMessage && (
                  <div className="alert alert-danger" role="alert">
                    {validationMessage}
                  </div>
                )} */}
                <input
                  type="button"
                  value="SAVE"
                  className="btn btn-primary"
                  onClick={handleSave}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default DepositAmountModal;
