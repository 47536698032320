import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "../components/admindashboard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Card, Row, Col, Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function Deliveryreports() {
  const [reward, setReward] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [searchOption, setSearchOption] = useState("MONTH");
  const [typeFilter, setTypeFilter] = useState("");
  const [vehicleFilter, setVehicleFilter] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [productFilter, setProductFilter] = useState("");


  const generatePDF = () => {
    setLoading(true);
    const input = document.getElementById("pdf-content");

    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("delivery_reports.pdf");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
        toast.error("Failed to generate PDF");
        setLoading(false);
      });
  };

  const generateTablePDF = () => {
    setLoading(true);
    const input = document.getElementById("pdf-table-content");

    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("delivery_reports_table.pdf");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error generating table PDF:", error);
        toast.error("Failed to generate table PDF");
        setLoading(false);
      });
  };

  const fetchVehicles = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgId = JSON.parse(localStorage.getItem("data")).orgcode;

    try {
      const response = await axios.get(`/api/vehicle/orgid=${orgId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const vehiclesData = response.data.map((vehicle) => ({
        id: vehicle.id,
        truckinfo: vehicle.truckinfo,
        licenceNo: vehicle.licenceNo,
      }));

      setVehicles(vehiclesData);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch vehicles");
    }
  };

  const handleProductChange = (event) => {
    setProductFilter(event.target.value);
  };
  const fetchData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
    setLoader(true);

    try {
      let url = `/api/returntransactions/orgcode=${orgcode}`;
      let params = "";

      if (searchOption === "MONTH" && selectedYear && selectedMonth !== null) {
        params = `/${selectedMonth + 1}/${selectedYear}`;
      } else if (searchOption === "YEAR" && selectedYear) {
        params = `/year=${selectedYear}`;
      }

      if (vehicleFilter) {
        url += `/vehicle=${vehicleFilter}`;
      }

      if (typeFilter) {
        url += `/transactiontype=${typeFilter}`;
      }

      url += params;

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Parse and map response data here
      setReward([...mapResponseData(response.data)]);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch data");
    } finally {
      setLoader(false);
    }
  };
  const fetchProducts = async () => {
    const token = JSON.parse(localStorage.getItem("data"))?.token;

    try {
      const response = await axios.get(`/api/product`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const productsData = response.data.map((product) => ({
        id: product.id,
        productname: product.productname,
      }));

      setProducts(productsData);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch products");
    }
  };
  const mapResponseData = (data) => {
    if (data.product_totals) {
      // Map data for the table
      const productTotals = data.product_totals || {};
      const mappedData = [];

      Object.entries(productTotals).forEach(([day, products]) => {
        Object.entries(products).forEach(([productName, totals]) => {
          mappedData.push({
            day,
            productName,
            ...totals,
          });
        });
      });

      return mappedData;
    }

    if (data.daily_values) {
      // Map data for individual transactions
      return data.daily_values.map(entry => ({
        day: entry.date,
        productName: data.productname || '',
        total: entry[data.transaction_type.toLowerCase()] || 0,
        base: 0, // Placeholder if needed
        additional: 0, // Placeholder if needed
        commission: 0, // Placeholder if needed
        rewardPoints: 0, // Placeholder if needed
        return: 0 // Placeholder if needed
      }));
    }

    return [];
  };

  useEffect(() => {
    fetchVehicles();
  
    fetchProducts(); // Fetch products on component mount
  }, []);

  useEffect(() => {
    if (searchOption === "MONTH" && selectedYear && selectedMonth !== null) {
      fetchData();
    } else if (searchOption === "YEAR" && selectedYear) {
      fetchData();
    }
  }, [typeFilter, searchOption, selectedMonth, selectedYear, vehicleFilter]);

  const handleTypeChange = (event) => {
    setTypeFilter(event.target.value);
  };

  const handleVehicleChange = (event) => {
    setVehicleFilter(event.target.value);
  };

  const handleFilterChange = (event) => {
    const selectedOption = event.target.value;
    setSearchOption(selectedOption);
    setSelectedMonth(null);
    setSelectedYear(null);
  };

  const handleBack = () => {
    navigate("/users/Operationalreport");
  };

  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <br />

      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10">
        <Card>
          <Card.Body>
            <Card.Title>
              Delivery Reports
              <Button
                style={{ marginLeft: "90%" }}
                variant="secondary"
                onClick={handleBack}
              >
                Back
              </Button>
            </Card.Title>
            <br />
            <div className="d-flex align-items-center">
              <div
                className="filter-dropdown"
                style={{ marginRight: "20px" }}
              >
                <label> Type:</label>
                <select value={typeFilter} onChange={handleTypeChange}>
                  <option value="">Select Type</option>
                  <option value="sales">Sales</option>
                  <option value="additional">Additional</option>
                  <option value="new connection">New Connection</option>
                  <option value="online">Online</option>
                </select>
              </div>
              </div>
              <div
                className="filter-dropdown"
                style={{ marginRight: "20px" }}
              >
                <label>Vehicle:</label>
                <select
                  value={vehicleFilter}
                  onChange={handleVehicleChange}
                >
                  <option value="">Select Vehicle</option>
                  {vehicles.map((vehicle) => (
                    <option key={vehicle.id} value={vehicle.id}>
                      {vehicle.truckinfo || vehicle.licenceNo}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="filter-dropdown"
                style={{ marginRight: "20px" }}
              >
                <label>FILTER :</label>
                <select
                  value={searchOption}
                  onChange={handleFilterChange}
                >
                  <option value="MONTH">Month</option>
                  <option value="YEAR">Year</option>
                </select>
              </div>
              <div>
                {searchOption === "MONTH" && (
                  <>
                    <Form.Label>Month:</Form.Label>
                    <DatePicker
                      selected={new Date(
                        selectedYear || new Date().getFullYear(),
                        selectedMonth || new Date().getMonth()
                      )}
                      onChange={(date) => {
                        setSelectedMonth(date?.getMonth());
                        setSelectedYear(date?.getFullYear());
                      }}
                      dateFormat="MMMM"
                      showMonthYearPicker
                    />
                  </>
                )}
                {searchOption === "YEAR" && (
                  <>
                    <Form.Label>Year:</Form.Label>
                    <Form.Control
                      type="number"
                      value={selectedYear || ""}
                      onChange={(e) => setSelectedYear(e.target.value)}
                    />
                  </>
                )}
              </div>
              <div className="form-group">
              <label>Product:</label>
              <select
                className="form-control"
                value={productFilter}
                onChange={handleProductChange}
              >
                <option value="">Select Product</option>
                {products.map((product) => (
                  <option key={product.id} value={product.productname}>
                    {product.productname}
                  </option>
                ))}
              </select>
            </div>
              <br />
              <div id="pdf-content">
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Product Name</th>
                      <th>Total</th>
                      <th>Base</th>
                      <th>Additional</th>
                      <th>Commission</th>
                      <th>Reward Points</th>
                      <th>Return</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reward.map((item, index) => (
                      <tr key={index}>
                        <td>{item.quantity}</td>
                        <td>{item.exempted}</td>
                        <td>{item.neworg}</td>
                        <td>{item.additional}</td>
                        <td>{item.onlinepay}</td>
                        <td>{item.returnedproduct}</td>
                        <td>{item.transfer}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <br />
              <div className="text-center">
                <Button
                  variant="primary"
                  onClick={generatePDF}
                  disabled={loading}
                >
                  {loading ? "Generating PDF..." : "Generate PDF"}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }

export default Deliveryreports;
