import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever } from "react-icons/md";
import RegisterMd from "./registermandd";
import UserProfile from "./userProfile";
import { Pagination, Form, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { FaRegUserCircle } from "react-icons/fa";

function Deliveryagentdetails() {
  const [Deliveryagentdetails, setDeliveryagentdetails] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [userProfileDetails, setUserProfileDetails] = useState("");
  const [searchResults, setSearchResults] = useState([]);
const [searchQuery, setSearchQuery] = useState("");
const [isSearchPerformed, setIsSearchPerformed] = useState(false);
const [currentPage, setCurrentPage] = useState(0);
const [totalPages, setTotalPages] = useState(0);
const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    if (searchTerm) {
      searchData();
    } else {
      getData(currentPage);
    }
  }, [searchTerm, currentPage]);
 
  const searchData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
   

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const searchUrl =`/api/accounts/getAllstaffs/orgid=${orgCode}`;
      const response = await axios.get(searchUrl,  {
        headers: { Authorization: `Bearer ${token}` },
      });

      setDeliveryagentdetails(response.data);
      setLoader(false);

    } catch (error) {
      handleError(error);
    }
  };
  const getData = async (pageNumber = 0) => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
  
      const trans = await axios.get(`/api/accounts/staff/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
        params: { page: pageNumber }, 
        headers: { Authorization: `Bearer ${token}` },
      });
  
      console.log(trans);
  
      setDeliveryagentdetails(trans.data.content);
      setCurrentPage(trans.data.number);
      setTotalPages(trans.data.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };
  function onClickhandler(e, id, disabled) {
    // setLoading(id);
    setLoading(true)
    const checked = e.target.checked;
   console.log(checked);
    const token = JSON.parse(localStorage.getItem("data")).token;
   
    console.log(token);
    axios.post(`/api/accounts/update`, {
      id: id,
      disabled: disabled
    }, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false);
        toast.success(" status updated");
      })
      .catch((error)=>{
        setLoading(false);

        handleError(error);
      })
  }
  const handleregistermd = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();

  };
  const filteredData = Deliveryagentdetails.filter((agent) =>
    (agent.username && agent.username.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (agent.name && agent.name.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  

  const openUserProfileModal = async (userid) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try{
    const bio = await axios.get(
      `/api/account-bio/userid=${userid}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setUserProfileDetails(bio.data);
    navigate(`/users/userProfile/${userid}`);
  }catch(error){
    handleError(error);
  }

  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };


  const handleDeleteAccount = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      await axios.delete(`/api/accounts/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      toast.success("Account Deleted successfully");
      getData();
    } catch (error) {
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } 
    else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

 
  
 
  return (
    <div>
      <div>
        <Dashboard />
      </div>
      <div>
        <div className="col-13  mx-xl-auto ps-xl-10">
          <div className="card card-raised " style={{marginTop:"10%"}}>
            <div className="card-body " >
              <div className="card-title">Staff Details
              <button className="btn btn-primary float-end" onClick={handleregistermd}>
               ADD
            </button>
            </div>
            <div className="row">
      <div style={{ position: "relative", display: "flex", alignItems: "center",  left:"380px" }}>
 
             
      <input
      type='text'
      className='form-control form-control w-450px ps-14'
      placeholder='Search Name or User Name'
      value={searchTerm}
      style={{ width: "210px" }}

      onChange={(e) => setSearchTerm(e.target.value)}
    />
        
       
       
      </div>
      </div>
            <br/>
            <Row>
            {filteredData.map((accd) => (
              
            <Col key={accd.id} md={4} className="mb-3">
<div className="card card-raised h-100" onClick={() => openUserProfileModal(accd.id)} style={{cursor:"pointer"}} title="View Profile">                 <div className="card-body">
<Card>
                <Card.Body>
                <Card.Text className="d-flex justify-content-end">
                          {loading == accd.id && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={!accd.disabled}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              onClickhandler(e, accd.id, !accd.disabled);
                            }}
                            title="Update Status"
                            value={accd.id}
                          />
                         
                    <MdDeleteForever
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteAccount(accd.id);
                      }}
                      title="Delete Account"
                    />
                                           </Card.Text>
                {/* <Card.Text>Id: {accd.id}</Card.Text> */}
             
                <div className="card-title text-center">
        {accd.bio && accd.bio.image ? (
          <img 
            src={`${accd.bio.imageMeta},${accd.bio.image}`} 
            alt="User Profile" 
            style={{ width: '100px', height: '100px', borderRadius: '50%' }} 
          />
        ) : (
          <FaRegUserCircle />
        )}
      </div>
                   <div className="card-title text-center">
 
                <Card.Text>{accd.name}</Card.Text>
                </div>
                  <br/>
                  <Card.Text>Email: {accd.username}</Card.Text>
                  <Card.Text>Contact: {accd.phNo}</Card.Text>
                  {/* <button className="btn btn-link" onClick={() => openUserProfileModal(accd.id)}>
                      View Profile
                    </button> */}
                  <Card.Text className="card-footer bg-light">{accd.role}</Card.Text>
                 
               
                </Card.Body>
              </Card>
              </div>
              </div>
             
              </Col>
             
          ))}
                </Row>
                <div className="d-flex justify-content-center">
                <Pagination>
                  <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                  <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                  {renderPageNumbers()}
                  <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                  <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
                </Pagination>
              </div>
            </div>
          </div>
          <br />
          <RegisterMd isOpen={isModalOpen} onRequestClose={handleCloseModal} />
        </div>
      </div>
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default Deliveryagentdetails;
